import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-devanbuttonrender',
  template: `
  <span *ngIf="label == 'DEVAN'"   (click)="devan('value')"
  class="badge badgeerror">{{ label }}</span>
  <span
    *ngIf="label == 'UNDEVAN'"  (click)="undevan('value')"
    class="badge complete">{{ label }}</span>

  `,
  styles: [
    `
      .badge {
        width: 94px;
        font-size: 12px;
        border-radius: 35px;
        font-weight: 600;
        cursor:pointer;

      }
      .complete {
        color: #000000;
        border: 1px solid #000000;
        cursor:pointer;

      }
      .badgeerror {
        background-color: #000000;
        border: 1px solid #000000;
        cursor:pointer;

      }
    `,
  ],
})
export class DevanButtonrenderComponent implements ICellRendererAngularComp {
  params;
  label: string;
  isDisable: boolean;
  showbutton: boolean = false;

  agInit(params): void {
    this.params = params;
    console.log("devannutton", params);
    // this.label="DEVAN";

    this.params = params;

    if (this.params.data.container_status == 'NAMC ARRIVED' ) {
      this.label = "DEVAN";
    }
    if (this.params.data.container_status == 'USER DEVANNED') {
      this.label = "UNDEVAN";
    }
  

  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        // ...something
      };
      this.params.onClick(params);
    }
  }
  devan(value) {

    this.params.context.componentParent.devan(
      this.params.data
    );

  }
  undevan(value) {
    this.params.context.componentParent.undevan(
      this.params.data
    );

  }
}
