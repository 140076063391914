import { Component } from '@angular/core';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      (keypress)="onKeyPress($event)"
      maxlength="4"
      pattern="[0-9]"
      [(ngModel)]="value"
      [ngClass]="{ 'invalid-text': isEmpty }"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class GridTextEditorComponent {
  /*
	<label *ngIf="!showInput" >{{value}}  </label>
		<input *ngIf="showInput" #input  [(ngModel)]="value" style="width: 100%" (change)="textUpdated()"
		(keydown)="onKeyDown($event)"/>*/
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  onInitVanningFrom = localStorage.getItem('minVanningFrom');
  constructor() {}

  agInit(params: any): void {
    if (params.column === 'man') {
      params.data.manualAdjQtyText = this;
      this.value = params.data.man;
      if ((this.value || this.value?.toString() === '0') && this.value?.toString() !== '-') {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;

    if (
      this.params.data.checkedEditMode &&
      (new Date(this.params.data.vanning_date) > new Date(this.onInitVanningFrom) ||
        (new Date(this.params.data.vanning_date) === new Date(this.onInitVanningFrom) &&
          this.params.data.order_status !== 'F'))
    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'man') {
      this.params.data.man = this.value;

      if ((this.value || this.value?.toString() === '0') && this.value?.toString() !== '-') {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }

  onKeyPress(event) {
    // && (event.target.value.length <= 4)
    const length = event.target.value?.replace('-', '')?.length;

    // ((event.charCode >= 48 && event.charCode <= 57) ||
    // (event.charCode === 45 && event.target.value === '')) &&

    if (length <= 8) {
      return true;
    } else {
      return false;
    }
  }
}
