/* tslint:disable:no-shadowed-variable */

import { environment as dev } from '../../../environments/environment.dev';
import { environment as prod } from '../../../environments/environment.prod';
import { environment as qa } from '../../../environments/environment.qa';
import { environment as local } from '../../../environments/environment';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { logData } from 'src/app/shared/logger';

declare var window: any;

export const environments = {
  default: local,
  dev,
  prod,
  qa,
};

export interface EnvironmentConfigurationSettings {
  [setting: string]: any;
}

export interface EnvironmentConfigurations {
  default: EnvironmentConfigurationSettings;
  [environment: string]: EnvironmentConfigurationSettings;
}

export const Environments = new InjectionToken<EnvironmentConfigurations>(
  'Environments'
);

@Injectable()
export class EnvironmentConfiguration
  implements EnvironmentConfigurationSettings
{
  [setting: string]: any;

  constructor(@Inject(Environments) environments: EnvironmentConfigurations) {
    logData('host ' + window.location.hostname);
    const hostname =
      window.location && window.location.hostname
        ? window.location.hostname.toLowerCase()
        : null;

    let environmentType = environments.default;

    const environmentNames: string[] = Object.keys(environments).filter(
      (envName) => envName !== 'default'
    );
    let selectedEnvName = environmentNames.find((envName) => {
      if (environments[envName] && environments[envName].hostname) {
        return environments[envName].hostname.toLowerCase() === hostname;
      } else {
        return false;
      }
    });

    // Override default behavior by setting window.environment in index.html
    if (window.environment && environments[window.environment]) {
      selectedEnvName = window.environment;
    }

    if (!selectedEnvName) {
      selectedEnvName = 'default';
    } else if (selectedEnvName && environments[selectedEnvName]) {
      environmentType = environments[selectedEnvName];
    }

    environmentType.envName = selectedEnvName;

    logData({ environmentType });

    Object.assign(this, environmentType);
  }
}
