import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MasterRefreshheaderComponent } from '../core/master-refreshheader/master-refreshheader.component';
import { MasterGridrefreshComponent } from '../core/master-gridrefresh/master-gridrefresh.component';
import { GridApi } from 'ag-grid-community';
import { NgxSpinnerService } from 'ngx-spinner';
import { SetupActionsComponent } from '../core/setup-actions/setup-actions.component';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { OspService } from '../core/master-services/osp.service';
import { HttpClient } from '@angular/common/http';
import { MasterEditDialogComponent } from '../core/master-edit-dialog/master-edit-dialog.component';
import { MasterDeleteComponent } from '../core/master-delete/master-delete.component';
import * as moment from 'moment';
import { forkJoin, Subscription } from 'rxjs';
import { GriddataService } from '../services/griddata.service';
import { OSPStateService } from '../services/osp-state.service';
import { checkAccess, resetSortingAndFilters } from '../constants';
import {
  allowedFilesizeInBytes,
  allowedFileTypes,
  dateFilterComparator,
  warningMessage,
  successMessage,
} from '../constants';

@Component({
  selector: 'app-master-setup',
  templateUrl: './master-setup.component.html',
  styleUrls: ['./master-setup.component.scss'],
})
export class MasterSetupComponent implements OnInit, OnDestroy {
  public detailCellRenderer;
  public frameworkComponents;
  public detailRowHeight;
  masterIdList: any = [];
  masterNameList: any = [];
  masterList: any = [];
  m_name: any;
  mid: any;
  mdid: any;
  mname: any;
  mkey: any;
  mdkey: any;
  mdata: any;
  gridColumnApi: any;
  successMessage = '';
  warningMessage = '';
  componentName = 'master';
  user: string;
  userRole: string;
  masterSetupData;
  userName: string;
  masterSubscription$: Subscription;

  public gridApi;

  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  rowData: any = [];
  gridOptions;
  paginationPageSize: any = 10;
  defaultPageSize = 100000;

  pagination = true;
  context: any;
  editType;
  suppressClickEdit = true;
  value = [];
  isEditMode = false;
  public isRowMaster;
  public icons;

  tooltipShowDelay = 0;

  rowCount = 0;
  gridData = [];

  errorMessage = '';

  excel = [];
  business_entity_name: string = '';
  master_id: any = [];
  business_entity: any;
  crossmappingData: any[] = [];
  loadingTxt = 'Loading... Please Wait...';
  userid: any;
  role: any;
  crossmappingSubscription: any;
  closeResult: string = '';
  jsonbody = {};
  offset: any;
  limit: any;
  dynamicHeight: number;
  itContact: string;
  modalComponent = 'modal-component';

  constructor(
    private readonly OspService: OspService,
    private readonly spinner: NgxSpinnerService,
    public readonly dialog: MatDialog,
    private readonly gridDataService: GriddataService,
    private readonly http: HttpClient,
    private readonly stateService: OSPStateService
  ) {
    this.dynamicHeight = 0.57 * window.innerHeight;
    this.context = {
      componentParent: this,
    };

    this.gridApi = GridApi;
  }
  ngOnDestroy(): void {
    this.masterSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    // grid
    this.gridOptions = this.gridDataService.getGridOptions();

    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 50,
        headerComponentFramework: MasterRefreshheaderComponent,
        floatingFilterComponentFramework: MasterGridrefreshComponent,
        floatingFilter: true,
      },
      {
        headerName: 'Master ID',
        field: 'master_id',
        width: 210,
        floatingFilter: true,
      },
      {
        headerName: 'Master Name',
        field: 'name',
        width: 210,
        floatingFilter: true,
        tooltipField: 'name',
      },
      {
        headerName: 'Key Item',
        field: 'key_item',
        width: 210,
        floatingFilter: true,
      },
      {
        headerName: 'Data Item',
        field: 'data_item',
        width: 210,
        floatingFilter: true,
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 250,
        floatingFilter: true,
        tooltipField: 'userid',
      },
      {
        headerName: 'Updated DT',
        field: 'to_char',
        width: 220,
        floatingFilter: true,
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        comparator: this.dateComparator,
      },
      {
        headerName: 'Actions',
        cellRendererFramework: SetupActionsComponent,
        field: 'actions',
        width: 124,
        sortable: false,
        floatingFilter: false,
      },
    ];

    this.defaultColDef = { resizable: true };
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.masterSubscription$ = this.stateService
      .getNamc()
      .subscribe((observable) => {
        this.initializeData();
      });
  }
  access1;
  initializeData() {
    this.spinner.show();

    this.rowData = [];
    this.onResetDropDown();

    this.business_entity = localStorage.getItem('namcvalue');
    this.user = localStorage.getItem('workdayId');
    this.userRole = localStorage.getItem('UserRoles');

    this.business_entity_name = localStorage.getItem('namcSelected') as string;
    const masterPermission = checkAccess();
    this.access1 = masterPermission.mastersetupfunc;

    localStorage.setItem('master', this.access1);
    this.itContact = localStorage.getItem('itContact');

    this.loadid();

    this.userName = localStorage.getItem('UserName');
  }
  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result =
        yearNumber * 10000 +
        monthNumber * 100 +
        dayNumber +
        hoursNumber * 60 +
        minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  //pagination
  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(
        Number(this.paginationPageSize)
      );
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onResetDropDown() {
    this.removeMessage();
    this.master_id = '';
    this.m_name = '';
    this.successMessage = '';
    this.rowData = [];
    this.setPageSizeToAll(this.defaultPageSize);

    if (this.osPartsGrid) {
      this.resetGrid();
    }
  }
  // add master data popup
  addData(): void {
    localStorage.setItem('popupname', 'add');
    this.openDialog();
  }
  onSearch() {
    this.removeMessage();
    this.setPageSizeToAll(this.defaultPageSize);

    if (!this.checkIfAnySelected()) {
      this.warningMessage = warningMessage.selectToSearch;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    if (this.osPartsGrid) {
      this.resetGrid();
    }

    this.loadMasterDetails();
  }
  checkIfAnySelected() {
    if (this.master_id.length > 0) {
      return true;
    }

    return false;
  }
  openDialog() {
    this.removeMessage();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '550px';
    dialogConfig.height = '300px';
    dialogConfig.data = {
      masterData: this.masterList,
      mode: 'add',
    };
    const dialogRef = this.dialog.open(MasterEditDialogComponent, dialogConfig);
    const addGetResponse =
      dialogRef.componentInstance.addMasterEventEmitter.subscribe((res) => {
        this.m_name = [];
        this.master_id = [];
        const mastername = this.masterList.filter((x) => {
          return x.master_id === res.masterid;
        });
        this.m_name.push(mastername[0]);
        this.master_id.push(mastername[0]);

        //this.master_id.push({master_id:res.masterid})
        //this.masterList=this.master_id
        this.OspService.insertMasterDetails(
          this.business_entity,
          this.userRole,
          this.user,
          res.masterid,
          res.keyitem,
          res.dataitem,
          res.mastername,
          this.userName
        ).subscribe({
          error: this.errorCallback,
          next: (response) => {
            const { error, message } = response.body.masteraddResponse;
            this.spinner.hide();

            if (error) {
              this.warningMessage = '';
              this.successMessage = '';

              if (message?.includes('duplicate')) {
                this.warningMessage = warningMessage.duplicateRecord;
              } else {
                this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}`;
              }
            } else {
              this.warningMessage = '';

              this.successMessage = warningMessage.addRecord;
              this.loadMasterDetails();
            }
            window.scroll(0, 0);
          },
        });
      });
    dialogRef.afterClosed().subscribe((res) => {
      addGetResponse.unsubscribe();
    });
  }
  //edit dialog popup
  openeditDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '500px';
    dialogConfig.data = {
      masterData: [
        {
          master_id: this.mid,
          name: this.mname,
        },
      ],
      mkey: this.mkey,
      mdata: this.mdata,
      mode: 'edit',
    };
    const dialogRef = this.dialog.open(MasterEditDialogComponent, dialogConfig);
    const editGetResponse =
      dialogRef.componentInstance.editMasterEventEmitter.subscribe((res) => {
        this.removeMessage();
        this.OspService.updateMasterDetails(
          this.business_entity,
          this.userRole,
          this.user,
          res.master_id,
          res.key_item,
          res.data_item,
          this.mkey,
          this.mdata,
          this.userName
        ).subscribe({
          error: this.errorCallback,
          next: (response) => {
            const { error, message } = response.body.mastereditResponse;
            this.spinner.hide();

            if (error) {
              this.warningMessage = '';
              this.successMessage = '';

              if (message?.includes('duplicate')) {
                this.warningMessage = warningMessage.duplicatesFound;
              } else {
                this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}`;
              }
            } else {
              if (response.body.mastereditResponse == 1) {
                this.warningMessage = '';
                this.successMessage = successMessage.recordUpdated;
                this.loadMasterDetails();
              } else {
                this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
                this.rowData = [];
                this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
                this.master_id = '';
                this.m_name = '';
                this.spinner.hide();
              }
            }
            window.scroll(0, 0);
          },
        });
      });
    dialogRef.afterClosed().subscribe((res) => {
      editGetResponse.unsubscribe();
    });
  }
  //fill masterid,name dropdowns
  setName() {
    // console.log(this.master_id);
    this.m_name = [];
    this.master_id.forEach((element) => {
      const mastername = this.masterList.filter((x) => {
        return x.master_id === element.master_id;
      });
      this.m_name.push(mastername[0]);
    });
  }
  setId() {
    this.master_id = [];
    this.m_name.forEach((element) => {
      const mastername = this.masterList.filter((x) => {
        return x.name === element.name;
      });
      this.master_id.push(mastername[0]);
    });
  }
  //delete dialog popup
  opendeleteDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;

    dialogConfig.width = '390px';
    const dialogRef = this.dialog.open(MasterDeleteComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      this.removeMessage();
      if (res === 'cancel') {
        return;
      }
      if (res === true) {
        return;
      }

      this.OspService.deleteMasterDetails(
        res.business_entity,
        this.userRole,
        this.user,
        this.mdid,
        this.mdkey
      ).subscribe({
        error: this.errorCallback,
        next: (data) => {
          if (data.body.masterdeleteResponse === 1) {
            this.successMessage = successMessage.deleteRecord;
            this.spinner.hide();
            window.scroll(0, 0);
            this.loadMasterDetails();
          } else {
            this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
            this.rowData = [];
            this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
            this.master_id = '';
            this.m_name = '';
            this.spinner.hide();
          }
        },
      });
    });
  }
  userCheckChanged(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    this.mid = rowNode.data.master_id;
    this.mname = rowNode.data.name;
    this.mkey = rowNode.data.key_item.trim();
    this.mdata = rowNode.data.data_item.trim();
    this.openeditDialog();
  }
  userDeleteChanged(rowIndex) {
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    this.mdid = rowNode.data.master_id;
    this.mdkey = rowNode.data.key_item.trim();
    this.opendeleteDialog();
  }
  //Api integration
  loadMasterDetails() {
    // const master_id = this.master_id[0] ? this.master_id[0].master_id: '';bus
    let master_id = '';
    if (this.master_id.length > 0) {
      master_id = this.master_id.map((x) => {
        return x.master_id;
      });
    }

    this.spinner.show();
    this.OspService.getMasterDetails(
      this.business_entity,
      this.userRole,
      this.user,
      master_id
    ).subscribe({
      error: this.errorCallback,
      next: (data) => {
        this.rowData = data.body.masterDetailResponse;
        this.spinner.hide();

        this.setPageSizeToAll();
      },
    });
  }
  //load search grid
  loadid() {
    this.OspService.getMasterId(
      this.business_entity,
      this.userRole,
      this.user
    ).subscribe({
      error: this.errorCallback,
      next: (data) => {
        this.masterSetupData = data.body.getMasterID;

        const records = data.body.masterIDResponse;

        this.masterList = records;

        this.spinner.hide();
      },
    });
  }
  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
    // this.fileName='';
    // this.uploadError = false;
  }
  errorCallback = (error) => {
    console.error(error);
    this.spinner.hide();

    if (error?.error?.message?.required_fields) {
      this.warningMessage = warningMessage.requiredFieldsMissing;
    } else {
      this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
    }
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };
}
