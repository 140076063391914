import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      [(ngModel)]="value"
      [ngClass]="{ 'invalid-text': isEmpty }"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class GridVanDtEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  lastDayOfVanMonth = localStorage.getItem('lastDayOfVanMonth');
  constructor() {}
  agInit(params: any): void {
    if (params.column === 'vanning_date') {
      params.data.rundownVanningDate = this;
      this.value = params.data.vanning_date;
      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;
    var d1 = new Date();
    var d2 = new Date(params.data.calc_date);
    var calcDateFuture = moment(d2).isAfter(d1, 'day');

    var iscurrentDate = moment(d2).isSame(new Date(), 'day');

    if (
      this.params.data.checkedEditMode &&
      (calcDateFuture || iscurrentDate) &&
      params.data.calc_status !== '' &&
      new Date(this.params.data.vanning_date) > new Date(this.lastDayOfVanMonth)
    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'vanning_date') {
      if (this.value !== this.params.data.vanning_date) {
        this.params.data.vanning_date = this.value;
        this.params.data.isVanningDateUpdated = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }
}
