import { Component } from '@angular/core';

@Component({
  selector: 'app-tapconfirmation-devandialog',
  templateUrl: './tapconfirm.component.html',
  styleUrls: ['./tapconfirm.component.scss']
})
export class TapconfirmComponent {

}
