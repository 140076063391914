import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../app/core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { map } from 'rxjs/operators';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class CanDeactivateGuard
  
{
  constructor(public dialog: MatDialog) {}

  canDeactivate(component: CanComponentDeactivate): Observable<boolean> {
    if (component.canDeactivate()) {
      return this.openDailog();
    } else {
      return of(true);
    }
  }

  openDailog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '475px';

    const dialogRef = this.dialog.open(
      ConfirmCancelDailogComponent,
      dialogConfig
    );

    return dialogRef.afterClosed().pipe(
      map((result) => {
        return result === 'cancel';
      })
    );
  }
}
