import { Component, Input, OnInit } from '@angular/core';
import { IAfterGuiAttachedParams, IHeaderGroup } from 'ag-grid-community';

@Component({
  selector: 'app-report-header-checkbox',
  templateUrl: './report-header-checkbox.component.html',
  styleUrls: ['./report-header-checkbox.component.scss']
})
export class ReportHeaderCheckboxComponent implements IHeaderGroup {

  @Input() cell: any;
	// @Output() onClicked = new EventEmitter<boolean>();

	private params: any;
  checked: any;
  displayName:any;
  agInit(params: any): void {
    
    this.params = params;
    this.checked = params.context.componentParent.headerCheckboxChecked;

  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  headerCheckChanged(event) {
    

    this.displayName=event.displayName
    this.params.checked=event.checked
		this.params.context.componentParent.headerCheckChanged(this.params);
	}

  
 }