import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

/**
 * Checkbox component inside ag-grid
 *
 * @export
 * @class CheckboxCellComponent
 * @implements {ICellRendererAngularComp}
 */

@Component({
  selector: 'app-report-checkbox',
  templateUrl: './report-checkbox.component.html',
  styleUrls: ['./report-checkbox.component.scss'],
})
export class ReportCheckboxComponent implements ICellRendererAngularComp {
  //   @ViewChild('.checkbox') checkbox: ElementRef;
  @Input() cell: any;
  public params: any;
  disabled: boolean = false;
  checked: boolean = false;

  agInit(params: any): void {
    this.params = params;
    this.checked = params.data.rowCheckBox;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  /*
   *On checked / unchecked
   *
   * @param {*} event
   * @memberof CheckboxCellComponent
   */
  checkChanged(event) {
    this.params.context.componentParent.userCheckChanged(
      event.checked,
      this.params.node.rowIndex,
      this.params.data.report_type
    );
  }

  constructor() {}
}
