import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, from, EMPTY } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import { MsalService } from './msal.service';
import { Minimatch } from 'minimatch';
import { AuthenticationResult, InteractionType } from '@azure/msal-browser';
import { Injectable, Inject } from '@angular/core';
import { MSAL_INTERCEPTOR_CONFIG } from './constants';
import { MsalInterceptorConfig } from './msal.interceptor.config';
import { OSPStateService } from '../services/osp-state.service';
import { LookupService } from '../services/lookup/lookup.service';

@Injectable()
export class MsalInterceptor implements HttpInterceptor {
  constructor(
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    private readonly msalInterceptorConfig: MsalInterceptorConfig,
    private readonly authService: MsalService,
    private readonly lookupService: LookupService,
    private readonly state: OSPStateService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const scopes = this.getScopesForEndpoint(req.url);
    const account = this.authService.getAllAccounts()[0];

    const d = new Date();

    d.setMinutes(d.getMinutes() + 5);

    const currentTime = d.toISOString();
    const token = localStorage.getItem('Token');
    if (token !== undefined && token !== null) {
      const tokenExpiresAt = new Date(JSON.parse(atob(token.split('.')[1])).exp * 1000).toISOString();

      const tokenExpired = currentTime >= tokenExpiresAt;

      if (!scopes || scopes.length === 0) {
        if (token && token.split('.')[0] && token.split('.')[1] && !tokenExpired) {
          // We need to update user session (last activity time) on every activity
          // Now, HTTP Interceptor will intercept all http requests, including user session request
          // So, if we don't exclude user session request from here, it will keep calling itself

          // Updating user session (last activity time) on every http call (except for user session update request)
          if (req.method === 'POST' && !req.url.includes('userSession')) {
            if (
              !localStorage.getItem('userSessionCooldown') ||
              new Date().toISOString() > localStorage.getItem('userSessionCooldown')
            ) {
              const currentTime = new Date();
              currentTime.setSeconds(currentTime.getSeconds() + 10);

              localStorage.setItem('userSessionCooldown', currentTime.toISOString());

              this.lookupService.updateUserSession('other').subscribe();
            }
          }

          return next.handle(req);
        }
      }
    }

    return this.authService.acquireTokenSilent({ scopes, account }).pipe(
      catchError(() => {
        console.log('acquiretoken error');
        if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
          return this.authService.acquireTokenPopup({
            ...this.msalInterceptorConfig.authRequest,
            scopes,
          });
        }
        const redirectStartPage = window.location.href;
        this.authService.acquireTokenRedirect({
          ...this.msalInterceptorConfig.authRequest,
          scopes,
          redirectStartPage,
        });
        return EMPTY;
      }),
      switchMap((result: AuthenticationResult) => {
        console.log('acquireTokenSilent switch');

        const headers = req.headers.set('authorization', `${result.idToken}`);

        localStorage.setItem('Token', result.idToken);

        if (req.method === 'PUT' && req.url.includes('Content-Type=application%2Foctet-stream')) {
          console.log('endgoal');
          return next.handle(req);
        }

        const requestClone = req.clone({ headers });
        return next.handle(requestClone);
      })
    );
  }

  private getScopesForEndpoint(endpoint: string): Array<string> | null {
    const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
    const keyMatchesEndpointArray = protectedResourcesArray.filter((key) => {
      const minimatch = new Minimatch(key);
      return minimatch.match(endpoint) || endpoint.indexOf(key) > -1;
    });

    // process all protected resources and send the first matched resource
    if (keyMatchesEndpointArray.length > 0) {
      const keyForEndpoint = keyMatchesEndpointArray[0];
      if (keyForEndpoint) {
        return this.msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
      }
    }

    return null;
  }
}
