import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class PartstatusService {
  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        'X-Api-Key': this.environment.partStatusKey,
      }),
    };
  };

  apiUrl = '';
 

  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) {
    this.apiUrl = environment.partStatusUrl;
  }

  getPartList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/partList`,
    // 'http://localhost:3000/partcontList',
      data,
      this.httpCustomOptions()
    );
  }
  getContList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/contList`,
    // 'http://localhost:3000/partcontList',
      data,
      this.httpCustomOptions()
    );
  }



  
  getStatusCount(data): Observable<any> {
    return this.http.post<any>(
     `${this.apiUrl}/count`,
    // 'http://localhost:3000/count',
      data,
      this.httpCustomOptions()
    );
  }

  getNamcArrivedData(data):Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/selectedpopup`,
    // 'http://localhost:3000/selectedpopup',

      data,
      this.httpCustomOptions()
    );}

    getInquiryData(data):Observable<any> {
      return this.http.post<any>(
        `${this.apiUrl}/inquirydata`,

        data,
        this.httpCustomOptions()
      );
  
  }

  inventoryReport(data): Observable<any>{
    return this.http.post<any>(
      `${this.apiUrl}/getInventoryReport`,
      //  `http://localhost:3000/status2`,
      data,
      this.httpCustomOptions()
    );

  }


 
}
