import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { ExcelService } from '../services/excel.service';
import { OSPStateService } from '../services/osp-state.service';
import { PartstmaintenanceService } from '../services/orderforecast/parts-maintenance/partsmaintenance.service';

import { RowSelectedEvent } from 'ag-grid-community';
import * as mm from 'moment-timezone';
import { PartstatusService } from '../services/part-status/partstatus.service';

import { forkJoin, Subscription } from 'rxjs';
import { checkAccessPipe, dateFilterComparator, resetSortingAndFilters, warningMessage } from '../constants';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

import { logData, logErrors } from '../shared/logger';
import { InquiryCommentsEditorComponent } from './inquirycomments-component';
import { PipeLookupService } from '../services/pipe-lookup/pipe-lookup.service';
import { Router } from '@angular/router';
import { log } from 'console';

@Component({
  selector: 'app-partsinquire',
  templateUrl: './partsinquire.component.html',
  styleUrls: ['./partsinquire.component.scss'],
})
export class PartsinquireComponent implements OnInit, OnDestroy {
  showDownloadUploadButtons: boolean = true;

  namcValue = localStorage.getItem('namcvalue');
  @ViewChild('osPartsGrid') osPartsGrid;

  count = 0;
  rowData = [];
  rowData2;
  columnDefsMaster;
  columnDefs;
  groupRowsSticky;
  user = '';
  userRole = '';
  rowGroupingDisplayType = 'custom';
  groupDefaultExpanded = 1;
  suppressGroupRowsSticky: boolean = true;
  partsinquireSubscription$: Subscription;
  disablePartNumber: boolean;
  disableKanban: boolean;
  vanningDT: any;
  inventoryRes: any = [];

  animateRows = true;
  showOpenedGroup = false;

  public defaultColDef;
  rowSelection;
  rowGroupPanelShow;
  groupSelectsChildren;
  suppressRowClickSelection;
  suppressAggFuncInHeader;
  public isChecked = false;

  groupDisplayType = 'groupRows';
  autoGroupColumnDef;
  getDataPath;

  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };

  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedrenban: any = [];
  seletedModuleCode: any = [];
  seletedModule: any = [];
  seletedStatus: any = [];
  selectedContainer: any = [];
  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;

  kanbanListSelected = [];
  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  renbanList: any = [];
  moduleCodeList: any = [];
  moduleList: any = [];
  statusList: any = [];
  containerList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;
  renbanFrom: any;
  renbanTo: any;

  showHideList: any = [
    {
      label: 'Renban',
      value: 'container_renban',
    },
    {
      label: 'Kanban',
      value: 'kanban',
    },
    {
      label: 'Part No',
      value: 'part_number',
    },
    {
      label: 'Module',
      value: 'module_number',
    },

    {
      label: 'Renban Status',
      value: 'container_status',
    },
    {
      label: 'Mode Of Freight',
      value: 'mode_of_freight',
    },

    {
      label: 'Container No',
      value: 'container_number',
    },
    {
      label: 'Van Comp DT',
      value: 'tmc_van_completion_date',
    },
    {
      label: 'Vess Sail DT',
      value: 'tmc_port_departure',
    },

    {
      label: 'Est Arr DT',
      value: 'estimated_namc_arrival',
    },
    {
      label: 'Act Arr DT',
      value: 'actual_namc_arrival',
    },
    {
      label: 'Devan DT',
      value: 'devan_date',
    },

    {
      label: 'Order Code',
      value: 'order_code',
    },
    {
      label: 'Part Status',
      value: 'part_status',
    },

    {
      label: 'Part Description',
      value: 'part_description',
    },

    {
      label: 'Supplier',
      value: 'supplier_code',
    },
    {
      label: 'PCS Per Module',
      value: 'mod_qty_received',
    },
    // {
    //   label: 'QPC',
    //   value: 'qpc',
    // },

    {
      label: 'PCS Per Renban',
      value: 'quantity',
    },
    // {
    //   label: 'Boxes Per Module',
    //   value: 'boxes_per_module',
    // },
    // {
    //   label: 'Boxes Per Renban',
    //   value: 'boxes_per_renban',
    // },

    {
      label: 'Tap QTY',
      value: 'tapped_qty',
    },
    { value: 'tap_datetime', label: 'Tap DT' },

    {
      label: 'Tap History',
      value: 'tap_history',
    },
    {
      label: 'User',
      value: 'updated_userid',
    },
    {
      label: 'Updated DT',
      value: 'updated_date_time',
    },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;

  page = 0;
  // apiDefaultLimit = 8000;
  commentsList: any = [];

  uploadError: boolean;
  uploadEnabled: boolean;
  namcName: string;
  files: any[];
  fileBuffer: any;

  exportData: any;

  uploading: boolean = false;

  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  batchRunLogID: any;
  headerHeight: number;
  loading: any;

  headerCheckboxChecked: boolean = false;
  allowedFilesize: number;
  onInitVanningFrom: any;
  onInitVanningTo: any;
  justFileName: any;
  validating: boolean;
  itContact: string;
  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;
  renbanSearchValue: any;
  renbanRangeSearchValue: any;
  renbanFromSearchValue: any;
  renbanValue: any;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly pipeLookupService: PipeLookupService,
    private readonly partstatusService: PartstatusService,
    private readonly stateService: OSPStateService,
    private readonly router: Router,
    public dialog: MatDialog,
    private readonly partstmaintenanceService: PartstmaintenanceService
  ) {
    this.context = {
      componentParent: this,
    };
  }

  fileName: string = '';
  access1;
  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }
  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }
  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  ngOnDestroy(): void {
    this.partsinquireSubscription$.unsubscribe();
  }
  ngOnInit(): void {
    const access = checkAccessPipe();
    this.access1 = access.access;

    // normal grid code added
    this.columnDefsMaster = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'Renban',
        headerTooltip: 'Renban',
        field: 'container_renban',
        sortable: true,
        width: 100,

        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'Kanban',
        headerTooltip: 'Kanban',
        field: 'kanban',
        sortable: true,
        width: 90,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'Part No',
        headerTooltip: 'Part Number',
        field: 'part_number',
        sortable: true,
        width: 130,
        tooltipField: 'order_code',
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'Module',
        headerTooltip: 'Module',
        field: 'module_number',
        sortable: true,
        width: 90,
        floatingFilter: true,
        pinned: 'left',
      },
      {
        headerName: 'Renban Status',
        field: 'container_status',
        headerTooltip: 'Renban Status',
        sortable: true,

        tooltipField: 'container_status',
        width: 130,
        floatingFilter: true,
      },
      {
        headerName: 'Mode Of Freight',
        field: 'mode_of_freight',
        headerTooltip: 'Mode Of Freight',
        width: 120,
        sortable: true,
        floatingFilter: true,
        valueGetter: function (params) {
          if (params.data) {
            if (params.data.mode_of_freight === '1') {
              params.data.mode_of_freight = 'Air';
            } else if (params.data.mode_of_freight === '0') {
              params.data.mode_of_freight = 'Vessel';
            }
            return params.data.mode_of_freight;
          }
        },
        hide: true,
      },

      {
        headerName: 'Container No',
        headerTooltip: 'Container Number',
        field: 'container_number',
        sortable: true,
        width: 130,
        floatingFilter: true,
        tooltipField: 'container_number',
      },
      {
        headerName: 'Van Comp DT',
        field: 'tmc_van_completion_date',
        headerTooltip: 'Vanning  Completion Date(mm/dd/yyyy)',
        sortable: true,
        width: 130,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },
      {
        headerName: 'Vess Sail DT',
        headerTooltip: 'Vessel Sail Date(mm/dd/yyyy)',
        field: 'tmc_port_departure',
        sortable: true,
        width: 130,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },
      {
        headerName: 'Est Arr DT',
        headerTooltip: 'Estimated Arrival Date(mm/dd/yyyy)',
        field: 'estimated_namc_arrival',
        sortable: true,
        width: 130,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },
      {
        headerName: 'Act Arr DT',
        headerTooltip: 'Actual Arrival Date(mm/dd/yyyy)',
        field: 'actual_namc_arrival',
        sortable: true,
        width: 130,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },

      {
        headerName: 'Devan DT',
        headerTooltip: 'Devan Date(mm/dd/yyyy)',
        field: 'devan_date',
        sortable: true,
        width: 130,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },
      {
        headerName: 'Order Code',
        headerTooltip: 'Order Code',

        field: 'order_code',
        sortable: true,
        width: 130,
        tooltipField: 'order_code',
        floatingFilter: true,
      },

      {
        headerName: 'Part Status',
        headerTooltip: 'Part Status',
        field: 'part_status',
        sortable: true,
        width: 130,
        floatingFilter: true,
        tooltipField: 'part_status',
      },

      {
        headerName: 'Part Description',
        headerTooltip: 'Part Description',
        field: 'part_description',
        sortable: true,
        width: 150,
        tooltipField: 'part_description',
        floatingFilter: true,
      },

      {
        headerName: 'Supplier',
        headerTooltip: 'Supplier Code',
        field: 'supplier_code',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'PCS Per Module',
        headerTooltip: 'Pieces Per Module',
        field: 'mod_qty_received',
        sortable: true,
        width: 120,
        floatingFilter: true,
      },
      // {
      //   headerName: 'QPC',
      //   field: 'qpc',
      //   width: 90,
      //   floatingFilter: true,
      //   headerTooltip: 'Quantity Per Container',
      // },

      {
        headerName: 'PCS Per Renban',
        headerTooltip: 'Pieces Per Renban',
        field: 'quantity',
        sortable: true,
        width: 120,
        floatingFilter: true,
      },
      // {
      //   headerName: 'Boxes Per Module',
      //   headerTooltip: 'Boxes Per Module',
      //   field: 'boxes_per_module',
      //   sortable: true,
      //   width: 120,
      //   floatingFilter: true,

      // },
      // {
      //   headerName: 'Boxes Per Renban',
      //   headerTooltip: 'Boxes Per Renban',
      //   field: 'boxes_per_renban',
      //   sortable: true,
      //   width: 120,
      //   floatingFilter: true,

      // },

      {
        headerName: 'Tap QTY',
        headerTooltip: 'Tap Quantity',
        field: 'tapped_qty',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'Tap DT',
        headerTooltip: 'Tap Date(mm/dd/yyyy)',
        field: 'tap_datetime',
        sortable: true,
        width: 140,
        floatingFilter: true,
        enableFilter: true,
        headerClass: 'ag-theme-custom-text-right',
        filter: 'agDateColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          comparator: dateFilterComparator,
        },

        comparator: this.dateComparator,
      },

      {
        headerName: 'Tap History',
        headerTooltip: 'Tap History',
        field: 'tap_history',
        cellRendererFramework: InquiryCommentsEditorComponent,
        cellRendererParams: { column: 'tap_history' },
        tooltipField: 'tap_history',
        tooltipComponent: 'customTooltip',
        valueGetter: (params) => {
          return params.data.tap_history;
        },

        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'User',
        field: 'updated_userid',
        headerTooltip: 'User',

        tooltipField: 'updated_userid',

        valueGetter: function (params) {
          if (params.data) {
            if (params.data.updated_userid.startsWith('T_')) {
              return params.data.updated_userid.slice(2);
            } else {
              return params.data.updated_userid;
            }
          }
        },

        sortable: true,
        width: 130,
        floatingFilter: true,
      },
      {
        headerName: 'Updated DT',
        headerTooltip: 'Updated Date (mm/dd/yyyy hh:mm:ss (Eastern))',
        field: 'updated_date_time',
        tooltipField: 'updated_date_time',
        sortable: true,
        width: 150,
        floatingFilter: true,
      },
    ];
    this.headerHeight = 45;

    this.setPageSizeToAll();
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.columnDefs = [...this.columnDefsMaster];

    this.partsinquireSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  initializeData() {
    this.spinner.show();
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    this.loadDrop();
    this.onResetDropDown();
  }
  checkIfAnySelected() {
    if (this.kanbanSearchValue) return true;
    if (this.renbanSearchValue) return true;
    if (this.renbanRangeSearchValue) return true;
    if (this.renbanFromSearchValue) return true;

    if (
      this.selectedKanban.length > 0 ||
      this.selectedPartNo.length > 0 ||
      this.seletedModule.length > 0 ||
      this.selectedContainer.length > 0 ||
      this.seletedModuleCode.length > 0 ||
      this.seletedStatus.length > 0 ||
      this.selectedrenban.length > 0 ||
      this.vanningDT
    ) {
      return true;
    }

    return false;
  }

  clearKanbanValue() {
    this.kanbanSearchValue = null;
  }
  clearRenbanValue() {
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null;
  }

  handleKanbanSearch(event) {
    this.renbanSearchValue = null;
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null;

    this.kanbanSearchValue = this.kanbanSearchValue.trim();
    const value = this.kanbanSearchValue;

    this.rowData = [];

    const searchedKanban = this.kanbanList.find((kanban) => kanban.kanban?.toLowerCase() === value.toLowerCase());

    if (!searchedKanban || searchedKanban.kanban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidKanbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.seletedModuleCode = [];
    this.seletedModule = [];
    this.seletedStatus = [];

    this.selectedContainer = [];
    this.selectedKanban = [searchedKanban];
    this.disablePartNumber = true;
    this.vanningDT = undefined;

    this.onSearch(0);
  }
  handleRenbanSearch(event) {
    this.kanbanSearchValue = null;
    this.renbanRangeSearchValue = null;

    this.renbanSearchValue = this.renbanSearchValue.trim().toUpperCase();
    const value = this.renbanSearchValue;

    this.rowData = [];

    const searchedRenban = this.renbanList.find(
      (renban) => renban.container_renban?.toLowerCase() === value.toLowerCase()
    );

    if (!searchedRenban || searchedRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.seletedModuleCode = [];
    this.seletedModule = [];
    this.seletedStatus = [];

    this.selectedContainer = [];
    this.selectedrenban = [searchedRenban];
    this.vanningDT = undefined;

    this.onSearch(0);
  }

  handleRenbanFromSearch(event) {
    this.selectedrenban = [];
    this.renbanRangeSearchValue = null;
    this.renbanSearchValue = null;

    this.kanbanSearchValue = null;
    this.vanningDT = undefined;
    this.renbanFromSearchValue = this.renbanFromSearchValue.trim().toUpperCase();

    const renbanFromSearchValue = this.renbanFromSearchValue;

    const renbanRangeSearchValueSplit = renbanFromSearchValue.split('-');
    this.renbanFrom = renbanRangeSearchValueSplit[0];
    this.renbanTo = this.renbanList[this.renbanList.length - 1].container_renban;

    this.rowData = [];

    const searchedFromRenban = this.renbanList.filter(
      (renban) => renban.container_renban?.toLowerCase() === this.renbanFrom.toLowerCase()
    );

    if (!searchedFromRenban || searchedFromRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.seletedModuleCode = [];
    this.seletedModule = [];
    this.seletedStatus = [];

    this.selectedContainer = [];
    this.selectedrenban = [];

    this.onSearch(0);
  }

  handleRenbanRangeSearch(event) {
    this.selectedrenban = [];

    this.renbanSearchValue = null;

    this.kanbanSearchValue = null;
    this.vanningDT = undefined;
    this.renbanRangeSearchValue = this.renbanRangeSearchValue.trim().toUpperCase();

    const renbanRangeSearchValue = this.renbanRangeSearchValue;
    if (renbanRangeSearchValue.includes('-')) {
      const renbanRangeSearchValueSplit = renbanRangeSearchValue.split('-');
      this.renbanFrom = renbanRangeSearchValueSplit[0];
      this.renbanTo = renbanRangeSearchValueSplit[1];
    }

    const greaterRenban = this.findGreaterElement(this.renbanFrom, this.renbanTo);
    console.log(greaterRenban, 'greater');

    if (greaterRenban === this.renbanFrom) {
      this.warningMessage = 'Please enter valid range.';
      return false;
    }

    this.rowData = [];

    const searchedRangeRenban = this.checkIfExist([this.renbanFrom, this.renbanTo]);

    if (!searchedRangeRenban || searchedRangeRenban.container_renban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidRenbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.seletedModuleCode = [];
    this.seletedModule = [];
    this.seletedStatus = [];

    this.selectedContainer = [];
    this.selectedrenban = [];

    this.onSearch(0);
  }

  checkIfExist(inputValues) {
    // Check if any input value exists in the data array (case-insensitive)
    const uppercaseInputValues = inputValues.map((value) => value.toUpperCase());

    // Check if all input values exist in the data array (case-insensitive)
    return uppercaseInputValues.every((input) =>
      this.renbanList.some((item) => item.container_renban.toUpperCase() === input)
    );
  }
  renbanSearch(event) {
    this.selectedrenban = [];

    this.renbanValue = this.renbanValue.trim().toUpperCase();

    this.omit_special_char(event);
    const onlyHyphens = this.containsOnlyHyphens(this.renbanValue);
    if (onlyHyphens) {
      this.warningMessage = 'Invalid Format, Format should be X, X-Y, X-';
      return false;
    }

    const lastChar = this.renbanValue.substring(this.renbanValue.length - 1);
    if (lastChar === '-' && this.renbanValue) {
      this.renbanFromSearchValue = this.renbanValue;
      this.handleRenbanFromSearch(event);
      return false;
    }

    if (this.renbanValue.includes('-')) {
      this.renbanRangeSearchValue = this.renbanValue;
      this.handleRenbanRangeSearch(event);
      return false;
    }
    if (!this.renbanValue.includes('-')) {
      this.renbanSearchValue = this.renbanValue;
      this.handleRenbanSearch(event);
      return false;
    }
  }

  omit_special_char(event) {
    var k;
    k = event.charCode;
    if (k == 45) {
      return true;
    }

    return (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57);
  }

  containsOnlyHyphens(str: string): boolean {
    return /^-+$/.test(str);
  }

  findGreaterElement(str1: string, str2: string): string {
    const alpha1 = str1.match(/[a-zA-Z]+/)[0];
    const alpha2 = str2.match(/[a-zA-Z]+/)[0];

    if (alpha1 !== alpha2) {
      return alpha1 > alpha2 ? str1 : str2;
    } else {
      const num1 = parseInt(str1.match(/\d+/)[0], 10);
      const num2 = parseInt(str2.match(/\d+/)[0], 10);
      return num1 > num2 ? str1 : str2;
    }
  }

  inventoryReport() {
    this.spinner.show();
    //  inventoryReportHeader =
    //['BUSINESS ENTITY', 'CONT. RENBAN', 'CONT. STATUS', 'TAP STATUS', 'VAN DATE', 'PORT DEP.', 'ACT.ARR'];

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    this.partstatusService.inventoryReport(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body.inventoryreportData.data.length > 0) {
          this.inventoryRes = res.body.inventoryreportData.data;

          this.generateReport(this.inventoryRes);
        } else {
          this.warningMessage = 'File is empty.';
          window.scroll(0, 0);
          this.spinner.hide();
          return false;
        }
      },
    });
  }

  generateReport(Response) {
    console.log(Response);
    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));

    if (Response.length === 0) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        const exportData: any = [];

        Response.forEach((e) => {
          const obj = {
            businessEntity: e.business_entity,
            contRenban: e.container_renban,
            conststatus: e.container_status,
            tapstatus: e.tap_status,
            vandate: e.tmc_van_completion_date,
            portdep: e.tmc_port_departure,
            actarr: e.actual_namc_arrival_user,
          };
          exportData.push(obj);
        });

        const headers = [
          'BUSINESS ENTITY',
          'CONT. RENBAN',
          'CONT. STATUS',
          'TAP STATUS',
          'VAN DATE',
          'PORT DEP.',
          'ACT.ARR',
        ];

        const timestamp = easternCurrentDate

          .split('.')[0]
          .replace('T', '_')
          .split('')

          .filter((d) => d !== '-' && d !== ':')
          .join('');

        this.excelService.exportAsExcelFile(
          exportData,
          'InventoryReport_' + timestamp,

          headers
        );

        this.spinner.hide();
      }, 1000);
    }
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onSearch(offset) {
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();
    if (this.renbanSearchValue && this.kanbanSearchValue && this.renbanFromSearchValue && this.renbanRangeSearchValue) {
      this.kanbanSearchValue = null;
      this.renbanSearchValue = null;
      this.renbanFromSearchValue = null;
      this.renbanFromSearchValue = null;
      this.renbanValue = null;
      this.renbanFrom = null;
      this.renbanTo = null;

      return;
    }
    if (this.renbanSearchValue) {
      this.kanbanSearchValue = null;
      this.renbanSearchValue = this.renbanSearchValue.trim();
      const value = this.renbanSearchValue;

      this.rowData = [];

      const searchedRenban = this.renbanList.find(
        (renban) => renban.container_renban?.toLowerCase() === value.toLowerCase()
      );

      if (!searchedRenban || searchedRenban.container_renban === 'ALL') {
        this.onResetDropDown();
        this.warningMessage = warningMessage.invalidRenbanSearch;
        return;
      }

      this.selectedKanban = [];
      this.selectedPartNo = [];
      this.selectedrenban = [];
      this.seletedModuleCode = [];
      this.seletedModule = [];
      this.seletedStatus = [];

      this.selectedContainer = [];
      this.selectedrenban = [searchedRenban];

      // this.onSearch(0);
    }
    if (this.kanbanSearchValue) {
      this.renbanSearchValue = null;
      this.renbanValue = null;
      this.renbanFrom = null;
      this.renbanTo = null;

      this.kanbanSearchValue = this.kanbanSearchValue.trim();
      const value = this.kanbanSearchValue;

      this.rowData = [];

      const searchedKanban = this.kanbanList.find((kanban) => kanban.kanban?.toLowerCase() === value.toLowerCase());

      if (!searchedKanban || searchedKanban.kanban === 'ALL') {
        this.onResetDropDown();
        this.warningMessage = warningMessage.invalidKanbanSearch;
        return;
      }

      this.selectedKanban = [];
      this.selectedPartNo = [];
      this.selectedrenban = [];
      this.seletedModuleCode = [];
      this.seletedModule = [];
      this.seletedStatus = [];

      this.selectedContainer = [];
      this.selectedKanban = [searchedKanban];

      // this.onSearch(0);
    }

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectToSearch;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    let kanban;
    let selectedPartNo;
    let selectedrenban;
    let selectedContainer;
    let seletedModuleCode;
    let seletedModule;
    let seletedStatus;

    if (this.selectedKanban.length > 0) {
      if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        kanban = [];
        this.selectedKanban.forEach((element) => {
          kanban.push(element.kanban);
        });
      }
    }

    if (this.selectedPartNo.length > 0) {
      if (!this.selectedPartNo.some((a) => a.part_number === 'ALL')) {
        selectedPartNo = [];
        this.selectedPartNo.forEach((element) => {
          selectedPartNo.push(element.part_number);
        });
      }
    }

    if (this.selectedrenban.length > 0) {
      if (!this.selectedrenban.some((a) => a.container_renban === 'ALL')) {
        selectedrenban = [];
        this.selectedrenban.forEach((element) => {
          selectedrenban.push(element.container_renban);
        });
      }
    }

    if (this.selectedContainer.length > 0) {
      if (!this.selectedContainer.some((a) => a.cc === 'ALL')) {
        selectedContainer = [];
        this.selectedContainer.forEach((element) => {
          selectedContainer.push(element.cc);
        });
      }
    }

    if (this.seletedModuleCode.length > 0) {
      if (!this.seletedModuleCode.some((a) => a.modulecode === 'ALL')) {
        seletedModuleCode = [];
        this.seletedModuleCode.forEach((element) => {
          seletedModuleCode.push(element.modulecode);
        });
      }
    }

    if (this.seletedModule.length > 0) {
      if (!this.seletedModule.some((a) => a.module_number === 'ALL')) {
        seletedModule = [];
        this.seletedModule.forEach((element) => {
          seletedModule.push(element.module_number);
        });
      }
    }

    if (this.seletedStatus.length > 0) {
      if (!this.seletedStatus.some((a) => a.container_status === 'ALL')) {
        seletedStatus = [];
        this.seletedStatus.forEach((element) => {
          seletedStatus.push(element.container_status);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 6500,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      kanban: kanban,
      part_number: selectedPartNo,
      renban: selectedrenban,
      cc: selectedContainer,
      module: seletedModule,
      module_code: seletedModuleCode,
      status: seletedStatus,
      renbanFrom: this.renbanFrom,
      renbanTo: this.renbanTo,
      tmc_van_completion_date: this.vanningDT,
    };
    this.partstatusService.getInquiryData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.pipeinquiryResponse) {
          this.rowData.push(...res.body.pipeinquiryResponse.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.pipeinquiryResponse.rowCount);
          const records = this.dataCount - (this.offset + 1) * 6500;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 6500);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    const dataShowHide = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.pipeLookupService.getModuleCodeList(data),
      this.pipeLookupService.getKanbanList(data),
      this.pipeLookupService.getPartNoList(data),

      this.pipeLookupService.getStatuList(data),
      this.pipeLookupService.getCcList(data),
      this.pipeLookupService.getRenbanList(data),
      this.pipeLookupService.getModuleList(data),
      this.pipeLookupService.getUserShowHideColumns(dataShowHide),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanbanList, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.moduleCodeList = [];
        this.moduleList = [];
        this.statusList = [];
        this.renbanList = [];
        this.containerList = [];

        if (res[1].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.kanbanList.push(...res[1].body.data);

        // if item id list, add ALL option
        if (res[2].body.data) {
          this.partNoList.push({ part_number: 'ALL' });
        }

        this.partNoList.push(...res[2].body.data);

        if (res[3].body.data) {
          this.statusList.push({ container_status: 'ALL' });
        }

        this.statusList.push(...res[3].body.data);

        if (res[0].body.data) {
          this.moduleCodeList.push({ modulecode: 'ALL' });
        }
        this.moduleCodeList.push(...res[0].body.data);

        if (res[5].body.data) {
          this.renbanList.push({ container_renban: 'ALL' });
        }
        this.renbanList.push(...res[5].body.data);

        if (res[6].body.data) {
          this.moduleList.push({ module_number: 'ALL' });
        }
        this.moduleList.push(...res[6].body.data);
        if (res[4].body.data) {
          this.containerList.push({ cc: 'ALL' });
        }
        this.containerList.push(...res[4].body.data);
        this.containerList = this.containerList.filter(
          (value, index, self) => index === self.findIndex((t) => t.cc === value.cc)
        );

        // batch status check
        const { parts_inquire_columns } = res[7].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(parts_inquire_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedrenban = [];
    this.seletedModuleCode = [];
    this.seletedModule = [];
    this.seletedStatus = [];

    this.selectedContainer = [];
    this.kanbanSearchValue = null;
    this.renbanSearchValue = null;
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null;
    this.vanningDT = null;

    this.kanbanSearchValue = null;
    this.renbanFromSearchValue = null;
    this.renbanRangeSearchValue = null;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disablePartNumber = false;

    this.disableKanban = false;
    this.offset = 0;
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  userCheckChanged(checked, rowIndex) {
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }

  headerCheckChanged(event) {
    console.log('headercheckchanged', event);

    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;

    this.osPartsGrid.gridOptions.api.forEachLeafNode(function (rowNode, index) {
      if (headerChecked) {
        rowNode.data.rowCheckBox = true;
        rowNode.setSelected(true);
      } else {
        rowNode.setSelected(false);
        rowNode.data.rowCheckBox = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  onRowSelected(event: RowSelectedEvent) {
    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(event.rowIndex);
    const rowNodeIndex = event.rowIndex;
    const rowData = this.osPartsGrid.gridOptions.api.getSelectedRows();
    // console.log("api----", event.node.isSelected());
    // console.log("rowData", rowData);

    // if(event.node.isSelected())
    // {
    //   // rowNode.setSelected(true);
    //   rowData.rowCheckBox = true;
    // }
    // else{
    //   // rowNode.setSelected(false);
    //   rowData.rowCheckBox = false;
    // }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowNodeIndex) {
        const color = event.node.isSelected() ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(event.node.isSelected());
    this.spinner.hide();
  }

  parts_inquire_columns;

  saveShowHide() {
    this.spinner.show();

    let parts_inquire_columns = this.parts_inquire_columns;
    const data = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    if (parts_inquire_columns) {
      this.pipeLookupService.updateUserShowHideColumns(data, { parts_inquire_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) return;

    this.parts_inquire_columns = hideItems;
    if (!hideItems.length) this.parts_inquire_columns = [{}];
  }
  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        const indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (this.selectedPartNo[this.selectedPartNo.length - 1].part_number === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;
        this.renbanSearchValue = null;
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;
        this.renbanSearchValue = null;
        let indexAll = this.selectedPartNo.findIndex((data) => data.part_number === 'ALL');

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
  }

  onRenbanChange(): void {
    if (this.selectedrenban.length >= 1) {
      if (this.selectedrenban[this.selectedrenban.length - 1].container_renban === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;
        this.renbanSearchValue = null;
        this.selectedrenban = [];
        this.selectedrenban.push(this.renbanList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;
        this.renbanSearchValue = null;
        let indexAll = this.selectedrenban.findIndex((data) => data.container_renban === 'ALL');

        if (indexAll > -1) {
          this.selectedrenban.splice(indexAll, 1);
        }
      }
    }
  }
  onStatusChange(): void {
    if (this.seletedStatus.length >= 1) {
      if (this.seletedStatus[this.seletedStatus.length - 1].container_status === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.seletedStatus = [];
        this.seletedStatus.push(this.statusList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        let indexAll = this.seletedStatus.findIndex((data) => data.container_status === 'ALL');

        if (indexAll > -1) {
          this.seletedStatus.splice(indexAll, 1);
        }
      }
    }
  }
  onModuleCodeChange(): void {
    if (this.seletedModuleCode.length >= 1) {
      if (this.seletedModuleCode[this.seletedModuleCode.length - 1].modulecode === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.seletedModuleCode = [];
        this.seletedModuleCode.push(this.moduleCodeList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        let indexAll = this.seletedModuleCode.findIndex((data) => data.modulecode === 'ALL');

        if (indexAll > -1) {
          this.seletedModuleCode.splice(indexAll, 1);
        }
      }
    }
  }

  onModuleChange(): void {
    if (this.seletedModule.length >= 1) {
      if (this.seletedModule[this.seletedModule.length - 1].module_number === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.seletedModule = [];
        this.seletedModule.push(this.moduleList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        let indexAll = this.seletedModule.findIndex((data) => data.module_number === 'ALL');

        if (indexAll > -1) {
          this.seletedModule.splice(indexAll, 1);
        }
      }
    }
  }

  onContainerChange(): void {
    if (this.selectedContainer.length >= 1) {
      if (this.selectedContainer[this.selectedContainer.length - 1].cc === 'ALL') {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        this.selectedContainer = [];
        this.selectedContainer.push(this.containerList[0]);
      } else {
        this.kanbanSearchValue = null;
        this.renbanSearchValue = null;
        this.renbanValue = null;
        this.renbanFrom = null;
        this.renbanTo = null;

        let indexAll = this.selectedContainer.findIndex((data) => data.cc === 'ALL');

        if (indexAll > -1) {
          this.selectedContainer.splice(indexAll, 1);
        }
      }
    }
  }

  onVanDtChange() {
    this.renbanSearchValue = null;
    this.kanbanSearchValue = null;
    this.renbanValue = null;
    this.renbanFrom = null;
    this.renbanTo = null;
  }

  getModifiedRowData(): any {
    const selectedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return selectedRowData;
  }

  // download for normal grid
  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api

    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }
    console.log('data1----', data);

    setTimeout(async () => {
      if (data.length < 1) {
        this.spinner.hide();
        this.warningMessage = warningMessage.noRowsSelected;

        window.scroll(0, 0);
      } else {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            if (e.tap_history_old) {
              e.tap_history_old = e.tap_history_old.replaceAll('$', ' ');
            }
            //  e.quantity1 = e.mod_qty_received + '/' + e.quantity;

            const obj = {
              container_renban: e.container_renban,
              kanban: e.kanban,
              part_number: e.part_number,
              module_number: e.module_number,
              container_status: e.container_status,
              mode_of_freight:
                e.mode_of_freight === '0' ? 'Vessel' : e.mode_of_freight === '1' ? 'Air' : e.mode_of_freight,
              container_number: e.container_number,
              tmc_van_completion_date: e.tmc_van_completion_date,
              saildate: e.tmc_port_departure,
              estimated_namc_arrival: e.estimated_namc_arrival,
              actual_namc_arrival: e.actual_namc_arrival,
              devan_date: e.devan_date,
              order_code: e.order_code,
              part_status: e.part_status,
              part_description: e.part_description,
              supplier_code: e.supplier_code,
              mod_qty_received: e.mod_qty_received,
              // qpc: e.qpc,
              quantity: e.quantity,
              // boxes_module:e.boxes_per_module,
              // boxes_renban:e.boxes_per_renban,
              tapped_qty: e.tapped_qty,
              tap_datetime: e.tap_datetime,
              tap_history_old: e.tap_history_old?.replaceAll('#', ''),
              updated_userid: e.updated_userid,
              updated_date_time: e.updated_date_time,
            };
            exportData.push(obj);
          });

          const headers = [
            'Renban',
            'Kanban',
            'Part No',
            'Module',
            'Renban Status',
            'Mode Of Freight',
            'Container No',
            'Van Comp DT',
            'Vess Sail DT',
            'Est Arr DT',
            'Act Arr DT',
            'Devan DT',
            'Order Code',
            'Part Status',
            'Part Description',
            'Supplier',
            'PCS Per Module',
            // 'QPC',
            'PCS Per Renban',
            // 'Boxes Per Module',
            // 'Boxes Per Renban',
            'Tap QTY',
            'Tap DT',
            'Tap History',
            'User',
            'Updated DT',
          ];

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          if (exportData.length > 0) {
            console.log('exported data', exportData);

            this.excelService.exportAsExcelFile(
              exportData,
              'Pipeline Inquiry' + timestamp,

              headers
            );
          } else {
            this.warningMessage = warningMessage.noRowsSelected;
          }
          this.spinner.hide();
        }
      }
    }, 1000);
  }
  removeMessage() {
    this.warningMessage = '';
  }
}
