import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';

import { GriddataService } from '../services/griddata.service';
import { CalenderGridCheckboxComponent } from './calender-grid-checkbox/calender-grid-checkbox.component';
import { CalenderService } from '../services/calender-setup/calender.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelService } from '../services/excel.service';
import * as XLSX from 'xlsx';

import { Subscription } from 'rxjs';
import { OSPStateService } from '../services/osp-state.service';
import { OspService } from '../core/master-services/osp.service';
import { LookupService } from '../services/lookup/lookup.service';
import { warningMessage, successMessage, resetSortingAndFilters } from '../constants';
import * as mm from 'moment-timezone';

import { getDayName, getDaysInMonth, dateComparator, checkAccess } from '../constants';

import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
// import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
//import { MatLegacyDialog as MatDialog, MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
//import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

import { logErrors } from '../shared/logger';
//import { AllCommunityModules } from '@ag-grid-enterprise/all-modules';
import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';

@Component({
  selector: 'app-calender-setup',
  templateUrl: './calender-setup.component.html',
  styleUrls: ['./calender-setup.component.scss'],
})
export class CalenderSetupComponent implements OnInit, OnDestroy {
  @ViewChild('osPartsGrid') osPartsGrid;
  namcValue: any;
  pagination = 'true';
  successMessage = '';
  warningMessage = '';
  BusinessEntityList: any = [];
  supplierBusinessEntityList: any = [];
  selectedSupplier: any;
  context: any;
  editType;
  converExcelData;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  gridOptions;
  rowSelection;
  uploadError = false;
  columnDefs;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  rowData = [];
  monthList: any;
  currentYear: any;
  yearsList = [];
  disabled: any;
  currentMonth: any;
  monthName: number;
  Name: any;
  saturdays = [];
  sundays = [];
  files: any[];
  entity: any;
  fileBuffer: any;
  isEditMode: boolean = false;
  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  namcName: string;
  user: string;
  userRole: string;
  paginationPageSize;
  fileName: string = '';
  userName: any;
  displayName: any;
  calendarGetResponse: any;

  gridEditedInfo = [];
  getsudogridEditedInfo = [];
  fagridEditedInfo = [];
  supplierList: any = [];
  defaultSupplier: any;
  supplierData: void;
  supplierDatahandle: any = [];
  enableupload: boolean = false;
  domLayout: any;
  calledit: boolean = true;
  giveEditPermission: boolean = false;
  valueModified: boolean = false;

  getSudoSubscription$: Subscription;
  olddata = [];
  access: { edit: boolean; upload: boolean; download: boolean } = {
    edit: false,
    upload: false,
    download: false,
  };
  itContact: string;
  easternCurrentDate: any;

  constructor(
    private readonly gridDataService: GriddataService,
    private readonly calenderService: CalenderService,
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly stateService: OSPStateService,
    private readonly OspService: OspService,
    private readonly lookupService: LookupService,
    private readonly flalService: FluctuationAllowanceService,
    public dialog: MatDialog
  ) {
    this.context = {
      componentParent: this,
    };
  }
  ngOnDestroy(): void {
    this.getSudoSubscription$.unsubscribe();
  }
  initializeAGGrid() {
    this.setYearMonthByDefault();
    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 46,

        // headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        // cellClass: 'ui-grid-cell-contents-auto',

        floatingFilter: true,
        sortable: false,
      },

      {
        headerName: 'Date',
        field: 'date',
        sortable: true,
        width: 168,
        floatingFilter: true,
        cellClass: 'no-border',
        headerTooltip: 'mm/dd/yyyy',
        comparator: dateComparator,
        //type: 'rightAligned',
      },
      {
        headerName: 'Days',
        field: 'Days',
        sortable: true,
        width: 168,
        floatingFilter: true,
        cellClass: 'no-border',
        // type: 'leftAligned',
      },
      {
        headerName: 'Namc Holiday',
        field: 'namcHolidayCheck',
        sortable: false,
        width: 168,
        floatingFilter: false,
        cellRendererFramework: CalenderGridCheckboxComponent,
        cellRendererParams: { column: 'namcholiday' },
        cellClass: 'no-border',
        tooltipValueGetter: (params) => {
          if (params.data.tooltip.makegetsudotooltip && this.isEditMode) {
            return 'Its faday';
          }

          if (params.data.tooltip.makefadaytooltip && this.isEditMode) {
            return 'Its getsudo day';
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'Supplier Holiday',
        field: 'supplierHolidayCheck',
        sortable: false,
        width: 168,
        floatingFilter: false,
        cellRendererFramework: CalenderGridCheckboxComponent,
        cellRendererParams: { column: 'supplierholiday' },
        cellClass: 'no-border',
      },
      {
        headerName: 'GetSudo',
        field: 'getSudoCheck',
        sortable: false,
        width: 168,
        floatingFilter: false,
        cellRendererFramework: CalenderGridCheckboxComponent,
        cellRendererParams: { column: 'getsudo' },
        cellClass: 'no-border',
        tooltipValueGetter: (params) => {
          if (params.data.tooltip.getsudoholidaytooltip && this.isEditMode) {
            return 'holiday';
          }
          if (params.data.tooltip.getsudoweekendtooltip && this.isEditMode) {
            return 'weekend';
          }
          if (params.data.tooltip.makegetsudotooltip && this.isEditMode) {
            return 'Its faday';
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'FA Day',
        field: 'fadayCheck',
        sortable: false,
        width: 168,
        floatingFilter: false,
        cellRendererFramework: CalenderGridCheckboxComponent,
        cellRendererParams: { column: 'faday' },
        cellClass: 'no-border',
        tooltipValueGetter: (params) => {
          if (params.data.tooltip.getsudoholidaytooltip && this.isEditMode) {
            return 'holiday';
          }
          if (params.data.tooltip.getsudoweekendtooltip && this.isEditMode) {
            return 'weekend';
          }
          if (params.data.tooltip.makefadaytooltip && this.isEditMode) {
            return 'Its getsudo day';
          } else {
            return '';
          }
        },
      },

      {
        headerName: 'User',
        field: 'userid',
        sortable: true,
        width: 240,
        floatingFilter: true,
        cellClass: 'no-border',
      },
      {
        headerName: 'Updated DT',
        field: 'date_time',
        sortable: true,
        width: 192,
        floatingFilter: true,
        cellClass: 'no-border',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
      },
    ];

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';

    //this.paginationPageSiz
    this.domLayout = 'autoHeight';
    this.gridOptions = this.gridDataService.getGridOptions();
  }

  ngOnInit(): void {
    this.removeMessage();

    this.initializeAGGrid();

    this.getSudoSubscription$ = this.stateService.getNamc().subscribe((observer) => {
      this.initializeData();
    });
  }
  access1;
  supplierBusinessEntityCheck;
  initializeData() {
    this.spinner.show();
    this.removeMessage();

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    } else {
      this.userName = 'Test User';
    }

    const calendarPermission = checkAccess();
    this.access1 = calendarPermission;
    this.itContact = localStorage.getItem('itContact');

    // eastern time api

    this.easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);

    this.setYearMonthByDefault();

    this.OspService.getSupplierName(this.namcValue, this.userRole, this.user).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.supplierDatahandle = res.body.data;

        this.supplierList = this.supplierDatahandle.map((namc) => {
          return {
            label: namc.supplier_name,
            value: namc.supplier_business_entity,
          };
        });

        const tmc = this.supplierList.find((data) => data.label === 'TMC');
        this.selectedSupplier = tmc;

        this.supplierBusinessEntityCheck = this.supplierList.map((object) => object.value);

        this.initialiseSupplierData(this.currentMonth.value, this.currentYear);
      },
    });

    //getbusinessEntity list

    this.lookupService.getNAMCList().subscribe({
      error: this.errorCallback,
      next: (response) => {
        const data = response.body.data;

        this.BusinessEntityList = data.map((namc) => {
          return namc.business_entity_code;
        });
      },
    });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    if (error?.error?.message?.required_fields) {
      this.warningMessage = warningMessage.requiredFieldsMissing;
    } else {
      this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
    }
    this.rowData = [];
  };
  onSearch() {
    this.spinner.show()
    this.gridEditedInfo=[]
    this.fagridEditedInfo=[]
    this.getsudogridEditedInfo=[]
    if (this.osPartsGrid) {
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
    this.editEnabled = false;
    this.isEditMode = false;
    this.removeMessage();
    this.initialiseSupplierData(this.currentMonth.value, this.currentYear);
  }

  setYearMonthByDefault() {
    this.easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);

    this.currentYear = this.easternCurrentDate.slice(0, 4);

    this.currentYear = parseInt(this.currentYear);
    this.yearsList = [];

    this.yearsList.push(this.currentYear - 1);
    this.yearsList.push(this.currentYear);
    this.yearsList.push(this.currentYear + 1);

    this.monthList = [
      {
        label: 'January',
        value: 1,
      },
      {
        label: 'February',
        value: 2,
      },
      {
        label: 'March',
        value: 3,
      },
      {
        label: 'April',
        value: 4,
      },
      {
        label: 'May',
        value: 5,
      },
      {
        label: 'June',
        value: 6,
      },
      {
        label: 'July',
        value: 7,
      },
      {
        label: 'August',
        value: 8,
      },
      {
        label: 'September',
        value: 9,
      },
      {
        label: 'October',
        value: 10,
      },
      {
        label: 'November',
        value: 11,
      },
      {
        label: 'December',
        value: 12,
      },
    ];
    const month = this.easternCurrentDate.slice(5, 7)

    this.currentMonth = this.monthList[month - 1];
  }

  initialiseSupplierData(currentMonth, currentYear) {
    let Result;

    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

    //console.log(daysInCurrentMonth);

    const data = {
      business_entity: this.selectedSupplier.value,
      workid: this.user,
      user_role: this.userRole,

      start_date: `${currentYear}-${currentMonth}-1`,
      end_date: `${currentYear}-${currentMonth}-${daysInCurrentMonth}`,
    };
    this.calenderService.getCalenderList(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        Result = res.body.calenderResponse;

        this.initialiseData(currentMonth, currentYear, Result);
      },
    });
  }

  initialiseData(currentMonth, currentYear, Result) {
    this.spinner.show();
    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,

      start_date: `${currentYear}-${currentMonth}-1`,
      end_date: `${currentYear}-${currentMonth}-${daysInCurrentMonth}`,
    };

    const tooltipwholecolumn = {
      getsudoinamonthtooltip: false,
      fadayinamonthtooltip: false,
    };

    this.calenderService.getCalenderList(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.spinner.hide();

        res.body.calenderResponse.push(...Result);

        this.rowData = [];

        for (let i = 1; i < daysInCurrentMonth + 1; i++) {
          const subdate = i < 10 ? '0' + i : i;
          const submonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
          const dateObj = `${currentYear}-${submonth}-${subdate}`;

          let displayusername = '';
          let updatedTimeStamp;

          const filteredData = res.body.calenderResponse.filter(
            (row) => dateObj >= row.start_date && dateObj <= row.end_date
          );

          if (filteredData.length > 1) {
            filteredData.sort(function (a, b) {
              return new Date(a.date_time).getTime() - new Date(b.date_time).getTime();
            });
          }
          const latestrecordarray = [];
          const latestrecord = filteredData[filteredData.length - 1];
          latestrecordarray.push(latestrecord);

          if (latestrecord) {
            for (let i = 0; i < latestrecordarray.length; i++) {
              updatedTimeStamp = latestrecordarray[0].date_time;
              displayusername = latestrecordarray[0].userid;
            }
          }

          let namcholiday: boolean = false;
          let getsudoholiday: boolean = false;
          let supplierholiday: boolean = false;
          let faday: boolean = false;

          const editable = {
            supplierholiday: true,
            getsudoholiday: true,
            namcholiday: true,
            faday: true,
          };
          this.disabled = {
            supplieredit: false,
            getsudoedit: false,
            namcedit: false,
            faday: false,
            getsudoholidayedit: false,
            fadayholidayedit: false,
            getsudoweekend: false,
            makegetsudo: false,
            makefaday: false,
            makenamc: false,
            getsudoinamonth: false,
          };
          const tooltip = {
            getsudotooltip: false,
            fadaytooltip: false,
            getsudoholidaytooltip: false,
            getsudoweekendtooltip: false,
            makefadaytooltip: false,
            makegetsudotooltip: false,
          };

          for (let i = 0; i < filteredData.length; i++) {
            this.displayName = '';

            if (
              this.supplierBusinessEntityCheck.indexOf(filteredData[i].business_entity) !== -1 &&
              filteredData[i].holiday_indicator.toLowerCase() === 'Y'.toLowerCase()
            ) {
              if (
                filteredData[i].event.toLowerCase() !== 'Getsudo'.toLowerCase() ||
                filteredData[i].event.toLowerCase() !== 'Fa day'.toLowerCase()
              ) {
                supplierholiday = true;
                this.displayName = filteredData[i].userid;

                tooltip.getsudoholidaytooltip = true;

                if (filteredData[i].start_date !== filteredData[i].end_date) {
                  editable.supplierholiday = false;
                  this.disabled.supplieredit = true;
                }
              }
            }

            if (
              this.BusinessEntityList.indexOf(filteredData[i].business_entity) !== -1 &&
              filteredData[i].holiday_indicator.toLowerCase() === 'y'.toLowerCase()
            ) {
              if (
                filteredData[i].event.toLowerCase() !== 'Getsudo'.toLowerCase() ||
                filteredData[i].event.toLowerCase() !== 'Fa day'.toLowerCase()
              ) {
                namcholiday = true;

                this.disabled.makefaday = true;
                this.disabled.makegetsudo = true;

                tooltip.getsudoholidaytooltip = true;

                if (filteredData[i].start_date !== filteredData[i].end_date) {
                  editable.namcholiday = false;
                  this.disabled.namcedit = true;
                }
              }
            }

            if (
              filteredData[i].event.toLowerCase() === 'GetSudo'.toLowerCase() &&
              filteredData[i].holiday_indicator.toLowerCase() === 'N'.toLowerCase()
            ) {
              getsudoholiday = true;

              this.disabled.makefaday = true;
              this.disabled.makenamc = true;
              tooltip.makefadaytooltip = true;

              if (filteredData[i].start_date !== filteredData[i].end_date) {
                editable.getsudoholiday = false;
                this.disabled.getsudoedit = true;
              }
            }

            if (
              filteredData[i].event.toLowerCase() === 'Fa day'.toLowerCase() &&
              filteredData[i].holiday_indicator.toLowerCase() === 'N'.toLowerCase()
            ) {
              faday = true;

              this.disabled.makegetsudo = true;
              this.disabled.makenamc = true;
              tooltip.makegetsudotooltip = true;

              tooltip.fadaytooltip = true;

              if (filteredData[i].start_date !== filteredData[i].end_date) {
                editable.faday = false;
                this.disabled.faday = true;
              }
            }
          }

          const getdayname = getDayName(this.currentYear, this.currentMonth.value, i);
          if (getdayname === 'Saturday' || getdayname === 'Sunday') {
            namcholiday = true;

            this.disabled.getsudoedit = true;
            supplierholiday = true;
            this.disabled.getsudoweekend = true;

            const subdate = i < 10 ? '0' + i : i;

            const submonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
            const satsun = `${currentYear}-${submonth}-${subdate}`;

            const satsunfilter = res.body.calenderResponse.filter((row) => {
              return row.start_date === satsun && row.end_date === satsun;
            });

            if (satsunfilter.length > 0) {
              for (let i = 0; i < satsunfilter.length; i++) {
                if (satsunfilter[i].event === 'Namc Working Day' && satsunfilter[i].holiday_indicator === 'N') {
                  namcholiday = false;
                }

                if (satsunfilter[i].event === 'Supplier Working Day' && satsunfilter[i].holiday_indicator === 'N') {
                  supplierholiday = false;
                }

                if (satsunfilter[i].event === 'Get Sudo' && satsunfilter[i].holiday_indicator === 'Y') {
                  getsudoholiday = true;
                }
                if (satsunfilter[i].event === 'Get Sudo' && satsunfilter[i].holiday_indicator === 'N') {
                  getsudoholiday = false;
                }
                if (satsunfilter[i].event === 'Faday' && satsunfilter[i].holiday_indicator === 'N') {
                  faday = false;
                }
                if (satsunfilter[i].event === 'Faday' && satsunfilter[i].holiday_indicator === 'Y') {
                  faday = true;
                }
              }
            }
          }

          const obj = {
            date: `${submonth}/${subdate}/${currentYear}`,
            getsudo: getsudoholiday,
            namcholiday: namcholiday,
            supplierholiday: supplierholiday,
            faday: faday,

            Days: `${getdayname}`,
            userid: displayusername,
            date_time: updatedTimeStamp,
            editable: editable,
            disabled: this.disabled,
            disabledfornamc: this.disabled,
            tooltip: tooltip,
            tooltipwholecolumn: tooltipwholecolumn,
          };

          this.rowData.push(obj);
        }

        this.paginationPageSize = this.rowData.length;
      },
    });
  }

  supplierChange() {}

  onResetDropDown() {
    this.removeMessage();
    this.spinner.show()

    this.yearsList = [];
    this.gridEditedInfo=[]
    this.fagridEditedInfo=[]
    this.getsudogridEditedInfo=[]

    console.log(this.isEditMode, this.editEnabled);
    //this.isEditMode=this.isEditMode

    this.setPageSizeToAll();

    this.setYearMonthByDefault();
    if (this.osPartsGrid) {
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
    this.isEditMode = false;
    this.editEnabled = false;
    this.onSearch();
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  getdownloadTemplate() {
    this.removeMessage();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      file_name: 'calender.xlsx',
    };

    this.calenderService.downloadCalenderFile(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res) {
          const a = document.createElement('a');
          a.href = res.body.data;
          a.target = '_blank';
          a.rel = 'noreferrer noopener';

          if (a) {
            window.location.href = a.href;
          }

          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.warningMessage = warningMessage.fileNotAvilable;
        }
      },
    });
  }

  onEditMode(): void {
    this.removeMessage();

    this.isEditMode = !this.isEditMode;
    this.editEnabled = this.isEditMode;

    if (this.isEditMode) {
      this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
        rowNode.data.checkedEditMode = true;
        rowNode.setData(rowNode.data);
      });
    } else {
      this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
        rowNode.data.checkedEditMode = false;
        rowNode.setData(rowNode.data);
      });
    }
    setTimeout(() => {
      window.scroll(0, document.body.scrollHeight);
    }, 100);
  }

  cancelEdit(): void {
    this.removeMessage();

    this.openDialog();
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '204px';
    dialogConfig.width = '475px';
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        this.gridEditedInfo = [];
        this.onSearch();
        this.editEnabled = false;
        this.isEditMode = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  onPageSizeChanged() {
    //console.log(this.paginationPageSize);

    this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
  }
  canDeactivate() {
    return this.editEnabled;
  }

  //for upload
  @HostListener('drop', ['$event']) onDrop(event) {
    this.onFileChange(event, true);
  }
  onFileChange(event: any, isDrag) {
    this.removeMessage();
    this.spinner.show();

    setTimeout(() => {
      const file = event.target.files.item(0);
      this.files = [file];
      this.excelService.uploadedFile = file;

      this.fileName = file.name;

      const allowedFileTypes = ['xlsx', 'xls'];

      const fileType = this.fileName.split('.')[this.fileName.split('.').length - 1];

      if (!allowedFileTypes.includes(fileType)) {
        this.warningMessage = warningMessage.onlyxlxsAlloed;
        this.spinner.hide();

        return;
      }

      const target: DataTransfer = (isDrag ? event.dataTransfer : event.target) as DataTransfer;

      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          cellDates: true,
          dateNF: 'mm/dd/yyyy;@',
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /*Remove empty spaces and convert to lower case */
        const range = XLSX.utils.decode_range(ws['!ref']);

        /* convert to Json */
        this.excelService.uploadedData = XLSX.utils.sheet_to_json(ws, {
          raw: false,
          dateNF: 'mm/dd/yyyy',
        });

        const fileData = this.excelService.uploadedData;
        if (fileData.length === 0) {
          this.warningMessage = warningMessage.fileEmpty;
          window.scroll(0, 0);
        } else {
          for (let C = range.s.c; C <= range.e.c; C++) {
            const address = `${XLSX.utils.encode_col(C)}1`; // <-- first row
            if (!ws[address]) {
              continue;
            }
            ws[address].w = ws[address].w.replace(/\s/g, '').toLowerCase();
            this.converExcelData = ws[address].w;

            var validColumns = ['businessentity', 'event', 'holidayindicator', 'startdate', 'enddate', 'user'];
            var columnCheck = validColumns.filter((x) => x === ws[address].w).length;
            if (columnCheck === 0) {
              this.warningMessage = warningMessage.invalidFileTemplate;
              window.scroll(0, 0);
            }
          }
        }

        if (this.warningMessage === '') {
          this.validation(fileData);
        }

        this.spinner.hide();

        event.target.value = null; // To clear the uploaded file
      };

      reader.readAsBinaryString(target.files[0]);

      return;
    }, 1000);
  }
  validation(JSONData) {
    const eventsMap = {}; // Initialize an object to track events for each combination of StartDate, EndDate, and Business Entity
  
    JSONData.forEach((element) => {
      // Check if any required column is missing
      if (
        element['EndDate'] === undefined ||
        element['Event'] === undefined ||
        element['Holiday Indicator'] === undefined ||
        element['StartDate'] === undefined
      ) {
        this.warningMessage = warningMessage.noColEmpty;
        window.scroll(0, 0);
        return; // Exit the loop if a warning message is set
      }
  
      // Check if Business Entity is valid
      const temp = this.BusinessEntityList.filter((x) => x === element['Business Entity']).length;
      const temp1 = this.supplierBusinessEntityCheck.filter((x) => x === element['Business Entity']).length;
      if (temp === 0 && temp1 === 0) {
        this.warningMessage = warningMessage.invalidBusinessEntity;
        window.scroll(0, 0);
        return; // Exit the loop if a warning message is set
      }
  
      // Construct a unique key for each combination of StartDate, EndDate, and Business Entity
      const key = `${element['StartDate']}_${element['EndDate']}_${element['Business Entity']}`;
      const event = element['Event'];
  
      // Check for duplicate events for the same combination
      if (eventsMap[key] && eventsMap[key] !== event) {
        this.warningMessage = 'More than one Event found for the same StartDate, EndDate, and Business Entity.';
        window.scroll(0, 0);
        return; // Exit the loop if a warning message is set
      }
  
      // Store the event in the eventsMap
      eventsMap[key] = event;
  
      // Check if StartDate and EndDate are the same
      if (element['StartDate'] !== element['EndDate']) {
        this.warningMessage = 'Every row StartDate and EndDate should be the same.';
        window.scroll(0, 0);
        return; // Exit the loop if a warning message is set
      }
    });
  
    // If no warning message is set, upload data to the database
    if (this.warningMessage === '') {
      this.uploadDataToDb(this.excelService.uploadedData);
    }
  }
  

  uploadDataToDb(uploadedData) {
    const data = {
      workid: this.user,
      user_role: this.userRole,
      userid: this.userName,
      updatedata: uploadedData,
    };

    this.calenderService.uploadCalenderFile(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res && res.body.calenderResponse !== 0) {
          this.successMessage = successMessage.recordUpdated;

          window.scroll(0, 0);
          this.uploadToS3();
        } else {
          this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
        }

        this.initialiseSupplierData(this.currentMonth.value, this.currentYear);
      },
    });
  }

  getEditObjInfo(params) {
    const { data: editedinfo, column: editedcolumn } = params;
    const filterGetsudoDays = this.rowData.filter((row) => {
      return row.getsudo;
    });

    const favalidateData = this.rowData.filter((row) => {
      return row.faday;
    });

    if (editedcolumn === 'faday') {
      if (filterGetsudoDays.length > 0) {
        for (let i = 0; i < filterGetsudoDays.length; i++) {
          const filteredGetsudoDay = filterGetsudoDays[i];

          if (filteredGetsudoDay.date > editedinfo.date) {
            this.warningMessage = 'Getsudo date should not be greter than FA Date.';

            this.rowData[params.rowIndex].faday = false;

            this.osPartsGrid.gridOptions.api.applyTransaction({
              update: this.rowData,
            });

            return;
          }
        }
      } else {
        if (editedinfo.faday === true) {
          this.warningMessage = 'Getsudo should be selected.';
          window.scroll(0, 0);
        }
      }
    }

    if (editedcolumn === 'getsudo') {
      if (favalidateData.length > 0) {
        for (let i = 0; i < favalidateData.length; i++) {
          if (favalidateData[i].date < editedinfo.date) {
            this.warningMessage = 'FA Day date should not be less than Getsudo date.';

            this.rowData[params.rowIndex].getsudo = false;

            this.osPartsGrid.gridOptions.api.applyTransaction({
              update: this.rowData,
            });

            return;
          }
        }
      }

      if (editedinfo.getsudo === true) {
        for (let i = 0; i < this.rowData.length; i++) {
          if (this.rowData[i].date === editedinfo.date) {
            this.rowData[i].disabled.makefaday = true;
            this.rowData[i].disabled.makenamc = true;

            this.rowData[i].getsudo = true;
            this.rowData[i].tooltip.makefadaytooltip = true;
          } else {
            if (this.rowData[i].getsudo === true) {
              this.rowData[i].getsudo = false;
              this.rowData[i].tooltip.makefadaytooltip = false;
              this.rowData[i].disabled.makefaday = false;
              this.rowData[i].disabled.makenamc = false;
            }
          }
        }
      } else {
        for (let i = 0; i < this.rowData.length; i++) {
          if (this.rowData[i].date === editedinfo.date) {
            this.rowData[i].disabled.makefaday = false;
            this.rowData[i].disabled.makenamc = false;
            this.rowData[i].tooltip.makefadaytooltip = false;
          }
          if (this.rowData[i].date !== editedinfo.date || this.rowData[i].date === editedinfo.date) {
            // this.rowData[i].getsudo = false;
          }
        }
      }
    }
    if (editedcolumn === 'faday') {
      if (editedinfo.faday === true) {
        for (let i = 0; i < this.rowData.length; i++) {
          if (this.rowData[i].date === editedinfo.date) {
            console.log('inside if');
            this.rowData[i].disabled.makegetsudo = true;
            this.rowData[i].disabled.makenamc = true;

            this.rowData[i].faday = true;
            this.rowData[i].tooltip.makegetsudotooltip = true;
          } else {
            if (this.rowData[i].faday === true) {
              this.rowData[i].faday = false;
              this.rowData[i].tooltip.makegetsudotooltip = false;

              this.rowData[i].disabled.makegetsudo = false;

              this.rowData[i].disabled.makenamc = false;
              // this.rowData[4].disabled.makegetsudo = true;
              // this.rowData[3].disabled.makegetsudo = true;
            }
          }
        }
      } else {
        for (let i = 0; i < this.rowData.length; i++) {
          if (this.rowData[i].date === editedinfo.date) {
            this.rowData[i].disabled.makegetsudo = false;
            this.rowData[i].disabled.makenamc = false;
            this.rowData[i].tooltip.makegetsudotooltip = false;
          }
          if (this.rowData[i].date !== editedinfo.date || this.rowData[i].date === editedinfo.date) {
            // this.rowData[i].getsudo = false;
          }
        }
      }
    }

    if (editedcolumn === 'namcholiday') {
      if (editedinfo.namcholiday === true) {
        for (let i = 0; i < this.rowData.length; i++) {
          if (this.rowData[i].date === editedinfo.date) {
            this.rowData[i].disabled.makegetsudo = true;
            this.rowData[i].disabled.makefaday = true;
            this.rowData[i].tooltip.getsudoholidaytooltip = true;
          } else {
            if (this.rowData[i].namcholiday === true) {
              this.rowData[i].disabled.makegetsudo = true;
              this.rowData[i].disabled.makefaday = true;
              this.rowData[i].tooltip.getsudoholidaytooltip = false;
            }
          }
        }
      } else {
        for (let i = 0; i < this.rowData.length; i++) {
          if (this.rowData[i].date === editedinfo.date) {
            this.rowData[i].disabled.makegetsudo = false;
            this.rowData[i].disabled.makefaday = false;
            this.rowData[i].tooltip.getsudoholidaytooltip = false;
          }
        }
      }
    }

    this.createEditData(editedinfo, editedcolumn);
    this.createGetsudoEditData(editedinfo, editedcolumn);
    this.createFaEditData(editedinfo, editedcolumn);

    this.osPartsGrid.gridOptions.api.applyTransaction({ update: this.rowData });
  }
  createGetsudoEditData(editedinfo, editedcolumn) {
    let event;
    let holiday_indicator;

    if (editedcolumn === 'getsudo') {
      event = 'Getsudo';

      holiday_indicator = 'N';

      const newRow = {
        start_date: editedinfo.date.replace(/[/]/g, '-'),
        end_date: editedinfo.date.replace(/[/]/g, '-'),
        event: event,
        holiday_indicator: holiday_indicator,
      };

      const getsudondex = this.getsudogridEditedInfo.findIndex((d) => {
        return d.start_date === newRow.start_date && d.event === event;
      });

      if (getsudondex >= 0) {
        this.getsudogridEditedInfo.splice(getsudondex);
        this.getsudogridEditedInfo.push(newRow);
      } else {
        this.getsudogridEditedInfo.push(newRow);
      }
    }
  }
  createFaEditData(editedinfo, editedcolumn) {
    let event;
    let holiday_indicator;

    if (editedcolumn === 'faday') {
      if (editedcolumn === 'faday') {
        event = 'FA Day';

        holiday_indicator = 'N';
      }

      const newRow = {
        start_date: editedinfo.date.replace(/[/]/g, '-'),
        end_date: editedinfo.date.replace(/[/]/g, '-'),
        event: event,
        holiday_indicator: holiday_indicator,
      };

      const getsudondex = this.fagridEditedInfo.findIndex((d) => {
        return d.start_date === newRow.start_date && d.event === event;
      });

      if (getsudondex >= 0) {
        this.fagridEditedInfo.splice(getsudondex);
        this.fagridEditedInfo.push(newRow);
      } else {
        this.fagridEditedInfo.push(newRow);
      }
    }
  }

  createEditData(editedinfo, editedcolumn) {
    let event;
    let holiday_indicator;

    if (editedcolumn === 'supplierholiday' || editedcolumn === 'namcholiday') {
      if (editedcolumn === 'supplierholiday') {
        event = 'Supplier Holiday';
        if (editedinfo.supplierholiday === true) {
          holiday_indicator = 'Y';
        } else {
          holiday_indicator = 'N';
        }
      } else if (editedcolumn === 'namcholiday') {
        event = 'Namc Holiday';
        if (editedinfo.namcholiday === true) {
          holiday_indicator = 'Y';
        } else {
          holiday_indicator = 'N';
        }
      }
      const newRow = {
        start_date: editedinfo.date.replace(/[/]/g, '-'),
        end_date: editedinfo.date.replace(/[/]/g, '-'),
        event: event,
        holiday_indicator: holiday_indicator,
        business_entity: this.namcValue,
        supplier_business_entity: this.selectedSupplier.value,
      };

      const index = this.gridEditedInfo.findIndex((d) => {
        return d.start_date === newRow.start_date && d.event === event;
      });

      if (index >= 0) {
        this.gridEditedInfo.splice(index, 1);
        this.gridEditedInfo.push(newRow);
      } else {
        this.gridEditedInfo.push(newRow);
      }
    }
  }

  saveChanges() {
    this.removeMessage();
    this.isEditMode = false;

    this.spinner.show();
    const isUPdated = [...this.gridEditedInfo, ...this.fagridEditedInfo, ...this.getsudogridEditedInfo];

    if (isUPdated.length === 0) {
      this.spinner.hide();
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);

      return;
    }

    const getsudo = this.rowData.filter((row) => {
      return row.getsudo;
    });
    if (!getsudo.length) {
      this.spinner.hide();
      this.warningMessage = 'Getsudo should have one occurance per month.';
      window.scroll(0, 0);

      return;
    }

    const faday = this.rowData.filter((row) => {
      return row.faday;
    });
    if (!faday.length) {
      this.spinner.hide();
      this.warningMessage = 'FA Day should have one occurance per month.';
      window.scroll(0, 0);

      return;
    }

    if (this.calledit) {
      const totalDays = getDaysInMonth(this.currentYear, this.currentMonth.value);

      this.editEnabled = false;
      const data = {
        workid: this.user,
        user_role: this.userRole,
        business_entity: this.namcValue,
        userid: this.userName,
        supplierBusiness: this.selectedSupplier.value,
        editedinfo: this.gridEditedInfo,
        monthStart: `${this.currentYear}-${this.currentMonth.value}-1`,
        monthEnd: `${this.currentYear}-${this.currentMonth.value}-${totalDays}`,
        specialData: this.getsudogridEditedInfo[this.getsudogridEditedInfo.length - 1],
        faspecial: this.fagridEditedInfo[this.fagridEditedInfo.length - 1],
      };

      this.calenderService.editCalenderFile(data).subscribe({
        error: this.errorCallback,
        next: (res) => {
          if (res.body.calenderResponse) {
            this.gridEditedInfo = [];
            this.getsudogridEditedInfo = [];
            this.fagridEditedInfo = [];
            this.successMessage = successMessage.recordUpdated;

            window.scroll(0, 0);

            this.initialiseSupplierData(this.currentMonth.value, this.currentYear);
          } else {
            this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
            window.scroll(0, 0);
            this.initialiseSupplierData(this.currentMonth.value, this.currentYear);
          }
        },
      });
    }
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }

  // save uploaded document in S3

  getFileName() {
    const timestamp = new Date()
      .toISOString()
      .split('.')[0]
      .replace('T', '_')
      .split('')
      .filter((d) => d !== '-' && d !== ':')
      .join('');
    return `calendersetup/success/calendar_upload_${timestamp}.xlsx`;
  }
  uploadToS3 = () => {
    const data = this.getExportData();

    const fileName = this.getFileName();
    const headers = ['Business Entity', 'Event', 'Holiday Indicator', 'StartDate', 'EndDate'];

    setTimeout(async () => {
      this.fileBuffer = await this.excelService.getFileBuffer(data, fileName, headers);

      this.uploadExcelDataToS3({ fileName });
    }, 1000);
  };

  getExportData() {
    const data = [];

    const dataToExport = this.excelService.uploadedData;

    dataToExport.forEach((row) => {
      data.push({
        business_entity: row['Business Entity'],
        event: row['Event'],
        holiday_indicator: row['Holiday Indicator'],
        start_date: row['StartDate'],
        end_date: row['EndDate'],
      });
    });

    return data;
  }
  uploadExcelDataToS3({ fileName }) {
    const data = {
      workid: this.user,
      user_role: this.userRole,
      fileName: fileName,
    };
    this.flalService.getExcelFileUrl(data).subscribe({
      error: this.errorCallback,
      next: (response) => {
        if (response.body.signedUrl) {
          const a = document.createElement('a');
          a.href = response.body.signedUrl;

          this.flalService.putDatainExcel(this.fileBuffer, a.href).subscribe({
            error: this.errorCallback,
          });
        }
      },
    });
  }
}
