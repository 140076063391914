import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'inquirycomments-component',
  template: `
  <img
  width="30px"
  height="30px"
  align-self="center"
  *ngIf="showInput"

  src="../../assets/images/bubble.png"
/>


`     ,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      input {
        width: 150px;
        height: 25px;
      }
    `,
  ],
})
export class InquiryCommentsEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  comments: any;
  singleComment: boolean = false;
  constructor(private readonly elementRef: ElementRef) {}

  agInit(params: any): void {

    if (params.column === 'tap_history') {
      this.showInput=true;
        params.data.comment = this;

        this.value = params.data.tap_history;
  

      if (params.data.tap_history) {
          
        if (params.data.tap_history.split('$').length > 1) {
            this.value = params.data.tap_history;
          this.value = '';
        } else {
          this.value = params.data.tap_history;
          this.singleComment = true;
        }
      }

    }

    this.params = params;
    if (
      params.data.tap_history === null  
    ) {
      this.showInput = false;
      this.value='';
    } else {
      this.showInput = true;
      this.value = params.data.tap_history;

    }

  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }


}
