import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ExcelService } from '../services/excel.service';
import { RundownService } from '../services/rundown/rundown.service';

import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { BulkadjustmentService } from '../services/bulk-adjustment/bulkadjustment.service';
import { OSPStateService } from '../services/osp-state.service';
import { LookupService } from '../services/lookup/lookup.service';
import { freezingMesssage } from '../constants';
import { warningMessage, successMessage } from '../constants';
import { currTimestamp, logData, logErrors } from '../shared/logger';

@Component({
  selector: 'app-bulk-adjustment',
  templateUrl: './bulk-adjustment.component.html',
  styleUrls: ['./bulk-adjustment.component.scss'],
})
export class BulkAdjustmentComponent implements OnInit, OnDestroy {
  namcValue = '01';
  user = '';
  userRole = '';
  rowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  columnDefs;
  public defaultColDef;
  rowSelection;
  msgNoContent = 'No Content';
  gridOptions;
  progress;
  minn_vann_date;
  paginationPageSize = 10;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  files = [];
  editEnabled: boolean = false;
  uploadEnabled: boolean = false;
  freezeStatus: boolean = false;
  filter: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;
  dragndrop: boolean = false;
  selectedDestCode: any = [];
  selectedLinePath: any = [];

  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;
  spinnerMessage = `Loading... Please wait...`;
  filenameInS3 = ``;

  destCodeList: any = [];
  linePathList: any = [];

  isEditMode: boolean = false;

  selectedShowHideList: any = [];
  showHideList: any = [];
  offset = 0;
  dataCount: number = 0;
  fileName: String = '';
  errorTemplate: boolean = false;
  successTemplate: boolean = false;
  errorMessage: string;
  headers = [
    'PART_NUMBER',
    'ORDER_TYPE',
    'CONT_CODE',
    'ADJ_QTY',
    'VANNING_DATE',
    'ADJ_CODE',
    'ADJ_REASON',
    'REMARKS                                                         ',
  ];
  namcName: string;
  bAdjsSubscription$: any;
  itContact: string;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly rundownService: RundownService,
    private readonly bulkadjustmentService: BulkadjustmentService,
    private readonly stateService: OSPStateService,
    private readonly lookupService: LookupService
  ) {
    this.context = {
      componentParent: this,
    };
  }

  ngOnDestroy(): void {
    this.bAdjsSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.bAdjsSubscription$ = this.stateService
      .getNamc()
      .subscribe((observer) => {
        this.initializeData();
      });

    this.spinner.hide();
  }

  initializeData() {
    this.removeMessage();
    this.itContact = localStorage.getItem('itContact');
    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    this.getBatchStatus('');
  }

  errorCallback = (error) => {
    logErrors(error);

    this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
  };

  batchCheck = true;
  freezingMesssage = '';

  getBatchStatus(callback) {
    this.spinner.show();
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.lookupService.getBatchStatus(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        let runningBatches = res.body.data.runningBatches ?? '';
        const batchesToIgnore = res.body.data.batchesToIgnore.map(
          (batch) => batch.batch_name
        );

        runningBatches = runningBatches
          .split(',')
          .filter((batchName) => !batchesToIgnore.includes(batchName))
          .join(',');

        const count = runningBatches.length;

        if (count > 0) {
          this.freezingMesssage = `${runningBatches}  ${freezingMesssage}`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;

          if (callback) {
            callback();
          }
        }
        this.spinner.hide();
      },
    });
  }

  onFileDropped($event) {
    this.removeMessage();
    logData('File dropped');
    this.files = [];
    this.prepareFilesList($event);
  }

  fileBrowseHandler(event) {
    this.removeMessage();
    this.files = [];
    logData('file received from file api ');
    if (event.target.files[0].name.split('.').pop().substring(0, 3) !== 'xls') {
      this.warningMessage = warningMessage.onlyxlxsAlloed;
    } else {
      this.files.push(event.target.files[0]);
      this.saveData();
    }
    event.target.value = null;
  }

  prepareFilesList(files: Array<any>) {
    for (const item of files) {
      this.files.push(item);
    }
    if (this.files.length > 1) {
      this.warningMessage = `Only one file can be uploaded at a time.`;
    } else if (this.files[0].name.split('.').pop().substring(0, 3) !== 'xls') {
      this.warningMessage = warningMessage.onlyxlxsAlloed;
    } else {
      this.saveData();
    }
  }

  getdownloadTemplate() {
    this.removeMessage();
    this.spinner.show();
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      file_name: 'bulk_adjustment.xlsx',
    };
    this.rundownService.getdownloadTemplate(data).subscribe(
      (success) => {
        if (success) {
          const a = document.createElement('a');
          a.href = success.body.data;
          logData(a.href);
          a.target = '_blank';
          a.rel = 'noreferrer noopener';
          if (a) {
            window.location.href = a.href;
          }
          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.warningMessage = warningMessage.fileNotAvilable;
          window.scroll(0, 0);
        }
      },
      (err) => {
        logErrors(err);
        this.spinner.hide();
        this.warningMessage = warningMessage.fileNotAvilable;
        window.scroll(0, 0);
      }
    );
  }
  uploadExcelDatatoS3(status, callback) {

    let dataToupload = ``;

    const fileName =
      status === 'error'
        ? `bulk_adjustment/${this.namcName}/error/Bulkadjustment_error_${currTimestamp()}.xlsx`
        : `bulk_adjustment/${this.namcName}/${status}/Bulkadjustment_${currTimestamp()}.xlsx`;

    this.filenameInS3 = fileName;
    const data = {
      workid: this.user,
      user_role: this.userRole,
      fileName: fileName,
    };
    if (status === 'error') {
      dataToupload = this.excelService.getFileBuffer(
        this.excelService.uploadedData,
        fileName,
        this.headers
      );
    }
    this.bulkadjustmentService.getExcelFileUrl(data).subscribe(
      (success) => {
        if (
          success.body.signedUrl !== undefined ||
          success.body.signedUrl !== null
        ) {
          const a = document.createElement('a');
          a.href = success.body.signedUrl;
          this.bulkadjustmentService
            .putDatainExcel(
              status === 'error' ? dataToupload : this.files[0],
              a.href
            )
            .subscribe(
              (success) => {
                logData(`Successfuly upload to S3 :: ${fileName}`);
                callback();
              },
              (err) => {
                this.spinner.hide();
                this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
                window.scroll(0, 0);
              }
            );
        }
      },
      (err) => {
        this.spinner.hide();
        this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
        window.scroll(0, 0);
      }
    );
  }
  saveData() {
    this.spinner.show();
    this.uploadExcelDatatoS3('upload', () => {
      const dataTosend = {
        workid: this.user,
        user_role: this.userRole,
        key: this.filenameInS3,
        business_entity: this.namcValue,
        userid: localStorage.getItem('UserName'),
        batch_name: `osp-bulk-adjustment-batch`,
      };

      this.bulkadjustmentService.calculation(dataTosend).subscribe(
        (success) => {
          if (success.body.status) {
            logData('Success');
            this.successMessage = successMessage.bulkAdjStarted;
          }
          this.spinner.hide();
        },
        (err) => {
          this.spinner.hide();
          this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
        }
      );
    });
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../run-down-maintenance/dialog.component.html',
  styleUrls: ['../core/master-delete/master-delete.component.scss'],
})
export class saveDialog1 {
  constructor(public dialogRef: MatDialogRef<saveDialog1>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
