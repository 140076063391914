import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup/lookup.service';

@Component({
  selector: 'app-training-videos',
  templateUrl: './training-videos.component.html',
  styleUrls: ['./training-videos.component.scss'],
})
export class TrainingVideosComponent implements OnInit {
  namcValue = localStorage.getItem('namcvalue');
  user: string;
  userRole: string;
  trainingVideos: any;

  constructor(
    private readonly lookupService: LookupService,
    private readonly spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      fileNames: [
        'Training_Videos/home_jobstatus.mp4',
        'Training_Videos/orderforecast.mp4',
        'Training_Videos/AdjRunBulk.mp4',
        'Training_Videos/ReportsFaGetsudo.mp4',
        'Training_Videos/mastercalendar.mp4',
        'Training_Videos/UserImprovementReview_PART1.mp4',
        'Training_Videos/UserImprovementReview_PART2.mp4',
      ],
    };

    this.lookupService.getVideos(data).subscribe({
      next: (res) => {
        this.trainingVideos = {
          homejobstatus: res.body.fileurls[0],
          orderforecast: res.body.fileurls[1],
          adjRunBulk: res.body.fileurls[2],
          reportsFaGetsudo: res.body.fileurls[3],
          masterCalendar: res.body.fileurls[4],
          UserImprovementReviewPART1:res.body.fileurls[5],
          UserImprovementReviewPART2:res.body.fileurls[6]


        };

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
}
