import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { GriddataService } from 'src/app/services/griddata.service';

@Component({
  selector: 'app-review-changes',
  templateUrl: './review-changes.component.html',
  styleUrls: ['./review-changes.component.scss'],
})
export class ReviewChangesComponent implements OnInit {
  rowData: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  gridOptions = {} as any;
  //gridOptions!: GridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = true;
  context: any;
  editType;
  suppressClickEdit = true;





  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private readonly dialogRef: MatDialogRef<ReviewChangesComponent>
  ) {
    this.columnDefs = [
      
        // {
        //   headerName: 'Renaban Status',
        //   field: 'container_status',
          
        //   width: 120,
        // },
        {
          headerName: 'Renban',
          field: 'container_renban',
          width: 120,
         
        },
        {
          headerName: 'Module',
          field: 'module_number',
          width: 100,
        },
        {
          headerName: 'Part No',
          field: 'part_number',
         
          width: 120,
        },

       
       
        
        {
          headerName: 'PCS Per Module',
          field: 'mod_qty_received',
          width: 140,
          
        },
        {
          headerName: 'Tap QTY',
          headerTooltip: 'Tap QTY',
          width: 120,
  
          field: 'tapped_qty',
          
        },

      {
        // headerName: 'Error',
        field: 'error',
        flex: 1,
        headerComponentParams: {
          template:
            '<span class="ag-header-cell-text" style="color:#D20000">Error</span>',
        },
        cellStyle: {
          color: '#D20000',
        },
        cellRenderer: (params) => {
          const html = params.value
            .split('\n')
            .map((line) => `<li style="list-style-type:none;">${line}</li>`)
            .join('\n');
          return `${html}`;
        },
      },
    ];
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';

    this.rowData = data.modifiedRowData;
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  enableSave: boolean = false;
  ngOnInit() {
    var errorCount = this.rowData.filter((x) => x.error !== '').length;

    this.enableSave = errorCount === 0;
  
  }

}

