import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { GriddataService } from '../services/griddata.service';
import { GridApi } from 'ag-grid-community';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { OspService } from '../core/master-services/osp.service';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import * as moment from 'moment';
import { ReportCheckboxComponent } from '../core/grid/report-checkbox/report-checkbox.component';
import { ReportHeaderCheckboxComponent } from '../core/grid/report-header-checkbox/report-header-checkbox.component';
import { saveAs } from 'file-saver';
import { GridtextchangeComponent } from './gridtextchange/gridtextchange.component';
import { Subscription } from 'rxjs';
import { OSPStateService } from '../services/osp-state.service';
import { LookupService } from '../services/lookup/lookup.service';
import { warningMessage, successMessage } from '../constants';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  namcValue: any;
  @ViewChild('ospMorningGrid') ospMorningGrid;
  @ViewChild('ospMonthlyGrid') ospMoGnthlyrid;
  @ViewChild('ospFinalGrid') ospFinalGrid;
  @ViewChild('ospExceptionGrid') ospExceptionGrid;
  namc = '';
  namcName = '';
  role = '';
  user = '';
  userRole: any;
  morningrowdata: any;
  monthlyrowdata: any;
  finalcalrowdata;
  exceptionrowdata: any;
  isEditMode: boolean = false;
  rowSelection;
  getSudoSubscription$: Subscription;
  headerCheckboxChecked: boolean = false;

  columnDefs;
  paginationSize;
  morninggridoptions;
  monthlygridoptions;
  finalgridoptions;
  exceptiongridoptions;
  rowData = [];
  context: any;
  gridApi: GridApi;
  business_entity_name: string = '';
  master_id: any = [];
  business_entity: any;
  reportdate: any;
  warningMessage = '';

  columnDefReport;
  rowDataReport = [];
  columnDefMorningCalc;
  columnDefFinalCalc;
  columnDefMonthlyReports;
  columnDefExceptionReport;
  columnDefForecastDataReportP2;
  reportFiles = [];
  rowDataNqcDataP1 = [];
  rowDataForecastDataP1 = [];
  rowDataForecastErrorReportP1 = [];
  rowDataForecastDataP2 = [];
  rowDataForecastDataReportP2 = [];
  NQCProcessStatus = 'NA';
  NQCProcessStatusToyota = 'NA';
  NQCProcessStatusMAZDA = 'NA';
  batchStatus = '';
  filename = '';

  reportSubscription$: Subscription;

  batchStatusToyota = '';
  batchStatusMazda = '';
  freezeStatus = true;
  freezeToyota = true;
  freezeMazda = true;
  showNQCDataP1 = true;
  ediBatchLog = '';
  ediBatchLogToyota = '';
  ediBatchLogMazda = '';
  downloadingFiles: boolean;
  downloadingFilesCount: number;
  itContact: string;
  mornCalReports = 'MORNING CALCULATION REPORTS';
  monthReports = 'MONTHLY REPORTS';
  finalCalReports = 'FINAL CALCULATION REPORTS';
  exceptionReorts = 'EXCEPTION REPORTS';
  disableDate: any;

  constructor(
    private readonly gridDataService: GriddataService,
    private readonly spinner: NgxSpinnerService,
    private readonly OspService: OspService,
    private readonly stateService: OSPStateService,
    private readonly lookupService: LookupService
  ) {
    this.context = {
      componentParent: this,
    };
  }

  columnDefErrorReport;
  rowDataErrorReport = [];

  onGridReady(params) {
    this.gridApi = params?.api;
  }
  ngOnDestroy(): void {
    this.reportSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.morninggridoptions = this.gridDataService.getGridOptions();
    this.morninggridoptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.morninggridoptions.floatingFilter = false;
    this.morninggridoptions.rowHeight = 34;

    this.finalgridoptions = { ...this.morninggridoptions };
    this.monthlygridoptions = { ...this.morninggridoptions };
    this.exceptiongridoptions = { ...this.morninggridoptions };

    this.columnDefMorningCalc = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: ReportHeaderCheckboxComponent,
        headerValueGetter: (params) => {
          //params?.displayName = 'mrng';
          return 'mrng';
        },
        // cellClass: 'ag-right-aligned-cell',
        cellRendererFramework: ReportCheckboxComponent,
      },
      {
        headerName: 'Morning Calculation Reports',
        field: 'filename',
        width: 430,
        floatingFilter: false,
        cellRendererFramework: GridtextchangeComponent,
        suppressNavigable: true,
        cellClass: 'no-border',
        cellStyle: {
          'padding-left': '12px',
          'line-height': '20px',
          'padding-top': '5px',
        },
        valueGetter: (params) => {
          if (params.data.filename === 'Order_Plan_Report') {
            return 'Order_Plan';
          }

          if (params.data.filename === 'BLDREQ_Vanndate_Report') {
            return 'Rundown_Vanndate_Report';
          }
          if (params.data.filename === 'FA_Min_No_Order_Plan') {
            return 'FA_Min_With_No_Order_Plan';
          }
          if (params.data.filename === 'Manual_FA_Chng') {
            return 'Manual_FA_Change';
          }
          if (params.data.filename === 'Fa_Chng_Compare') {
            return 'Fa_Change_Compare';
          }
          if (params.data.filename === 'Daily_veh_var') {
            return 'Daily_Vehicle_Variance';
          }
          if (params.data.filename === 'Daily_nveh_var') {
            return 'Daily_NonVehicle_Variance';
          }
          if (params.data.filename === 'Daily_nveh_var') {
            return 'Daily_NonVehicle_Variance';
          }
          if (params.data.filename === 'BLDREQ_REPORT') {
            return 'Daily_BRD_Report';
          }
          if (params.data.filename === 'DAILY_BLDREQ') {
            return 'FOD-Vehicle Requirement Report';
          } else {
            return params.data.filename;
          }
        },
      },
    ];

    this.columnDefFinalCalc = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,

        headerComponentFramework: ReportHeaderCheckboxComponent,

        headerValueGetter: (params) => {
          //params?.displayName = 'mrng';
          return 'final';
        },
        cellRendererFramework: ReportCheckboxComponent,
      },
      {
        headerName: 'Final Calculation Reports',
        field: 'filename',
        width: 430,
        floatingFilter: false,

        cellRendererFramework: GridtextchangeComponent,
        suppressNavigable: true,
        cellClass: 'no-border',
        cellStyle: {
          'padding-left': '12px',
          'line-height': '20px',
          'padding-top': '5px',
        },
        valueGetter: (params) => {
          if (params.data.filename === 'PXP_Output') {
            return 'PxP_Forecast';
          } else {
            return params.data.filename;
          }
        },
      },
    ];

    this.columnDefMonthlyReports = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,

        headerComponentFramework: ReportHeaderCheckboxComponent,
        headerValueGetter: (params) => {
          //params?.displayName = 'mrng';
          return 'monthly';
        },
        cellRendererFramework: ReportCheckboxComponent,
        suppressNavigable: true,
        cellClass: 'no-border',
      },
      {
        headerName: 'Monthly Reports',
        field: 'filename',
        width: 430,
        floatingFilter: false,
        cellRendererFramework: GridtextchangeComponent,
        suppressNavigable: true,
        cellClass: 'no-border',
        cellStyle: {
          'padding-left': '12px',
          'line-height': '20px',
          'padding-top': '5px',
        },
        valueGetter: (params) => {
          if (params.data.filename === 'FA_RCVD_NO_ORDR') {
            return 'FA_Received_No_Order';
          } else {
            return params.data.filename;
          }
        },
      },
    ];

    this.columnDefExceptionReport = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,

        headerComponentFramework: ReportHeaderCheckboxComponent,
        headerValueGetter: (params) => {
          //params?.displayName = 'mrng';
          return 'exception';
        },
        cellRendererFramework: ReportCheckboxComponent,
      },
      {
        headerName: 'Exception Reports',
        field: 'filename',
        width: 430,
        floatingFilter: false,
        cellRendererFramework: GridtextchangeComponent,
        cellStyle: {
          'padding-left': '12px',
          'line-height': '20px',
          'padding-top': '5px',
        },
        valueGetter: (params) => {
          if (params.data.filename === 'Order_Plan_Err') {
            return 'Order_Plan_Error';
          }
          if (params.data.filename === 'BLDREQ_Proddate_Report') {
            return 'Rundown_Proddate_Report';
          } else {
            return params.data.filename;
          }
        },
      },
    ];

    this.rowSelection = 'multiple';
    this.reportSubscription$ = this.stateService
      .getNamc()
      .subscribe((observable) => {
        this.initializeData();
      });
  }

  initializeData() {
    this.spinner.show();

    this.itContact = localStorage.getItem('itContact');

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    console.log(localStorage.getItem('UserName'));

    // eastern time api
    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.reportdate = res.body.data;
        this.disableDate = moment(this.reportdate).subtract(6, 'month').format('YYYY-MM-DD');
        console.log("disableDate",this.disableDate);
        
        this.changenotice();
      },
    });

    //this.reportList();
  }
  togleHeaderCheckBox(checked, reportType) {
    let gridOptions;
    if (reportType === this.mornCalReports) {
      gridOptions = this.morninggridoptions;
    }
    if (reportType === this.monthReports) {
      gridOptions = this.monthlygridoptions;
    }
    if (reportType === this.finalCalReports) {
      gridOptions = this.finalgridoptions;
    }
    if (reportType === this.exceptionReorts) {
      gridOptions = this.exceptiongridoptions;
    }

    if (!checked) {
      this.headerCheckboxChecked = false;

      gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = gridOptions.rowData.length;
    const selectedRowCount = gridOptions.rowData.filter(
      (x) => x.rowCheckBox === true
    ).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      gridOptions.api.refreshHeader();
    }
  }
  userCheckChanged(checked, rowIndex, reportType) {
    this.spinner.show();
    let gridOptions;
    if (reportType === this.mornCalReports) {
      gridOptions = this.morninggridoptions;
    }
    if (reportType === this.monthReports) {
      gridOptions = this.monthlygridoptions;
    }
    if (reportType === this.finalCalReports) {
      gridOptions = this.finalgridoptions;
    }
    if (reportType === this.exceptionReorts) {
      gridOptions = this.exceptiongridoptions;
    }
    const rowNode = gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    this.filename = rowNode.data.filename;

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.morninggridoptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
    };

    rowNode.setData(rowNode.data);
    this.togleHeaderCheckBox(checked, reportType);
    this.spinner.hide();
  }
  //handling all 4 grid options by displayname
  headerCheckChanged(event) {
    const displayName = event.displayName;

    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    let gridOptions;
    if (displayName === 'mrng') {
      gridOptions = this.morninggridoptions;
    }
    if (displayName === 'monthly') {
      gridOptions = this.monthlygridoptions;
    }
    if (displayName === 'final') {
      gridOptions = this.finalgridoptions;
    }
    if (displayName === 'exception') {
      gridOptions = this.exceptiongridoptions;
    }

    gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }
  // // waiting b/w multiple files to download one by one
  // delay(callback, file, i, delayMs) {
  //   setTimeout(() => {
  //     console.log(i);
  //     console.log(i * delayMs);
  //     this.downloadingFilesCount--;
  //     callback(file);
  //     // console.log(i, this.downloadingFilesCount, 'downloadingFilesCount');
  //     // if (i === this.downloadingFilesCount) {
  //     //   this.completeDownload();
  //     // }

  //     if (this.downloadingFilesCount === 0) {
  //       this.completeDownload();
  //     }
  //   }, i * delayMs);
  // }

  //dynamically creating anchor tag to handle multiple href
  createAnchorElement(link) {
    const a = document.createElement('a');
    //a.target = '_blank';
    a.href = link;

    a.target = '_blank';
    a.rel = 'noreferrer noopener';
    return a;
  }

  // waiting b/w multiple files to download one by ones
  delay(callback, file, i, delayMs) {
    setTimeout(() => {
      callback(file);
      console.log(this.downloadingFilesCount, i);
      if (i === this.downloadingFilesCount) {
        this.completeDownload();
      }
    }, i * delayMs);
  }

  downloadAllReports = (reports) => {
    const downloadOne = (index) => {
      this.downloadfile(reports[index]);

      if (index === this.downloadingFilesCount - 1) {
        this.completeDownload();
        return;
      }

      setTimeout(() => {
        downloadOne(++index);
      }, 2500);
    };

    return downloadOne;
  };

  downloadfile = (link) => {
    if (link) {
      const a = this.createAnchorElement(link);
      window.location.href = a.href;
      // saveAs(link);
    }
  };
  //call the download apis on clicking on download icon
  noS3File = '';

  onDownload() {
    this.removeMessage();
    this.spinner.show();
    const filenames = [];

    const combineddata = [
      ...this.morningrowdata,
      ...this.monthlyrowdata,
      ...this.finalcalrowdata,
      ...this.exceptionrowdata,
    ];

    //calling all

    const modifiedrowdata = this.getModifiedRowData(combineddata);

    if (modifiedrowdata.length < 1) {
      this.warningMessage = warningMessage.reportDownload;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }
    for (let i = 0; i < modifiedrowdata.length; i++) {
      // if (modifiedrowdata[i].report_type === 'MONTHLY REPORTS') {
      //   console.log('hi', modifiedrowdata[i].filename);
      //   //monthlyfilenames = modifiedrowdata[i].filename;
      //   monthlylistfilenames.push(modifiedrowdata[i].filename);
      // }
      // else {
      const a = {
        filename: modifiedrowdata[i].filename,
        reporttype: modifiedrowdata[i].report_type,
      };
      filenames.push(a);
      //}
    }

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      fileName: filenames,
      reportDate: this.reportdate,
      namcName: this.namcName,
    };

    if (data.fileName.length > 0) {
      this.multiplereportsdownload(data);
    }
  }

  completeDownload() {
    this.downloadingFiles = false;
    this.downloadingFilesCount = 0;
  }

  multiplereportsdownload(data) {
    

    this.downloadingFiles = true;
    this.downloadingFilesCount = data.fileName.length;

    this.OspService.downloadMultipleReport(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.spinner.hide();

        if (res.body.ReportSuccessResponse.length > 0) {
         
          const aList = [];

          // for (let i = 0; i < res.body.ReportSuccessResponse.length; i++) {
          //   const a = res.body.ReportSuccessResponse[i];
          //   this.delay(this.downloadfile, a, i + 1, 2000);
          // }

          const startDownloading = this.downloadAllReports(
            res.body.ReportSuccessResponse
          );
          startDownloading(0);
        }
        if (res.body.ReportERRORResponse.length > 0) {
    
          this.warningMessage = `${res.body.ReportERRORResponse} Report(s) not available. Please contact OS IT and Business Support.`;

          this.downloadingFilesCount -=
            res.body.ReportERRORResponse.split(',').length;
         
        }
        if (res.body.ReportSuccessResponse.length == 0) {
          this.completeDownload();
        }
      },
    });
  }

  //calling reportlist api when reportdate changed
  changenotice() {
    this.removeMessage();
    this.completeDownload();
    if (this.ospMorningGrid) {
      this.headerCheckboxChecked = false;
      this.ospMorningGrid.gridOptions.api.refreshHeader();
    }
    if (this.ospMoGnthlyrid) {
      this.headerCheckboxChecked = false;
      this.ospMoGnthlyrid.gridOptions.api.refreshHeader();
    }
    if (this.ospFinalGrid) {
      this.headerCheckboxChecked = false;
      this.ospFinalGrid.gridOptions.api.refreshHeader();
    }
    if (this.ospExceptionGrid) {
      this.headerCheckboxChecked = false;
      this.ospExceptionGrid.gridOptions.api.refreshHeader();
    }
    
    if (!this.reportdate) {
      this.warningMessage = 'Please Select Report Date.';
      this.morningrowdata = [];
      this.monthlyrowdata = [];
      this.finalcalrowdata = [];
      this.exceptionrowdata = [];
      return;
    }
    this.reportList();
  }
  //get rowdata when checkbox checked
  getModifiedRowData(rowData): any {
    const modifiedRowData = rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }
  errorCallback = (error) => {
    console.error(error);
    this.spinner.hide();

    if (error?.error?.message?.required_fields) {
      this.warningMessage = warningMessage.requiredFieldsMissing;
    } else {
      this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
      this.completeDownload();
    }
  };

  reportList() {
    this.spinner.show();
    const data = {
      business_entity: this.namcValue,
      reportDate: this.reportdate,
      workid: this.user,
      user_role: this.userRole,
      namcName: this.namcName,
    };
    //seperating griddata based on report type
    this.OspService.getReport(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.spinner.hide();
        

        this.monthlyrowdata =
          res.body.getReportResponse.monthlyReportResponse.filter(
            (x) => x.report_type === this.monthReports
          );
        this.morningrowdata = res.body.getReportResponse.response.filter(
          (x) => x.report_type === this.mornCalReports
        );

        // loop through morning rowdata and slice err

        this.finalcalrowdata = res.body.getReportResponse.response.filter(
          (x) => x.report_type === this.finalCalReports
        );
        this.exceptionrowdata = res.body.getReportResponse.response.filter(
          (x) => x.report_type === this.exceptionReorts
        );
      },
    });
  }

  //chnage cell rowdata to anchortag format methos
  textUpdate(event, reportType) {
    let filenames = [];
    const a = {
      filename: event,
      reporttype: reportType,
    };
    filenames.push(a);
    this.spinner.show();
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      namcName: this.namcName,
      //fileName: ['PARTS_EXCLUSION', 'FA_CHNG_COMPARE'],
      fileName: filenames,
      reportDate: this.reportdate,
    };

    this.multiplereportsdownload(data);
  }
  checkIfAnySelected() {
    if (this.filename.length > 0) {
      return true;
    }

    return false;
  }

  removeMessage() {
    this.warningMessage = '';
  }

  stopTyping() {
    return false;
  }
}
