import { Component, Input } from '@angular/core';
import * as mm from 'moment-timezone';

@Component({
  selector: 'app-calender-grid-checkbox',
  template: `
    <label *ngIf="showInput" style="color:#2688DA"> &#10004; </label>
    <mat-checkbox
      [disabled]="disable"
      *ngIf="showCheckBox"
      (change)="checkCheckBoxvalue($event)"
      [(ngModel)]="value"
    ></mat-checkbox>
  `,
  styles: [
    `
      ::ng-deep .mat-checkbox .mat-checkbox-frame {
        border: 1px solid #c5c5c5 !important;
      }
      ::ng-deep .mat-checkbox-disabled .mat-checkbox-background {
        background-color: #d3d3d3;
      }
    `,
  ],
})
export class CalenderGridCheckboxComponent {
  @Input() cell: any;

  showInput: boolean;
  value = '';
  isEmpty = true;
  showCheckBox = false;
  editable: boolean;
  disable: boolean = false;

  public params: any;
  agInit(params: any): void {
    if (params.column === 'getsudo') {
      params.data.getSudoCheck = this;

      this.value = params.data.getsudo;
      this.disable =
        params.data.disabled.getsudoholidayedit ||
        params.data.disabled.getsudoweekend ||
        params.data.disabled.makegetsudo;
    }
    if (params.column === 'faday') {
      params.data.fadayCheck = this;
      this.value = params.data.faday;
      this.disable =
        params.data.disabled.fadayholidayedit ||
        params.data.disabled.getsudoweekend ||
        params.data.disabled.makefaday;
    }
    if (params.column === 'namcholiday') {
      params.data.namcHolidayCheck = this;
      this.value = params.data.namcholiday;
      this.disable = params.data.disabled.namcedit;
      this.disable = params.data.disabled.makenamc;
    }
    if (params.column === 'supplierholiday') {
      params.data.supplierHolidayCheck = this;
      this.value = params.data.supplierholiday;
      this.disable = params.data.disabled.supplieredit;
    }

    if (this.value) {
      this.showInput = true;
    } else {
      this.showInput = false;
    }

    this.params = params;

    if (this.params.data.checkedEditMode) {
      this.showCheckBox = true;
      this.showInput = false;
    } else {
      this.showCheckBox = false;
    }

    if (
      this.params.data.checkedEditMode &&
      new Date(this.params.data.date) <=
        new Date(String(mm().tz('US/Michigan').format('MM/DD/YYYY')).substring(0, 10))
    ) {
      
      this.disable = true;
    }
  }

  constructor() {}

  checkCheckBoxvalue(event) {
    if (this.params.column === 'supplierholiday') {
      this.params.data.supplierholiday = this.value;
    }
    if (this.params.column === 'getsudo') {
      this.params.data.getsudo = this.value;
    }
    if (this.params.column === 'namcholiday') {
      this.params.data.namcholiday = this.value;
    }
    if (this.params.column === 'faday') {
      this.params.data.faday = this.value;
    }

    this.params.context.componentParent.getEditObjInfo(
     
      this.params
    );
  }
}