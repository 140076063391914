import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-rundown-upload',
  templateUrl: './rundown-upload.component.html',
  styleUrls: ['./rundown-upload.component.scss']
})
export class RundownUploadComponent implements OnInit {

  vanningRangeEventEmitter = new EventEmitter();
  business_entity: any;
  vanningFrom: any;
  vanningTo: any;
  enableError: boolean = false;

  form: UntypedFormGroup = this.formBuilder.group({
    vanningFrom: ['', Validators.required],
    vanningTo: ['', Validators.required],
    
  });
  formcontrol=this.form.controls;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogeRef: MatDialogRef<RundownUploadComponent>,
    private formBuilder: UntypedFormBuilder
  ) {}
 
  ngOnInit(): void {
  
    console.log(this.data);
  }
  
  submitted: boolean = false;
  addVanningDetails() {
    if (this.form.invalid) {
      this.submitted = true;
      return;
    }
    console.log(this.form.value);

    const addEmittedData = {
      vanningFrom: this.form.value.vanningFrom,
      vanningTo: this.form.value.vanningTo,
      
    };

    this.vanningRangeEventEmitter.emit(addEmittedData);
    this.dialogeRef.close();
  }
  
}
