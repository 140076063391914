import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AgGridCheckboxComponent } from '../ag-grid-checkbox/ag-grid-checkbox.component';
import { DownloadComponent } from '../download/download.component';
import { JobgridService } from '../jobstatus/jobgrid.service';
import { ButtonrenderComponent } from './buttonrender/buttonrender.component';
import { OrderforecastService } from '../services/orderforecast/orderforecast.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, interval, Subscription } from 'rxjs';
import { autoRefreshInterval, checkAccess } from '../constants';
import { warningMessage } from '../constants';
import * as mm from 'moment-timezone';
import * as moment from 'moment';

import { trigger, state, style, animate, transition } from '@angular/animations';
import { GetSudoStatusService } from '../services/get-sudo-status/get-sudo-status.service';
import { filter } from 'rxjs/operators';
import { logData } from '../shared/logger';
import { Color } from 'ag-charts-community/dist/cjs/es5/sparklines-util';

@Component({
  selector: 'app-jobstatus',
  templateUrl: './jobstatus.component.html',
  styleUrls: ['./jobstatus.component.scss'],
  animations: [
    trigger('scroll', [
      state('on', style({ left: '-100px' })),
      transition('* => *', [style({ left: '-100px' }), animate(30000, style({ left: '80%' }))]),
    ]),
  ],
})
export class JobstatusComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  namcName = localStorage.getItem('namcName');
  user: string;
  userRole: any;
  indexOfObject: any;
  indexOfObject1: any;
  indexOfObject2: any;
  public gridColumnApi;
  public columnDefs;
  public columnDefs1;
  public defaultColDef;
  tooltipShowDelay = 0;
  public rowData: any;
  public rowData1: any;
  frameworkComponents: any;
  blrq_status: boolean;
  userName: string;
  holdreleaseNotification: any;
  jobGridOptions = {} as any;
  fileGridOptions = {} as any;
  vehicleFile: string;

  warningMessage = '';
  count = 0;
  getSudoDate;
  sudoDate = [];

  jobSubscription$: Subscription;
  dateFormat = 'MM/DD/YYYY';
  newDate = String(mm().tz('US/Michigan').format(this.dateFormat)).substring(0, 10);

  context: any;
  public modules = [];
  namc: string = '';
  @ViewChild('jobGrid') jobGrid;
  gridApi;
  toggleStatus: boolean;
  bulkadjustmentBatchRunLog: any;
  itContact: string;
  dailyOrderTrs = 'Daily Order Transmission';
  dailyPxpTrs = 'Daily PxP Transmission';
  bulkAdjustmentStatus: any;
  monthList: any;
  easternCurrentDate: any;
  currentMonth: any;
  currentYear: any;
  jobStatusInterval$: any;
  batchSchedule: any;
  isgetsudo: boolean;

  constructor(
    private readonly JobgridService: JobgridService,
    private readonly service: OrderforecastService,
    private readonly spinner: NgxSpinnerService,
    private readonly stateService: OSPStateService,
    private readonly getSudoService: GetSudoStatusService
  ) {
    this.frameworkComponents = {
      buttonRenderer: ButtonrenderComponent,
    };
    this.context = {
      componentParent: this,
    };
  }
  ngOnDestroy(): void {
    this.jobSubscription$.unsubscribe();

    console.log('Stopped Auto Refresh for Job Status!');
    this.jobStatusInterval$.unsubscribe();
  }
  ngOnInit(): void {
    // first table columns

    this.jobGridOptions = this.JobgridService.getGridOptions();
    this.jobGridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };

    this.fileGridOptions = this.JobgridService.getGridOptions();
    this.fileGridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };

    this.columnDefs = [
      {
        headerName: 'Actions',
        cellRendererFramework: AgGridCheckboxComponent,
        sortable: false,
        suppressSizeToFit: true,
        field: 'currentstatus',
        width: 198,
        floatingFilter: false,
      },
      {
        headerName: 'Activities',
        field: 'batch_name',
        //cellRenderer: 'agGroupCellRenderer',
        sortable: false,
        suppressSizeToFit: true,
        width: 270,
        floatingFilter: false,
        valueGetter: (params) => {
          if (params.data.batch_name === 'Initial Order Calculation Process') {
            return 'Initial Order Calculation Process (CALC1)';
          } else {
            return params.data.batch_name;
          }
        },
      },
      {
        headerName: 'Start DateTime',
        field: 'start_date',
        sortable: false,
        width: 230,
        floatingFilter: false,
        headerTooltip: 'mm/dd/yyyy hh:mm:ss (Eastern)',
        cellStyle: function (params) {
          var dateFormat = 'MM/DD/YYYY';
          var newDate = String(mm().tz('US/Michigan').format(dateFormat)).substring(0, 10);

          if (
            moment(params.data.start_date).isSame(new Date(newDate), 'day') &&

            moment(params.data.end_date).isSame(new Date(newDate), 'day') &&
            params.data.status === 'COMPLETE'
          ) {
            return { background: 'rgb(225, 255,221)', color: 'rgb(37,170,0)' }; // green color

          }
        },
      },
      {
        headerName: 'End DateTime',
        field: 'end_date',
        sortable: false,
        width: 230,
        floatingFilter: false,
        headerTooltip: 'mm/dd/yyyy hh:mm:ss (Eastern)',
        cellStyle: function (params) {
          var dateFormat = 'MM/DD/YYYY';
          var newDate = String(mm().tz('US/Michigan').format(dateFormat)).substring(0, 10);

          if (
            moment(params.data.end_date).isSame(new Date(newDate), 'day') &&
            moment(params.data.start_date).isSame(new Date(newDate), 'day') &&
            params.data.status === 'COMPLETE'
          ) {
            return { background: 'rgb(225, 255,221)', color: 'rgb(37,170,0)' }; // green color
          }
        },
      },
      {
        headerName: 'Duration',
        field: 'duration',
        sortable: false,
        width: 142,
        headerTooltip: 'hh:mm:ss',
        floatingFilter: false,
      },
      {
        headerName: 'User',
        field: 'userid',
        sortable: false,
        width: 230,
        floatingFilter: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        sortable: false,

        width: 200,
        floatingFilter: false,
        cellRenderer: 'buttonRenderer',

        cellRendererParams: {
          label: 'Click 2',
        },
      },
    ];

    // second table columns
    this.columnDefs1 = [
      {
        headerName: 'Actions',
        cellRendererFramework: AgGridCheckboxComponent,
        sortable: false,
        suppressSizeToFit: true,
        width: 198,
      },
      {
        headerName: 'Activities',
        field: 'batch_name',
        sortable: false,
        suppressSizeToFit: true,
        width: 260,
      },
      {
        headerName: 'Latest Transmission',
        headerTooltip: 'mm/dd/yyyy hh:mm:ss',
        field: 'date_time',
        sortable: false,
        width: 230,
      },
      {
        headerName: 'User',
        field: 'userid',
        sortable: false,
        width: 230,
      },
      {
        headerName: 'Download File',
        cellRendererFramework: DownloadComponent,
        field: 'downloadfile',
        sortable: false,
        width: 572,
      },
    ];

    this.jobSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
      this.startAutoRefresh();
    });
  }

  startAutoRefresh(): void {
    if (this.jobStatusInterval$) return;

    console.log('Started Auto Refresh for Job Status!');
    this.jobStatusInterval$ = interval(autoRefreshInterval)
      .pipe(filter(() => !document.hidden))
      .subscribe(() => {
        this.refresh();
      });
  }

  access1;
  initializeData() {
    this.removeMessage();
    this.spinner.show();
    this.getMonthYear();
    this.itContact = localStorage.getItem('itContact');

    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('namcSelected')) {
      this.namc = JSON.parse(localStorage.getItem('namcSelected') as string);
    }
    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    /* istanbul ignore if*/
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    this.userName = localStorage.getItem('UserName');
    //access permissions

    const jobstatusPermission = checkAccess();
    this.access1 = jobstatusPermission.holdrelease;
    localStorage.setItem('jobstatus', this.access1);

    //find filestatus indexces

    //getjob
    const data = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
      easternCurrentDate: String(mm().tz('US/Michigan').format('YYYY-MM-DD')),
    };

    const dataCalendar = {
      event: 'Getsudo',
      workid: this.user,
      user_role: this.userRole,
      business_entity: this.namcValue,

      month: this.currentMonth.value < 10 ? '0' + this.currentMonth.value : this.currentMonth.value,
      year: this.currentYear,
    };

    const jobApiSubScriptions = [
      this.service.getJob(data),
      this.service.getToggle(data),
      this.service.getFile(data),
      this.service.getSchedule(data),
      this.getSudoService.fetchGetSudoDates(dataCalendar),
    ];

    forkJoin(jobApiSubScriptions).subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.batchSchedule = res[3].body.schedule;

        this.sudoDate = res[4].body.calenderResponse.map((row) => {
          const rowDate = row.start_date.split('T')[0].split('-');

          const date = `${rowDate[1]}/${rowDate[2]}/${rowDate[0]}`;
          return { date };
        });

        this.isgetsudo = this.loadGetSudoDates();

        this.holdreleaseNotification = res[1].body.getJobResponse.holdReleaseUITrack;

        if (res[0].body.getJobResponse?.length > 0) {
          for (let k = 0; k < res[0].body.getJobResponse.length; k++) {
            res[0].body.getJobResponse[k].duration = res[0].body.getJobResponse[k].duration.replace('::', '');

            if (res[0].body.getJobResponse[k].userid === null) {
              res[0].body.getJobResponse[k].userid = 'OSP';
            }
            if (res[0].body.getJobResponse[k].batch_name === 'Bulk Adjustment Process') {
              this.bulkAdjustmentStatus = res[0].body.getJobResponse[k].status;
              this.bulkadjustmentBatchRunLog = res[0].body.getJobResponse[k].batch_run_logid;
            }
            if (
              res[0].body.getJobResponse[k].batch_name === 'Build Requirement (SAP)' &&
              res[0].body.getJobResponse[k].start_date === null &&
              res[0].body.getJobResponse[k].end_date === null
            ) {
              res[0].body.getJobResponse[k].slice(k, 1);
            }
            if (
              res[0].body.getJobResponse[k].batch_name === 'Build Requirement (UALC)' &&
              res[0].body.getJobResponse[k].start_date === null &&
              res[0].body.getJobResponse[k].end_date === null
            ) {
              res[0].body.getJobResponse[k].slice(k, 1);
            }
            if (
              res[0].body.getJobResponse[k].batch_name === 'Build Requirement (UALC)' &&
              res[0].body.getJobResponse[k].start_date === null &&
              res[0].body.getJobResponse[k].end_date === null
            ) {
              res[0].body.getJobResponse[k].slice(k, 1);
            }
          }

          // this.service.getToggle(data).subscribe((res1) => {

          const i = res[0].body.getJobResponse.findIndex((element) => {
            if (element.batch_name.toLowerCase() == 'Initial Order Calculation Process'.toLowerCase()) {
              element.timeField = 'initial_calc_starttime';
              return true;
            }
          });
          if (i > -1) {
            res[0].body.getJobResponse[i].currentstatus = res[1].body.getJobResponse.response[0]
              ? res[1].body.getJobResponse.response[0].initial_order_hold_status
              : null;
          }

          //final order
          const j = res[0].body.getJobResponse.findIndex((element) => {
            if (element.batch_name.toLowerCase() === 'Final Order Batch (FINAL CALC)'.toLowerCase()) {
              element.timeField = 'final_calc_starttime';
              return true;
            }
          });
          if (j > -1) {
            res[0].body.getJobResponse[j].currentstatus = res[1].body.getJobResponse.response[0]
              ? res[1].body.getJobResponse.response[0].final_order_hold_status
              : null;
          }

          this.rowData = res[0].body.getJobResponse;
          // for (let i = 0; i < this.rowData.length; i++ {

          // }

          //"Build Requirement (UALC)"
          // this.rowData = res[0].body.getJobResponse.filter((x) => {
          //   if (
          //     x.batch_name === 'Build Requirement (SAP)' &&
          //     x.start_date === null &&
          //     x.end_date === null
          //   ) {
          //     return false;
          //   } else {
          //     return true;
          //   }
          // });
          // this.rowData = this.rowData.filter((x) => {
          //   if (
          //     x.batch_name === 'Build Requirement (UALC)' &&
          //     x.start_date === null &&
          //     x.end_date === null
          //   ) {
          //     return false;
          //   } else {
          //     return true;
          //   }
          // });
          // this.rowData = this.rowData.filter((x) => {
          //   if (
          //     x.batch_name === 'Build Requirement (NQC)' &&
          //     x.start_date === null &&
          //     x.end_date === null
          //   ) {
          //     return false;
          //   } else {
          //     return true;
          //   }
          // });
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        if (res[2].body.getFileResponse?.length > 0) {
          this.indexOfObject = res[2].body.getFileResponse.findIndex((element) => {
            if (element.batch_name === 'FOD - Unit Requirement') {
              return true;
            }
          });
          this.indexOfObject1 = res[2].body.getFileResponse.findIndex((element) => {
            if (element.batch_name === 'FOD - Non Vehicle Requirement') {
              return true;
            }
          });
          this.indexOfObject2 = res[2].body.getFileResponse.findIndex((element) => {
            if (element.batch_name === 'FOD - Vehicle Requirement') {
              this.vehicleFile = element.userid;
              return true;
            }
          });

          const i = res[2].body.getFileResponse.findIndex((element) => {
            if (element.batch_name === this.dailyOrderTrs) {
              element.timeField = 'tmcFileTransmissionTime';
              return true;
            }
          });

          res[2].body.getFileResponse[i].currentstatus = res[1].body.getJobResponse.response[0].daily_order_hold_status;

          const j = res[2].body.getFileResponse.findIndex((element) => {
            if (element.batch_name === this.dailyPxpTrs) {
              element.timeField = 'tmcFileTransmissionTime';
              return true;
            }
          });

          res[2].body.getFileResponse[j].currentstatus = res[1].body.getJobResponse.response[0].daily_pxp_hold_status;

          if (
            // this.namcName == 'TMMK' ||
            this.namcName === 'TMMI' ||
            this.namcName === 'TMMTX' ||
            this.namcName === 'TMMMS' ||
            this.namcName === 'TMMGT' ||
            this.namcName === 'MTM'
          ) {
            res[2].body.getFileResponse.splice(this.indexOfObject, 1);
            this.rowData1 = res[2].body.getFileResponse;
          } else if (this.namcName == 'TMMAL' || this.namcName == 'TMMWV') {
            res[2].body.getFileResponse.splice(this.indexOfObject1, 1);
            res[2].body.getFileResponse.splice(this.indexOfObject2, 1);
            this.rowData1 = res[2].body.getFileResponse;
          } else if (this.namcName === 'TMMBC') {
            res[2].body.getFileResponse.splice(this.indexOfObject, 1);
            res[2].body.getFileResponse.splice(this.indexOfObject1, 1);
            this.rowData1 = res[2].body.getFileResponse;
          } else {
            this.rowData1 = res[2].body.getFileResponse;
          }
          this.rowData1 = this.rowData1.map((row) => {
            return {
              ...row,
              userid: !!!row.userid ? 'OSP' : row.userid,
            };
          });
        } else {
          this.spinner.hide();
        }
      

       this.enableDisableToggles();
         // on getsudo day if any of the batch status is error
        //disable initial and final toggle button
        const finalOrderIndex = res[0].body.getJobResponse.findIndex(
          (element) => element.batch_name.toLowerCase() === 'Final Order Batch (FINAL CALC)'.toLowerCase()
        );
        const initialOrderIndex = res[0].body.getJobResponse.findIndex(
          (element) => element.batch_name.toLowerCase() === 'Initial Order Calculation Process'.toLowerCase()
        );
        const ordrIndx = this.rowData1.findIndex(
          (element) => element.batch_name.includes('Order Transmission')
        )
        const pxpIndx = this.rowData1.findIndex(
          (element) => element.batch_name.includes('PxP Transmission')
        )
        const currentESTDate = String(mm().tz('US/Michigan').format('MM/DD/YYYY'));
        if (this.isgetsudo) {
          
          //exclude buld adj from response           
          let batchesToCheck = res[0].body.getJobResponse.filter((element) => {
            return (element.batch_name !== 'FA Monthly Process' && element.batch_name !== 'Bulk Adjustment Process'
            && !(element.batch_name.toLowerCase().includes('calc')));
          })

          console.log({batchesToCheck, fullBody : this.rowData1});
          
          const initialCalcFreeze = (batchesToCheck.filter((element) => {
            return (element.start_date.includes(currentESTDate) &&
            element.status === 'COMPLETE')
            }).length === batchesToCheck.length);
          console.log("initialCalcFreeze",initialCalcFreeze)
          if (!initialCalcFreeze) {
            console.log({finalOrderIndex, initialOrderIndex, ordrIndx, pxpIndx});
              res[0].body.getJobResponse[initialOrderIndex].disableToggle = true;
              res[0].body.getJobResponse[finalOrderIndex].disableToggle = true;
              this.rowData1[ordrIndx].disableToggle = true;
              this.rowData1[pxpIndx].disableToggle = true;
          }
          if (!(res[0].body.getJobResponse[finalOrderIndex].start_date.includes(currentESTDate) &&
          res[0].body.getJobResponse[finalOrderIndex].status === 'COMPLETE')){
            this.rowData1[ordrIndx].disableToggle = true;
            this.rowData1[pxpIndx].disableToggle = true;
          }
        }
      },
    });
  }

  enableDisableToggles() {
    this.handleEnableDisable(this.rowData, 'start_date');
    this.handleEnableDisable(this.rowData1, 'date_time');

    this.spinner.hide();
  }

  handleEnableDisable(data, dateField) {
    for (let index = 0; index < data.length; index++) {
      const executionDate = data[index][dateField];

      if (!executionDate) continue; // If for some reason, dateField doesn't have any value

      let { datePartString } = this.getDateTime(executionDate);

      const currentESTDateString = String(mm().tz('US/Michigan').format('MM/DD/YYYY'));

      const currentESTDate = new Date(currentESTDateString);
      const batchRunDate = new Date(datePartString);

      // If batch run date is same or later than today's est date
      data[index].disableToggle = batchRunDate >= currentESTDate;

      const todayIsSatOrSun = this.isTodaySatOrSun();

      if (!this.isgetsudo && todayIsSatOrSun) {
        data[index].disableToggle = false;
      }

      // This is to disable Releasing Final Calc
      // Final Calc will stay disabled until Initial Calc has run
      if (data[index].batch_name.toLowerCase() === 'Final Order Batch (FINAL CALC)'.toLowerCase()) {
        this.handleFinalCalcEnableDisable({ finalCalc: data[index], data, currentESTDate, todayIsSatOrSun });
      }
    }
  }

  isTodaySatOrSun() {
    const today = mm().tz('US/Michigan').format('dddd');

    return today === 'Saturday' || today === 'Sunday';
  }

  handleFinalCalcEnableDisable({ finalCalc, data, currentESTDate, todayIsSatOrSun }) {
    const finalCalcHoldStatus = finalCalc.currentstatus;

    logData({ finalCalcHoldStatus, todayIsSatOrSun });

    // if it is not on hold, handle based on common code above in handleEnableDisable
    if (!finalCalcHoldStatus) return;

    // if it is not getsudo today and is is saturday/sunday
    if (!this.isgetsudo && todayIsSatOrSun) {
      finalCalc.disableToggle = false; // enable final calc toggle
      return;
    }

    // If final calc batch is on hold

    const initialCalc = data.find(
      (element) => element.batch_name.toLowerCase() === 'Initial Order Calculation Process'.toLowerCase()
    );

    logData({ initialCalc, finalCalc });

    if (
      initialCalc.currentstatus || // initial calc is on hold
      initialCalc.status !== 'COMPLETE' || // initial calc has not completed
      !initialCalc.end_date // initial calc doesn't have end date
    ) {
      finalCalc.disableToggle = true; // disable final calc toggle button
      return;
    }

    // if initial calc has completed
    const currentESTTimeString = String(mm().tz('US/Michigan').format('MM/DD/YYYY HH:mm:ss'));
    const currentESTTime = this.getCustomDate(this.getDateTime(currentESTTimeString));

    const { datePartString } = this.getDateTime(initialCalc.end_date);
    const initialCalcEndDate = new Date(datePartString);

    // Scheduled Time for comparison
    const scheduledTimeString = this.batchSchedule?.[initialCalc.timeField];
    if (
      !scheduledTimeString ||
      this.isgetsudo // getsudo today - if it is getsudo today, then we only need to check whether initial calc has completed or not
    ) {
      // If batch is not scheduled for today, and initial calc has not run today - disable final calc toggle button
      finalCalc.disableToggle = currentESTDate > initialCalcEndDate;
      return;
    }

    const [hours, minutes] = scheduledTimeString.split(':');
    const initialCalcScheduledTime = this.getCustomDate({ hours, minutes });
    // const initialCalcScheduledTime = this.getCustomDate({ hours: 0, minutes: 0 }); // This is for testing

    // If it is past the scheduled time
    if (currentESTTime >= initialCalcScheduledTime) {
      finalCalc.disableToggle = currentESTDate > initialCalcEndDate;
      return;
    }

    // if none of above conditions matched then
    // handle based on common code above in handleEnableDisable
  }

  getDateTime(dateString) {
    const [datePartString, timeString] = dateString.split(' ');

    const [hours, minutes, seconds] = timeString.split(':');

    return { datePartString, hours, minutes, seconds };
  }

  errorCallback = (error) => {
    console.error(error);
    this.spinner.hide();

    if (error?.error?.message?.required_fields) {
      this.warningMessage = `${warningMessage.requiredFieldsMissing}  ${this.itContact}.`;
    } else {
      this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
    }
  };
  userToggle(checked, data) {
    let isgetsudo;
    this.spinner.show();

    const dataCalendar = {
      event: 'Getsudo',
      workid: this.user,
      user_role: this.userRole,
      business_entity: this.namcValue,

      month: this.currentMonth.value < 10 ? '0' + this.currentMonth.value : this.currentMonth.value,
      year: this.currentYear,
    };

    const info = this.batchStatus(checked, data);
    let initial_order_hold_status;
    let final_order_hold_status;
    let daily_order_hold_status;
    let daily_pxp_hold_status;
    let s3FileName;

    if (data?.batch_name.toLowerCase() === 'Initial Order Calculation Process'.toLowerCase()) {
      initial_order_hold_status = checked;
    }
    if (data?.batch_name.toLowerCase() === 'Final Order Batch (FINAL CALC)'.toLowerCase()) {
      final_order_hold_status = checked;
    }
    if (data?.batch_name.toLowerCase() === this.dailyPxpTrs.toLowerCase()) {
      daily_pxp_hold_status = checked;
    }
    if (data?.batch_name.toLowerCase() === this.dailyOrderTrs.toLowerCase()) {
      daily_order_hold_status = checked;
      // s3FileName='daily_order'
    }

    this.getSudoService.fetchGetSudoDates(dataCalendar).subscribe((res) => {
      this.sudoDate = res.body.calenderResponse.map((row) => {
        const rowDate = row.start_date.split('T')[0].split('-');

        const date = `${rowDate[1]}/${rowDate[2]}/${rowDate[0]}`;
        return { date };
      });

      isgetsudo = this.loadGetSudoDates();

      if (!isgetsudo) isgetsudo = this.handleTriggerOrSchedule(data);

      //release job
      var data1 = {
        business_entity: this.namcValue,
        workid: this.user,
        user_role: this.userRole,
        initial_order_hold_status: initial_order_hold_status !== undefined ? '' + initial_order_hold_status : null,
        daily_order_hold_status: daily_order_hold_status !== undefined ? '' + daily_order_hold_status : null,
        final_order_hold_status: final_order_hold_status !== undefined ? '' + final_order_hold_status : null,
        daily_pxp_hold_status: daily_pxp_hold_status !== undefined ? '' + daily_pxp_hold_status : null,
        info: info,
        isgetsudo: isgetsudo,
      };

      this.service.releaseJob(data1).subscribe((res) => {
        const toggleData = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          easternCurrentDate: String(mm().tz('US/Michigan').format('YYYY-MM-DD')),
        };

        this.service.getToggle(toggleData).subscribe((res) => {
          //
          this.initializeData();
          this.spinner.hide();
        });
      });

      // update file copy table
      let dailyOrderToggleStatus;
      let dailyOrderToggleStatus1;
      let dailypxpToggleStatus;
      let dailypxpToggleStatus1;

      if (data?.batch_name.toLowerCase() === this.dailyOrderTrs.toLowerCase()) {
        dailyOrderToggleStatus = checked;
        if (dailyOrderToggleStatus === true) {
          dailyOrderToggleStatus1 = 'false';
        } else {
          dailyOrderToggleStatus1 = 'true';
        }
        s3FileName = 'OSDAILY.DPO';

        const fileData = {
          business_entity: this.namcValue,
          namcName: this.namcName,
          workid: this.user,
          user_role: this.userRole,
          mapFileName: s3FileName,
          toggleState: dailyOrderToggleStatus1,
          isgetsudo: isgetsudo,
        };

        console.log(fileData,'fileData')

        this.service.getFileToggle(fileData).subscribe((res) => {});
      }

      if (data?.batch_name.toLowerCase() === this.dailyPxpTrs.toLowerCase()) {
        dailypxpToggleStatus = checked;
        if (dailypxpToggleStatus === true) {
          dailypxpToggleStatus1 = 'false';
        } else {
          dailypxpToggleStatus1 = 'true';
        }
        daily_order_hold_status = checked;
        s3FileName = `${this.namcName}_pxp_output`;

        const fileData = {
          business_entity: this.namcValue,
          workid: this.user,
          user_role: this.userRole,
          mapFileName: s3FileName,
          toggleState: dailypxpToggleStatus1,
          namcName: this.namcName,
          isgetsudo: isgetsudo,
        };

        this.service.getFileToggle(fileData).subscribe((res) => {
          console.log(res);
        });
      }
    });
  }

  getCustomDate({ hours, minutes }: { hours: number; minutes: number }) {
    const date = new Date();

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(0);
    date.setMilliseconds(0);

    return date;
  }

  handleTriggerOrSchedule(toggledEvent) {
    const todayIsSatOrSun = this.isTodaySatOrSun();
    if (todayIsSatOrSun) return false; // if today is saturday or sunday, don't trigger

    const { timeField } = toggledEvent;

    // EST Time for comparison
    const currentESTTimeString = String(mm().tz('US/Michigan').format('MM/DD/YYYY HH:mm:ss'));
    const currentESTTime = this.getCustomDate(this.getDateTime(currentESTTimeString));
    // const currentESTTime = this.getCustomDate({ hours: 16, minutes: 31 }); // This is for testing

    // Scheduled Time for comparison
    const scheduledTimeString = this.batchSchedule?.[timeField];
    if (!scheduledTimeString) return false; // If there is no sceduled time, don't trigger

    const [hours, minutes] = scheduledTimeString.split(':');
    const scheduledTime = this.getCustomDate({ hours, minutes });

    logData(currentESTTime, scheduledTime, currentESTTime > scheduledTime);

    // Trigger (return true) if current time is past the scheduled time
    return currentESTTime > scheduledTime;
    // return true
  }

  batchStatus(checked, data) {
    let status;
    if (checked == true) {
      status = 'HOLD';
    } else {
      status = 'RELEASE';
    }
    const info = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      status: status,
      batch_name: data.batch_name,
      userid: this.userName,
    };
    return info;
  }

  getFileName(data, type) {
    if (data === 'FOD - Vehicle Requirement') {
      let vehicleFile = this.vehicleFile as any;
      vehicleFile = vehicleFile.toLowerCase().replaceAll(' ', '');
      if (vehicleFile.includes('getsudo')) {
        return 'GETSUDO_BLDREQ';
      } else {
        return 'DAILY_BLDREQ';
      }
    }
    if (data === 'FOD - Non Vehicle Requirement') {
      return 'SAP_BLDREQ';
    } else if (data === 'FOD - Unit Requirement') {
      return 'UALC_BLDREQ';
    } else if (data === 'Monthly FA Transmission') {
      return 'FA_input';
    } else if (data === this.dailyOrderTrs) {
      if (type == '.xls') {
        return 'Final_Order';
      } else {
        return 'OSDAILY.DPO';
      }
    } else if (data === this.dailyPxpTrs) {
      if (type == '.xls') {
        return `${this.namcName}_pxp_forecast_report`;
      } else {
        return `${this.namcName}_pxp_output`;
      }
    } else if (data === 'CCS Input File') {
      return 'CCS_RESP';
    } else if (data === 'Daily Order Generation') {
      if (type == '.xls') {
        return 'Final_Order';
      } else {
        return 'OSDAILY.DPO';
      }
    } else if (data === 'Daily PxP Generation') {
      if (type == '.xls') {
        return `${this.namcName}_pxp_forecast_report`;
      } else {
        return `${this.namcName}_pxp_output`;
      }
    }
  }

  //download file
  getdownloadTemplate(data, type) {
    this.spinner.show();
    this.removeMessage();
    if (data.batch_name === 'CCS Input File') {
      type = '.json';
    } else {
      if (type === '.xls') {
        type = '.xls';
      } else {
        type = '.txt';
      }
    }
    var data3 = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      namcName: this.namcName,
      processName: data.batch_name,
      fileName: this.getFileName(data.batch_name, type),
      type: type,
    };

    this.service.getTemplate(data3).subscribe({
      error: this.errorCallback,
      next: (success) => {
        if (success.body.jobReportResponse) {
          const { message, error, url } = success.body.jobReportResponse;
          if (error) {
            this.warningMessage = `${warningMessage.fileNotAva}`;
            window.scroll(0, 0);
          }
          if (message === null) {
            this.warningMessage = `${warningMessage.fileNotAva}`;
            window.scroll(0, 0);
          } else {
            saveAs(url);
          }

          // const a = document.createElement('a');

          // a.href = success.body.jobReportResponse;
          // //console.log(a.href);
          // a.target = '_blank';
          // a.rel = 'noreferrer noopener';
          // if (a) {
          //   window.location.href = a.href;
          // }
          this.spinner.hide();
        }
        if (success.body.error) {
          this.warningMessage = `${warningMessage.fileNotAva}`;
          window.scroll(0, 0);
          this.spinner.hide();
        }
      },
    });
  }

  removeMessage() {
    this.warningMessage = '';
  }
  bulkAdjustmentDownload() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      batchRunLog: this.bulkadjustmentBatchRunLog,
    };
    this.service.getbulkAdjustmentDownloadPath(data).subscribe({
      error: this.errorCallback,
      next: (success) => {
        console.log(success, 'success');
        if (success) {
          if (success.body.error) {
            this.spinner.hide();
            this.warningMessage = `${warningMessage.fileNotAva}`;
            window.scroll(0, 0);
          }
          const { message, error, url } = success.body.bulkAdjustmentS3Download;
          if (error) {
            this.spinner.hide();
            this.warningMessage = `${warningMessage.fileNotAva}`;
            window.scroll(0, 0);
          }
          if (message === null) {
            this.spinner.hide();
            this.warningMessage = `${warningMessage.fileNotAva}`;
            window.scroll(0, 0);
          } else {
            //saveAs(url);
            const a = document.createElement('a');

            a.href = url;
            a.target = '_blank';
            a.rel = 'noreferrer noopener';
            if (a) {
              window.location.href = a.href;
            }

            if (url.includes('upload') && this.bulkAdjustmentStatus === 'ERROR') {
              this.warningMessage =
                'Invalid headers found in uploaded template.Please download template from Bulk adjustment Screen for reference.';
            }
            this.spinner.hide();
          }
        }
      },
    });
  }
  refresh() {
    this.removeMessage();

    this.stateService.setNamc({
      namc: localStorage.getItem('namcSelected'),
      WorkdayID: '',
      Name: '',
      Role: '',
    });

    this.initializeData();
  }

  loadGetSudoDates() {
    let isTodayGetSudo = false;

    this.getSudoDate = this.sudoDate.filter((getsudo) => {
      return moment(getsudo.date).isSame(new Date(this.newDate), 'day');
    });

    if (this.getSudoDate.length !== 0) {
      isTodayGetSudo = true;
    }

    return isTodayGetSudo;
  }
  getMonthYear() {
    this.easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD')).substring(0, 10);
    console.log(this.easternCurrentDate);

    this.monthList = [
      {
        label: 'January',
        value: 1,
      },
      {
        label: 'February',
        value: 2,
      },
      {
        label: 'March',
        value: 3,
      },
      {
        label: 'April',
        value: 4,
      },
      {
        label: 'May',
        value: 5,
      },
      {
        label: 'June',
        value: 6,
      },
      {
        label: 'July',
        value: 7,
      },
      {
        label: 'August',
        value: 8,
      },
      {
        label: 'September',
        value: 9,
      },
      {
        label: 'October',
        value: 10,
      },
      {
        label: 'November',
        value: 11,
      },
      {
        label: 'December',
        value: 12,
      },
    ];
    this.currentYear = this.easternCurrentDate.slice(0, 4);

    const month = this.easternCurrentDate.slice(5, 7);

    this.currentMonth = this.monthList[month - 1];
  }
}
