import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      (keypress)="onKeyPress($event)"
      maxlength="8"
      pattern="[0-9]"
      [(ngModel)]="value"
      [ngClass]="{ 'invalid-text': isEmpty }"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class GridVanningVolEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  lastDayOfVanMonth = localStorage.getItem('lastDayOfVanMonth');

  constructor() {}

  agInit(params: any): void {
    params.data.rundownVanningVol = this;
    if (params.column === 'vanning_qty') {
      this.value = params.data.vanning_qty;
      if (this.value > 0) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }
    var d1 = new Date();
    var d2 = new Date(params.data.calc_date);
    this.params = params;
    var calcDateFuture = moment(d2).isAfter(d1, 'day');

    var iscurrentDate = moment(d2).isSame(new Date(), 'day');

    if (
      this.params.data.checkedEditMode &&
      (calcDateFuture || iscurrentDate) &&
      params.data.calc_status !== '' &&
      new Date(this.params.data.vanning_date) > new Date(this.lastDayOfVanMonth)
    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'vanning_qty') {
      if (this.value !== this.params.data.vanning_qty) {
        this.params.data.vanning_qty = this.value;
        this.params.data.isVanningVolUpdated = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }
  // ((event.charCode >= 48 && event.charCode <= 57) ||
  // (event.charCode === 45 && event.target.value === '')) &&
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
}
