<div class="row header1 movable" cdkDrag cdkDragHandle cdkDragRootElement=".cdk-overlay-pane">
  <div class="col">
    <h3 class="head">

      {{headerName}}
      <span class="badge">{{ rowData.length }}</span> &nbsp;&nbsp;
      <span>
        <img class="img-tool" (click)="exportAsXLSX()" src="./assets/images/download.svg" alt="download icon"
          title="Download" />
      </span>


    </h3>
  </div>

  <div class="col-md-1">
    <button type="button" class="btn btn-dark btn-circle btn-xl" mat-dialog-close="cancel" data-dismiss="modal"
      aria-label="Close">
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>

<div class="col-md-12">
  <ag-grid-angular *ngIf="selectedPopupName == 'IN-TRANSIT'" #osPartsGrid
 class="ag-theme-balham" [rowData]="rowData"style="
 width: 100%;
 margin-top: 7px;
 height: 400px !important;
 top: 3%;
 margin-bottom: 3%;"
    [columnDefs]="inTransitcolumnDefs" [rowSelection]="rowSelection" [gridOptions]="gridOptions"
    [paginationPageSize]="paginationPageSize" [context]="context" [editType]="editType" [pagination]="true"
    [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
    [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true" [headerHeight]="headerHeight">
  </ag-grid-angular>

  <ag-grid-angular *ngIf="selectedPopupName == 'NAMC ARRIVED'" #osPartsGrid
  style="
  width: 100%;
  margin-top: 7px;
  height: 400px !important;
  top: 3%;
  margin-bottom: 3%;" class="ag-theme-balham" [rowData]="rowData"
    [columnDefs]="NamcArrivedcolumnDefs" [rowSelection]="rowSelection" [gridOptions]="gridOptions"
    [paginationPageSize]="paginationPageSize" [context]="context" [editType]="editType" [pagination]="true"
    [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
    [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true" [headerHeight]="headerHeight">
  </ag-grid-angular>


  <ag-grid-angular *ngIf="selectedPopupName == 'VANNING COMPLETE'" #osPartsGrid
  style="
  width: 100%;
  margin-top: 7px;
  height: 400px !important;
  top: 3%;
  margin-bottom: 3%;" class="ag-theme-balham" [rowData]="rowData"
    [columnDefs]="vanningCompletedColdef" [rowSelection]="rowSelection" [gridOptions]="gridOptions"
    [paginationPageSize]="paginationPageSize" [context]="context" [editType]="editType" [pagination]="true"
    [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
    [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true" [headerHeight]="headerHeight">
  </ag-grid-angular>

  <ag-grid-angular *ngIf="selectedPopupName == 'DEVANNED'" #osPartsGrid 
  style="
  width: 100%;
  margin-top: 7px;
  height: 400px !important;
  top: 3%;
  margin-bottom: 3%;"class="ag-theme-balham" [rowData]="rowData" [columnDefs]="devannedColdef" [rowSelection]="rowSelection"
    [gridOptions]="gridOptions" [paginationPageSize]="paginationPageSize" [context]="context" [editType]="editType"
    [pagination]="true" [suppressClickEdit]="suppressClickEdit" [frameworkComponents]="frameworkComponents"
    [tooltipShowDelay]="tooltipShowDelay" [tooltipMouseTrack]="true" [headerHeight]="headerHeight">
  </ag-grid-angular>
</div>