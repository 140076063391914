<div *ngIf="data">
  <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <div class="col-sm-11 movable">
      <h3
        class="head"
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        {{ data.mode | uppercase }} MASTER DATA
      </h3>
    </div>

    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        [mat-dialog-close]="true"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>
  </div>
  <form [formGroup]="addOrEditform">
    <div class="row">
      <div class="col-md-6">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label required class="searchLable">MASTER ID </label>

          <p-dropdown
            id="selSupplier"
            [options]="data.masterData"
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            [showTransitionOptions]="'0ms'"
            placeholder="Select"
            optionLabel="master_id"
            formControlName="masterId"
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'"
            autoWidth="true"
            [style]="{ width: '100%' }"
            (onChange)="setName()"
            appendTo="body"
            scrollHeight="180px"
          >
          </p-dropdown>
          <ng-container *ngIf="addOrEdit.masterId.errors && submitted">
            <small *ngIf="addOrEdit.masterId.errors?.required">
              MasterId is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-6 col-lg-6">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">MASTER NAME</label>
          <p-dropdown
            id="selSupplier"
            [options]="data.masterData"
            [filter]="true"
            [virtualScroll]="true"
            [itemSize]="30"
            [showTransitionOptions]="'0ms'"
            placeholder="Select"
            optionLabel="name"
            formControlName="masterName"
            [resetFilterOnHide]="true"
            [hideTransitionOptions]="'0ms'"
            autoWidth="true"
            [style]="{ width: '100%' }"
            (onChange)="setId()"
            appendTo="body"
            scrollHeight="180px"
          >
          </p-dropdown>
          <ng-container *ngIf="addOrEdit.masterName.errors && submitted">
            <small *ngIf="addOrEdit.masterName.errors?.required">
              MasterName is required.
            </small>
          </ng-container>
        </div>
      </div>

      <div class="col-sm-6" style="margin-top: 10px">
        <div class="drpchildctrl">
          <span class="v1"></span>
          <label class="searchLable">KEY ITEM</label>
          <input
            class="key"
            type="text"
            autoWidth="false"
            formControlName="keyItem"
            placeholder="--Type--"
            ng-required="true"
          />
          <ng-container *ngIf="addOrEdit.keyItem.errors && submitted">
            <small *ngIf="addOrEdit.keyItem.errors?.required">
              Key Item is required.
            </small>
          </ng-container>
        </div>
      </div>
      <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
        <div class="drpchildctrl" style="width: 100%">
          <span class="v1"></span>
          <label class="searchLable">DATA ITEM</label>
          <input
            class="key"
            type="text"
            autoWidth="false"
            formControlName="dataItem"
            placeholder="--Type--"
            required
          />
          <ng-container *ngIf="addOrEdit.dataItem.errors && submitted">
            <small *ngIf="addOrEdit.dataItem.errors?.required">
              Data Item is required.
            </small>
          </ng-container>
        </div>
      </div>
    </div>
  </form>
  <div class="text-right" style="margin-top: 20px">
    <button class="review_btn" mat-button (click)="callRespectiveFunction()">
      Save
    </button>

    <button class="cancel_btn" mat-button mat-dialog-close="cancel">
      Cancel
    </button>
  </div>
</div>
