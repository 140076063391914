import { Component, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';



@Component({
  selector: 'app-containerundevan-dialog',
  templateUrl: './containerundevan-dialog.component.html',
  styleUrls: ['./containerundevan-dialog.component.scss']
})
export class ContainerundevanDialogComponent {
  rowdata: any=[];
  renban: any=[];
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,


  ) {}

  ngOnInit(): void {
    
    
    this.renban=this.data.container_renban

  }




}
