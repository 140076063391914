import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	EnvironmentConfiguration, Environments,
	environments
} from './services/environment.configuration';


@NgModule({
	declarations: [],
	imports: [
		CommonModule
	],
	providers: [
		{
			provide: Environments,
			useValue: environments,
		},
		EnvironmentConfiguration
	]
})
export class EnvironmentModule { }
