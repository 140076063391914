import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { JobstatusComponent } from './jobstatus/jobstatus.component';
import { AdjustmentInquiryComponent } from './adjustment-inquiry/adjustment-inquiry.component';
import { OrderforecastComponent } from './orderforecast/orderforecast.component';
import { MasterSetupComponent } from './master-setup/master-setup.component';
import { MsalGuard } from './msal';
import { RunDownMaintenanceComponent } from './run-down-maintenance/run-down-maintenance.component';
import { BulkAdjustmentComponent } from './bulk-adjustment/bulk-adjustment.component';
import { ReportsComponent } from './reports/reports.component';
import { FluctuationAllowanceMaintenanceComponent } from './fluctuation-allowance-maintenance/fluctuation-allowance-maintenance.component';
import { CalenderSetupComponent } from './calender-setup/calender-setup.component';
import { ContactComponent } from './contact/contact.component';
import { GetSudoStatusComponent } from './get-sudo-status/get-sudo-status.component';
import { FaqComponent } from './faq/faq.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { TrainingVideosComponent } from './training-videos/training-videos.component';
import { ReleaseNotesComponent } from './release-notes/release-notes.component';
import {PipeJobstatusComponent} from './pipe-jobstatus/pipe-jobstatus.component'
import { PipeReportsComponent } from './pipe-reports/pipe-reports.component';
import { PartsinquireComponent } from './partsinquire/partsinquire.component';
import { PartMaintenanceComponent } from './part-maintenance/part-maintenance.component';
import { ContainerMaintenanceComponent } from './container-maintenance/container-maintenance.component';
import { SealVerificationComponent } from './seal-verification/seal-verification.component';
import { RoleGuard } from './msal/roles.guard';
import { PipeRelasenotesComponent } from './pipe-relasenotes/pipe-relasenotes.component';

const pipeRoles = ['OSPIPE.READONLY', 'OSPIPE.ADMIN', 'OSPIPE.NAMCADMIN', 'OSPIPE.FLUSER'];

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'dashboard', component: HomeComponent, canActivate: [MsalGuard] },
  {
    path: 'ordering/jobstatus',
    component: JobstatusComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ordering/adjustmentinquiry',
    component: AdjustmentInquiryComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ordering/orderforecast',
    component: OrderforecastComponent,
    canActivate: [MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'setup/master',
    component: MasterSetupComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ordering/rundownmaintenance',
    component: RunDownMaintenanceComponent,
    canActivate: [MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'ordering/bulkadjustment',
    component: BulkAdjustmentComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ordering/reports',
    component: ReportsComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'ordering/famaintenance',
    component: FluctuationAllowanceMaintenanceComponent,
    canActivate: [MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'ordering/getsudostatus',
    component: GetSudoStatusComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'setup/calendar',
    component: CalenderSetupComponent,
    canActivate: [MsalGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  { path: 'contact', component: ContactComponent, canActivate: [MsalGuard] },
  { path: 'faq', component: FaqComponent, canActivate: [MsalGuard] },
  {
    path: 'help/trainingvideos',
    component: TrainingVideosComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'help/releasenotes',
    component: ReleaseNotesComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'help/pipereleasenotes',
    component: PipeRelasenotesComponent,
    canActivate: [MsalGuard],
  },
  {
    path: 'pipe/jobstatus',
    component: PipeJobstatusComponent,
    canActivate: [MsalGuard],
    
  },
  {
    path: 'pipe/partsinquire',
    component: PartsinquireComponent,
    data: {
      roles: pipeRoles
    },
    canActivate: [ MsalGuard, RoleGuard ],
    // canActivate: [MsalGuard],
    // canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'pipe/partsmaintenance',
    component: PartMaintenanceComponent,
    data: {
      roles: pipeRoles
    },
    canActivate: [ MsalGuard, RoleGuard ],
  },
  {
    path: 'pipe/containermaintenance',
    component: ContainerMaintenanceComponent,
    data: {
      roles: pipeRoles
    },
    canActivate: [ MsalGuard, RoleGuard ],
  },
  {
    path: 'pipe/sealverification',
    component: SealVerificationComponent,
    data: {
      roles: [ 'OSPIPE.READONLY','OSPIPE.ADMIN','OSPIPE.NAMCADMIN','OSPIPE.LP','OSPIPE.SECUSER' ]
    },
    canActivate: [ MsalGuard, RoleGuard ],
  },
  {
    path: 'pipe/jobstatus',
    component: PipeJobstatusComponent,
    data: {
      roles: pipeRoles
    },
    canActivate: [ MsalGuard, RoleGuard ],
    
  },
  {
    path: 'pipe/reports',
    component: PipeReportsComponent,
    data: {
      roles: pipeRoles
    },
    canActivate: [ MsalGuard, RoleGuard ],
    
  },
  { path: '**', component: HomeComponent, canActivate: [MsalGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
