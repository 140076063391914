<span
  ><img
    class="margin-imagetag-download"
    (click)="downloadFile('.txt')"
    width="20px"
    height="20px"
    align-self="center"
    src="./assets/images/download.svg"
    alt=""
/></span> &nbsp;&nbsp;
<span
  ><img
    class="margin-imagetag-download"
    (click)="downloadFile('.xls')"
    *ngIf="!ccsDownload"
    width="20px"
    height="20px"
    align-self="center"
    src="./assets/images/excel.png"
    alt=""
/></span>

