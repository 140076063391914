import { Injectable } from '@angular/core';
import { GridOptions } from 'ag-grid-community';
import { suppressNavigation } from '../shared/suppressNavigation';
@Injectable({
  providedIn: 'root',
})
export class GriddataService {
  GridOptions: any = {};
  osPartsGridOptions!: GridOptions;
  constructor() {}
  getGridOptions() {
    this.osPartsGridOptions = {} as any;

    this.osPartsGridOptions.headerHeight = 38;

    this.osPartsGridOptions.rowHeight = 28;
    this.osPartsGridOptions.floatingFiltersHeight = 30;

    this.osPartsGridOptions.rowBuffer = 20;
    this.osPartsGridOptions.suppressMovableColumns = true;


    // this.osPartsGridOptions.suppressRowClickSelection = true;
    // this.osPartsGridOptions.enableCellTextSelection = true;
    // this.osPartsGridOptions.suppressCopyRowsToClipboard = true;
    // this.osPartsGridOptions.enableRangeSelection = true;
    // this.osPartsGridOptions.rowSelection = 'single';
    this.osPartsGridOptions.enableCellTextSelection = true;
    this.osPartsGridOptions.suppressContextMenu = false;
    this.osPartsGridOptions.suppressClipboardPaste = true;

    //copy

    this.osPartsGridOptions.suppressCopyRowsToClipboard=true;

    // this.osPartsGridOptions.enableRangeSelection = true;

    this.osPartsGridOptions.defaultColDef = {
      // make every column use 'text' filter by default
      filter: 'agTextColumnFilter',
      sortable: true,
      suppressMenu: true,
      floatingFilter: false,

      suppressKeyboardEvent: suppressNavigation,

      // suppressSizeToFit: true,
      // headerClass: 'grid-header-class',
      // cellStyle(params) {
      //   return {
      //     'line-height': '30px',
      //   };
      // },

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        // Debounce time - Delay before Floating filter search
        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.osPartsGridOptions;
  }
}
