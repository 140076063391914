<div class="background">
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Fluctuation Allowance Maintenance</h2>
      </div>
      <div class="p-2 kanbanp2">
        <input
          type="text"
          [(ngModel)]="kanbanSearchValue"
          class="form-control-sm kanbanform"
          placeholder="KANBAN - Press Enter to Search"
          (keyup.enter)="handleKanbanSearch($event)"
        />
      </div>
      <div class="p-2" *ngIf="showDownloadUploadButtons">
        <button
          *ngIf="access1.download"
          type="button"
          class="btn btn-light"
          (click)="download()"
        >
          <img
            *ngIf="access1.download"
            src="./assets/images/Group 115533.png"
            class="downloadIcon"
            alt="download icon"
          />
          TEMPLATE
        </button>
      </div>
      <div class="p-2" *ngIf="showDownloadUploadButtons">
        <input
          style="display: none"
          type="file"
          id="my-file"
          (change)="onFileChange($event, false)"
          accept=".xlsx, .xls"
        />
        <button
          *ngIf="access1.edit"
          [disabled]="batchCheck"
          type="button"
          onclick="document.getElementById('my-file').click()"
          class="btn btn-dark"
        >
          <img
            *ngIf="access1.edit"
            src="./assets/images/Mask_Group_515.svg"
            alt="Upload icon"
          />
          UPLOAD
        </button>
      </div>
    </div>
  </div>
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div
      *ngIf="warningMessage || freezingMesssage"
      class="alert alert-warning"
      role="alert"
    >
      <span *ngIf="warningMessage" class="freezedivider"
        >{{ warningMessage }}&nbsp; &nbsp;</span
      >&nbsp;&nbsp; <span>{{ freezingMesssage }}</span> &nbsp; &nbsp;
      <a *ngIf="uploadError" class="fileName">{{ justFileName }}</a>
      &nbsp;&nbsp;
      <button
        *ngIf="uploadError"
        type="button"
        class="btn btn-danger"
        (click)="exportAsXLSX()"
      >
        <img
          src="./assets/images/Group 115689.png"
          class="downloadIcon"
          alt="Upload icon"
        />
        ERROR REPORT
      </button>
      <button
        *ngIf="warningMessage"
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">PART NO</label>
                <p-multiSelect
                  [(ngModel)]="selectedPartNo"
                  [showToggleAll]="false"
                  (onChange)="onPartNoChange()"
                  [options]="partNoList"
                  optionLabel="item_id"
                  [disabled]="disablePartNumber"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">KANBAN </label>
                <p-multiSelect
                  [(ngModel)]="selectedKanban"
                  [showToggleAll]="false"
                  (onChange)="onKanbanChange()"
                  [options]="kanbanList"
                  optionLabel="kanban"
                  [disabled]="disableKanban"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">DEST CODE</label>
                <p-multiSelect
                  [(ngModel)]="selectedDestCode"
                  [showToggleAll]="false"
                  (onChange)="onDestCodeChange()"
                  [options]="destCodeList"
                  optionLabel="dest_code"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable" title="Container Code">CC</label>
                <p-multiSelect
                  [(ngModel)]="selectedContainer"
                  [showToggleAll]="false"
                  (onChange)="onContainerChange()"
                  [options]="containerList"
                  optionLabel="cont_code"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">SUPPLIER</label>
                <p-multiSelect
                  [(ngModel)]="selectedSupplier"
                  [showToggleAll]="false"
                  (onChange)="onSupplierChange()"
                  [options]="supplierList"
                  optionLabel="customer_supp"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">SPECIALIST</label>
                <p-multiSelect
                  [(ngModel)]="selectedSpecialist"
                  [showToggleAll]="false"
                  (onChange)="onSpecialistChange()"
                  [options]="specialistList"
                  optionLabel="EMPLOYEE"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">VAN FROM</label>
                <input
                  [(ngModel)]="vanningFrom"
                  [max]="vanningTo"
                  class="form-control-sm"
                  type="date"
                  autoWidth="false"
                />
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">VAN TO</label>
                <input
                  [(ngModel)]="vanningTo"
                  [min]="vanningFrom"
                  class="form-control-sm"
                  type="date"
                  autoWidth="false"
                />
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img
                  src="./assets/images/Group 115380.png"
                  alt="refresh icon"
                />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()"
                >Reset</label
              >
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0, true)">
                SEARCH
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <span class="edit_icon">
          <img
            *ngIf="access1.edit"
            [ngClass]="{ disabled: batchCheck }"
            (click)="onEditMode()"
            class="img-tool"
            src="./assets/images/edit.svg"
            alt="edit button icon"
            title="Edit"
          />
          <img
            class="img-tool"
            (click)="exportAsXLSX()"
            src="./assets/images/download.svg"
            alt="download icon"
            title="Download for upload"
          />
          <img
            class="img-tool"
            (click)="exportAsXLSXSameAsUI()"
            src="./assets/images/download.svg"
            alt="download icon ui template"
            title="Download same as UI"
          />
        </span>
      </div>

      <div class="col-sm-4">
        <div class="records">
          <p-multiSelect #dd
            class="page-drpdwn-label showhide"
            [(ngModel)]="selectedShowHideList"
            (onChange)="onShowHideChange($event)"
            [options]="showHideList"
            optionLabel="label"
            [displaySelectedLabel]="false"
            [defaultLabel]="'SHOW/HIDE COLUMN'"
            [showTransitionOptions]="'0ms'"
            [hideTransitionOptions]="'0ms'"
            autoWidth="true"
            [style]="{ width: '50%', background: '#F5F5F5' }"
            [resetFilterOnHide]="true"
          >
          <p-footer>
            <div class="flex justify-content-between p-1">
              <button type="button" (click)="saveShowHide();dd.hide()" class="save_btn"> Save</button>
              
            </div>
          </p-footer>
          </p-multiSelect>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select
            class="page-drpdwn"
            (change)="onPageSizeChanged()"
            [(ngModel)]="paginationPageSize"
          >
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <ag-grid-angular
      #faMainGrid
      style="width: 100%; top: 1%; margin-bottom: 5%"
      class="ag-theme-balham"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      [rowSelection]="rowSelection"
      [gridOptions]="gridOptions"
      [paginationPageSize]="paginationPageSize"
      [context]="context"
      [editType]="editType"
      [pagination]="true"
      [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay"
      [tooltipMouseTrack]="true"
      [groupDefaultExpanded]="groupDefaultExpanded"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [getDataPath]="getDataPath"
      [treeData]="true"
      [headerHeight]="headerHeight"
    >
    </ag-grid-angular>
  </div>
  <div *ngIf="editEnabled || uploadEnabled" class="saveCancel">
    <button
      matRipple
      [matRippleCentered]="true"
      [matRippleUnbounded]="false"
      class="review_btn"
      (click)="uploadFileBeforeSaveChanges()"
    >
      {{ editEnabled ? "Review Changes" : "Upload data" }}
    </button>
    <button (click)="cancelEdit()" class="cancel_btn">Cancel</button>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
