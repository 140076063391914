import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AgRendererComponent } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, Optional } from 'ag-grid-community';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-ag-grid-checkbox',
  templateUrl: './ag-grid-checkbox.component.html',
  styleUrls: ['./ag-grid-checkbox.component.scss'],
})
export class AgGridCheckboxComponent implements AgRendererComponent {
  checkBoxValue: boolean = true;
  private params: any;
  toggle: boolean = false;
  color = 'warn';
  checked = true;
  label: string;
  isDisabled: boolean;
  hi: string;
  labelPosition = 'before';
  access;
  modalComponent = 'modal-component';
  constructor(public dialogRef: MatDialogRef<saveDialog2>, public readonly dialog: MatDialog) {}

  agInit(params: any): void {
    this.access = JSON.parse(localStorage.getItem('jobstatus'));

    this.params = params;
    this.checked = !params.data.currentstatus;
    this.label = params.data.currentstatus;
    const { disableToggle } = params.data;
    console.log(params.data);

    if (this.label === 'InProgress' || disableToggle) {
      this.isDisabled = true;
    }

    if (params.value === 'Running') {
      this.checkBoxValue = true;
    } else {
      this.checkBoxValue = false;
    }
    this.toggle = true;

    if (
      params.data?.filestatus?.toLowerCase() === 'Daily Order Transmission'.toLowerCase() ||
      params.data?.filestatus?.toLowerCase() === 'Daily PxP Transmission'.toLowerCase() ||
      params.data?.batch_name?.toLowerCase() === 'INITIAL ORDER CALCULATION PROCESS'.toLowerCase() ||
      params.data?.batch_name?.toLowerCase() === 'Final Order Batch (FINAL CALC)'.toLowerCase() ||
      params.data?.batch_name?.toLowerCase() === 'Daily Order Transmission'.toLowerCase() ||
      params.data?.batch_name?.toLowerCase() === 'Daily PxP Transmission'.toLowerCase()
    ) {
      this.toggle = false;
    }
  }

  changed() {
    console.log('changed------', this.checked);
    if (this.checked == true) {
      if (
        this.params.data.batch_name === 'Daily Order Transmission' ||
        this.params.data.batch_name === 'Daily PxP Transmission'
      ) {
        this.openDialog();
      } else {
        this.params.context.componentParent.userToggle(!this.checked, this.params.data);
      }
    } else {
      this.params.context.componentParent.userToggle(!this.checked, this.params.data);
    }
  }

  afterGuiAttached(_params?: IAfterGuiAttachedParams): void {}

  refresh(params: any): boolean {
    params.data.amount++;
    params.data.cbox = params.value;

    params.api.refreshCells(params);

    return false;
  }
  timeLeft: number = 5;
  interval;

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.width = '400px';

    const dialogRef = this.dialog.open(saveDialog2, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      // this.spinner.show();

      if (res === 'cancel') {
        this.params.context.componentParent.refresh();
      } else if (res === 'save') {
        this.params.context.componentParent.userToggle(!this.checked, this.params.data);
      }
    });
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: '../pipe-jobstatus/dialog.component.html',
  styleUrls: ['../core/master-delete/master-delete.component.scss'],
})
export class saveDialog2 {
  public message: any;

  constructor(public dialogRef: MatDialogRef<saveDialog2>, @Inject(MAT_DIALOG_DATA) public data) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
