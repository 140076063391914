import { Component } from '@angular/core';
@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      (keypress)="onKeyPress($event)"
      maxlength="8"
      pattern="[0-9]"
      [(ngModel)]="value"
      class="grid-cell-edit-layout"
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `,
  ],
})
export class GridTapVolEditorComponent {
  public params: any;
  showInput = false;
  value: any;
  isEmpty = true;
  agInit(params: any): void {
    params.data.tapQuantity = this;
    this.params = params;
    if (params.column === 'tapped_qty') {
      this.value = params.data.tapped_qty;
      this.isEmpty = false;

      if (this.value == null) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }
    if (this.params.data.checkedEditMode) {
      this.showInput = false;
      console.log(this.params.data.tapped_qty, typeof this.params.data.tapped_qty,'hi....')
      if (this.params.data.old_tapped_qty === 0) {
        console.log("nalini",this.params.data.mod_qty_received)
        this.value = this.params.data.mod_qty_received;
        this.params.data.tapped_qty = this.value;
        this.params.data.tapped = true;


      }
      else{
        this.value = 0;
        this.params.data.tapped_qty = this.value;
        this.params.data.tapped = true;

      }
    } else {
      this.showInput = true;
      
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    console.log("textupdated")
    if (this.params.column === 'tapped_qty') {
      if (this.value !== this.params.data.old_tapped_qty) {
        this.params.data.tapped_qty = this.value;
        this.params.data.tapped = true;
      } else {
        this.params.data.tapped = false;
        this.params.data.tapped_qty = this.value;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
}
