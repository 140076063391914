import { Component } from '@angular/core';
import { ITooltipAngularComp } from 'ag-grid-angular';

class CommentSection {
  user: string;
  time: string;
  changeQty: string;
  selectesCmd: string;
}

@Component({
  selector: 'app-custom-tooltip',
  templateUrl: './custom-tooltip.component.html',
  styleUrls: ['./custom-tooltip.component.scss'],
})
export class CustomTooltipComponent implements ITooltipAngularComp {
  private params: any;
  dd: string;
  comments: string;
  isVisiable: boolean;

  cmdList: CommentSection[] = [];
  constructor() {}
  agInit(params): void {
    this.isVisiable = false;
    this.params = params;
    if (this.params.data.tap_history) {
      let splitedCmd = this.params.data.tap_history.split('$');

      if (splitedCmd?.length > 5) {
        splitedCmd = this.params.data.tap_history.split('$').slice(-6);
      }

      splitedCmd.splice(0, 1);
      splitedCmd.forEach((element) => {
        const cmdSec = new CommentSection();
        const cmdSecList = element.split('#');

        if (cmdSecList.length > 0) {
          cmdSec.user = cmdSecList[0];
          cmdSec.time = cmdSecList[1];
          cmdSec.changeQty = cmdSecList[2].split('\n');
          cmdSec.selectesCmd = cmdSecList[3];

          this.cmdList.unshift(cmdSec);
        }
      });
    }

    //for seal verification screen
    if (this.params.data.customs_seal_status) {
      console.log("customs seal", this.params.data.customs_seal_status);
      
      let splitedCmd = this.params.data.customs_seal_status.split('$');

      if (splitedCmd?.length > 5) {
        splitedCmd = this.params.data.customs_seal_status.split('$').slice(-6);
      }

      splitedCmd.splice(0, 1);
      splitedCmd.forEach((element) => {
        const cmdSec = new CommentSection();
        const cmdSecList = element.split('#');

        if (cmdSecList.length > 0) {
          cmdSec.user = cmdSecList[0];
          cmdSec.time = cmdSecList[1];
          cmdSec.changeQty = cmdSecList[2].split('\n');
          cmdSec.selectesCmd = cmdSecList[3];

          this.cmdList.unshift(cmdSec);
        }
      });
    }


    if (this.params.data.fa_comment) {
      const splitedCmd = this.params.data.fa_comment.split('$');
      splitedCmd.splice(0, 1);
      splitedCmd.forEach((element) => {
        const cmdSec = new CommentSection();
        const cmdSecList = element.split('#');

        if (cmdSecList.length > 0) {
          cmdSec.user = cmdSecList[0];
          cmdSec.time = cmdSecList[1];
          cmdSec.changeQty = cmdSecList[2].split('\n');
         // cmdSec.changeQty = cmdSecList[2].split('.');
          cmdSec.selectesCmd = cmdSecList[3];
          this.cmdList.unshift(cmdSec);
        }
      });
    }

    if (this.params.data.checkedEditMode) {
      this.isVisiable = false;
    } else {
      this.isVisiable = true;
    }

    this.comments = this.params.value.value;
  }
}
