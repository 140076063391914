<div class="background">
  <div class="container-fluid">
    <div class="row">
      <div class="col-7">
        <h2 class="heading">Calendar Setup</h2>
      </div>
      <div class="col-2 right-button">
        <button *ngIf="uploadError" type="button" class="btn btn-danger">
          DOWNLOAD ERROR
        </button>
      </div>

      <div class="col-2 right-button">
        <button
          *ngIf="access1.calendardownload"
          type="button"
          class="btn btn-light"
          (click)="getdownloadTemplate()"
        >
          <img
            *ngIf="access1.calendardownload"
            src="./assets/images/Group 115533.png"
            class="downloadIcon"
            alt="download icon"
          />
          TEMPLATE
        </button>
      </div>
      <div class="col-1">
        <input
          style="display: none"
          type="file"
          id="my-file"
          (change)="onFileChange($event, false)"
          accept=".xlsx"
        />
        <button
          *ngIf="access1.calendarupload"
          type="button"
          onclick="document.getElementById('my-file').click()"
          class="btn btn-dark"
        >
          <img src="./assets/images/Mask_Group_515.svg" alt="Upload icon" />
          UPLOAD
        </button>
      </div>
    </div>
  </div>
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-2 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">SELECT MONTH/YEAR</label>

                <p-dropdown
                  [options]="monthList"
                  [(ngModel)]="currentMonth"
                  optionLabel="label"
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="20"
                  [showTransitionOptions]="'0ms'"
                  [resetFilterOnHide]="true"
                  [hideTransitionOptions]="'0ms'"
                  autoWidth="false"
                  [style]="{ width: '50%' }"
                  appendTo="body"
                >
                </p-dropdown
                >&nbsp;

                <p-dropdown
                  [options]="yearsList"
                  [(ngModel)]="currentYear"
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="20"
                  [showTransitionOptions]="'0ms'"
                  [resetFilterOnHide]="true"
                  [hideTransitionOptions]="'0ms'"
                  autoWidth="false"
                  [style]="{ width: '45%' }"
                  appendTo="body"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="col-md-1">
              <div class="drpchildctrl">
                <label class="searchLable">SUPPLIER</label>
                <p-dropdown
                  [options]="supplierList"
                  [(ngModel)]="selectedSupplier"
                  (onChange)="supplierChange()"
                  optionLabel="label"
                  [filter]="true"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  [showTransitionOptions]="'0ms'"
                  [resetFilterOnHide]="true"
                  [hideTransitionOptions]="'0ms'"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  appendTo="body"
                >
                </p-dropdown>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img
                  src="./assets/images/Group 115380.png"
                  alt="refresh icon"
                />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()"
                >Reset</label
              >
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch()">SEARCH</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-10">
        <span class="edit_icon">
          <img
            *ngIf="access1.edit"
            (click)="onEditMode()"
            class="img-tool"
            src="./assets/images/edit.svg"
            alt="edit button icon"
            title="Edit"
          />
        </span>
      </div>
      <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select
            class="page-drpdwn"
            (change)="onPageSizeChanged()"
            [(ngModel)]="paginationPageSize"
          >
            <option value="10">10</option>

            <option value="{{ rowData.length }}" selected>All</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div id="dvgrid" class="gridStyle">
    <app-grid
      class="table-outer"
      #osPartsGrid
      [pagination]="pagination"
      [paginationPageSize]="paginationPageSize"
      [pagination]="true"
      [rowSelection]="rowSelection"
      [gridOptions]="gridOptions"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [context]="context"
      [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay"
      [tooltipMouseTrack]="true"
      [editType]="editType"
      [domLayout]="domLayout"
    >
    </app-grid>
  </div>
  <div *ngIf="editEnabled" class="saveCancel">
    <button
      matRipple
      [matRippleCentered]="true"
      [matRippleUnbounded]="false"
      class="review_btn"
      (click)="saveChanges()"
    >
      Save Changes
    </button>
    <button (click)="cancelEdit()" class="cancel_btn">Cancel</button>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
