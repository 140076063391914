<div class="background1">
  <div class="p-2 messageBackground reset_btn">
    <label class="resetIcon" (click)="goBack()">
      <img src="./assets/images/back arrow.png" alt="bell icon" class="bell" />
    </label>

    <label class="message calendarFont" (click)="goBack()"> BACK</label>
  </div>
  <h4 class="main-heading">Frequently Asked Questions(FAQs)</h4>

  <div class="container">
    <mat-accordion class="example-headers-align">
      <mat-expansion-panel *ngFor="let faq of forecastResponse">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <strong>{{ faq.faq_question }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <p [innerHTML]="faq.faq_answer"></p>
      </mat-expansion-panel>
    </mat-accordion>

    <!-- <mat-accordion>
		<mat-expansion-panel *ngIf="forecastResponse.length > 0">
			<!-- <mat-expansion-panel-header>
				<mat-panel-title>
					<strong>{{forecastApp | uppercase}}</strong>
				</mat-panel-title>
			</mat-expansion-panel-header> -->
    <!-- <mat-accordion>
				<mat-expansion-panel *ngFor="let faq of forecastResponse">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<strong>{{faq.faq_question}}</strong>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<p [innerHTML]="faq.faq_answer"> </p>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-expansion-panel> -->

    <!-- <mat-expansion-panel *ngIf="iveResponse.length > 0">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<strong>{{iveApp | uppercase}}</strong>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-accordion>
				<mat-expansion-panel *ngFor="let faq of iveResponse">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<strong>{{faq.faq_question}}</strong>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<p [innerHTML]="faq.faq_answer"> </p>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-expansion-panel>

		<mat-expansion-panel *ngIf="vehicleSpecResponse.length > 0">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<strong>{{vehicleSpecApp | uppercase}}</strong>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-accordion>
				<mat-expansion-panel *ngFor="let faq of vehicleSpecResponse">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<strong>{{faq.faq_question}}</strong>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<p [innerHTML]="faq.faq_answer"> </p>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-expansion-panel>

		<mat-expansion-panel *ngIf="lineshareResponse.length > 0">
			<mat-expansion-panel-header>
				<mat-panel-title>
					<strong>{{lineshareApp | uppercase}}</strong>
				</mat-panel-title>
			</mat-expansion-panel-header>
			<mat-accordion>
				<mat-expansion-panel *ngFor="let faq of lineshareResponse">
					<mat-expansion-panel-header>
						<mat-panel-title>
							<strong>{{faq.faq_question}}</strong>
						</mat-panel-title>
					</mat-expansion-panel-header>
					<p [innerHTML]="faq.faq_answer"> </p>
				</mat-expansion-panel>
			</mat-accordion>
		</mat-expansion-panel> -->
    <!-- //</mat-accordion> -->
  </div>
</div>
