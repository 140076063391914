import { Component, OnInit } from '@angular/core';
import{Location} from '@angular/common'
import { LookupService } from '../services/lookup/lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
  emailstring = `mailto:xyz@example.com?Subject=Hello Team&body=Hi, %0D This is mail from OSP dashboard!`;
  emailOSP = ``;
  namcName: string;
  user: string;
  userRole: string;
  namcValue: string;
  userName: string;
  redirect;
  redirect1;
  emailHelpDesk = `is_service_desk@toyota.com`;

  constructor(
    private readonly lookupService: LookupService,
    private readonly spinner: NgxSpinnerService,
    private  location:Location
  ) {}

  ngOnInit(): void {
    this.spinner.show();
    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    }
    this.getContact();
  }

  goBack(){

    this.location.back();
 
    console.log('goBack()...')
 
  }

  getContact() {
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    this.lookupService.getContact(data).subscribe((res) => {
      this.spinner.hide();

      this.emailOSP = `mailto:${res.body.data.contactInfos
      }?`;
      this.emailHelpDesk = `mailto:${res.body.data.isServiceDeskEmails}?`;
      this.redirect = res.body.data.contactInfos;
      this.redirect1=res.body.data.isServiceDeskEmails
    });
  }
}
