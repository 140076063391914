<div class="background">
  <div class="container-fluid">
    <mat-toolbar id="msgTicker" class="layout-header">
      <span id="msgTickerLabel" class="marquee-label">
        <mat-icon id="notificationImp" class="notification"
          >notifications_active</mat-icon
        >Messages

        <span class="arrow-msg"> </span>
      </span>

      <p class="marquee" *ngIf="holdreleaseNotification?.length > 0" >
        <span>
          <div
            id="msgTickerDiv"
            class="marquee-message"
            *ngFor="let urgentMsg of holdreleaseNotification; let i = index"
          >
         
            <a class="ticker_message calendarDate" *ngIf="urgentMsg.status==='RELEASE'">
              {{ urgentMsg.batch_name }} has been RELEASED By {{urgentMsg.userid}} at {{urgentMsg.date_time}} (Eastern) &nbsp;</a
            >
            <a class="ticker_message calendarDate" *ngIf="urgentMsg.status==='HOLD'">
              {{ urgentMsg.batch_name }} has been put on HOLD By {{urgentMsg.userid}} at {{urgentMsg.date_time}} (Eastern)&nbsp;</a
            >
          </div>
        </span>
      </p>

      <div
        id="urgentTicker"
        class="no-messages"
        *ngIf="!holdreleaseNotification?.length"
      >
        *** No Urgent Messages ***
      </div>
    </mat-toolbar>
    <div class="successMessage alert-dismissible">
      <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
        {{ warningMessage }}
        <button
          type="button"
          class="btn-close"
          (click)="removeMessage()"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>

    <div class="card">

      <div class="d-flex flex-row">
        <div class="p-2 flex-grow-1">
          <h2 class="heading">Job/Process Status</h2>
          
        </div>
        <div class="p-2">
          <button
            type="button"
            class="btn btn-light refresh"
            (click)="refresh()"
          >
            <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;&nbsp; REFRESH
          </button>
        </div>
      </div>

      <div id="dvgrid" class="gridContent1 mb-35">
        <div class="grid-outer-layout grid-outer-shadow js-grid">
          <ag-grid-angular
            style="width: 102%; height: 50vh"
            class="ag-theme-balham"
            [rowData]="rowData"
            [columnDefs]="columnDefs"
            [gridOptions]="jobGridOptions"
            [frameworkComponents]="frameworkComponents"
            [context]="context"
          >
          </ag-grid-angular>
          <ag-grid-angular
            style="width: 102%; height: 35vh"
            class="ag-theme-balham"
            [rowData]="rowData1"
            [columnDefs]="columnDefs1"
            [gridOptions]="fileGridOptions"
            [frameworkComponents]="frameworkComponents"
            [context]="context"
          >
          </ag-grid-angular>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p class="loading">Loading... Please wait...</p>
  </ngx-spinner>
</div>
