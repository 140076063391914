import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  header = new HttpHeaders({
    'Content-Type':
      'application/json, application/x-www-form-urlencoded;charset=ISO-8859-15',
    Accept: 'application/json',
  });
  dataSuccess='data success';
  dataFail='data fails';

  constructor(private http: HttpClient) {}

  getUserDetails(): Observable<any> {
    const httpCustomOptions = {
      headers: this.header,
    };
    return this.http.get('/api/api/v1/getusers', httpCustomOptions).pipe(
      tap((_) => this.log(this.dataSuccess)),
      catchError(this.handleError<any>(this.dataFail))
    );
  }

  getUserInfo(toyotaGUID): Observable<any> {
    const httpCustomOptions = {
      headers: this.header,
    };
    return (
      this.http
        // ${toyotaGUID}
        .get(`/api/api/v1/getuserinfo/${toyotaGUID}`, httpCustomOptions)
        .pipe(
          tap((_) => this.log(this.dataSuccess)),
          catchError(this.handleError<any>(this.dataFail))
        )
    );
  }

  // multiselect region dropdown - add this service in boards service ..
  getRegionDetails(): Observable<any> {
    const httpCustomOptions = {
      headers: this.header,
    };
    return this.http.get('/api/api/v1/regions', httpCustomOptions).pipe(
      tap((_) => this.log(this.dataSuccess)),
      catchError(this.handleError<any>(this.dataFail))
    );
  }

  private handleError<T>(operation, result?: T) {
    return (error: any): Observable<T> => {
      console.error(error); // log to console instead
      return of(result as T);
    };
  }

  /** Log a message */
  private log(message: string) {
    console.log('Message: ' + message);
  }
}
