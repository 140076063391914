export const logData = (...data) => {
  console.log(...data);
};

export const logErrors = (...errors) => {
  console.error(...errors);
};

export const currTimestamp = () => {
  const mm = require('moment-timezone');
  const timeStamp = String(mm().tz('US/Michigan').format());
  const edtTime = timeStamp.split('T')[0].split('-').join('')+'_'+
  timeStamp.split('T')[1].split('-')[0].split(':').join('');
  logData(`CURRENT EDT TIME - ${edtTime}`)
  return edtTime;
}
