import { Component, Input } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';

@Component({
  selector: 'app-grid-refresh',
  template: `
    <label class="resetIcon" (click)="refreshClicked()">
      <img src="../../../assets/images/refresh.svg" title="Reset Filters" />
    </label>
  `,
  styles: [
    `
      .mat-icon {
        margin-left: 0px;
        vertical-align: middle;
      }
      .mat-icon:hover {
        cursor: pointer;
      }
      .resetIcon {
        cursor: pointer;
      }
      .filterText {
        position: absolute;
        top: 13px;
        font: normal normal normal 13px/16px Roboto;
        letter-spacing: 0px;
        color: #7b7c7c;
      }
      img {
        margin: auto;
        display: inline-block;
        position: relative;
        left: -5px;
      }
    `,
  ],
})
export class GridRefreshComponent implements AgRendererComponent {
  @Input() cell: any;
  public params: any;
  constructor() {}
  test() {
    alert('Test');
  }
  refreshClicked(): void {
    this.params.api.setFilterModel(null);
    this.params.api.onFilterChanged();
    this.params.api.refreshView();

    const refreshCallback =
      this.params.api.gridOptionsWrapper.gridOptions.context.componentParent;
    // Callback method
    if (
      refreshCallback != null &&
      refreshCallback.onRefresh != null &&
      typeof refreshCallback.onRefresh === 'function'
    ) {
      refreshCallback.onRefresh();
    }
  }
  // called on init
  agInit(params: any): void {
    this.params = params;
  }
  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
