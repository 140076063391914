<div style="background-color: #f5f5f5; height: 84vh;">
  <div class="container-fluid">
    <div class="row">
      <div class="col-9">
        <h2 class="heading">Bulk Adjustment Screen</h2>
      </div>
    </div>
  </div>
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div *ngIf="warningMessage || freezingMesssage" class="alert alert-warning" role="alert">
     
      <span *ngIf="warningMessage" class="freezedivider">{{ warningMessage }} </span>&nbsp;&nbsp;
      <span >{{ freezingMesssage }}</span> &nbsp; &nbsp;
      <button *ngIf="warningMessage" type="button" class="btn-close" (click)='removeMessage()' data-bs-dismiss="alert" aria-label="Close"></button>
      
    </div>
  </div>
  <div class="row" style="margin: auto">
    <div
      style="margin-left: -0.5%"
      class="col-sm-6 d-flex align-items-stretch"
      appDnd
      (fileDropped)="onFileDropped($event)"
    >
      <div class="card text-center">
        <div class="text-end">
          <button
            type="button"
            style="margin-right: 5px"
            class="btn btn-light"
            (click)="getdownloadTemplate()"
          >
          <img
          src="./assets/images/Group 115533.png"
          class="downloadIcon"
          alt="download icon"
        />
        TEMPLATE
          </button>
        </div>
        <div class="card-title">
          <img
            src="./assets/images/upload-icon-77.png"
            style="width: 2em; height: 2em"
            alt="File icon"
          />
        </div>
        <div class="card-body">
          <p class="card-text">
            Drag and drop or
            <span
              onclick="document.getElementById('fileDropRef').click()"
              style="color: blue; cursor: pointer"
              >Browse your files here</span
            >
          </p>
          <input
            style="display: none"
            type="file"
            #fileDropRef
            id="fileDropRef"
            (change)="fileBrowseHandler($event)"
            accept=".xlsx,.xls"
          />
          <button
          [disabled]="batchCheck"
            type="button"
            onclick="document.getElementById('fileDropRef').click()"
            class="btn btn-dark"
          >
            UPLOAD
          </button>
        </div>
      </div>
    </div>
  </div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">{{ spinnerMessage }}</p>
</ngx-spinner>
