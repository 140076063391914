import { Component } from '@angular/core';
@Component({
  selector: 'grid-text-editor',
  template: `
  <label *ngIf="showInput">{{ value }} </label>
  <input
    *ngIf="!showInput"
    #input
    type="Text"
    (keypress)="omit_special_char($event)"
    [(ngModel)]="value"

      maxlength="10"
      class="grid-cell-edit-layout"
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `,
  ],
})
export class ModifiedboltComponent {
  public params: any;
  showInput = false;
  value: any;
  isEmpty = true;
  agInit(params: any): void {
    params.data.modifiedBolt = this;
    this.params = params;
    if (params.column === 'customs_seal_number_user_modified') {
      this.value = params.data.customs_seal_number_user_modified;
      this.isEmpty = false;

      if (this.value == null) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }
    if (
      this.params.data.checkedEditMode && this.params.data.customs_seal_number != null
      && this.params.data.customs_seal_number.trim() !=''

    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }



  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {

    if (this.params.column === 'customs_seal_number_user_modified') {
      console.log("textUpdated");
      if (this.value !== this.params.data.customs_seal_number_user_modified) {
        this.params.data.customs_seal_number_user_modified = this.value;
        this.params.data.boltmodified = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }
  omit_special_char(event) {
    console.log('onkeypress')

    {
      var k; k = event.charCode;  // k = event.keyCode;  (Both can be used) 
      if (k === 32){
        return false;}
   
      return((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57)); 
    }


  }
}
