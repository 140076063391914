import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { ExcelService } from '../services/excel.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as mm from 'moment-timezone';

import { forkJoin, Subscription } from 'rxjs';
import { LookupService } from '../services/lookup/lookup.service';
import { checkAccess, dateFilterComparator, resetSortingAndFilters, validateVanningDates } from '../constants';

import { FAGridTextEditorComponent } from './fa-grid-text-edit.component';

import { FACommentsEditorComponent } from './fa-comments-edit.component';
import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';

import { OSPStateService } from '../services/osp-state.service';

import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { freezingMesssage } from '../constants';
import { EditDialogComponent } from './edit-dialog/edit-dialog.component';
import { warningMessage, successMessage } from '../constants';
import { logData, logErrors } from '../shared/logger';
import { AgGridAngular } from 'ag-grid-angular';

@Component({
  selector: 'app-fluctuation-allowance-maintenance',
  templateUrl: './fluctuation-allowance-maintenance.component.html',
  styleUrls: ['./fluctuation-allowance-maintenance.component.scss'],
})
export class FluctuationAllowanceMaintenanceComponent implements OnInit, OnDestroy {
  showDownloadUploadButtons: boolean = true;
  // Rameshkumar has asked to hide upload and download for Phase 1
  // Rameshkumar and Sunil asked to show buttons again

  namcValue = '01';
  user = '';
  userRole = '';
  rowGroupingDisplayType = 'custom';
  groupDefaultExpanded = 10;
  rowData: any = [];
  @ViewChild('faMainGrid') faMainGrid: AgGridAngular;
  columnDefs;

  public defaultColDef;
  rowSelection;

  groupDisplayType = 'groupRows';
  autoGroupColumnDef;
  getDataPath;

  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedSupplier: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  successMessage = '';
  warningMessage = '';
  vanningFrom: any;
  vanningTo: any;

  kanbanListSelected = [];
  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  destCodeList: any = [];
  containerList: any = [];
  supplierList: any = [];
  dockList: any = [];
  specialistList: any = [];
  lifecycleList: any = [];
  adjCodeList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;

  orig_min_qty = 'FA Min Original';
  orig_max_qty = 'FA Max Original';
  rev_min_qty = 'FA Min Final';
  rev_max_qty = 'FA Max Final';

  showHideList: any = [
    // { value: 'vanning_date', label: 'Vanning DT' },
    { value: 'item_id', label: 'Part No' },
    { value: 'part_description', label: 'Part Description' },
    { value: 'kanban', label: 'Kanban' },
    { value: 'cont_code', label: 'CC (Container Code)' },
    { value: 'order_lot', label: 'QPC (Quantity Per Container, Order Lot)' },
    { value: 'specialist', label: 'SPC (Specialist)' },
    { value: 'orig_min_qty', label: this.orig_min_qty },
    { value: 'orig_max_qty', label: this.orig_max_qty },
    { value: 'revMinQtyText', label: this.rev_min_qty },
    { value: 'revMaxQtyText', label: this.rev_max_qty },
    { value: 'date_time', label: 'Updated DT' },
    { value: 'userid', label: 'User' },
    { value: 'comment', label: 'Comments' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  // Dropdowns

  selectedLinePath: any = [];

  // Dropdown Options
  linePathList: any = [];

  page = 0;
  apiDefaultLimit = 5000;
  icons: { groupExpanded: string; groupContracted: string };
  commentsList: any = [];

  uploadError: boolean;
  uploadEnabled: boolean;
  namcName: string;
  files: any[];
  fileBuffer: any;

  exportData: any;

  minVanDate: any;
  maxVanDate: any;

  uploading: boolean = false;

  displayNames = {
    vanning_date: 'Van DT',
    item_id: 'Part No',
    kanban: 'Kanban',
    order_lot: 'QPC',
    cont_code: 'CC',
    orig_min_qty: this.orig_min_qty,
    orig_max_qty: this.orig_max_qty,
    rev_min_qty: this.rev_min_qty,
    rev_max_qty: this.rev_max_qty,
  };

  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  batchRunLogID: any;
  headerHeight: number;
  loading: any;

  flalSubscription$: Subscription;
  headerCheckboxChecked: boolean = false;
  allowedFilesize: number;
  onInitVanningFrom: any;
  onInitVanningTo: any;
  justFileName: any;
  validating: boolean;
  itContact: string;
  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;
  flalLicenseKeySubscription$: Subscription;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly flalService: FluctuationAllowanceService,
    private readonly lookupService: LookupService,
    private readonly stateService: OSPStateService,
    public dialog: MatDialog
  ) {
    this.context = {
      componentParent: this,
    };
  }

  headers = [
    'Van DT',
    'Part No',
    'Kanban',
    'CC',
    'QPC',
    this.orig_min_qty,
    this.orig_max_qty,
    this.rev_min_qty,
    this.rev_max_qty,
    'Comments',
    'Remarks',
  ];

  headersSameAsUI = [
    'Van DT',
    'Part No',
    'Part Description',
    'Kanban',
    'CC',
    'QPC',
    'SPC',
    this.orig_min_qty,
    this.orig_max_qty,
    this.rev_min_qty,
    this.rev_max_qty,
    'Updated DT',
    'User',
    'Comments',
  ];

  fileName: string = '';
  access1;

  setVanningDates(): void {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.flalService.getMaxDate(data).subscribe((success) => {
      if (!success.body.data.minVanDate[0].min) {
        this.spinner.hide();
        return;
      }

      this.vanningFrom = success.body.data.minVanDate[0].min;
      this.onInitVanningFrom = this.vanningFrom;

      // this.vanningTo = success.body.data.minVanDateTo[0].min;
      // this.onInitVanningTo = this.vanningTo;
      this.spinner.hide();
    });
  }

  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    if (this.flalSubscription$) this.flalSubscription$.unsubscribe();
    if (this.flalLicenseKeySubscription$) this.flalLicenseKeySubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };

    this.icons = {
      groupExpanded:
        '<img src="/assets/images/cellgrid_minus.png" style="height: 18px; width: 18px;padding-right: 2px"/>',
      groupContracted:
        '<img src="/assets/images/cellgrid_plus.png" style="height: 14px; width: 14px;padding-right: 2px"/>',
    };

    this.getDataPath = function (data) {
      return data.group;
    };

    this.autoGroupColumnDef = {
      headerName: 'Van DT',
      sortable: true,
      resizable: true,
      width: 140,
      headerTooltip: 'Vanning Date(mm/dd/yyyy)',
      valueGetter: (params) => {
        return params.node.data.vanning_date; // <--- what to show in the group column
      },
      cellRendererParams: {
        suppressCount: true,
      },
      filter: 'agDateColumnFilter',
      filterParams: {
        comparator: dateFilterComparator,
      },
      floatingFilter: true,

      comparator: this.dateComparator,
    };
    this.gridOptions.defaultColDef.floatingFilter = true;

    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 40,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
        pinned: 'left',
        tooltipValueGetter: (params) => {
          if (
            new Date(params.data.vanning_date) <= new Date(this.onInitVanningFrom) ||
            new Date(params.data.hidden_vanning_date) <= new Date(this.onInitVanningFrom)
          ) {
            return 'You are not allowed to edit past records.';
          } else {
            return '';
          }
        },
      },
      {
        headerName: 'Part No',
        field: 'item_id',
        sortable: true,
        width: 130,
      },
      {
        headerName: 'Part Description',
        field: 'part_description',
        width: 150,
        floatingFilter: true,
        tooltipField: 'part_description',
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        headerTooltip: 'Kanban',
        sortable: true,
        width: 100,
      },
      {
        headerName: 'CC',
        field: 'cont_code',
        headerTooltip: 'Container Code',
        width: 80,
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        
        filterParams: {
          textCustomComparator: function (filter, value, filterText){
                        
            var filterTextLoweCase = filterText.toLowerCase();
            var valueLowerCase = value.toString().toLowerCase();
            return valueLowerCase.indexOf(filterTextLoweCase) === 0;

            }
          },
         
      },
      {
        headerName: 'QPC',
        field: 'order_lot',
        headerTooltip: 'Quantity Per Container, Order Lot',
        width: 110,
        //cellClass: this.agRightAlignedCell,
        sortable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        valueGetter: (params) => (params.data.order_lot ? Math.round(parseFloat(params.data.order_lot)) : undefined),
      },
      {
        headerName: 'SPC',
        field: 'specialist',
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Specialist',
        tooltipField: 'specialist',
      },
      {
        headerName: this.orig_min_qty,
        field: 'orig_min_qty',
        width: 120,
        //cellClass: this.agRightAlignedCell,
        headerClass: 'ag-theme-custom-text-right',
        sortable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        valueGetter: (params) => Math.round(parseFloat(params.data.orig_min_qty)),
      },
      {
        headerName: this.orig_max_qty,
        field: 'orig_max_qty',
        width: 120,
        //cellClass: this.agRightAlignedCell,
        headerClass: 'ag-theme-custom-text-right',
        sortable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        valueGetter: (params) => Math.round(parseFloat(params.data.orig_max_qty)),
      },
      {
        headerName: this.rev_min_qty,
        field: 'revMinQtyText',
        cellRendererFramework: FAGridTextEditorComponent,
        cellRendererParams: { column: 'rev_min_qty' },
        width: 120,
        //cellClass: this.agRightAlignedCell,
        headerClass: 'ag-theme-custom-text-right',
        sortable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return Math.round(parseFloat(params.data.rev_min_qty));
          } else {
            const value = params.data.revMinQtyText?.value ?? params.data.man;

            return Math.round(parseFloat(value));
          }
        },
      },
      {
        headerName: this.rev_max_qty,
        field: 'revMaxQtyText',
        cellRendererFramework: FAGridTextEditorComponent,
        cellRendererParams: { column: 'rev_max_qty' },
        width: 120,
        //cellClass: this.agRightAlignedCell,
        headerClass: 'ag-theme-custom-text-right',
        sortable: true,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },

        valueGetter: (params) => {
          if (!this.editEnabled || !params.data.rowCheckBox) {
            return Math.round(parseFloat(params.data.rev_max_qty));
          } else {
            const value = params.data.revMaxQtyText?.value ?? params.data.man;

            return Math.round(parseFloat(value));
          }
        },
      },
      {
        headerName: 'Updated DT',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        field: 'date_time',
        width: 160,
        floatingFilter: true,
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 170,
        floatingFilter: true,
        resizable: true,
        tooltipField: 'userid',
      },
      {
        headerName: 'Comments',
        field: 'comment',
        cellRendererFramework: FACommentsEditorComponent,
        cellRendererParams: { column: 'fa_comment' },
        tooltipField: 'fa_comment',
        tooltipComponent: 'customTooltip',
        valueGetter: (params) => {
          if (!params.data.rowCheckBox) {
            return params.data.fa_comment;
          } else {
            return params.data.comment;
          }
        },

        width: 180,
        floatingFilter: true,
      },
    ];

    this.headerHeight = 45;

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.setPageSizeToAll(this.defaultPageSize);

    this.flalLicenseKeySubscription$ = this.stateService
      .getLicenseKeySetValue()
      .subscribe((licenseKeyHasBeenSet: boolean) => {
        // console.log(licenseKeyHasBeenSet);
        if (!licenseKeyHasBeenSet) return;
      });

    this.flalSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  initializeData() {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');

    this.resetVariablesState();
    this.spinner.show();
    this.rowData = [];

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    const faPermission = checkAccess();
    this.access1 = faPermission;

    this.loadDrop();

    // Setting van dates after getting batch status
    // this.getBatchStatus('');
    // this.setVanningDates();
    // eastern time api
    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.easternDate = res.body.data;
      },
    });
  }

  //freezing logic
  batchCheck = true;
  freezingMesssage = '';

  getBatchStatus(callback) {
    this.spinner.show();
    var data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.lookupService.getBatchStatus(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        const count = res.body.data.runningBatches?.length;

        if (count > 0) {
          this.freezingMesssage = `${res.body.data.runningBatches}  batches are running, so edit/upload is not allowed for some time.`;
          this.spinner.hide();
          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;
          this.spinner.hide();

          if (callback) {
            callback();
          }
        }
      },
    });
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.lookupService.getKanban(),
      this.lookupService.getPartNumber(),
      this.lookupService.getDestCode(),
      this.lookupService.getSupplier(),
      this.lookupService.getContainer(),
      this.lookupService.getSpecialist(),
      this.lookupService.getBatchStatus(data),
      this.flalService.getMaxDate(data),
      this.lookupService.getUserShowHideColumns(),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanbanList, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.destCodeList = [];
        this.supplierList = [];
        this.specialistList = [];
        this.containerList = [];
        if (res[0].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.kanbanList.push(...res[0].body.data);

        //if item id list, add ALL option
        if (res[1].body.data) {
          this.partNoList.push({ item_id: 'ALL' });
        }

        this.partNoList.push(...res[1].body.data);

        if (res[2].body.data) {
          this.destCodeList.push({ dest_code: 'ALL' });
        }

        this.destCodeList.push(...res[2].body.data);

        if (res[3].body.data) {
          this.supplierList.push({ customer_supp: 'ALL' });
        }
        this.supplierList.push(...res[3].body.data);

        if (res[4].body.data) {
          this.containerList.push({ cont_code: 'ALL' });
        }
        this.containerList.push(...res[4].body.data);

        if (res[5].body.data) {
          this.specialistList.push({ EMPLOYEE: 'ALL' });
        }
        this.specialistList.push(...res[5].body.data);

        // batch status check
        const batchStatusResponse = res[6].body.data.runningBatches;

        const count = batchStatusResponse?.length;

        if (count > 0) {
          this.freezingMesssage = `${batchStatusResponse}  batches are running, so edit/upload is not allowed for some time.`;

          this.batchCheck = true;
        } else {
          this.freezingMesssage = '';
          this.batchCheck = false;
        }

        const { fa_columns } = res[8].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(fa_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        // set vanning dates in UI dropdowns

        this.vanningFrom = res[7].body.data.minVanDate[0].vanning_date;
        // this.vanningTo = res[7].body.data.minVanDateTo[0].vanning_date;

        localStorage.setItem('vanningFrom', this.vanningFrom);

        const vanningDatesData = res[7].body.data.minVanDate[0].vanning_date;

        if (!vanningDatesData) {
          this.spinner.hide();
          return;
        }

        this.onInitVanningFrom = this.vanningFrom;
        // this.onInitVanningTo = this.vanningTo;
        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();
    this.uploading = false;
    this.validating = false;

    if (error?.error?.message?.required_fields) {
      this.warningMessage = `${warningMessage.requiredFieldsMissing}  ${this.itContact}.`;
    } else {
      this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
    }

    this.rowData = [];
    this.faMainGrid.gridOptions.api.setRowData(this.rowData);
  };

  checkIfAnySelected() {
    if (this.kanbanSearchValue) return true;

    if (
      this.vanningFrom
      // this.selectedKanban.length > 0 ||
      // this.selectedPartNo.length > 0 ||
      // this.selectedDestCode.length > 0 ||
      // this.selectedContainer.length > 0 ||
      // this.selectedSupplier.length > 0 ||
      // this.selectedDock.length > 0 ||
      // this.selectedSpecialist.length > 0 ||
      // this.selectedLifecycle.length > 0 ||
      // (this.vanningFrom &&
      // this.vanningTo)
    ) {
      return true;
    }

    return false;
  }

  clearKanbanValue() {
    this.kanbanSearchValue = null;
  }

  handleKanbanSearch(event) {
    this.kanbanSearchValue = this.kanbanSearchValue.trim();
    const value = this.kanbanSearchValue;

    this.rowData = [];

    const searchedKanban = this.kanbanList.find((kanban) => kanban.kanban === value);

    if (!searchedKanban || searchedKanban.kanban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidKanbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedLifecycle = [];

    this.selectedKanban = [searchedKanban];
    this.vanningFrom = undefined;
    this.vanningTo = undefined;

    this.disablePartNumber = true;

    this.onSearch(0, true);
  }

  onSearch(offset, removeMessage) {
    if (removeMessage) {
      this.removeMessage();
    }

    if (offset === 0) {
      this.resetVariablesState();
      this.rowData = [];
      this.faMainGrid.gridOptions.api.setRowData(this.rowData);

      const { valid, error } = validateVanningDates({
        vanningFrom: this.vanningFrom,
        vanningTo: this.vanningTo,
      });

      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);

        this.spinner.hide();

        return;
      }

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectVanFrom;

      window.scroll(0, 0);

      this.spinner.hide();

      return;
    }

    this.faMainGrid.gridOptions.api.showLoadingOverlay();

    this.loading = true;

    const data = {
      offset: offset,
      limit: this.apiDefaultLimit,

      kanban: this.selectedKanban.map((e) => e.kanban),
      item_id: this.selectedPartNo.map((e) => e.item_id),
      dest_code: this.selectedDestCode.map((e) => e.dest_code),
      cont_code: this.selectedContainer.map((e) => e.cont_code),
      customer_supp: this.selectedSupplier.map((e) => e.customer_supp),
      specialist: this.selectedSpecialist.map((e) => e.EMPLOYEE),

      vanning_to: this.vanningTo,
      vanning_from: this.vanningFrom,

      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      currenteasternDate: this.easternDate,
    };

    //if all selected
    if (data.kanban.includes('ALL')) {
      data.kanban = [];
    }

    if (data.item_id.includes('ALL')) {
      data.item_id = [];
    }
    if (data.dest_code.includes('ALL')) {
      data.dest_code = [];
    }
    if (data.cont_code.includes('ALL')) {
      data.cont_code = [];
    }
    if (data.customer_supp.includes('ALL')) {
      data.customer_supp = [];
    }
    if (data.specialist.includes('ALL')) {
      data.specialist = [];
    }

    this.flalService.getFAGrid(data).subscribe({
      error: this.errorCallback,
      next: (response) => {
        const { data, rowCount } = response.body.data;
        if (!offset && data.length < 1) {
          this.spinner.hide();
          this.warningMessage = warningMessage.noDataAvailble;

          return;
        }

        this.rowData.push(...data);

        this.dataCount = Math.round(parseFloat(rowCount));

        const records = this.dataCount - (this.offset + 1) * this.apiDefaultLimit;

        if (records > 0) {
          this.offset++;
          this.onSearch(this.offset * this.apiDefaultLimit, false);
          return;
        } else {
          this.faMainGrid.gridOptions.api.setRowData(this.rowData);

          this.spinner.hide();
          this.loading = false;
        }

        this.setPageSizeToAll();
      },
    });
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  canDeactivate() {
    return this.editEnabled;
  }

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox && row.cont_code !== 'ALL');
    return modifiedRowData;
  }

  getSelectedRows(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];

    this.kanbanSearchValue = null;

    this.vanningFrom = this.onInitVanningFrom;
    this.vanningTo = this.onInitVanningTo;

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;

    if (this.faMainGrid) {
      this.headerCheckboxChecked = false;
      this.faMainGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }

  resetGrid() {
    if (this.faMainGrid) {
      resetSortingAndFilters(this.faMainGrid);

      return;
    }
  }

  onEditMode(): void {
    if (this.uploadEnabled) {
      return;
    }

    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData();
    if (this.loading) {
      this.warningMessage = warningMessage.waitToLoadData;

      window.scroll(0, 0);

      return;
    }
    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.faMainGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (!rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.faMainGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }

      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);
    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;
      this.isEditMode = false;
      this.editEnabled = false;
      window.scroll(0, 0);
    }
  }

  enableDisableRowNode(rowIndex, checked) {
    const rowNode = this.faMainGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
  }

  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.spinner.show();

    const rowNode = this.faMainGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    // All leaf nodes (includes parent node)
    const leafNodesIndexes = rowNode.allLeafChildren.map((childNode) => childNode.rowIndex);

    // console.log(rowNode);
    // console.log(leafNodesIndexes);

    // Enable/Disable all leaf nodes
    for (let index = 0; index < leafNodesIndexes.length; index++) {
      const leafNodeIndex = leafNodesIndexes[index];
      this.enableDisableRowNode(leafNodeIndex, checked);
    }

    // If any child row is checked/unchecked
    const parentNode = rowNode.parent;
    if (parentNode.rowIndex) {
      // If child row is unchecked
      if (checked === false) {
        this.enableDisableRowNode(parentNode.rowIndex, checked);
      }

      // If child row is checked
      const childrenCount = parentNode.allChildrenCount; // Children count (excluding parent node)

      // All checked leaf nodes (includes parent)
      const checkedChildrenCount = parentNode.allLeafChildren.filter((childNode) => childNode.data.rowCheckBox).length;

      // console.log(
      //   childrenCount,
      //   checkedChildrenCount,
      //   childrenCount === checkedChildrenCount
      // );

      // Self explanatory
      if (childrenCount === checkedChildrenCount) {
        this.enableDisableRowNode(parentNode.rowIndex, checked);
      }
    }

    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }

  headerCheckChanged(event) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    const headerChecked = event.checked;

    this.headerCheckboxChecked = event.checked;

    const isEditable = this.isEditMode;

    this.faMainGrid.gridOptions.api.forEachNode((rowNode) => {
      if (headerChecked) {
        rowNode.data.rowCheckBox = true;

        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }

      rowNode.setData(rowNode.data);
    });
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.faMainGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.faMainGrid.gridOptions.rowData.length;
    const selectedRowCount = this.faMainGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.faMainGrid.gridOptions.api.refreshHeader();
    }
  }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL') {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex((data) => data.kanban === 'ALL');

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(): void {
    if (this.selectedPartNo.length >= 1) {
      if (this.selectedPartNo[this.selectedPartNo.length - 1].item_id === 'ALL') {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex((data) => data.item_id === 'ALL');

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }

    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
    this.selectedPartNo.forEach((element) => {
      this.kanbanListSelected.push(element.kanban);
    });
  }
  // onDestCodeChange(): void {}
  // onContainerChange(): void {}
  // onSupplierChange(): void {}
  // onSpecialistChange(): void {}
  onDestCodeChange(): void {
    if (this.selectedDestCode.length >= 1) {
      if (this.selectedDestCode[this.selectedDestCode.length - 1].dest_code === 'ALL') {
        this.selectedDestCode = [];
        this.selectedDestCode.push(this.destCodeList[0]);
      } else {
        let indexAll = this.selectedDestCode.findIndex((data) => data.dest_code === 'ALL');

        if (indexAll > -1) {
          this.selectedDestCode.splice(indexAll, 1);
        }
      }
    }
  }
  onContainerChange(): void {
    if (this.selectedContainer.length >= 1) {
      if (this.selectedContainer[this.selectedContainer.length - 1].cont_code === 'ALL') {
        this.selectedContainer = [];
        this.selectedContainer.push(this.containerList[0]);
      } else {
        let indexAll = this.selectedContainer.findIndex((data) => data.cont_code === 'ALL');

        if (indexAll > -1) {
          this.selectedContainer.splice(indexAll, 1);
        }
      }
    }
  }

  onSupplierChange(): void {
    if (this.selectedSupplier.length >= 1) {
      if (this.selectedSupplier[this.selectedSupplier.length - 1].customer_supp === 'ALL') {
        this.selectedSupplier = [];
        this.selectedSupplier.push(this.supplierList[0]);
      } else {
        let indexAll = this.selectedSupplier.findIndex((data) => data.customer_supp === 'ALL');

        if (indexAll > -1) {
          this.selectedSupplier.splice(indexAll, 1);
        }
      }
    }
  }
  onSpecialistChange(): void {
    if (this.selectedSpecialist.length >= 1) {
      if (this.selectedSpecialist[this.selectedSpecialist.length - 1].EMPLOYEE === 'ALL') {
        this.selectedSpecialist = [];
        this.selectedSpecialist.push(this.specialistList[0]);
      } else {
        let indexAll = this.selectedSpecialist.findIndex((data) => data.EMPLOYEE === 'ALL');

        if (indexAll > -1) {
          this.selectedSpecialist.splice(indexAll, 1);
        }
      }
    }
  }

  hasErrors(data): any {
    const editedData = [];
    for (let index = 0; index < data.length; index++) {
      const row = data[index];
      var p = [];
      row.error = '';

      if (
        !row.fa_comment ||
        row.fa_comment.includes('$') ||
        row.fa_comment?.length < 1 ||
        row.revMinQtyText?.isEmpty ||
        row.revMaxQtyText?.isEmpty
      ) {
        p.push('Please update the cell(s) highlighted in red color');
      }

      if (row.rev_min_qty > row.rev_max_qty) {
        p.push('FA Min Final Quantity cannot be greater than FA Max Final Quantity');
      }

      const order_lot = row.order_lot ?? row.hidden_order_lot;

      let numberIsNotAll9s = !this.checkIfAll9s(row.rev_min_qty);

      if (numberIsNotAll9s && row.rev_min_qty % order_lot !== 0) {
        p.push(`FA Min Final Quantity must be a multiple of order lot (${order_lot})`);
      }

      numberIsNotAll9s = !this.checkIfAll9s(row.rev_max_qty);

      if (numberIsNotAll9s && row.rev_max_qty % order_lot !== 0) {
        p.push(`FA Max Final Quantity must be a multiple of order lot (${order_lot})`);
      }

      if (p.length > 0) {
        row.error = p
          .map(function (val, index) {
            return `${(index + 1).toString()}.   ${val}`;
          })
          .join('\n');
      }
      editedData.push(row);
    }
    return editedData;
  }

  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };
    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-component';
      dialogConfig.height = '350px';
      dialogConfig.width = '1100px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(EditDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          this.spinner.show();
          this.saveChanges(data.modifiedRowData);
        }
      });
    }
    // else {
    //   this.warningMessage = 'Select rows to edit';
    //   // setTimeout(() => {
    //   //   this.warningMessage = '';
    //   // }, 10000);
    //   window.scroll(0, 0);
    // }
  }

  getDataForEdit(): any {
    let data = this.getModifiedRowData();
    data = this.hasErrors(data);
    if (data.length < 1) {
      this.warningMessage = warningMessage.editToReview;
    }

    // this.minVanDate = this.vanningFrom;
    // this.maxVanDate = this.vanningTo;

    const dateField = 'vanning_date';

    const hiddenDateField = 'hidden_vanning_date';

    const baseDate = data[0][dateField] ? data[0][dateField] : data[0][hiddenDateField];

    this.minVanDate = baseDate;
    this.maxVanDate = baseDate;

    const updatedData = [];
    let changedValuesCount = 0;

    data.forEach((element) => {
      // get min max vanning dates
      const maxDate = new Date(this.maxVanDate);
      const minDate = new Date(this.minVanDate);

      const rowVal = element[dateField] ? element[dateField] : element[hiddenDateField];

      const rowDate = new Date(rowVal);

      if (rowDate > maxDate) {
        this.maxVanDate = rowVal;
      }

      if (rowDate < minDate) {
        this.minVanDate = rowVal;
      }

      let valuesChanged = false;
      const details = [];

      if (element.old_rev_min_qty !== element.rev_min_qty) {
        details.push(`Updated FA Min Final from ${element.old_rev_min_qty} to ${element.rev_min_qty}.`);
        valuesChanged = true;
      }
      if (element.old_rev_max_qty !== element.rev_max_qty) {
        details.push(`Updated FA Max Final from ${element.old_rev_max_qty} to ${element.rev_max_qty}.`);

        valuesChanged = true;
      }

      if (valuesChanged) {
        changedValuesCount++;
      }

      const updatedFields = {
        business_entity: this.namcValue,
        ...element,

        item_id: element.item_id ? element.item_id : element.hidden_item_id,
        kanban: element.kanban ? element.kanban : element.hidden_kanban,
        vanning_date: element.vanning_date ? element.vanning_date : element.hidden_vanning_date,

        rev_min_qty: '' + (element.rev_min_qty ?? ''),
        rev_max_qty: '' + (element.rev_max_qty ?? ''),
        fa_comment: element.fa_comment,
        details: details?.join('\n\r'),
        // details: details?.join(' '),
        valuesChanged,
      };
      delete updatedFields.revMinQtyText;
      delete updatedFields.revMaxQtyText;
      delete updatedFields.comment;
      updatedData.push(updatedFields);
    });

    const editedRecords = updatedData.filter((element) => element.valuesChanged);
    if (editedRecords.length > 0) {
      this.openReviewDialog(editedRecords);
    } else {
      this.warningMessage = warningMessage.editToReview;
    }
    // return reviewData;
  }

  getDataForUpload(): any {
    const data = [...this.rowData];

    const updatedData = [];

    const dateField = 'vanning_date';
    this.maxVanDate = data[0][dateField];
    this.minVanDate = data[0][dateField];

    data.forEach((element) => {
      // get min max vanning dates
      const maxDate = new Date(this.maxVanDate);
      const minDate = new Date(this.minVanDate);
      const rowDate = new Date(element[dateField]);
      const rowVal = element[dateField];

      if (rowDate > maxDate) {
        this.maxVanDate = rowVal;
      }

      if (rowDate < minDate) {
        this.minVanDate = rowVal;
      }

      const fa_comment = element.fa_comment;

      // ...
      const updatedFields = {
        ...element,
        business_entity: this.namcValue,
        rev_min_qty: '' + element.rev_min_qty,
        rev_max_qty: '' + element.rev_max_qty,
        fa_comment: fa_comment ?? '',
      };

      delete updatedFields.revMinQtyText;
      delete updatedFields.revMaxQtyText;
      delete updatedFields.comment;

      updatedData.push(updatedFields);
    });

    this.exportData = [...updatedData];

    return updatedData;
  }

  getData(): any {
    return this.uploadEnabled ? this.getDataForUpload() : this.getDataForEdit();
  }

  uploadFileBeforeSaveChanges() {
    this.getBatchStatus(this.uploadFileBeforeSaveChange);
  }

  uploadFileBeforeSaveChange = () => {
    this.removeMessage();
    if (this.uploadEnabled) {
      this.uploadToS3();
    } else {
      this.getData();
    }
  };

  saveChanges(data?): void {
    this.spinner.show();
    this.removeMessage();
    if (this.uploadEnabled) {
      data = this.getData();
    }

    if (data === undefined) {
      this.spinner.hide();
      return;
    }

    if (data.length < 1) {
      this.warningMessage = warningMessage.editToReview;
      return;
    }

    if (this.uploading) {
      setTimeout(() => {
        this.saveChanges();
      }, 100);
      return;
    }

    const data1 = {
      workid: this.user,
      user_role: this.userRole,
      name: localStorage.getItem('UserName'),
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      vanning_from: this.minVanDate,
      vanning_to: this.maxVanDate,
      data: this.uploadEnabled ? [] : data,
      // data: data.slice(this.editApiStartPosition, this.editApiEndPosition),
      fileKey: this.uploadEnabled ? this.fileName : undefined,
      operation: this.uploadEnabled ? 'upload' : 'edit',
      doValidation: this.timesEditApiCalled === 0,
      startPosition: this.editApiStartPosition,
      endPosition: this.editApiEndPosition,
      batchRunLogID: this.timesEditApiCalled === 0 ? 0 : this.batchRunLogID,
      currenteasternDate: this.easternDate,
    };

    const totalRows = data.length;

    this.flalService.editFAMaintenance(data1).subscribe({
      error: this.errorCallback,
      next: (response) => {
        const {
          errors,
          validatedData,
          // totalRows,
          batchRunLogID,
          editedRows,
        } = response.body;

        this.batchRunLogID = batchRunLogID;

        if (errors) {
          this.timesEditApiCalled = 0;
          this.editApiStartPosition = 0;
          this.editApiEndPosition = this.editApiDefaultLimit;

          this.uploadError = true;
          this.excelService.uploadedData = validatedData;
          this.uploadEnabled = false;
          this.rowData = [];

          this.spinner.hide();
          this.warningMessage = warningMessage.uploadFileError;

          return;
        } else {
          this.exportData = [...this.rowData];

          if (totalRows > this.editApiEndPosition) {
            this.timesEditApiCalled++;

            this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;

            this.editApiEndPosition = this.editApiStartPosition + this.editApiDefaultLimit;

            this.saveChanges();

            return;
          }

          this.timesEditApiCalled = 0;
          this.editApiStartPosition = 0;
          this.editApiEndPosition = this.editApiDefaultLimit;

          if (editedRows.length > 0) {
            // Show only updated rows
            this.resetVariablesState();
            // console.log(editedRows);
            this.rowData = editedRows;
            this.faMainGrid.gridOptions.api.setRowData(this.rowData);
            this.setPageSizeToAll();

            this.removeMessage();
            this.successMessage = successMessage.recordUpdated;
          } else {
            this.warningMessage = `${warningMessage.recordsNotUpdated}`;

            this.headerCheckboxChecked = false;

            this.faMainGrid.gridOptions.api.refreshHeader();

            this.rowData = [];
            this.faMainGrid.gridOptions.api.setRowData(this.rowData);
            this.setPageSizeToAll();
          }

          window.scroll(0, 0);

          // this.vanningFrom = moment(this.minVanDate).format('YYYY-MM-DD');
          // this.vanningTo = moment(this.maxVanDate).format('YYYY-MM-DD');
        }
        // Upload error file or uploaded file to S3
        this.uploadEnabled = false;
        this.uploadToS3();

        // Not calling search function now to show only updated rows
        // this.onSearch(0, false);

        this.spinner.hide();
      },
    });
  }

  resetVariablesState(): void {
    this.uploadError = false;
    this.uploadEnabled = false;
    this.isEditMode = false;
    this.editEnabled = this.isEditMode;
    this.setPageSizeToAll(this.defaultPageSize);

    if (this.faMainGrid) {
      this.headerCheckboxChecked = false;
      this.faMainGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }
  }

  cancelEdit(): void {
    this.openConfirmationDialog();
  }

  openConfirmationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.id = 'modal-component';
    dialogConfig.height = '20 4px';
    dialogConfig.width = '470px';

    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.isEditMode = !this.isEditMode;
        this.editEnabled = this.isEditMode;
        this.resetVariablesState();
        this.faMainGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.setSelected(false);
            rowNode.data.checkedEditMode = false;
            rowNode.data.rowCheckBox = false;

            // Set old data to editable fields
            rowNode.data.rev_min_qty = rowNode.data.old_rev_min_qty;
            rowNode.data.rev_max_qty = rowNode.data.old_rev_max_qty;
          }
          rowNode.setData(rowNode.data);
        });

        this.headerCheckboxChecked = false;

        this.faMainGrid.gridOptions.api.refreshHeader();

        this.onSearch(0, true);
      }
    });
  }

  getWrappedColumn(v) {
    return { v, t: 's', s: { alignment: { wrapText: true } } };
  }

  modifyRowDataForExport(data) {
    const modifiedDataForExport = [];

    for (const row of data) {
      if (row.cont_code === 'ALL') {
        continue;
      }

      modifiedDataForExport.push({
        ...row,
        item_id: row.item_id ? row.item_id : row.hidden_item_id,
        kanban: row.kanban ? row.kanban : row.hidden_kanban,
        vanning_date: row.vanning_date ? row.vanning_date : row.hidden_vanning_date,
        order_lot: row.order_lot ? row.order_lot : row.hidden_order_lot,
      });
    }

    return modifiedDataForExport;
  }

  getExportData() {
    const data = [];

    const dataToExport = this.uploadError
      ? this.excelService.uploadedData
      : this.modifyRowDataForExport(this.exportData);

    // Normal export means user is normally exporting
    // There are no errors or it is not going to be uploaded
    const normalExport = !this.validating && !this.uploadEnabled && !this.uploadError && !this.uploading;

    dataToExport.forEach((row) => {
      const fa_comment = row.fa_comment;
      // typeof row.comment === 'string' && row.comment?.length > 0
      //   ? row.comment
      //   : row.fa_comment ?? '';

      const newRow = {
        vanning_date: row.vanning_date,
        item_id: row.item_id,
        kanban: row.kanban,
        cont_code: row.cont_code,
        order_lot: row.order_lot,
        fa_min_orig: row.orig_min_qty,
        fa_max_orig: row.orig_max_qty,
        fa_min_final: row.rev_min_qty,
        fa_max_final: row.rev_max_qty,
        fa_comment: fa_comment,
        remarks: row.errors?.join('\r\n'),
      };

      if (normalExport) {
        delete newRow['fa_comment'];
        delete newRow['remarks'];
      }

      Object.keys(newRow).forEach((key) => {
        newRow[key] = this.getWrappedColumn(newRow[key]);
      });

      data.push(newRow);
    });

    return data;
  }

  getExportDataSameAsUI() {
    const data = [];

    this.exportData.forEach((row) => {
      const {
        vanning_date,
        item_id,
        part_description,
        kanban,
        cont_code,
        order_lot,
        specialist,
        orig_min_qty,
        orig_max_qty,
        rev_min_qty,
        rev_max_qty,
        date_time,
        userid,
        fa_comment,
      } = row;

      // Prepare comments string for Excel Export/Download
      // 1st replace all replaces parts of a single comment
      // 2nd replace removes $ at the start without adding preceeding new lines
      // 3rd replace replaces $ and adds new lines
      const comments = fa_comment && fa_comment.replaceAll('#', '  ').replace('$', '').replaceAll('$', '\n\n');

      // Keys should be same as in Show/Hide List
      const newRow = {
        vanning_date,
        item_id,
        part_description,
        kanban,
        cont_code,
        order_lot,
        specialist,
        orig_min_qty,
        orig_max_qty,
        revMinQtyText: rev_min_qty,
        revMaxQtyText: rev_max_qty,
        date_time,
        userid,
        comment: comments ?? '',
      };

      const showHideValues: string[] = this.selectedShowHideList.map((item) => item.value);

      Object.keys(newRow).forEach((key) => {
        newRow[key] = this.getWrappedColumn(newRow[key]);

        // Show/Hide does not have vanning date, but it is needed in export/download
        if (key === 'vanning_date') return;

        // Remove keys of columns which are hidden
        if (!showHideValues.includes(key)) {
          delete newRow[key];
        }
      });

      data.push(newRow);
    });

    return data;
  }

  exportAsXLSXSameAsUI(): void {
    this.removeMessage();
    this.spinner.show();

    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));

    this.exportData = this.getSelectedRows();
    if (this.headerCheckboxChecked) {
      this.exportData = [];
      this.faMainGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        this.exportData.push(node.data);
      });
    }

    const data = this.getExportDataSameAsUI();

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      return;
    }

    const showHideLabels: string[] = this.selectedShowHideList.map((item) => {
      const { label } = item;
      // Few columns in Show/Hide List are having abbreviations in ()
      // Like SPC (Specialist) so we only need to consider text before ()
      const indexOfParenthesis = label.indexOf(' (') === -1 ? label.length : label.indexOf(' (');

      return label.slice(0, indexOfParenthesis);
    });

    // logData(showHideLabels);

    // All shown columns' headers
    const shownColumnHeaders = this.headersSameAsUI.filter((header) => showHideLabels.includes(header));

    // 1st header is Van DT, which is not in show hide list, so adding here
    const UIHeaders = [this.headersSameAsUI[0], ...shownColumnHeaders];

    setTimeout(async () => {
      let headers = UIHeaders;
      const timestamp = easternCurrentDate
        .split('.')[0]
        .replace('T', '_')
        .split('')
        .filter((d) => d !== '-' && d !== ':')
        .join('');

      this.excelService.exportExcelFileStyle(data, 'Fluctuation-Allowance_' + timestamp + '_UI_export.xlsx', headers);

      this.spinner.hide();
    }, 100);
  }

  exportAsXLSX(): void {
    this.removeMessage();
    this.spinner.show();

    this.exportData = this.getSelectedRows();
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    // console.log(easternCurrentDate);

    if (this.headerCheckboxChecked) {
      this.exportData = [];
      this.faMainGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        this.exportData.push(node.data);
      });
    }

    const data = this.getExportData();

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      return;
    }

    setTimeout(async () => {
      const normalExport = !this.uploadEnabled && !this.uploadError && !this.uploading;

      let headers = [...this.headers];

      if (normalExport) {
        headers = [...this.headers.slice(0, this.headers.length - 1)];
      }
      const timestamp = easternCurrentDate
        .split('.')[0]
        .replace('T', '_')
        .split('')
        .filter((d) => d !== '-' && d !== ':')
        .join('');

      this.excelService.downloadFLALExcel(
        data,
        this.uploadError ? this.getFileName() : 'Fluctuation-Allowance_' + timestamp + '_upload',
        headers
      );

      this.spinner.hide();
    }, 100);
  }

  download() {
    this.removeMessage();
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      file_name: 'fluctuationallowance.xlsx',
    };

    this.flalService.downloadFluctuationAllowanceFile(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res) {
          const a = document.createElement('a');
          a.href = res.body.data;
          a.target = '_blank';
          a.rel = 'noreferrer noopener';

          if (a) {
            window.location.href = a.href;
          }

          this.spinner.hide();
        } else {
          this.spinner.hide();
          this.warningMessage = warningMessage.fileNotAvilable;
        }
      },
    });
  }

  convertNaN(value) {
    value = Math.round(parseFloat(value?.trim()));
    return isNaN(value) ? '' : value;
  }

  parseUploadedRow = (row) => {
    row = {
      vanning_date: row['vandt']?.trim(),
      item_id: row['partno']?.toUpperCase(),
      kanban: row['kanban']?.trim(),
      order_lot: this.convertNaN(row['qpc']),
      cont_code: row['cc']?.trim().toUpperCase(),
      orig_min_qty: this.convertNaN(row['faminoriginal']),
      orig_max_qty: this.convertNaN(row['famaxoriginal']),
      rev_min_qty: this.convertNaN(row['faminfinal']),
      rev_max_qty: this.convertNaN(row['famaxfinal']),
      fa_comment: row['comments']?.trim(),
    };

    delete row['vanningdate'];
    delete row['faminoriginal'];
    delete row['famaxoriginal'];
    delete row['faminfinal'];
    delete row['famaxfinal'];

    return row;
  };

  get9sBasedOnLength(length) {
    switch (length) {
      case 6:
        return '999999';

      case 7:
        return '9999999';

      default:
        return '';
    }
  }

  checkIfAll9s(quantity) {
    quantity = '' + quantity;
    const length = quantity.length;

    if (length === 6 && quantity === '999999') {
      return true;
    }

    return false;
  }

  // Validate uploaded file's data
  validateData = (data) => {
    let groupCount = 1;
    let errorCount = 0;

    const validatedData = [];
    data.forEach((row2) => {
      const row = this.parseUploadedRow(row2);

      row.group = [groupCount];
      groupCount++;

      const errors = [];

      const numericValidationKeys = ['orig_min_qty', 'orig_max_qty', 'rev_max_qty', 'rev_min_qty'];

      const emptyValidationKeys = [
        'vanning_date',
        'item_id',
        'kanban',
        'cont_code',
        'order_lot',
        ...numericValidationKeys,
      ];

      emptyValidationKeys.forEach((key) => {
        if (row[key] === undefined || row[key] === '') {
          errors.push(`${this.displayNames[key]} cannot be empty.`);
        }
      });

      numericValidationKeys.forEach((key) => {
        if (!/^\d+$/.test(row[key])) {
          errors.push(`${this.displayNames[key]} must be an integer.`);
        } else if (row[key] < 0 || row[key] > 9999999) {
          errors.push(`${this.displayNames[key]} must be between 0 and 9999999.`);
        } else {
          row[key] = Math.round(parseFloat(row[key]));
        }
      });

      if (row.orig_min_qty > row.orig_max_qty) {
        errors.push(`FA Min Original cannot be greater than FA Max Original.`);
      }

      if (row.rev_min_qty > row.rev_max_qty) {
        errors.push(`FA Min Final cannot be greater than FA Max Final.`);
      }

      let numberIsNotAll9s = !this.checkIfAll9s(row.rev_min_qty);

      const { order_lot } = row;
      if (numberIsNotAll9s && row.rev_min_qty % order_lot !== 0) {
        errors.push(`FA Min Final must be a multiple of order lot (${order_lot})`);
      }

      numberIsNotAll9s = !this.checkIfAll9s(row.rev_max_qty);

      if (numberIsNotAll9s && row.rev_max_qty % order_lot !== 0) {
        errors.push(`FA Max Final must be a multiple of order lot (${order_lot})`);
      }

      const vanDate = String(new Date(row.vanning_date));

      if (vanDate === 'Invalid Date') {
        errors.push(`Vanning Date must be in MM/DD/YYYY format.`);
      } else if (new Date(row.vanning_date) < new Date(this.onInitVanningFrom)) {
        errors.push(`Vanning Date cannot be older than today's vanning date.`);
      }

      const indexes = this.findAllIndexes(data, row, validatedData.length);

      if (indexes.length > 0) {
        errors.push(`Duplicate record(s) found in row(s) ${indexes.join(', ')}`);
      }

      row.errors = errors;
      errorCount += errors.length;

      validatedData.push(row);
    });

    return { uploadedData: validatedData, errorCount };
  };

  validateDataWithDatabase = () => {
    setTimeout(() => {
      if (this.uploading) {
        this.validateDataWithDatabase();
        return;
      }

      const data = {
        workid: this.user,
        user_role: this.userRole,
        name: localStorage.getItem('UserName'),
        business_entity: this.namcValue,
        fileKey: this.fileName,
      };

      this.flalService.validateFAMaintenance(data).subscribe({
        error: this.errorCallback,
        next: (res) => {
          const { errorCount, validatedData } = res.body;

          if (errorCount) {
            this.timesEditApiCalled = 0;
            this.editApiStartPosition = 0;
            this.editApiEndPosition = this.editApiDefaultLimit;

            this.uploadError = true;
            this.excelService.uploadedData = validatedData;
            this.uploadEnabled = false;
            this.rowData = [];

            this.spinner.hide();
            this.warningMessage = warningMessage.uploadFileError;

            this.uploadToS3();

            this.validating = false;

            return;
          }

          this.rowData = [...this.exportData];

          this.setPageSizeToAll();

          this.faMainGrid.gridOptions.api.setRowData(this.rowData);

          this.headerCheckboxChecked = false;
          this.faMainGrid.gridOptions.api.refreshHeader();

          this.uploadError = false;
          this.validating = false;
          this.uploadEnabled = true; // To enable save/cancel button
          this.editEnabled = false;
          this.warningMessage = warningMessage.uploadEnabled;

          setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
            this.spinner.hide();
          }, 200);
        },
      });
    }, 1000);
  };

  findAllIndexes(data, row, index) {
    const indexes = [];

    for (let i = 0; i < data.length; i++) {
      const dataRow = this.parseUploadedRow(data[i]);

      if (
        dataRow.vanning_date === row.vanning_date &&
        dataRow.item_id === row.item_id &&
        dataRow.kanban === row.kanban &&
        dataRow.cont_code === row.cont_code &&
        dataRow.order_lot === row.order_lot &&
        i !== index
      ) {
        indexes.push(i + 2);
      }
    }
    return indexes;
  }

  validateFile(filename, sizeInBytes) {
    this.uploadError = false;

    this.justFileName = filename;
    this.allowedFilesize = 5000; // Kilobytes

    const allowedFileTypes = ['xlsx', 'xls'];

    const fileType = filename.split('.')[filename.split('.').length - 1];

    const allowedFilesizeInBytes = this.allowedFilesize * 1024;

    if (!allowedFileTypes.includes(fileType)) {
      this.warningMessage = `Only ${allowedFileTypes} files are accepted.`;

      return false;
    }

    if (!(sizeInBytes <= allowedFilesizeInBytes)) {
      this.warningMessage = warningMessage.uploadFileSizeTooBig;

      return false;
    }

    return true;
  }

  @HostListener('drop', ['$event']) onDrop(event) {
    this.onFileChange(event, true);
  }
  onFileChange(event: any, isDrag) {
    this.removeMessage();

    this.spinner.show();

    const file: File = event.target.files.item(0);

    const isFileValid = this.validateFile(file.name, file.size);

    if (!isFileValid) {
      this.spinner.hide();
      return;
    }

    setTimeout(() => {
      this.files = [file];
      this.excelService.uploadedFile = file;

      this.fileName = file.name;

      const target: DataTransfer = (isDrag ? event.dataTransfer : event.target) as DataTransfer;

      const reader: FileReader = new FileReader();

      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, {
          type: 'binary',
          cellDates: true,
          dateNF: 'mm/dd/yyyy;@',
        });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /*Remove empty spaces and convert to lower case */
        const range = XLSX.utils.decode_range(ws['!ref']);
        for (let C = range.s.c; C <= range.e.c; C++) {
          const address = `${XLSX.utils.encode_col(C)}1`; // <-- first row
          if (!ws[address]) {
            continue;
          }
          ws[address].w = ws[address].w.replace(/\s/g, '').toLowerCase();
        }

        /* convert to Json */
        this.excelService.uploadedData = XLSX.utils.sheet_to_json(ws, {
          raw: false,
          dateNF: 'mm/dd/yyyy',
        });

        this.spinner.hide();

        const { uploadedData, errorCount } = this.validateData(this.excelService.uploadedData);

        this.excelService.uploadedData = uploadedData;

        event.target.value = null; // To clear the uploaded file

        if (uploadedData.length < 1) {
          this.spinner.hide();
          this.warningMessage = warningMessage.fileEmpty;

          return;
        }

        if (errorCount > 0) {
          this.uploadError = true;
          this.rowData = [];

          this.spinner.hide();
          this.warningMessage = warningMessage.uploadFileError;

          this.uploadToS3();

          return;
        }

        this.exportData = [...uploadedData];

        this.validating = true;
        this.uploadToS3();

        this.validateDataWithDatabase();

        return;
      };
      reader.readAsBinaryString(target.files[0]);
    }, 1000);
  }

  getFileName() {
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    const Etimestamp = easternCurrentDate

      .split('.')[0]
      .replace('T', '_')
      .split('')

      .filter((d) => d !== '-' && d !== ':')
      .join('');

    const timestamp = new Date()
      .toISOString()
      .split('.')[0]
      .replace('T', '_')
      .split('')
      .filter((d) => d !== '-' && d !== ':')
      .join('');
    return this.uploadError
      ? `fluctuationallowance/${this.namcName}/error/${Etimestamp}.xlsx`
      : `fluctuationallowance/${this.namcName}/upload/flal_upload_${timestamp}.xlsx`;
  }

  uploadToS3(): void {
    this.spinner.show();

    const data = this.getExportData();

    this.fileName = this.getFileName();

    this.uploading = true;

    setTimeout(async () => {
      this.fileBuffer = await this.excelService.getFileBuffer(data, this.fileName, this.headers);

      // this.excelService.readFileBuffer(this.fileBuffer)

      this.uploadExcelDataToS3({ fileName: this.fileName });

      if (this.uploadError) {
        this.spinner.hide();
      }
    }, 100);
  }

  uploadExcelDataToS3({ fileName }) {
    const data = {
      workid: this.user,
      user_role: this.userRole,
      fileName: fileName,
    };

    this.flalService.getExcelFileUrl(data).subscribe({
      error: this.errorCallback,
      next: (response) => {
        if (response.body.signedUrl) {
          const a = document.createElement('a');
          a.href = response.body.signedUrl;

          this.flalService.putDatainExcel(this.fileBuffer, a.href).subscribe({
            error: this.errorCallback,
            next: (res) => {
              if (this.uploadEnabled) {
                this.saveChanges();
              }

              this.uploading = false;
            },
          });
        }
      },
    });
  }
  fa_columns;
  onShowHideChange(ev: any) {
    this.faMainGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.faMainGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) return;

    this.fa_columns = hideItems;
    if (!hideItems.length) this.fa_columns = [{}];
  }

  saveShowHide() {
    this.spinner.show();
    let fa_columns = this.fa_columns;
    if (fa_columns) {
      this.lookupService.updateUserShowHideColumns({ fa_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
  }
}
