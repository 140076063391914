import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],
})
export class FaqComponent implements OnInit {
  user: string;
  userRole: string;
  namcName: string;

  constructor(private readonly spinner: NgxSpinnerService, private location: Location) {}

  panelOpenState = false;
  role = '';
  workid = '';
  msgNoContent = 'No Content';
  faqResponse = [];
  forecastResponse = [];
  iveResponse = [];
  lineshareResponse = [];
  vehicleSpecResponse = [];
  forecastApp = 'Forecast';
  iveApp = 'IVE';
  vehicleSpecApp = '100 Vehicle Spec';
  lineshareApp = 'Line Share';

  namcValue;

  ngOnInit(): void {
    this.spinner.show();
    this.namcValue = localStorage.getItem('namcvalue');

    this.user = localStorage.getItem('workdayId');

    this.userRole = localStorage.getItem('UserRoles');

    this.namcName = localStorage.getItem('namcName');

    this.forecastResponse = [
      {
        faq_id: '1',

        faq_question: 'What NAMCs use Overseas Parts application ?',

        faq_answer: 'TMMK, TMMI, TMMTX, TMMMS, TMMBC, TMMGT, MTM,TMMAL, TMMWV',

        application: 'Forecast',
      },
    ];
    // this.iveCommonService.getFaq(this.role, this.workid).subscribe(success => {
    // 	if (success.body !== this.msgNoContent) {
    // 		this.faqResponse = success.body
    // 		for (const faq of this.faqResponse) {
    // 			if (faq.application === 'Forecast') {
    // 				this.forecastResponse.push(faq)
    // 			}
    // 			else if (faq.application === 'Line Share') {
    // 				this.lineshareResponse.push(faq)
    // 			}
    // 			else if (faq.application === 'IVE') {
    // 				this.iveResponse.push(faq)
    // 			}
    // 			else if (faq.application === '100 Vehicle Spec') {
    // 				this.vehicleSpecResponse.push(faq)
    // 			}
    // 		}
    // 	}
    // 	if (success.statusCode === 204 || success.body === this.msgNoContent) {
    // 		this.spinner.hide();
    // 	}
    // 	this.spinner.hide();
    // }, err => {
    // 	this.spinner.hide();
    // 	console.log(err, "Error happened while getting faq")
    // })
  }
  goBack() {
    this.location.back();

    console.log('goBack()...');
  }
}
