
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { JobgridService } from '../jobstatus/jobgrid.service';
import { StatusrenderComponent } from './statusrender/statusrender.component';
// import { OrderforecastService } from '../services/orderforecast/orderforecast.service';
import { PipeJobstatusService } from '../services/pipe-jobstatus/pipe-jobstatus.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, interval, Subscription } from 'rxjs';
import { autoRefreshInterval } from '../constants';
import { warningMessage } from '../constants';
import * as mm from 'moment-timezone';
import * as moment from 'moment';

import { filter } from 'rxjs/operators';


import { Router } from '@angular/router';


@Component({
  selector: 'app-pipe-jobstatus',
  templateUrl: './pipe-jobstatus.component.html',
  styleUrls: ['./pipe-jobstatus.component.scss']
})
export class PipeJobstatusComponent implements OnInit, OnDestroy {


  namcValue = localStorage.getItem('namcvalue');
  namcName = localStorage.getItem('namcName');
  user: string;
  userRole: any;
  indexOfBrazilAir: any;
  indexOfBrazilVessel: any;
  indexOfObject1: any;
  indexOfObject2: any;
  public gridColumnApi;
  public columnDefs;
  public columnDefs1;
  public defaultColDef;
  tooltipShowDelay = 0;
  public rowData: any;
  public rowData1: any;
  frameworkComponents: any;
  blrq_status: boolean;
  userName: string;
  holdreleaseNotification: any;
  jobGridOptions = {} as any;
  fileGridOptions = {} as any;
  vehicleFile: string;

  warningMessage = '';
  count = 0;
  getSudoDate;
  sudoDate = [];

  jobSubscription$: Subscription;
  dateFormat = 'MM/DD/YYYY';
  newDate = String(mm().tz('US/Michigan').format(this.dateFormat)).substring(0, 10);

  context: any;
  public modules = [];
  namc: string = '';
  @ViewChild('jobGrid') jobGrid;
  gridApi;
  toggleStatus: boolean;
  bulkadjustmentBatchRunLog: any;
  itContact: string;
  dailyOrderTrs = 'Daily Order Transmission';
  dailyPxpTrs = 'Daily PxP Transmission';
  bulkAdjustmentStatus: any;
  monthList: any;
  easternCurrentDate: any;
  currentMonth: any;
  currentYear: any;
  jobStatusInterval$: any;
  batchSchedule: any;
  isgetsudo: boolean;

  constructor(
    private readonly JobgridService: JobgridService,

    private readonly spinner: NgxSpinnerService,
    private readonly stateService: OSPStateService,

    private readonly pipeJobstatusService: PipeJobstatusService,
    private readonly router: Router
  ) {
    this.frameworkComponents = {
      buttonRenderer: StatusrenderComponent,
    };
    this.context = {
      componentParent: this,
    };
  }
  ngOnDestroy(): void {
    this.jobSubscription$.unsubscribe();

    console.log('Stopped Auto Refresh for Job Status!');
    this.jobStatusInterval$.unsubscribe();
  }
  ngOnInit(): void {
    // first table columns



    this.jobGridOptions = this.JobgridService.getGridOptions();
    this.jobGridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };

    this.fileGridOptions = this.JobgridService.getGridOptions();
    this.fileGridOptions.onGridReady = function (params) {
      params.api.hideOverlay();
      this.gridApi = params.api;
    };

    this.columnDefs = [
      // {
      //   headerName: 'Actions',
      //   cellRendererFramework: TogglestatusComponent,
      //   sortable: false,
      //   suppressSizeToFit: true,
      //   //field: 'currentstatus',
      //   width: 198,
      //   floatingFilter: false,
      // },
      {
        headerName: 'Activities',
        field: 'batch_name',
        //cellRenderer: 'agGroupCellRenderer',
        sortable: false,
        suppressSizeToFit: true,
        width: 270,
        floatingFilter: false,

      },
      {
        headerName: 'Start DateTime',
        field: 'start_date',
        sortable: false,
        width: 230,
        floatingFilter: false,
        headerTooltip: 'mm/dd/yyyy hh:mm:ss (Eastern)',
        cellStyle: function (params) {
          var dateFormat = 'MM/DD/YYYY';
          var newDate = String(mm().tz('US/Michigan').format(dateFormat)).substring(0, 10);

          if (
            moment(params.data.start_date).isSame(new Date(newDate), 'day') &&
            moment(params.data.end_date).isSame(new Date(newDate), 'day') && params.data.status === 'COMPLETE'

          ) {
            return { background: 'rgb(225, 255,221)', color: 'rgb(37,170,0)' }; // green color

          }
        },
      },
      {
        headerName: 'End DateTime',
        field: 'end_date',
        sortable: false,
        width: 230,
        floatingFilter: false,
        headerTooltip: 'mm/dd/yyyy hh:mm:ss (Eastern)',
        cellStyle: function (params) {
          var dateFormat = 'MM/DD/YYYY';
          var newDate = String(mm().tz('US/Michigan').format(dateFormat)).substring(0, 10);

          if (
            moment(params.data.end_date).isSame(new Date(newDate), 'day') &&
            moment(params.data.start_date).isSame(new Date(newDate), 'day') && params.data.status === 'COMPLETE'
          ) {
            return { background: 'rgb(225, 255,221)', color: 'rgb(37,170,0)' }; // green color
          }
        },
      },
      {
        headerName: 'Duration',
        field: 'duration',
        sortable: false,
        width: 142,
        headerTooltip: 'hh:mm:ss',
        floatingFilter: false,
      },
      {
        headerName: 'User',
        field: 'userid',
        sortable: false,
        width: 230,
        floatingFilter: false,
      },
      {
        headerName: 'Status',
        field: 'status',
        sortable: false,

        width: 200,
        floatingFilter: false,
        cellRenderer: 'buttonRenderer',

        cellRendererParams: {
          label: 'Click 2',
        },
      },
    ];

    // second table columns
    this.columnDefs1 = [

      {
        headerName: 'Activities',
        field: 'batch_name',
        sortable: false,
        suppressSizeToFit: true,
        width: 260,
      },
      {
        headerName: 'Latest Transmission',
        headerTooltip: 'mm/dd/yyyy hh:mm:ss',
        field: 'date_time',
        sortable: false,
        width: 230,
      },
      {
        headerName: 'User',
        field: 'userid',
        sortable: false,
        width: 230,
      },
      // {
      //   headerName: 'Download File',
      //   cellRendererFramework: DownloadComponent,
      //   field: 'downloadfile',
      //   sortable: false,
      //   width: 572,
      // },
    ];

    this.jobSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
      this.startAutoRefresh();
    });
  }

  startAutoRefresh(): void {
    if (this.jobStatusInterval$) {
      return
    }

    console.log('Started Auto Refresh for Job Status!');
    this.jobStatusInterval$ = interval(autoRefreshInterval)
      .pipe(filter(() => !document.hidden))
      .subscribe(() => {
        this.refresh();
      });
  }

  access1;
  initializeData() {
    this.removeMessage();
    this.spinner.show();

    this.itContact = localStorage.getItem('itContact');

    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('namcSelected')) {
      this.namc = JSON.parse(localStorage.getItem('namcSelected') as string);
    }
    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }

    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    this.userName = localStorage.getItem('UserName');
    //access permissions






    //getjob
    const data = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
      easternCurrentDate: String(mm().tz('US/Michigan').format('YYYY-MM-DD')),
    };



    const jobApiSubScriptions = [
      this.pipeJobstatusService.getJob(data),

      // this.pipeJobstatusService.getFile(data),


    ];

    forkJoin(jobApiSubScriptions).subscribe({
      error: this.errorCallback,
      next: (res) => {
        let newResponse = []
        const idsToRemove = ['Container Addition Process LLP TNSO', 'Container Addition Process Brazil Air', 'Container Addition Process Brazil Vessel'];
        const idsToRemove2 = ['Container Addition Process Brazil Air', 'Container Addition Process Brazil Vessel'];
        if (res[0].body.getJobResponse.response?.length > 0) {

          newResponse = res[0].body.getJobResponse.response





          // if batchname includes Container Addition user name to Container Addition Batch
          newResponse = newResponse.map(obj => {
            if (obj.batch_name.includes('Container Addition')) {
              obj.userid = 'Container Addition Batch';
            }
            return obj;

          });



        }
        if (res[0].body.getJobResponse.llptdbDetails?.length > 0) {
          //removing llp and tdb files

          if (!res[0].body.getJobResponse.llptdbDetails[0].llp_enabled && !res[0].body.getJobResponse.llptdbDetails[0].tdb_enabled) {

            newResponse = newResponse.filter(item => !idsToRemove.includes(item.
              batch_name
            ));


          }
          //removing llp files
          else if (!res[0].body.getJobResponse.llptdbDetails[0].llp_enabled) {


            newResponse = newResponse.filter(item => item.batch_name !== 'Container Addition Process LLP TNSO');
          }
          //removing tdb files

          else if (!res[0].body.getJobResponse.llptdbDetails[0].tdb_enabled) {


            newResponse = newResponse.filter(item => !idsToRemove2.includes(item.
              batch_name
            ));

          }
          else {

            newResponse = res[0].body.getJobResponse.response


          }

        }


        if (res[0].body.getJobResponse.response?.length > 0) {

          this.rowData = newResponse;
          this.spinner.hide()

        } else {
          this.rowData = [];
          this.spinner.hide();
        }



      },
    });
  }





  errorCallback = (error) => {
    console.error(error);
    this.spinner.hide();

    if (error?.error?.message?.required_fields) {
      this.warningMessage = `${warningMessage.requiredFieldsMissing}  ${this.itContact}.`;
    } else {
      this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
    }
  };





  // getFileName(data, type) {
  //   // if (data === 'FOD - Vehicle Requirement') {
  //   //   let vehicleFile = this.vehicleFile as any;
  //   //   vehicleFile = vehicleFile.toLowerCase().replaceAll(' ', '');
  //   //   if (vehicleFile.includes('getsudo')) {
  //   //     return 'GETSUDO_BLDREQ';
  //   //   } else {
  //   //     return 'DAILY_BLDREQ';
  //   //   }
  //   // }
  //   if (data === 'Daily LLP TNSO Transmission') {
  //     return 'LLPTNSO';
  //   } else if (data === 'Daily TMC Air Transmission') {
  //     return 'OSTNSO_JP_AIR';
  //   } else if (data === 'Daily TMC Vessel Transmission') {
  //     return 'OSTNSO_JP_VESSEL';
  //   }
  //   else if (data === 'Daily Brazil Air Transmission') {
  //     return 'OSTNSO_BR_AIR';
  //   }
  //   else if (data === 'Daily Brazil Vessel Transmission') {
  //     return 'OSTNSO_BR_VESSEL';
  //   }

  //   else if (data === this.dailyOrderTrs) {
  //     if (type == '.xls') {
  //       return 'Final_Order';
  //     } else {
  //       return 'OSDAILY.DPO';
  //     }
  //   } else if (data === this.dailyPxpTrs) {
  //     if (type == '.xls') {
  //       return `${this.namcName}_pxp_forecast_report`;
  //     } else {
  //       return `${this.namcName}_pxp_output`;
  //     }
  //   } else if (data === 'CCS Input File') {
  //     return 'CCS_RESP';
  //   } else if (data === 'Daily Order Generation') {
  //     if (type == '.xls') {
  //       return 'Final_Order';
  //     } else {
  //       return 'OSDAILY.DPO';
  //     }
  //   } else if (data === 'Daily PxP Generation') {
  //     if (type == '.xls') {
  //       return `${this.namcName}_pxp_forecast_report`;
  //     } else {
  //       return `${this.namcName}_pxp_output`;
  //     }
  //   }
  // }

  // //download file
  // getdownloadTemplate(data, type) {
  //   this.spinner.show();
  //   this.removeMessage();
  //   if (data.batch_name === 'CCS Input File') {
  //     type = '.json';
  //   } else {
  //     if (type === '.xls') {
  //       type = '.xls';
  //     } else {
  //       type = '.txt';
  //     }
  //   }
  //   var data3 = {
  //     business_entity: this.namcValue,
  //     workid: this.user,
  //     user_role: this.userRole,
  //     namcName: this.namcName,
  //     processName: data.batch_name,
  //     fileName: this.getFileName(data.batch_name, type),
  //     type: type,
  //   };

  //   this.pipeJobstatusService.downloadFile(data3).subscribe({
  //     error: this.errorCallback,
  //     next: (success) => {
  //       if (success.body.jobReportResponse) {
  //         const { message, error, url } = success.body.jobReportResponse;
  //         if (error) {
  //           this.warningMessage = `${warningMessage.fileNotAva}`;
  //           window.scroll(0, 0);
  //         }
  //         if (message === null) {
  //           this.warningMessage = `${warningMessage.fileNotAva}`;
  //           window.scroll(0, 0);
  //         } else {
  //           saveAs(url);
  //         }

  //         // const a = document.createElement('a');

  //         // a.href = success.body.jobReportResponse;
  //         // //console.log(a.href);
  //         // a.target = '_blank';
  //         // a.rel = 'noreferrer noopener';
  //         // if (a) {
  //         //   window.location.href = a.href;
  //         // }
  //         this.spinner.hide();
  //       }
  //       if (success.body.error) {
  //         this.warningMessage = `${warningMessage.fileNotAva}`;
  //         window.scroll(0, 0);
  //         this.spinner.hide();
  //       }
  //     },
  //   });
  // }

  removeMessage() {
    this.warningMessage = '';
  }

  refresh() {
    this.removeMessage();

    this.stateService.setNamc({
      namc: localStorage.getItem('namcSelected'),
      WorkdayID: '',
      Name: '',
      Role: '',
    });

    this.initializeData();
  }



}