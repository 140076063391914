import { Component } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'grid-text-editor',
  template: `
  <button
  *ngIf="!isDisabled"
  type="button"
  class="devanall"
  (click)="devanall('value')"
  title="All container must be in NAMC ARRIVED status"


>DEVAN ALL
</button>

`,
  styles: [
    `
    .badge {
      width: 94px;
      font-size: 12px;
      border-radius: 35px;
      font-weight: 600;
    }

  `,
  ],
})
export class GridDevancontainerComponent {
  public params: any;
  showInput = true;
  label: string;

  value: any;
  isEmpty = true;
  isDisabled: boolean;

  constructor() {}

  agInit(params: any): void {
    this.params = params;
    this.params = params;

    this.onLoad(params);

    params.context.refreshHeader.subscribe(() => { 
      this.onLoad(params)
     

    
  });

  }

onLoad(params){
    let isDevan =localStorage.getItem('isDevanAll')
    // this.isDisabled=true;
    console.log("isDevan",isDevan);
    if(isDevan==='true'){

     this.isDisabled=false
     this.params = params;


    }
    else{
      this.isDisabled=true;
      this.params = params;



    }
    this.params = params;


  }

  refresh(params?: any): boolean {
    return true;
  }

  devanall(value) {

    this.params.context.componentParent.devanAll(
      this.params.data
    );
    
  }

  }


