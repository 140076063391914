

<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
 
    <div
      class="col-sm-11 movable"
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      <h3 class="head">
        
       Tap
      </h3>
      <div class="successMessage alert-dismissible">
        <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
          {{ warningMessage }}
          <button
            type="button"
            class="btn-close"
            (click)="removeMessage()"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      
    
      <div *ngIf="successMessage" class="alert alert-success" role="alert">
        {{ successMessage }}
        <button
          type="button"
          class="btn-close"
          (click)="removeMessage()"
          data-bs-dismiss="alert"
          aria-label="Close"
        ></button>
      </div>
    </div>
    </div>
    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        mat-dialog-close="cancel"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true">×</span>
      </button>
    </div>


</div>
      <div class="container-fluid">
        <div class="row">

    
      <div class="col-xl-1 col-md-2 col-sm-3 dropwidth">
        <div class="drpchildctrl">
          <span class="v1"></span>

          <label class="searchLable" >RENBAN</label>
          <p-dropdown
          [showTransitionOptions]="'0ms'"
          [hideTransitionOptions]="'0ms'"
          autoWidth="true"
          [resetFilterOnHide]="true"
          (onChange)="RenbanList()"
          [(ngModel)]="selectedRenban"
          [options]="renbanList"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="10"
          placeholder="Renban"
          appendTo="body"
          optionLabel="container_renban"
          [style]="{ width: '100%' }"
          scrollHeight="100px"        >
        </p-dropdown>        </div>
      </div>
      <div class="col-xl-1 col-md-2 col-sm-3 dropwidth">
        <div class="drpchildctrl">
          <span class="v1"></span>

          <label class="searchLable">MODULE</label>
          <p-multiSelect
            [(ngModel)]="selectedModule"
            [showToggleAll]="false"
            (onChange)="onModuleChange()"
            [options]="moduleList"
            optionLabel="module_number"
            [showTransitionOptions]="'0ms'"
            [hideTransitionOptions]="'0ms'"
            [defaultLabel]="'--Select--'"
            autoWidth="true"
            [disabled]="disablemodule"

            [style]="{ width: '100%' }"
            [resetFilterOnHide]="true"
          >
          </p-multiSelect>
        </div>
      </div>
      <div class="col-xl-2 col-md-2 col-sm-3 reset_btn">
        <label class="resetIcon" (click)="onResetDropDown()" >
          <img
            src="./assets/images/Group 115380.png"
            alt="refresh icon"
          />
        </label>
        <label class="resetlabel" (click)="onResetDropDown()">Reset</label>
        <span class="vertical"></span>
      </div>

      <div class="col-xl-1 col-md-2 col-sm-3 search">
        <button class="search_btn"  (click)="onSearch(0)"  [disabled]="disableSearch"
        title="Select at least one Renban and Module 
to perform the search"
        >SEARCH</button>
      </div>
        </div>
</div>
    <div class="col-md-12">
      <ag-grid-angular
        #osPartsGrid
        style="
          width: 100%;
          margin-top: 7px;
          height: 350px !important;
          overflow-y: auto;
          top: 3%;
          margin-bottom: 3%;
        "
        class="ag-theme-balham table-outer"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [rowSelection]="rowSelection"
        [gridOptions]="gridOptions"
        [pagination]="pagination"
        [paginationPageSize]="paginationPageSize"
        [context]="context"
        [editType]="editType"
        [suppressClickEdit]="suppressClickEdit"
      >
      </ag-grid-angular>
    </div>
    
    <div class="text-right">
      <button class="review_btn" mat-button (click)="saveChanges()"     [hidden]="!enableTap"
      >
        Fully Tap
      </button>

      <button class="cancel_btn" mat-button mat-dialog-close="cancel">
        Cancel
      </button>
    </div>
