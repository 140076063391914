export const environment = {
  production: false,
  hostname: 'localhost',
  REDIRECT_URL: 'http://localhost:4200/',

  CLIENT_ID: '1f3d2e7a-9722-4070-8fff-a41e95aece0f',
  TENANT_ID:
    'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',

  ccsApiUrl: 'https://alb.ccsdev.toyota.com/os-parts/',
  ccsApiBasicAuth: 'Basic ' + btoa('CCS:78fa095d-3f4c-48b1-ad50-e24c31d5cf35'),

  fluctuationAllowanceKey: 'TPbSDPpjBW6PRlnvPaGVJan7iSBQzdfuaMKYY6YH',
  fluctuationAllowanceUrl:
    'https://gsr4e49wu8.execute-api.us-west-2.amazonaws.com/dev/api/v1/fluctuationallowance',

  bulkAdjustmentKey: 'IVOxedD0r4aQDbSyWlZ5O9pST5OjH8zF3Bvj2axt',
  bulkAdjustmentUrl:
    'https://5uk7f7jrxg.execute-api.us-west-2.amazonaws.com/dev/api/v1/bulkadjustment',

  rundownKey: '0kW7pdAxr274hLtMKH7RJ6J48uYQuVvUxW2Dm1na',
  rundownUrl:
    'https://b8er23ct7h.execute-api.us-west-2.amazonaws.com/dev/api/v1/rundown',

  orderForecastKey: 'JJuFTFMUBH9YHFa1iq9Tk4RaA18frtMn7hUZeEMP',
  orderForecastUrl:
    'https://b0ioprk4a7.execute-api.us-west-2.amazonaws.com/dev/api/v1/orderforecast',

  lookupKey: 'XqIgfbWRZ135bW1GAIUP7aksfeez5E2Ua0BcwDVg',
  lookupUrl:
    'https://qjrik58l3f.execute-api.us-west-2.amazonaws.com/dev/api/v1/lookup',

  getsudoKey: 'n7zDPBJBcj8a6Gzv0MSfJ6DSb5COV3D74iovdSgK',
  getsudoUrl:
    'https://n3bkji5l6j.execute-api.us-west-2.amazonaws.com/dev/api/v1/getsudo',

  // This is in Setup service lambda with different url
  getsudoDatesUrl:
    'https://soam9jqsd8.execute-api.us-west-2.amazonaws.com/dev/api/v1/getsudo',

  setupKey: 'XqIgfbWRZ135bW1GAIUP7aksfeez5E2Ua0BcwDVg',
  setupUrl:
    'https://soam9jqsd8.execute-api.us-west-2.amazonaws.com/dev/api/v1/setup',

  adjustmentEnquiryKey: '2FPCfuTW5c7Tk1rImMvjkyzIPbUru4L1iwCJtAw4',
  adjustmentEnquiryUrl:
    'https://7peql79tg6.execute-api.us-west-2.amazonaws.com/dev/api/v1/adjustment',


  // pipe urls
  ospLookupUrl: 'https://d3i073ujn0.execute-api.us-west-2.amazonaws.com/dev/api/v1/lookup',
  ospLookupKey: 'kZ4MKMQfwTaXE71FrD9zu6DyNsSkWQa19N71nCW0',

  partStatusUrl: 'https://2o3wk8zrqh.execute-api.us-west-2.amazonaws.com/dev/api/v1/getpartstatus',
  partStatusKey: 'iQ9QGchHJrAMjjH5vBkU8gqIN0UyEKv3FC9yJZD0',

  partMaintenance:'https://x0wnpwhqv8.execute-api.us-west-2.amazonaws.com/dev/api/v1/partMaintenance',
  partMaintenanceKey: '2TRhLd4Y652E7cLsiGkDm5Ddcr5tUNyR7szalucU',

  ospSealVerificationUrl: 'https://om49jo8qnl.execute-api.us-west-2.amazonaws.com/dev/api/v1/sealVerification',
  ospSealVerificationKey: 'yb2Nvqi7Gl65Dru4w3nExanv1qB5T80s9EFjUI2K',

  //PIE lambda urls

  sendToPieData: `https://internalapi.pie.subprod.toyota.com/tnso/update-container-info`



};
