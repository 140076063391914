import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  ViewChild,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GriddataService } from 'src/app/services/griddata.service';

@Component({
  selector: 'app-dialog-grid',
  templateUrl: './dialog-grid.component.html',
  styleUrls: ['./dialog-grid.component.scss'],
})
export class DialogGridComponent implements OnInit {
  editEventEmitter = new EventEmitter();
  rowData: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  gridOptions = {} as any;
  //gridOptions!: GridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = false;
  context: any;
  editType;
  suppressClickEdit = true;

  editEnabled: boolean = false;
  freezeStatus: boolean = false;

  adjCodeList: any = [];
  editform: UntypedFormGroup = this.formBuilder.group({
    adjCode: [[]],
    adjReason: ['', Validators.required],
  });
  editFormcontrol = this.editform.controls;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private readonly dialogRef: MatDialogRef<DialogGridComponent>
  ) {
    this.columnDefs = [
      {
        headerName: 'Van DT',
        headerTooltip: 'Vanning Date(mm/dd/yyyy)',
        field: 'vanning_date',
        sortable: true,
        width: 135,
      },
      {
        headerName: 'Part No',
        field: 'item_id',
        sortable: true,
        width: 135,
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        sortable: true,
        width: 95,
        //cellClass: 'ag-right-aligned-cell',
      },
      {
        headerName: 'Dock',
        field: 'dock',
        width: 85,
      },
      {
        headerName: 'CC',
        headerTooltip: 'Container Code',
        field: 'cont_code',
        width: 100,
      },
      {
        headerName: 'Man Adj',
        field: 'man',
        width: 95,
        //cellClass: 'ag-right-aligned-cell',
      },
    ];
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.paginationPageSize = data.modifiedRowData.length;
    this.rowData = data.modifiedRowData;
    this.adjCodeList = data.adjCodeList;
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;
    // this.gridOptions.domLayout = 'autoHeight';
    // this.gridOptions.suppressRowClickSelection = true;
    // this.gridOptions.enableCellTextSelection = true;
    // this.gridOptions.suppressCopyRowsToClipboard = true;
    // this.gridOptions.enableRangeSelection = true;
    // this.gridOptions.rowSelection = 'single';
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,
      // suppressSizeToFit: true,
      // headerClass: 'grid-header-class',
      // cellStyle(params) {
      //   return {
      //     'line-height': '36px',
      //   };
      // },

      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        // Debounce time - Delay before Floating filter search
        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  ngOnInit() {}

  submitted: boolean = false;
  saveChanges(): any {
    if (this.editform.invalid) {
      this.submitted = true;
      return;
    }

    const addEmittedData = {
      adjCode: 'MAN',
      adjReason: this.editform.value.adjReason,
    };

    this.editEventEmitter.emit(addEmittedData);
    this.dialogRef.close();
  }
}
