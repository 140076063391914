import { Component, OnInit } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-togglestatus',
  templateUrl: './togglestatus.component.html',
  styleUrls: ['./togglestatus.component.scss']
})
export class TogglestatusComponent {


  checkBoxValue: boolean = true;
  private params: any;
  toggle: boolean = false;
  color = 'warn';
  checked = true;
  label: string;
  isDisabled: boolean;
  hi: string;
  labelPosition = 'before';
  access;
  agInit(params: any): void {
    this.access = JSON.parse(localStorage.getItem('jobstatus'));

    this.params = params;
    this.checked = !params.data.currentstatus;
    this.label = params.data.currentstatus;
    const { disableToggle } = params.data;

    if (this.label === 'InProgress' || disableToggle) {
      this.isDisabled = true;
    }

    if (params.value === 'Running') {
      this.checkBoxValue = true;
    } else {
      this.checkBoxValue = false;
    }
    this.toggle = true;

    if (
      params.data?.filestatus?.toLowerCase() ===
        'Daily Order Transmission'.toLowerCase() ||
      params.data?.filestatus?.toLowerCase() ===
        'Daily PxP Transmission'.toLowerCase() ||
      params.data?.batch_name?.toLowerCase() ===
        'Container Add Update Process'.toLowerCase() ||
      params.data?.batch_name?.toLowerCase() ===
        'Devan Tap Process'.toLowerCase() ||
      params.data?.batch_name?.toLowerCase() ===
        'Daily Order Transmission'.toLowerCase() ||
      params.data?.batch_name?.toLowerCase() ===
        'Daily PxP Transmission'.toLowerCase()
    ) {
      this.toggle = false;
    }
  }

  changed() {
    this.params.context.componentParent.userToggle(
      !this.checked,
      this.params.data
    );
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  refresh(params: any): boolean {
    params.data.amount++;
    params.data.cbox = params.value;

    params.api.refreshCells(params);

    return false;
  }
  timeLeft: number = 5;
  interval;
}
