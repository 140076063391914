import { Component, OnInit, Inject } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { MasterEditDialogComponent } from '../master-edit-dialog/master-edit-dialog.component';
import { GridApi } from 'ag-grid-community';
import { MasterDeleteComponent } from '../master-delete/master-delete.component';

@Component({
  selector: 'app-setup-actions',
  templateUrl: './setup-actions.component.html',
  styleUrls: [],
})
export class SetupActionsComponent implements ICellRendererAngularComp {
  editAccess: boolean = false;
  download: boolean = false;
  deleteAccess: boolean = false;
  params: any;
  master_id: any;
  master_name: any;
  /*editmode*/
  isEditMode: boolean = false;

  constructor(public dialog: MatDialog) {}
  access;
  refresh(): boolean {
    return false;
  }
  agInit(params): void {
    this.params = params;
    this.access = JSON.parse(localStorage.getItem('master'));
  }

  ngOnInit(): void {}

  checkChanged() {
    this.params.context.componentParent.userCheckChanged(
      this.params.node.rowIndex
    );
  }
  deleteChanged() {
    this.params.context.componentParent.userDeleteChanged(
      this.params.node.rowIndex
    );
  }
  editData(params: any): void {
    localStorage.setItem('popupname', 'edit');
    this.checkChanged();
  }
  deleteData() {
    this.deleteChanged();
  }
}
