<div class="background">
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Rundown Maintenance</h2>
      </div>
      <div class="p-2">
        <button
          *ngIf="access1.download"
          type="button"
          class="btn btn-light"
          (click)="getdownloadTemplate()"
        >
          <img
            *ngIf="access1.download"
            src="./assets/images/Group 115533.png"
            class="downloadIcon"
            alt="download icon"
          />
          TEMPLATE
        </button>
      </div>
      <div class="p-2">
        <input
          style="display: none"
          type="file"
          id="my-file"
          (change)="onFileChange($event, false)"
          accept=".xlsx"
        />
        <button
          *ngIf="access1.edit"
          [disabled]="batchCheck || !finalInialBatchCheck"
          type="button"
          onclick="document.getElementById('my-file').click()"
          class="btn btn-dark"
        >
          <img
            *ngIf="access1.edit"
            src="./assets/images/Mask_Group_515.svg"
            alt="Upload icon"
          />
          UPLOAD
        </button>
      </div>
    </div>
  </div>
  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div
      *ngIf="warningMessage || freezingMesssage"
      class="alert alert-warning"
      role="alert"
    >
      <span class="freezedivider"
        >{{ freezingMesssage }}&nbsp;&nbsp;&nbsp;&nbsp;</span
      >
      &nbsp; &nbsp;
      <span *ngIf="warningMessage"
        >{{ warningMessage }}

        <a *ngIf="uploadError" class="fileName">{{ this.fileName }}</a>
        &nbsp;&nbsp;
        <button
          *ngIf="uploadError"
          type="button"
          class="btn btn-danger"
          (click)="getErrorReport()"
        >
          <img
            src="./assets/images/Group 115689.png"
            class="downloadIcon"
            alt="Upload icon"
          />

          ERROR REPORT
        </button></span
      >&nbsp;&nbsp;
      <button
        *ngIf="warningMessage"
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
           

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">LINE PATH</label>
                <p-multiSelect
                  [showTransitionOptions]="'0ms'"
                  [(ngModel)]="selectedLinePath"
                  [options]="linePathList"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select Line path--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="line_path"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <span class="v1"></span>
                <label class="searchLable">VAN FROM</label>
                <input
                  [(ngModel)]="vanningFrom"
                  class="form-control-sm"
                  type="date"
                  autoWidth="false"
                />
              </div>
            </div>
            

            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img
                  src="./assets/images/Group 115380.png"
                  alt="refresh icon"
                />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()"
                >Reset</label
              >
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0, true)">
                SEARCH
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-6">
        <span class="edit_icon">
          <img
            *ngIf="access1.edit"
            [ngClass]="{ disabled: batchCheck|| !finalInialBatchCheck }"
            (click)="onEditMode()"
            class="img-tool"
            src="./assets/images/edit.svg"
            alt="edit button icon"
            title="Edit"
          />
          <img
            class="img-tool"
            (click)="exportAsXLSX()"
            src="./assets/images/download.svg"
            alt="download icon"
            title="Download"
          />
        </span>
      </div>

      <div class="col-sm-4"></div>
      <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select
            class="page-drpdwn"
            (change)="onPageSizeChanged()"
            [(ngModel)]="paginationPageSize"
          >
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
    <app-grid
      class="table-outer"
      #osPartsGrid
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [rowSelection]="rowSelection"
      [gridOptions]="gridOptions"
      [pagination]="pagination"
      [paginationPageSize]="paginationPageSize"
      [context]="context"
      [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay"
      [tooltipMouseTrack]="true"
      [editType]="editType"
      [detailRowAutoHeight]="true"
    >
    </app-grid>
    
  </div>
  <div *ngIf="uploadEnabled && !editEnabled" class="saveCancel">
    <button
      matRipple
      [matRippleCentered]="true"
      [matRippleUnbounded]="false"
      class="review_btn"
      (click)="uploadData()"
    >
      Upload Data
    </button>
    <button (click)="cancelSave()" class="cancel_btn">Cancel</button>
  </div>
  <div *ngIf="editEnabled" class="saveCancel">
    <button
      matRipple
      [matRippleCentered]="true"
      [matRippleUnbounded]="false"
      class="review_btn"
      (click)="ReviewChanges()"
    >
      Review Changes
    </button>
    <button (click)="cancelEdit()" class="cancel_btn">Cancel</button>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
