<div class="container-fluid">
  <div class="row" style="background: #f5f5f5">
    <div class="col-md-12">
      <ol class="breadcrumb">
        <li
          class="breadcrumb-item"
          *ngFor="let item of breadcrumbList; let i = index"
          [class.active]="i === breadcrumbList.length - 1"
        >
          <span class="textstyling" *ngIf="i !== breadcrumbList.length - 1">
            {{ item.name }}
          </span>
          <span class="textstyling1" *ngIf="i === breadcrumbList.length - 1">
            {{ item.name }}
          </span>
        </li>
      </ol>
    </div>
  </div>
</div>
