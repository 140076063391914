<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
  <div class="col-sm-11">
    <h3 class="head">CONFIRMATION</h3>
  </div>
  <div class="col-sm-1">
    <button
      type="button"
      class="btn btn-dark btn-circle btn-xl"
      [mat-dialog-close]="true"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>
<p class="head">
  {{message}}
</p>
<div class="text-right">
  <button class="review_btn" mat-button mat-dialog-close="cancel">YES</button>
  <button class="cancel_btn" mat-button [mat-dialog-close]="" cdkFocusInitial>
    NO
  </button>
</div>
