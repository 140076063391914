import { Inject, Injectable } from "@angular/core";
import {
	IPublicClientApplication,
	AccountInfo,
	EndSessionRequest,
	AuthorizationUrlRequest,
	AuthenticationResult,
	PopupRequest,
	RedirectRequest,
	SilentRequest
} from "@azure/msal-browser";
import { MSAL_INSTANCE } from "./constants";
import { Observable, from } from 'rxjs';
import { Location } from '@angular/common';
import { IMsalService } from "./IMsalService";

@Injectable()
export class MsalService implements IMsalService {
	private readonly redirectHash: string;

	constructor(
		@Inject(MSAL_INSTANCE) public instance: IPublicClientApplication,
		private readonly location: Location
	) {
		// Cache the code hash before Angular router clears it
		const hash = this.location.path(true).split('#').pop();
		console.log("HASH", this.location.path(true).split('#'))
		if (hash) {
			this.redirectHash = `#${hash}`;
			console.log("this.redirectHash", this.redirectHash)
		}
	}

	acquireTokenPopup(request: PopupRequest): Observable<AuthenticationResult> {
		return from(this.instance.acquireTokenPopup(request));
	}
	acquireTokenRedirect(request: RedirectRequest): Observable<void> {
		return from(this.instance.acquireTokenRedirect(request));
	}
	acquireTokenSilent(silentRequest: SilentRequest): Observable<AuthenticationResult> {
		return from(this.instance.acquireTokenSilent(silentRequest));
	}
	getAccountByUsername(userName: string): AccountInfo {
		return this.instance.getAccountByUsername(userName);
	}
	getAllAccounts(): AccountInfo[] {
		return this.instance.getAllAccounts();
	}
	handleRedirectObservable(): Observable<AuthenticationResult> {
		return from(this.instance.handleRedirectPromise(this.redirectHash));
	}
	loginPopup(request?: PopupRequest): Observable<AuthenticationResult> {
		return from(this.instance.loginPopup(request));
	}
	loginRedirect(request?: RedirectRequest): Observable<void> {
		return from(this.instance.loginRedirect(request));
	}
	logout(logoutRequest?: EndSessionRequest): Observable<void> {
		return from(this.instance.logout(logoutRequest));
	}
	ssoSilent(request: AuthorizationUrlRequest): Observable<AuthenticationResult> {
		return from(this.instance.ssoSilent(request));
	}

}
