import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class OrderforecastService {
  lookupUrl = '';
  orderForecastUrl = '';
  ccsApiUrl = '';
  ccsApiBasicAuth = '';
  appJson = 'application/json';

  getLookupHeaders() {
    return new HttpHeaders({
      'Content-Type': this.appJson,
      Accept: this.appJson,
      Authorization: localStorage.getItem('Token'),
      'X-Api-Key': this.environment.lookupKey,
    });
  }

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.orderForecastUrl = environment.orderForecastUrl;
    this.ccsApiUrl = environment.ccsApiUrl;
    this.ccsApiBasicAuth = environment.ccsApiBasicAuth;
    this.lookupUrl = environment.lookupUrl;
  }

  getOrderForecasthttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        // 'noloader': 'true',
        Authorization: localStorage.getItem('Token'),
        'X-Api-Key': this.environment.orderForecastKey,
      }),
    };
  }

  getBusinessEntity = () => {
    return localStorage.getItem('namcvalue');
  };

  getOrderForecast(data): Observable<any> {
    return this.http.post<any>(
      // `http://localhost:3000/api/v1/orderforecast/getMaintenance`,
      `${this.orderForecastUrl}/getMaintenance`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  orderForecastCalculation(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/calculation`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getApiHeaders = () => {
    return new HttpHeaders({
      'Content-Type': this.appJson,
      Accept: this.appJson,
      Authorization: this.ccsApiBasicAuth,
    });
  };

  getCountDownQuantityFromCCS(data): Observable<any> {
    return this.http.get<any>(this.ccsApiUrl, {
      params: {
        namcId: this.getBusinessEntity(),
        supplierCode: data.customer_supp,
      },
      headers: this.getApiHeaders(),
    });
  }

  getCCSData(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/ccs`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  updateOrderForecast(data): Observable<any> {
    return this.http.put<any>(
      `${this.orderForecastUrl}/editMaintenance`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getPartNumber(
    business_entity: string,
    user_role: string,
    workid: string
  ): Observable<any> {
    const httpCustomOptions = {
      params: { business_entity, user_role, workid },
      headers: this.getLookupHeaders(),
    };

    return this.http.get<any>(
      this.lookupUrl + '/partnumber',
      httpCustomOptions
    );
  }

  getLifeCycle(
    business_entity,
    user_role: string,
    workid: string
  ): Observable<any> {
    const httpCustomOptions = {
      params: { business_entity, user_role, workid },
      headers: this.getLookupHeaders(),
    };

    return this.http.get<any>(this.lookupUrl + '/lifecycle', httpCustomOptions);
  }

  getLinePath(
    business_entity: string,
    user_role: string,
    workid: string
  ): Observable<any> {
    const httpCustomOptions = {
      params: { business_entity, user_role, workid },
      headers: this.getLookupHeaders(),
    };

    return this.http.get<any>(this.lookupUrl + '/linepath', httpCustomOptions);
  }

  getAdjCode(
    business_entity: string,
    user_role: string,
    workid: string
  ): Observable<any> {
    const httpCustomOptions = {
      params: { business_entity, user_role, workid },
      headers: this.getLookupHeaders(),
    };

    return this.http.get<any>(this.lookupUrl + '/adjcode', httpCustomOptions);
  }

  getSchedule(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/getSchedule`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getJob(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/getJob`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getFile(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/getFile`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getToggle(toggle): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/toggle`,
      toggle,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  releaseJob(data): Observable<any> {
    return this.http.put<any>(
      `${this.orderForecastUrl}/releasejob`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getTemplate(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/download`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }
  getbulkAdjustmentDownloadPath(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/bulkadjustmentdownload`,
      // 'http://localhost:3000/bulkadjustmentdownload',
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getOverageShortage(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/getOverageShortage`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getPartsStatusCount(data): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/getPartsStatusCount`,
      data,
      this.getOrderForecasthttpCustomOptions()
    );
  }

  getFileToggle(toggle): Observable<any> {
    return this.http.post<any>(
      `${this.orderForecastUrl}/filetoggle`,
      toggle,
      this.getOrderForecasthttpCustomOptions()
    );
  }
}
