import { Component, OnInit, Inject, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-master-edit-dialog',
  templateUrl: './master-edit-dialog.component.html',
  styleUrls: ['./master-edit-dialog.component.scss'],
})
export class MasterEditDialogComponent implements OnInit {
  addMasterEventEmitter = new EventEmitter();
  editMasterEventEmitter = new EventEmitter();
  master_id: any;
  master_name: any;
  key_item: any;
  data_item: any;
  business_entity: any;
  new_key_item: any;
  cstatus: any;
  showaddpopup: boolean = false;
  showeditpopup: boolean = false;
  popupname: any;
  masterId: any;
  masterName: any;
  keyItem: any;
  dataItem: any;
  enableError: boolean = false;
  userForm: UntypedFormGroup;

  addOrEditform: UntypedFormGroup = this.formBuilder.group({
    masterId: ['', Validators.required],
    masterName: ['', Validators.required],
    keyItem: ['', Validators.required],
    dataItem: ['', Validators.required],
  });
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly dialogeRef: MatDialogRef<MasterEditDialogComponent>,
    private readonly formBuilder: UntypedFormBuilder
  ) {}
  get addOrEdit() {
    return this.addOrEditform.controls;
  }
  access;
  ngOnInit(): void {
    this.business_entity = localStorage.getItem('namcvalue');
    // masterData: this.masterList,
    this.access = JSON.parse(localStorage.getItem('master'));

    if (this.data?.mode === 'edit') {
      if (this.data?.masterData) {
        this.addOrEditform.controls.masterName.setValue(
          this.data?.masterData[0]
        );
        this.addOrEditform.controls.masterName.disable();
      }
      if (this.data?.masterData) {
        this.addOrEdit.masterId.setValue(this.data?.masterData[0]);
        this.addOrEdit.masterId.disable();
      }
      if (this.data?.mkey) {
        this.addOrEdit.keyItem.setValue(this.data?.mkey);
      }
      if (this.data?.mdata) {
        this.addOrEdit.dataItem.setValue(this.data?.mdata);
      }
    }
  }

  saveUser() {}
  setName() {
    const selectedObject = this.addOrEdit.masterId.value;
    const mastername = this.data?.masterData.filter((x) => {
      return x.master_id === selectedObject?.master_id;
    });
    this.addOrEdit.masterName.setValue(mastername[0]);
  }
  setId() {
    const selectedObject = this.addOrEdit.masterName.value;
    const mastername = this.data?.masterData.filter((x) => {
      return x.name.toLowerCase() === selectedObject?.name.toLowerCase();
    });
    this.addOrEdit.masterId.setValue(mastername[0]);
  }
  submitted: boolean = false;
  addMasterDetails() {
    if (this.addOrEditform.invalid) {
      this.submitted = true;
      return;
    }

    const addEmittedData = {
      masterid: this.addOrEdit.masterId.value.master_id,
      mastername: this.addOrEdit.masterId.value.name,
      keyitem: this.addOrEdit.keyItem.value,
      dataitem: this.addOrEdit.dataItem.value,
    };

    this.addMasterEventEmitter.emit(addEmittedData);
    this.dialogeRef.close();
  }
  editRowMasterDetails() {
    if (this.addOrEditform.invalid) {
      this.submitted = true;
      return;
    }

    const editEmittedData = {
      master_id: this.addOrEdit.masterId.value.master_id,
      master_name: this.addOrEdit.masterName.value.name,
      key_item: this.addOrEdit.keyItem.value,
      data_item: this.addOrEdit.dataItem.value,
    };
    this.editMasterEventEmitter.emit(editEmittedData);
    this.dialogeRef.close();
    
  }

  callRespectiveFunction() {
    if (this.data?.mode === 'edit') {
      this.editRowMasterDetails();
    } else {
      this.addMasterDetails();
    }
  }
}
