import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

import * as moment from 'moment-timezone';

/**
 * Checkbox component inside ag-grid
 *
 * @export
 * @class CheckboxCellComponent
 * @implements {ICellRendererAngularComp}
 */
@Component({
  selector: 'row-checkbox',
  template: `<mat-checkbox
    class="cellcheckbox custom-mat-checkbox"
    [disabled]="disabled"
    [checked]="checked"
    (change)="checkChanged($event)"
  ></mat-checkbox>`,
  styles: [
    `
      ::ng-deep .mat-checkbox .mat-checkbox-frame {
        border: 1px solid #c5c5c5 !important;
      }
      ::ng-deep .mat-checkbox-disabled .mat-checkbox-background {
        background-color: #d3d3d3;
      }
    `,
  ],
})
export class RowCheckboxComponent implements ICellRendererAngularComp {
  //   @ViewChild('.checkbox') checkbox: ElementRef;
  @Input() cell: any;
  public params: any;
  disabled: boolean = false;
  checked: boolean = false;

  agInit(params: any): void {
    this.params = params;
    this.checked = params.data.rowCheckBox;
  //   if (
  //     new Date(this.params.data.prod_date) <=
  //     new Date(
  //       String(moment().tz('US/Michigan').format('MM/DD/YYYY')).substring(0, 10)
  //     )
  //   ) {
  //     this.disabled = true;
  //   }

  //   if (localStorage.getItem('urlLocation') === '/ordering/famaintenance') {
  //     if (
  //       new Date(this.params.data.vanning_date) <=
  //         new Date(localStorage.getItem('vanningFrom')) ||
  //       new Date(this.params.data.hidden_vanning_date) <=
  //         new Date(localStorage.getItem('vanningFrom'))
  //     ) {
  //       this.disabled = true;
  //     }
  //   }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  /*
   *On checked / unchecked
   *
   * @param {*} event
   * @memberof CheckboxCellComponent
   */
  checkChanged(event) {
    this.params.context.componentParent.userCheckChanged(
      event.checked,
      this.params.node.rowIndex
    );
  }

  constructor() {}
}
