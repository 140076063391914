<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button type="button" class="btn-close" (click)="removeMessage()" data-bs-dismiss="alert"
        aria-label="Close"></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <h2 class="heading">Reports</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <div class="drpchildctrl">
          <label required class="searchLable">REPORTED DATE</label>
        </div>
      </div>

      <div class="col-5">
        <input class="form-control-sm" [(ngModel)]="reportdate" (change)="changenotice()" type="date"
          (keydown)="stopTyping()" autoWidth="false" [min]="disableDate" />
      </div>

      <div class="col-6">
        <button type="button" class="btn btn-dark" (click)="onDownload()" [disabled]="downloadingFiles">
          DOWNLOAD
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">

        
        <div id="dvgrid" class="gridContent">
          <ag-grid-angular class="ag-theme-balham" #ospMorningGrid [columnDefs]="columnDefMorningCalc"
            style="width: 100%; height: 73vh" [gridOptions]="morninggridoptions" [rowData]="dailyrowdata"
            [tooltipMouseTrack]="true" [context]="context" (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>

       

      </div>
      <div class="col-md-6">

        <div id="dvgrid" class="gridContent">
          <ag-grid-angular class="ag-theme-balham" #ospExceptionGrid [columnDefs]="columnDefExceptionReport"
            [rowData]="exceptionrowdata" style="width: 100%; height: 20vh" [gridOptions]="exceptiongridoptions"
            [tooltipMouseTrack]="true" [context]="context" (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>
        <div id="dvgrid" class="gridContent">
          <ag-grid-angular class="ag-theme-balham" #ospWeeklyGrid [columnDefs]="columnDefWeekly"
            [rowData]="weeklyrowdata" style="width: 100%; height: 20vh" [gridOptions]="weeklygridoptions"
            [tooltipMouseTrack]="true" [context]="context" (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>

        <div id="dvgrid" class="gridContent">
          <ag-grid-angular class="ag-theme-balham" #ospMonthlyGrid [columnDefs]="columnDefMonthlyReports"
            [rowData]="monthlyrowdata" style="width: 100%; height: 20vh" [gridOptions]="monthlygridoptions"
            [tooltipMouseTrack]="true" [context]="context" (gridReady)="onGridReady($event)">
          </ag-grid-angular>
        </div>



      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>