<div
  class="row header1 movable"
  cdkDrag
  cdkDragHandle
  cdkDragRootElement=".cdk-overlay-pane"
>
  <div class="col">
    <h3 class="head">
      {{ orverageOrShortage === "shortage" ? "SHORTAGE" : "OVERAGE" }}
      <span class="badge">{{ rowData.length }}</span> &nbsp;&nbsp;
      <span>
        <img
        class="img-tool"
        (click)="exportAsXLSX()"
        src="./assets/images/download.svg"
        alt="download icon"
        title="Download"
      />
      </span>
    </h3>
 
  
   
  </div>

  <div class="col-md-1">
    <button
      type="button"
      class="btn btn-dark btn-circle btn-xl"
      mat-dialog-close="cancel"
      data-dismiss="modal"
      aria-label="Close"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
</div>

<div class="col-md-12">
  <ag-grid-angular
    *ngIf="orverageOrShortage == 'shortage'"
    #osPartsGrid
    style="
      width: 100%;
      margin-top: 7px;
      height: 400px !important;
      top: 3%;
      margin-bottom: 3%;
    "
    class="ag-theme-balham table-outer"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    [rowSelection]="rowSelection"
    [gridOptions]="gridOptions"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [context]="context"
    [editType]="editType"
    [suppressClickEdit]="suppressClickEdit"
  >
  </ag-grid-angular>
  <ag-grid-angular
    *ngIf="orverageOrShortage == 'overage'"
    #osPartsGrid
    style="
      width: 100%;
      margin-top: 7px;
      height: 400px !important;
      top: 3%;
      margin-bottom: 3%;
    "
    class="ag-theme-balham table-outer"
    [rowData]="rowData"
    [columnDefs]="columnDefsOverage"
    [rowSelection]="rowSelection"
    [gridOptions]="gridOptions"
    [pagination]="pagination"
    [paginationPageSize]="paginationPageSize"
    [context]="context"
    [editType]="editType"
    [suppressClickEdit]="suppressClickEdit"
  >
  </ag-grid-angular>
</div>
