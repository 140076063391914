<div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
    <!-- <div class="col-sm-11">
      <h3 class="head">CONFIRMATION</h3>
    </div> -->
    <div class="col-sm-11 movable">
      <h3
        class="head"
        cdkDrag
        cdkDragHandle
        cdkDragRootElement=".cdk-overlay-pane"
      >
        <!-- <mat-icon> drag_handle </mat-icon> -->
        CONFIRMATION
      </h3>
    </div>
    <div class="col-sm-1">
      <button
        type="button"
        class="btn btn-dark btn-circle btn-xl"
        mat-dialog-close="cancel"
        data-dismiss="modal"
        aria-label="Close"
      >
        <span aria-hidden="true" >×</span>
      </button>
    </div>
  </div>
  <p class="head">Are you sure to transmit the file to TMC?</p>
  <div class="text-right">
    <button
      class="review_btn"
      mat-button
      mat-dialog-close="save"
    >
      YES
    </button>
    <button class="cancel_btn" mat-button mat-dialog-close="cancel">
      NO
    </button>
  </div>
  