import { Component } from '@angular/core';

@Component({
  selector: 'fa-grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      type="number"
      (keypress)="onKeyPress($event)"
      maxlength="4"
      pattern="[0-9]"
      [(ngModel)]="value"
      [ngClass]="{ 'invalid-text': isEmpty }"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class FAGridTextEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;

  constructor() {}

  agInit(params: any): void {
    if (params.column === 'rev_min_qty') {
      params.data.revMinQtyText = this;
      this.value = params.data.rev_min_qty;
      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    if (params.column === 'rev_max_qty') {
      params.data.revMaxQtyText = this;
      this.value = params.data.rev_max_qty;
      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;
    if (
      this.params.data.checkedEditMode &&
      params.data.cont_code !== 'ALL' &&
      (new Date(this.params.data.vanning_date) >
        new Date(localStorage.getItem('vanningFrom')) ||
        new Date(this.params.data.hidden_vanning_date) >
          new Date(localStorage.getItem('vanningFrom')))
    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'rev_min_qty') {
      this.params.data.rev_min_qty = this.value;

      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    } else if (this.params.column === 'rev_max_qty') {
      this.params.data.rev_max_qty = this.value;

      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }

  onKeyPress(event) {
    // To allow Hyphen symbol
    // ||(event.charCode === 45 && event.target.value === ''))
    if (
      event.charCode >= 48 &&
      event.charCode <= 57 &&
      event.target.value.length <= 6 // <= 6 means user can enter 7 characters
    ) {
      return true;
    } else {
      return false;
    }
  }
}
