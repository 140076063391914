<div class="background1">
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Training Videos</h2>
      </div>
    </div>
  </div>
  <div class="card" style="width: 18rem" *ngIf="trainingVideos">
    <div class="card-header">Application Demo</div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item" *ngIf="trainingVideos.homejobstatus">
        <a
          target="_blank"
          rel="noreferrer noopener"
          [href]="trainingVideos.homejobstatus"
        >
          Sign In to WEB-APP, Home, Jobstatus</a
        >
      </li>
      <li class="list-group-item" *ngIf="trainingVideos.orderforecast">
        <a
          target="_blank"
          rel="noreferrer noopener"
          [href]="trainingVideos.orderforecast"
          >Order Forecast</a
        >
      </li>
      <li class="list-group-item" *ngIf="trainingVideos.adjRunBulk">
        <a
          target="_blank"
          rel="noreferrer noopener"
          [href]="trainingVideos.adjRunBulk"
          >Adjustment Inquiry, Rundown Maintenance, Bulk Adjustment</a
        >
      </li>
      <li class="list-group-item" *ngIf="trainingVideos.reportsFaGetsudo">
        <a
          target="_blank"
          rel="noreferrer noopener"
          [href]="trainingVideos.reportsFaGetsudo"
          >Reports, Fluctuation Allowance Maintenance, Getsudo Status</a
        >
      </li>
      
      <li class="list-group-item" *ngIf="trainingVideos.masterCalendar">
        <a
          target="_blank"
          rel="noreferrer noopener"
          [href]="trainingVideos.masterCalendar"
          >Master Setup, Calendar Setup sri</a
        >
      </li>

      <li class="list-group-item" *ngIf="trainingVideos.UserImprovementReviewPART1">
        <a
          target="_blank"
          rel="noreferrer noopener"
          [href]="trainingVideos.UserImprovementReviewPART1"
          >User Improvement Review - PART1</a
        >
      </li>
      <li class="list-group-item" *ngIf="trainingVideos.UserImprovementReviewPART2">
        <a
          target="_blank"
          rel="noreferrer noopener"
          [href]="trainingVideos.UserImprovementReviewPART2"
          >User Improvement Review - PART2</a
        >
      </li>
      
    </ul>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
