import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { MsalService } from './msal.service';
import { Injectable, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { InteractionType, AuthenticationResult } from '@azure/msal-browser';
import { MsalGuardConfiguration } from './msal.guard.config';
import { MSAL_GUARD_CONFIG } from './constants';
import { concatMap, catchError, map, switchMap } from 'rxjs/operators';
import { Observable, of, from, EMPTY } from 'rxjs';
import { Minimatch } from 'minimatch';
import { MSAL_INTERCEPTOR_CONFIG } from './constants';
import { MsalInterceptorConfig } from './msal.interceptor.config';
import { OSPStateService } from '../services/osp-state.service';
import { Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LookupService } from '../services/lookup/lookup.service';
import { OspService } from '../core/master-services/osp.service';

@Injectable()
export class MsalGuard {
  constructor(
    @Inject(MSAL_GUARD_CONFIG)
    private readonly msalGuardConfig: MsalGuardConfiguration,
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    private readonly msalInterceptorConfig: MsalInterceptorConfig,
    private readonly authService: MsalService,
    private readonly location: Location,
    private readonly state: OSPStateService,
    private readonly router: Router,
    private readonly userService: UsersService,
    private readonly lookupService: LookupService,
    private readonly ngxUiLoaderService: NgxUiLoaderService
  ) {}

  /**
   * Builds the absolute url for the destination page
   * @param path Relative path of requested page
   * @returns Full destination url
   */
  getDestinationUrl(path: string): string {
    // Absolute base url for the application (default to origin if base element not present)
    const baseElements = document.getElementsByTagName('base');
    const baseUrl = this.location.normalize(baseElements.length ? baseElements[0].href : window.location.origin);

    // Path of page (including hash, if using hash routing)
    const pathUrl = this.location.prepareExternalUrl(path);

    // Hash location strategy
    if (pathUrl.startsWith('#')) {
      return `${baseUrl}/${pathUrl}`;
    }

    // If using path location strategy, pathUrl will include the relative portion of the base path (e.g. /base/page).
    // Since baseUrl also includes /base, can just concatentate baseUrl + path
    return `${baseUrl}${path}`;
  }

  private loginInteractively(url: string): Observable<boolean> {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      return this.authService.loginPopup({ ...this.msalGuardConfig.authRequest }).pipe(
        map(() => true),

        catchError(() => of(false))
      );
    }

    const redirectStartPage = this.getDestinationUrl(url);
    this.authService.loginRedirect({
      redirectStartPage,
      scopes: [],
      ...this.msalGuardConfig.authRequest,
    });
    return of(false);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    return this.authService.handleRedirectObservable().pipe(
      concatMap(() => {
        if (!this.authService.getAllAccounts().length) {
          return this.loginInteractively(state.url);
        }
        return this.setUserInfo();
      }),
      catchError(() => {
        this.router.navigate(['./unauthorized']);
        return of(false);
      })
    );
  }

  setUserInfo() {
    const graphurl = 'https://graph.microsoft.com/v1.0/me';
    const scopes = this.getScopesForEndpoint(graphurl);
    const account = this.authService.getAllAccounts()[0];

    return this.authService.acquireTokenSilent({ scopes, account }).pipe(
      catchError(() => {
        if (this.msalInterceptorConfig.interactionType === InteractionType.Popup) {
          return this.authService.acquireTokenPopup({
            ...this.msalInterceptorConfig.authRequest,
            scopes,
          });
        }
        const redirectStartPage = window.location.href;
        this.authService.acquireTokenRedirect({
          ...this.msalInterceptorConfig.authRequest,
          scopes,
          redirectStartPage,
        });
        return EMPTY;
      }),
      switchMap((result: AuthenticationResult) => {
        if (
          'ToyotaGUID' in result.idTokenClaims &&
          typeof result.idTokenClaims['ToyotaGUID'] === 'string' &&
          result.idTokenClaims['ToyotaGUID'].length
        ) {
          console.log("result.idTokenClaims",result.idTokenClaims);
          localStorage.setItem('toyotaGUID', result.idTokenClaims['ToyotaGUID']);
          localStorage.setItem('workdayId', result.idTokenClaims['WorkdayID']);
          localStorage.setItem('UserName', result.idTokenClaims['name']);
          localStorage.setItem('UserRoles', result.idTokenClaims['roles']);
          localStorage.setItem('Token', result.idToken);
          localStorage.setItem('Company', result.idTokenClaims['Company']);
          localStorage.setItem('UPN', result.idTokenClaims['upn']);

          this.state.setGUIDState(result.idTokenClaims['ToyotaGUID']);
          this.state.setNameState(result.idTokenClaims['name']);
          this.state.setRoleState(result.idTokenClaims['roles']);
          this.state.setCompanyState(result.idTokenClaims['Company']);
          return of(true);
        } else {
          this.router.navigate(['./unauthorized']);
          return of(false);
        }
      })
    );
  }

  private getScopesForEndpoint(endpoint: string): Array<string> | null {
    const protectedResourcesArray = Array.from(this.msalInterceptorConfig.protectedResourceMap.keys());
    const keyMatchesEndpointArray = protectedResourcesArray.filter((key) => {
      const minimatch = new Minimatch(key);
      return minimatch.match(endpoint) || endpoint.indexOf(key) > -1;
    });

    // process all protected resources and send the first matched resource
    if (keyMatchesEndpointArray.length > 0) {
      const keyForEndpoint = keyMatchesEndpointArray[0];
      if (keyForEndpoint) {
        return this.msalInterceptorConfig.protectedResourceMap.get(keyForEndpoint);
      }
    }

    return null;
  }
}
