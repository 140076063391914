import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class SealVerificationService {
  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        'X-Api-Key': this.environment.ospSealVerificationKey
      }),
    };
  };

  apiUrl = '';
 

  constructor(private http: HttpClient, private environment: EnvironmentConfiguration) {
    this.apiUrl = environment.ospSealVerificationUrl;
  }

  getSealVerificationData(data):Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/getSealVerificationData`,

      data,
      this.httpCustomOptions()
    );

}

editSealdata(data):Observable<any>{
  return this.http.put<any>(
    `${this.apiUrl}/editSealData`,
          //  `http://localhost:3000/seal`,


    data,
    this.httpCustomOptions()
  );
}
}
