<div class="background" style="height: 100%">
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 messageBackground reset_btn">
        <label class="resetIcon" (click)="goBack()">
          <img
            src="./assets/images/back arrow.png"
            alt="bell icon"
            class="bell"
          />
        </label>

        <label class="message calendarFont" (click)="goBack()"> BACK</label>
      </div>
    </div>
    <h4 class="main-heading">Contact Us</h4>
    <div class="d-flex flex-row">
      <div class="p-2">
        <mat-card class="example-card" style="width: 390px">
          <mat-card-header>
            <mat-card-title>
              <h2 class="heading">TOYOTA HELPDESK</h2></mat-card-title
            >
          </mat-card-header>
          <mat-card-content>
            <div class="d-flex flex-column">
              <div class="p-2">
                <img
                  src="./assets/images/phone.png"
                  alt="Mail icon"
                  style="height: 16px; padding-right: 7px"
                />
                <a href="tel:1-855-349-9898">1-855-349-9898</a>
              </div>
              <div class="p-2">
                <img
                  src="./assets/images/gmail.png"
                  alt="Mail icon"
                  style="height: 16px; padding-right: 7px"
                />
                <a [href]="emailHelpDesk" target="_top">{{ redirect1 }}</a>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="p-2">
        <mat-card class="example-card" style="width: 390px; height: 150px">
          <mat-card-header>
            <mat-card-title>
              <h2 class="heading">
                OS ITx and Business Support
              </h2></mat-card-title
            >
          </mat-card-header>
          <mat-card-content>
            <div class="d-flex flex-column">
              <div class="p-2">
                <img
                  src="./assets/images/gmail.png"
                  alt="Mail icon"
                  style="height: 16px; padding-right: 7px"
                />
                <a class="email" [href]="emailOSP" target="_top">{{
                  redirect
                }}</a>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
