import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RundownService {
  getHttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        authorization: localStorage.getItem('Token'),
        'X-Api-Key': this.environment.rundownKey,
      }),
    };
  }

  rundownApiUrl = '';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.rundownApiUrl = environment.rundownUrl;
  }

  getRundownGrid(data): Observable<any> {
    return this.http.post<any>(
      `${this.rundownApiUrl}/getRundown`,
      data,
      this.getHttpCustomOptions()
    );
  }

  getRundownProdDate(data): Observable<any> {
    return this.http.post<any>(
      `${this.rundownApiUrl}/getRundownProdDate`,
      data,
      this.getHttpCustomOptions()
    );
  }

  //for rundown download template
  getdownloadTemplate(data): Observable<any> {
    return this.http.post<any>(
      `${this.rundownApiUrl}/template`,
      data,
      this.getHttpCustomOptions()
    );
  }

  getCalendar(data): Observable<any> {
    return this.http.post<any>(
      `${this.rundownApiUrl}/getCalendar`,
      data,
      this.getHttpCustomOptions()
    );
  }
  getMaxDate(data): Observable<any> {
    return this.http.post<any>(
      `${this.rundownApiUrl}/getMaxDate`,
      data,
      this.getHttpCustomOptions()
    );
  }
  editRundown(data): Observable<any> {
    return this.http.put<any>(
      `${this.rundownApiUrl}/editRundown`, 
        // `http://localhost:3000/api/v1/rundown/editRundown`,
      data,
      this.getHttpCustomOptions()
    );
  }
  uploadRundown(data): Observable<any> {
    return this.http.put<any>(
      `${this.rundownApiUrl}/uploadRundown`,
      // `http://localhost:3000/api/v1/rundown/uploadRundown`,
      data,
      this.getHttpCustomOptions()
    );
  }
  
  isFinalForecastCompleted(data): Observable<any> {
    return this.http.post<any>(
      // `http://localhost:3000/api/v1/rundown/isFinalForecastCompleted`,
      `${this.rundownApiUrl}/isFinalForecastCompleted`,
      data,
      this.getHttpCustomOptions()
    );
  }
}
