import { Component,Inject,  ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

import { dateFilterComparator } from 'src/app/constants';
import * as moment from 'moment';


@Component({
  selector: 'app-review-container.component',
  templateUrl: './review-container.component.component.html',
  styleUrls: ['./review-container.component.component.scss']
})
export class ReviewContainerComponentComponent {
  rowData: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  defaultColDef;
  rowSelection;
  gridOptions = {} as any;
  //gridOptions!: GridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = true;
  context: any;
  editType;
  suppressClickEdit = true;

  editEnabled: boolean = false;


  editform: UntypedFormGroup = this.formBuilder.group({
    adjCode: [[], Validators.required],
    adjReason: [''],
  });
  editFormcontrol = this.editform.controls;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: UntypedFormBuilder,
  ) {
    this.columnDefs = [
      
        {
          headerName: 'Status',
          field: 'container_status',
          
          width: 150,
        },
        {
          headerName: 'Renban',
          field: 'container_renban',
          width: 150,
         
        },
        {
          headerName: 'Container No',
          field: 'container_number',
          width: 150,
         
        },
       
  
        {
          headerName: 'Estimated Arrival',
          field: 'estimated_namc_arrival',
          width: 150,
          filter: 'agDateColumnFilter',
          valueGetter: (params) => {
            if(params.data.estimated_namc_arrival){  
          return moment(params.data.estimated_namc_arrival).format('MM/DD/YYYY').substring(0, 10);}
          else{
            return params.data.estimated_namc_arrival;
          }
        },
          filterParams: {
            comparator: dateFilterComparator,
          },
          floatingFilter: true,
  
          comparator: this.dateComparator,
  
  
        },
        {
          headerName: 'Actual Arrival DT',
          field: 'actual_namc_arrival',
          width: 140,
          filter: 'agDateColumnFilter',
          valueGetter: (params) => {
              if(params.data.actual_namc_arrival){  
            return moment(params.data.actual_namc_arrival).format('MM/DD/YYYY').substring(0, 10);}
            else{
              return params.data.actual_namc_arrival;
            }
          },
  
          filterParams: {
            comparator: dateFilterComparator,
          },
          floatingFilter: true,
  
          comparator: this.dateComparator,
  
  
        },

          
      


      {
        // headerName: 'Error',
        field: 'error',
        flex: 1,
        headerComponentParams: {
          template:
            '<span class="ag-header-cell-text" style="color:#D20000">Error</span>',
        },
        cellStyle: {
          color: '#D20000',
        },
        cellRenderer: (params) => {
          const html = params.value
            .split('\n')
            .map((line) => `<li style="list-style-type:none;">${line}</li>`)
            .join('\n');
          return `${html}`;
        },
      },
    ];
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';

    this.rowData = data.modifiedRowData;
    this.getGridOptions();
  }

  getGridOptions() {
    this.gridOptions = {} as any;

    this.gridOptions.headerHeight = 38;

    this.gridOptions.rowHeight = 28;
    this.gridOptions.floatingFiltersHeight = 0;

    this.gridOptions.rowBuffer = 20;
    this.gridOptions.suppressMovableColumns = true;
    this.gridOptions.enableCellTextSelection = true;
    this.gridOptions.suppressContextMenu = false;
    this.gridOptions.suppressClipboardPaste = true;

    this.gridOptions.defaultColDef = {
      sortable: true,
      suppressMenu: true,
      filter: false,
      floatingFilter: false,

      wrapText: true,
      autoHeight: true,
      suppressPaste: false,

      floatingFilterComponentParams: { suppressFilterButton: true },
      unSortIcon: true,
      icons: {
        sortUnSort: '<img src="../../../assets/images/Rectangle8.svg">',
      },
      filterParams: {
        textCustomComparator(filter, value, filterText) {
          const filterTextLoweCase = filterText.toLowerCase();

          let valueLowerCase = '';
          if (value.params) {
            valueLowerCase = value.value.toString().toLowerCase();
          } else {
            valueLowerCase = value.toString().toLowerCase();
          }
          function contains(target, lookingFor) {
            if (target === null) {
              return false;
            }
            return target.indexOf(lookingFor) >= 0;
          }
          return contains(valueLowerCase, filterTextLoweCase);
        },

        // Debounce time - Delay before Floating filter search
        debounceMs: 1000,

        caseSensitive: true,
        suppressAndOrCondition: true,
        inRangeInclusive: true,
      },
    };

    return this.gridOptions;
  }

  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }
  enableSave: boolean = false;

  ngOnInit() {
    this.enableSave=true;
    var errorCount = this.rowData.filter((x) => x.error !== '').length;

    this.enableSave = errorCount === 0;
  }


}
