import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { MsalService } from './msal.service';
import { MsalGuard } from './msal.guard';
@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(private _msalGuard: MsalGuard,private router:Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userRoles = localStorage.getItem('UserRoles')?.split(',')
    

    const allowedRoles = next.data["roles"];
    
    
    const matchingRoles = userRoles.filter(x => allowedRoles.includes(x));
    if(!(matchingRoles.length > 0)){
      this.router.navigate(['/dashboard'])
    }
    return matchingRoles.length > 0;
  }
}