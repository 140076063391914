import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      style="width:93px"
      type="date"
      [(ngModel)]="value"
      [max]="disableDate"
      (keydown)="stopTyping()"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    `,
  ],
})
export class GridConatinerActualdtEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  disableDate = '';
  reportdate = new Date();
  constructor() {}
  agInit(params: any): void {
    if (params.column === 'estimated_namc_arrival') {
      params.data.estArrival = this;
      this.value = params.data.estimated_namc_arrival;

      this.disableDate = moment(this.reportdate).add(4, 'month').format('YYYY-MM-DD');
      console.log('disableDate', this.disableDate);

      if (this.value) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }
    if (params.column === 'actual_namc_arrival') {
      params.data.actualArrival = this;
      this.value = params.data.actual_namc_arrival;

      // this.disableDate = '';
      this.disableDate = moment(this.reportdate).format('YYYY-MM-DD');
      console.log('disableDate', this.disableDate);

      if (this.value) {
        this.isEmpty = false;
        this.value = moment(this.value).format('MM/DD/YYYY');
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;

    if (
      this.params.data.checkedEditMode &&
      (this.params.data.container_status == 'NAMC ARRIVED' ||
        this.params.data.container_status == 'IN-TRANSIT' ||
        this.params.data.container_status == 'in-transit')
    ) {
      this.showInput = false;
      this.value = moment(this.value).format('YYYY-MM-DD');
    } else {
      this.showInput = true;
      if (this.value) {
        this.value = moment(this.value).format('MM/DD/YYYY');
      }
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'estimated_namc_arrival') {
      if (this.value !== this.params.data.estimated_namc_arrival) {
        this.params.data.estimated_namc_arrival = this.value;
        this.params.data.isestArrival = true;
      }
    } else {
      this.params.data.finalestdt = this.value;
    }
    if (this.params.column === 'actual_namc_arrival') {
      if (this.value !== this.params.data.actual_namc_arrival) {
        this.params.data.actual_namc_arrival = this.value;
        this.params.data.isActualarrival = true;
      }
    } else {
      this.params.data.actualdt = this.value;
    }
  }
  onKeyPress(event) {
    if (event.target.value.length <= 4) {
      return true;
    } else {
      return false;
    }
  }
  stopTyping() {
    return false;
  }
}