import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import * as XLSXSTYLE from 'xlsx-js-style';
import * as ExcelJS from 'exceljs';

const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  uploadedData: any;
  uploadedFile: any;
  constructor() {}

  private getColumnFromIndex(index: number) {
    return String.fromCharCode(index + 65);
  }

  private getWorkbook(json: any[], headers: string[], excelFileName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {});

    const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
    for (let C = range.s.c; C <= range.e.c; C++) {
      const address = `${XLSX.utils.encode_col(C)}1`; // <-- first row
      if (!worksheet[address]) {
        continue;
      }
      worksheet[address].v = headers[C];
    }
    const wscols: any = [];
    for (const [index, head] of headers.entries()) {
      // columns length added
      let wch = head.length + 7;

      switch (head.toLowerCase()) {
        case 'cc':
          wch = 3;
          break;
        case 'van dt':
          wch = 12;
          break;
        case 'part no':
          wch = 14;
          break;
        case 'kanban':
          wch = 8;
          break;
        case 'qpc':
        case 'spc':
          wch = 8;
          break;
        case 'fa min original':
        case 'fa max original':
        case 'fa min final':
        case 'fa max final':
          wch = head.length;
          break;
        case 'updated dt':
          wch = 20;
          break;
        case 'user':
          wch = 30;
          break;
        case 'comments':
          wch = 140;
          break;
        case 'remarks':
        case 'errors':
        case 'error':
          wch = 50;
          break;
      }

      if (
        !excelFileName.includes('flal') &&
        head.toUpperCase().includes('DT') &&
        !head.toUpperCase().includes('UPDATE')
      ) {
        for (let rowIndex = 0; rowIndex < range.e.r; rowIndex++) {
          // console.log(index + '' + (rowIndex + 2));
          const address = `${XLSX.utils.encode_col(index)}${rowIndex + 2}`;
          // console.log({ address }, worksheet[address].v);
          
          if(!(String(new Date(worksheet[address].v)) === 'Invalid Date'))
          if(worksheet[address].v !== null )
          worksheet[address].t = 'd';
        }
        
      }

      wscols.push({ wch });
    }
    worksheet['!cols'] = wscols;

    // console.log(worksheet);

    const workbook: XLSX.WorkBook = {
      Sheets: { sheet_1: worksheet },
      SheetNames: ['sheet_1'],
    };

    return workbook;
  }
  // This function is for Daownload grid data in all screen
  private getWorkbook1(json: any[], headers: string[], excelFileName: string) {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json, {});

    const range = XLSX.utils.decode_range(worksheet['!ref'] as string);
    for (let C = range.s.c; C <= range.e.c; C++) {
      const address = `${XLSX.utils.encode_col(C)}1`; // <-- first row
      if (!worksheet[address]) {
        continue;
      }
      worksheet[address].v = headers[C];
    }
    const wscols: any = [];
    for (const [index, head] of headers.entries()) {
      // columns length added
      let wch = head.length + 7;

      switch (head.toLowerCase()) {
        case 'cc':
          wch = 3;
          break;
        case 'van dt':
          wch = 12;
          break;
        case 'part no':
          wch = 14;
          break;
        case 'kanban':
          wch = 8;
          break;
        case 'qpc':
        case 'spc':
          wch = 8;
          break;
        case 'fa min original':
        case 'fa max original':
        case 'fa min final':
        case 'fa max final':
          wch = head.length;
          break;
        case 'updated dt':
          wch = 20;
          break;
        case 'user':
          wch = 30;
          break;
        case 'comments':
          wch = 140;
          break;
        case 'remarks':
        case 'errors':
        case 'error':
          wch = 50;
          break;
      }

      if (
        !excelFileName.includes('flal') &&
        head.toUpperCase().includes('DT') &&
        !head.toUpperCase().includes('UPDATE')
      ) {
        for (let rowIndex = 0; rowIndex < range.e.r; rowIndex++) {
          // console.log(index + '' + (rowIndex + 2));
          const address = `${XLSX.utils.encode_col(index)}${rowIndex + 2}`;
          // console.log({ address }, worksheet[address].v);

          if (!(String(new Date(worksheet[address].v)) === 'Invalid Date'))
            if (worksheet[address].v !== null)
              worksheet[address].t = 'd';
        }

      }

      wscols.push({ wch });
    }
    worksheet['!cols'] = wscols;
    const headers1 = worksheet['!cols'];
    for (let i = 0; i < headers1.length; i++) {
      const headerName = XLSX.utils.encode_cell({ r: 0, c: i });
      // Function to check if header name matches the condition
      function isTextColumn(headerName: string): boolean {
        return (headerName === "Part No" || headerName === "PART NO"
          || headerName === "Kanban" || headerName === "KANBAN"
          || headerName === "Order Code");
      }

      if (isTextColumn(worksheet[headerName].v)) {
        console.log("inside convertion logic")
        // Set the column format to text
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let j = range.s.r + 1; j <= range.e.r; j++) { // Start from the second row
          const cellAddress = XLSX.utils.encode_cell({ r: j, c: i });
          if (worksheet[cellAddress]) {
            worksheet[cellAddress].z = '@'; // Set format to text
          }
        }
      }
    }






    // console.log(worksheet);

    const workbook: XLSX.WorkBook = {
      Sheets: { sheet_1: worksheet },
      SheetNames: ['sheet_1'],
    };

    return workbook;
  }

  // This function is only for flal
  // It sets a few fields readonly - so that user cannot edit those
  public downloadFLALExcel(
    json: any[],
    excelFileName: string,
    headers: string[]
  ) {
    const workbook = new ExcelJS.Workbook();
    const workSheet = workbook.addWorksheet('FluctuationAllowance');

    workSheet.addRow(headers);

    for (const row of json) {
      workSheet.addRow(
        Object.values(row).map((v: any) => {
          if (
            !excelFileName.includes('error') &&
            typeof v.v === 'string' &&
            v.v.includes('/')
          ) {
            return new Date(v.v);
          }
          return v.v;
        })
      );
    }

    for (let iCount = 0; iCount < headers.length; iCount++) {
      let width = headers[iCount].length;

      switch (headers[iCount].toLowerCase()) {
        case 'cc':
          width = 3;
          break;
        case 'van dt':
          width = 12;
          break;
        case 'part no':
          width = 14;
          break;
        case 'kanban':
          width = 8;
          break;
        case 'qpc':
          width = 7;
          break;
        case 'comments':
          width = 20;
          break;
        case 'remarks':
          width = 50;
          break;
      }

      workSheet.getColumn(iCount + 1).width = width;
      workSheet.getColumn(iCount + 1).alignment = { wrapText: true };
    }

    // const char = String.fromCharCode(0 + 65);

    // for (let j = 0; j < json.length; j++) {
    //   const cellAddr = char + '' + (j + 2);

    //   workSheet.getCell(cellAddr).type = 4;
    // }

    const func = async () => {
      // FA Min Final till end
      for (let index = 7; index < headers.length; index++) {
        const char = String.fromCharCode(index + 65);

        for (let j = 0; j < json.length; j++) {
          const cellAddr = char + '' + (j + 2);

          workSheet.getCell(cellAddr).protection = {
            locked: false,
          };
        }
      }

      // console.log(workSheet);

      const res = await workSheet.protect('password', {
        selectUnlockedCells: true,
        autoFilter: true,
      });
    };

    if (!excelFileName.includes('error')) func();

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      FileSaver.saveAs(blob, excelFileName);
    });

    return;
  }

  public exportAsExcelFile(
    json: any[],
    excelFileName: string,
    headers: string[]
  ): void {
    const workbook = this.getWorkbook1(json, headers, excelFileName);

    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  public exportExcelFileStyle(
    json: any[],
    excelFileName: string,
    headers: string[]
  ) {
    const workbook = this.getWorkbook(json, headers, excelFileName);
    XLSXSTYLE.writeFile(workbook, excelFileName);
  }

  public getFileBuffer(json: any[], excelFileName: string, headers: string[]) {
    const workbook = this.getWorkbook(json, headers, excelFileName);

    const excelBuffer: any = XLSXSTYLE.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    return excelBuffer;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    FileSaver.saveAs(data, `${fileName}_export${EXCEL_EXTENSION}`);
  }

  getUploadedData() {
    return this.uploadedData;
  }
}