import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
@Component({
  selector: 'app-confirm-cancel-dailog',
  templateUrl: './confirm-cancel-dailog.component.html',
  styleUrls: ['./confirm-cancel-dailog.component.scss'],
})
export class ConfirmCancelDailogComponent implements OnInit {
  // Message to display in the dialog
  message: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit() {
    if (this.data === 'ospipe') {
      this.message = `If you click Yes, The system won't save your changes. Are you sure you want to cancel these changes?`;
    } else {
      this.message = `If you click Yes, you won’t be able to undo the changes you made. Are you sure
			you want to cancel this changes?`;
    }
  }
}
