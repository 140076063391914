import { Component, OnInit, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { logData } from 'src/app/shared/logger';

@Component({
  selector: 'app-master-gridrefresh',
  templateUrl: './master-gridrefresh.component.html',
  styleUrls: ['./master-gridrefresh.component.scss'],
})
export class MasterGridrefreshComponent implements ICellRendererAngularComp {
  constructor() {}

  // tslint:disable-next-line:use-lifecycle-interface
  ngOnInit(): void {}

  // tslint:disable-next-line:member-ordering
  @Input() cell: any;
  // tslint:disable-next-line:member-ordering
  public params: any;
  // tslint:disable-next-line:typedef
  test() {
    alert('Test');
  }
  refreshClicked(): void {
    this.params.api.setFilterModel(null);
    this.params.api.onFilterChanged();
    this.params.api.refreshView();

    const refreshCallback =
      this.params.api.gridOptionsWrapper.gridOptions.context.componentParent;
    // Callback method
    if (
      refreshCallback != null &&
      refreshCallback.onRefresh != null &&
      typeof refreshCallback.onRefresh === 'function'
    ) {
      refreshCallback.onRefresh();
    }

    logData(
      'Refresh Clicked',
      this.params.api.gridOptionsWrapper.gridOptions.context.componentParentName
    );
  }
  // called on init
  agInit(params: any): void {
    this.params = params;
  }
  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
