import { Component, OnInit } from '@angular/core';
import {
  ICellRendererParams,
  IAfterGuiAttachedParams,
} from 'ag-grid-community';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'app-buttonrender',
  template: `
    <span *ngIf="label == 'ERROR'" class="badge badgeerror">{{ label }}</span>
    <span
      *ngIf="label == 'COMPLETE' || label == 'COMPLETED'"
      class="badge complete"
    >
      {{ label }}
    </span>
    <span *ngIf="label == 'RUNNING'" class="badge inprogress">
      {{ label }} </span
    >&nbsp;&nbsp;

    <span
      ><img
        class="margin-imagetag-download"
        *ngIf="downloadenable"
        width="20px"
        height="20px"
        align-self="center"
        (click)="downloadFile('value')"
        src="./assets/images/download.svg"
        alt=""
    /></span>
  `,
  styles: [
    `
      .badge {
        width: 94px;
        font-size: 12px;
        border-radius: 35px;
        font-weight: 600;
      }
      .complete {
        color: #22b785;
        border: 1px solid #22b785;
      }
      .badgeerror {
        color: #ea5558;
        border: 1px solid #ea5558;
      }
      .inprogress {
        color: #e4b50c;
        border: 1px solid #e4b50c;
      }
    `,
  ],
})
export class ButtonrenderComponent implements ICellRendererAngularComp {
  params;
  label: string;
  isDisable: boolean;
  downloadenable: boolean = false;

  agInit(params): void {
    this.params = params;
    this.label = params.data.status;

    if (
      this.params.data.batch_name.toLowerCase() ===
      'Bulk Adjustment Process'.toLocaleLowerCase()
    ) {
      this.downloadenable = true;
    }
  }

  refresh(params?: any): boolean {
    return true;
  }

  onClick($event) {
    if (this.params.onClick instanceof Function) {
      // put anything into params u want pass into parents component
      const params = {
        event: $event,
        rowData: this.params.node.data,
        // ...something
      };
      this.params.onClick(params);
    }
  }
  downloadFile(value) {
    this.params.context.componentParent.bulkAdjustmentDownload(
      this.params.data
    );
  }
}
