<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div class="container-fluid">
    
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">GetSudo Status</h2>
      </div>
      <div class="p-2">
        <button
          type="button"
          class="btn btn-light refresh"
          (click)="refresh()"
        >
          <i class="fa fa-refresh" aria-hidden="true"></i>&nbsp;&nbsp; REFRESH
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <div class="drpchildctrl">
          <label required class="searchLable">GETSUDO DATE</label>
        </div>
      </div>

      <div class="col-2">
        <p-dropdown
          [options]="getSudoDatesList"
          [(ngModel)]="getSudoDate"
          (onChange)="onSearch()"
          optionLabel="date"
          defaultLabel="Select date"
          [filter]="true"
          [virtualScroll]="true"
          [itemSize]="20"
          [showTransitionOptions]="'0ms'"
          [resetFilterOnHide]="true"
          [hideTransitionOptions]="'0ms'"
          autoWidth="false"
          [style]="{ width: '50%' }"
          appendTo="body"
        >
        </p-dropdown>
      </div>

      <div class="col-3"></div>

      <div class="col-6">
        <label class="radio">
          <input
            name="slaStatus"
            value="false"
            [(ngModel)]="slaStatus"
            type="radio"
            (change)="onSLAStatusChange()"
          />
          HIDE SLA CURRENT STATUS
        </label>

        <label class="radio">
          <input
            name="slaStatus"
            value="true"
            [(ngModel)]="slaStatus"
            type="radio"
            (change)="onSLAStatusChange()"
          />
          SHOW SLA CURRENT STATUS
        </label>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div id="dvgrid" class="gridStyle">
      <ag-grid-angular
        #getSudoGrid
        style="width: 100%; top: 1%; margin-bottom: 15%"
        class="ag-theme-balham small-header"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [gridOptions]="gridOptions"
        [context]="context"
        [headerHeight]="headerHeight"
        [getRowHeight]="getRowHeight"
      >
      </ag-grid-angular>
    </div>

    <div class="info">
      <label class="info-label"> SP - Service Parts</label>
      <label class="info-label"> FOD - Forecast On Demand</label>

      <label class="f-right">
        <span class="red-block"></span> SLA Missed
      </label>
      <label class="f-right">
        <span class="green-block"></span>SLA Achieved</label
      >
    </div>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
