import { ColDef, ColGroupDef, GridOptions } from 'ag-grid-community';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { interval, Subscription } from 'rxjs';
import * as mm from 'moment-timezone';

import { GetSudoStatusService } from '../services/get-sudo-status/get-sudo-status.service';
import { GriddataService } from '../services/griddata.service';
import { OSPStateService } from '../services/osp-state.service';
import { logErrors } from '../shared/logger';
import { GetSudoCellRendererComponent } from './get-sudo-cell-renderer/get-sudo-cell-renderer.component';
import { autoRefreshInterval } from '../constants';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-get-sudo-status',
  templateUrl: './get-sudo-status.component.html',
  styleUrls: ['./get-sudo-status.component.scss'],
})
export class GetSudoStatusComponent implements OnInit, OnDestroy {
  namcValue = '01';
  user = '';
  userRole = '';

  warningMessage: string;

  @ViewChild('getSudoGrid') getSudoGrid;

  // AG Grid Properties
  columnDefs = {} as any;
  //columnDefs: (ColDef | ColGroupDef)[];
  gridOptions = {} as any;
  //gridOptions: GridOptions;
  rowData;
  context;
  headerHeight;

  colDefsDynamicFields;
  getSudoDate;
  getSudoDatesList;
  slaStatus: string;
  namcName: string;

  getSudoSubscription$: Subscription;

  agCenterAlignedCell = 'ag-center-aligned-cell';
  agCenterAlignedHeader = 'ag-center-aligned-header';
  getSudoInterval$: Subscription;

  constructor(
    private readonly gridDataService: GriddataService,
    private readonly getSudoService: GetSudoStatusService,
    private readonly spinner: NgxSpinnerService,
    private readonly stateService: OSPStateService
  ) {}

  ngOnDestroy(): void {
    this.getSudoSubscription$.unsubscribe();

    console.log('Stopped Auto Refresh for Get Sudo!');
    this.getSudoInterval$.unsubscribe();
  }

  ngOnInit(): void {
    this.initializeAGGrid();
    this.initializeData();

    this.getSudoSubscription$ = this.stateService.getNamc().subscribe((observer) => {
      this.initializeData();
      this.startAutoRefresh();
    });
  }

  startAutoRefresh(): void {
    if (this.getSudoInterval$) return;

    console.log('Started Auto Refresh for GetSudo Status!');
    this.getSudoInterval$ = interval(autoRefreshInterval)
      .pipe(filter(() => !document.hidden))
      .subscribe(() => {
        this.refresh();
      });
  }

  initializeAGGrid(): void {
    this.columnDefs = [
      {
        headerName: 'GETSUDO DAY MILESTONE',
        field: 'getsudo_day_milestone',
        flex: 1,
        pinned: 'left',
        cellClass: this.agCenterAlignedCell,
        headerClass: this.agCenterAlignedHeader,
        sortable:false
      },
      {
        headerName: 'FROM',
        field: 'from',
        width: 80,
        pinned: 'left',
        cellClass: this.agCenterAlignedCell,
        headerClass: this.agCenterAlignedHeader,
        sortable:false
      },
      {
        headerName: 'VEH TARGET (EST)',
        field: 'vehicle_target',
        width: 100,
        cellClass: 'ag-center-aligned-cell target',
        headerClass: this.agCenterAlignedHeader,
        sortable:false
      },
    ];

    this.headerHeight = 45;
    const gridOptions = this.gridDataService.getGridOptions();
    delete gridOptions.defaultColDef.unSortIcon;
    delete gridOptions.defaultColDef.icons;

    gridOptions.onGridReady = (params) => {
      this.onAPIResponse();
    };

    this.gridOptions = gridOptions;

    this.colDefsDynamicFields = ['TMMK', 'TMMI', 'TMMTX', 'TMMBC', 'TMMMS', 'TMMGT', 'MTM', 'TMMC', 'TMMAL', 'TMMWV'];

    this.slaStatus = 'true';
  }

  initializeData(): void {
    this.removeMessage();
    this.spinner.show();

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    // this.lookupService.getNAMCList().subscribe((res) => {
    //   this.colDefsDynamicFields = res.body.data.map(
    //     (row) => row.business_entity_name
    //   );

    //   this.onAPIResponse();
    // });

    this.loadGetSudoDates();
  }

  errorCallback = (error) => {
    logErrors(error);

    this.warningMessage = 'Something went wrong!';
    window.scroll(0, 0);

    this.spinner.hide();
  };

  loadGetSudoDates(): void {
    const data = {
      event: 'Getsudo',
      workid: this.user,
      user_role: this.userRole,
    };

    this.getSudoService.fetchGetSudoDates(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        const data = res.body.calenderResponse.map((row) => {
          const rowDate = row.start_date.split('T')[0].split('-');

          const date = `${rowDate[1]}/${rowDate[2]}/${rowDate[0]}`;
          return { date };
        });

        this.getSudoDatesList = data;

        const [eMonth, eYear] = String(mm().tz('US/Michigan').format('MM/YYYY')).split('/');

        const filteredDate = data.filter((row) => {
          const [month, _, year] = row.date.split('/');

          return month === eMonth && year === eYear;
        })[0];

        this.getSudoDate = filteredDate ?? data[data.length - 1];

        this.onSearch();
      },
    });
  }

  onSearch() {
    this.spinner.show();

    const data = {
      getsudo_date: this.getSudoDate?.date,
      workid: this.user,
      user_role: this.userRole,
    };

    this.getSudoService.getStatus(data).subscribe({
      error: this.errorCallback,
      next: (response) => {
        this.rowData = response.body.data.getSudoStatus;

        this.getSudoGrid.gridOptions.api.setRowData(this.rowData);

        this.spinner.hide();
      },
    });
  }

  getRowHeight(params) {
    return 40;
  }

  getDynamicColDef(columnField, withStatus): ColGroupDef {
    const actualField = {
      headerName: 'ACTUAL',
      field: `${columnField}_ACTUAL`,
      width: 100,
      cellClass: this.agCenterAlignedCell,
      headerClass: this.agCenterAlignedHeader,
      cellRendererFramework: GetSudoCellRendererComponent,
      sortable: false,
    };

    const statusField = {
      headerName: 'STATUS',
      field: `${columnField}_STATUS`,
      width: 100,
      cellClass: this.agCenterAlignedCell,
      headerClass: this.agCenterAlignedHeader,
      cellRendererFramework: GetSudoCellRendererComponent,
      sortable: false,
    };

    const children = [actualField];
    // if (withStatus === 'true')
    children.push(statusField);

    return {
      headerName: `${columnField}`,
      headerClass: this.agCenterAlignedHeader,
      children,
    };
  }

  onDateChange(): void {}

  onSLAStatusChange(): void {
    this.getSudoGrid.gridOptions.columnApi.setColumnsVisible(
      this.colDefsDynamicFields.map((c) => c + '_STATUS'),
      this.slaStatus === 'true' ? true : false
    );
  }

  onAPIResponse(): void {
    const colDefs: any[] = [...this.columnDefs.slice(0, 3)];

    colDefs.push(...this.colDefsDynamicFields.map((c) => this.getDynamicColDef(c, this.slaStatus)));

    const index = colDefs.findIndex((c) => c.headerName === 'TMMAL');

    if (index > -1) {
      colDefs.splice(index, 0, {
        headerName: 'UNIT TARGET (EST)',
        field: 'unit_target',
        width: 100,
        cellClass: 'ag-center-aligned-cell target',
        headerClass: this.agCenterAlignedHeader,
        sortable:false
      });
    }

    this.getSudoGrid.gridOptions.api.setColumnDefs(colDefs);
  }

  removeMessage() {
    this.warningMessage = '';
  }

  refresh() {
    this.stateService.setNamc({
      namc: localStorage.getItem('namcSelected'),
      WorkdayID: '',
      Name: '',
      Role: '',
    });

    this.onSLAStatusChange();
    this.initializeData();
  }
}
