import { Component, OnInit,Input } from '@angular/core';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  @Input() columnDefs: any;
	@Input() rowData: any;
	@Input() rowSelection: any;
	@Input() gridOptions: any;
	@Input() pagination: any;
	@Input() paginationPageSize: any;
	@Input() context: any;
	@Input() editType: any;
	@Input() suppressClickEdit: boolean=false;
	@Input() paginationNumberFormatter;
	@Input() frameworkComponents: any;
	@Input() tooltipShowDelay: any;
	@Input() tooltipMouseTrack: boolean=false;
	@Input() enableFilter: boolean=true;

	constructor() {

	}

	ngOnInit(): void {
	}

	public onGridReady(params) {
	}
}

