import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class GetSudoStatusService {
  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        'X-Api-Key': this.environment.getsudoKey,
      }),
    };
  };

  apiUrl = '';
  getsudoDatesUrl = '';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.apiUrl = environment.getsudoUrl;
    this.getsudoDatesUrl = environment.getsudoDatesUrl;
  }

  getStatus(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/status`,
      data,
      this.httpCustomOptions()
    );
  }

  fetchGetSudoDates(data): Observable<any> {
    const headers = {
      headers: {
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        'X-Api-Key': this.environment.setupKey,
      },
    };

    return this.http.post<any>(this.getsudoDatesUrl, data, headers);
  }
}
