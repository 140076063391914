export const environment = {
  production: true,
  hostname: 'osparts.toyota.com',
  REDIRECT_URL: 'https://osparts.toyota.com/',

  CLIENT_ID: 'cfbff0df-3131-4017-a0e6-bb94de0626f5',
  TENANT_ID:
    'https://login.microsoftonline.com/8c642d1d-d709-47b0-ab10-080af10798fb',

  ccsApiUrl: 'https://alb.ccsdev.toyota.com/os-parts/',
  ccsApiBasicAuth: 'Basic ' + btoa('CCS:78fa095d-3f4c-48b1-ad50-e24c31d5cf35'),
  
  fluctuationAllowanceKey: 'NxrdoCyDP07wPrajz7onJ8xfJPfVP4K58MthCQ9G',
  fluctuationAllowanceUrl:
    'https://as1t6zet1k.execute-api.us-east-1.amazonaws.com/prod/api/v1/fluctuationallowance',

  bulkAdjustmentKey: '2VJ4hNr3ru6Y5UT3p5ikg2ykfmt1PrBK39WeCy1i',
  bulkAdjustmentUrl:
    'https://x723v0fngc.execute-api.us-east-1.amazonaws.com/prod/api/v1/bulkadjustment',

  rundownKey: 'JDLHDEq3Fs9UMBbcO3THtaUmihv7iCR4L4FgyZhe',
  rundownUrl:
    'https://w5gfe0pd66.execute-api.us-east-1.amazonaws.com/prod/api/v1/rundown',

  orderForecastKey: 't4iY0Cr8mh6QpIzZpbng978gHVyH8RCg1iw1sn0k',
  orderForecastUrl:
    'https://cy1frhob26.execute-api.us-east-1.amazonaws.com/prod/api/v1/orderforecast',

  lookupKey: 'bC5vB3fdqi58ApuWVCibM2MdE6yt8n0F5NEa1j2Q',
  lookupUrl:
    'https://mwqxr4xng9.execute-api.us-east-1.amazonaws.com/prod/api/v1/lookup',

  getsudoKey: 'oqqEae0Qbd2c0O4ZMKOM52C6QlMhopEz2VbEdbAJ',
  getsudoUrl:
    'https://2p4bb85l21.execute-api.us-east-1.amazonaws.com/prod/api/v1/getsudo',

  // This is in Setup service lambda with different url
  getsudoDatesUrl:
    'https://61bmsdk4g6.execute-api.us-east-1.amazonaws.com/prod/api/v1/getsudo',

  setupKey: 'bC5vB3fdqi58ApuWVCibM2MdE6yt8n0F5NEa1j2Q',
  setupUrl:
    'https://61bmsdk4g6.execute-api.us-east-1.amazonaws.com/prod/api/v1/setup',

  adjustmentEnquiryKey: 'kljRvCrjqwFX1YSZtQYc9T1TVDVMNU3FjSkK9ei0',
  adjustmentEnquiryUrl:
    'https://oixrqdhn1b.execute-api.us-east-1.amazonaws.com/prod/api/v1/adjustment',


  //ospipe roles

  partMaintenance:'https://pp45141stj.execute-api.us-east-1.amazonaws.com/prod/api/v1/partMaintenance',
  partMaintenanceKey:'kNjtwO6Vg53XZdKXa75Ls11Fgl6NaHzA997PjORD',

  partStatusUrl:'https://yx7kwf4kkb.execute-api.us-east-1.amazonaws.com/prod/api/v1/getpartstatus',
  partStatusKey:'1gtH4aYNwu47prWqcKwH1nvrJCrCCi14FyrhAHJ5',

  ospLookupUrl:'https://296hr1723i.execute-api.us-east-1.amazonaws.com/prod/api/v1/lookup',
  ospLookupKey:'lH9JX3coAh1IW24ueVYbU2XsINrgTgST2pNnUfXT',

  ospSealVerificationUrl:' https://e33aq4e88c.execute-api.us-east-1.amazonaws.com/prod/api/v1/sealVerification',
  ospSealVerificationKey:'XocQY1vXyv8aEhU3jgOAN9HC1GyddUI08fJ0uCAw',

      //PIE lambda urls
  
      sendToPieData: `https://api-int.pie.toyota.com/tnso/update-container-info`


};
