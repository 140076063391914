<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Parts Inquiry</h2>
      </div>
      <div class="p-2 kanbanp2">
        <input
          type="text"
          pTooltip="Allowed formats: X, X-Y, X-"
          [(ngModel)]="renbanValue"
          class="form-control-sm kanbanform"
          placeholder="RENBAN(s) - Press Enter to Search"
          (keyup.enter)="renbanSearch($event)"
          (keypress)="omit_special_char($event)"
          maxlength="11"
          tooltipEvent="focus"
        />
      </div>
      <!-- <div class="p-2 kanbanp2">
        <input
          type="text"
          [(ngModel)]="renbanSearchValue"
          class="form-control-sm kanbanform"
          placeholder="RENBAN - Press Enter to Search"
          (keyup.enter)="renbanSearch($event)"
        />
      </div> -->
      <div class="p-2 kanbanp2">
        <input
          type="text"
          [(ngModel)]="kanbanSearchValue"
          class="form-control-sm kanbanform"
          placeholder="KANBAN - Press Enter to Search"
          (keyup.enter)="handleKanbanSearch($event)"
        />
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">PART NO</label>
                <p-multiSelect
                  [(ngModel)]="selectedPartNo"
                  [showToggleAll]="false"
                  (onChange)="onPartNoChange($event)"
                  [options]="partNoList"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  [virtualScroll]="true"
                  [itemSize]="25"
                  autoWidth="false"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  optionLabel="part_number"
                  [panelStyle]="{ width: '140%' }"
                  [disabled]="disablePartNumber"
                  optionDisabled="disabled"
                  selected="false"

                >
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">KANBAN </label>
                <p-multiSelect
                  [(ngModel)]="selectedKanban"
                  [showToggleAll]="false"
                  (onChange)="onKanbanChange()"
                  [options]="kanbanList"
                  optionLabel="kanban"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                  [disabled]="disableKanban"
                >
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable" title="Container Code">CC</label>
                <p-multiSelect
                  [(ngModel)]="selectedContainer"
                  [showToggleAll]="false"
                  (onChange)="onContainerChange()"
                  [options]="containerList"
                  optionLabel="cc"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">RENBAN</label>
                <p-multiSelect
                  [(ngModel)]="selectedrenban"
                  [showToggleAll]="false"
                  (onChange)="onRenbanChange()"
                  [options]="renbanList"
                  optionLabel="container_renban"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">MODULE CODE</label>
                <p-multiSelect
                  [(ngModel)]="seletedModuleCode"
                  [showToggleAll]="false"
                  (onChange)="onModuleCodeChange()"
                  [options]="moduleCodeList"
                  optionLabel="modulecode"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">MODULE</label>
                <p-multiSelect
                  [(ngModel)]="seletedModule"
                  [showToggleAll]="false"
                  (onChange)="onModuleChange()"
                  [options]="moduleList"
                  optionLabel="module_number"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-1 col-md-4 col-sm-6">
              <div class="drpchildctrl">
                <label class="searchLable">STATUS</label>
                <p-multiSelect
                  [(ngModel)]="seletedStatus"
                  [showToggleAll]="false"
                  (onChange)="onStatusChange()"
                  [options]="statusList"
                  optionLabel="container_status"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-4 col-sm-6">
              <div class="drpchildctrl">
                <label class="searchLable">VAN COMP DT</label>
                <input [(ngModel)]="vanningDT" class="form-control-sm" type="date" 
                autoWidth="false" (change)="onVanDtChange()" />
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon" (click)="onResetDropDown()">
                <img
                  src="./assets/images/Group 115380.png"
                  alt="refresh icon"
                />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()"
                >Reset</label
              >
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch(0)">SEARCH</button>
            </div>
            <div  class="col-xl-2 col-md-2 col-sm-3 tap">
              <label class="resetlabel1" >
                <p style="color:blue" (click)="inventoryReport()">On-Demand Inventory Report</p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-8">
        <span class="edit_icon">
          <img
            class="img-tool"
            (click)="exportAsXLSX()"
            src="./assets/images/download.svg"
            alt="download icon"
            title="Download"
          />
        </span>
      </div>

      <div class="col-sm-2">
        <div class="records">
          <div class="drpchildctrl">
            <p-multiSelect
              #dd
              class="page-drpdwn-label showhide"
              [(ngModel)]="selectedShowHideList"
              (onChange)="onShowHideChange($event)"
              [options]="showHideList"
              optionLabel="label"
              [displaySelectedLabel]="false"
              [defaultLabel]="'SHOW/HIDE COLUMN'"
              [showTransitionOptions]="'0ms'"
              [hideTransitionOptions]="'0ms'"
              autoWidth="true"
              [style]="{ width: '100%', background: '#F5F5F5' }"
              [resetFilterOnHide]="true"
            >
              <p-footer>
                <div class="flex justify-content-between p-1">
                  <button
                    type="button"
                    (click)="saveShowHide(); dd.hide()"
                    class="save_btn"
                  >
                    Save
                  </button>
                </div>
              </p-footer>
            </p-multiSelect>
          </div>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          <select
            class="page-drpdwn"
            (change)="onPageSizeChanged()"
            [(ngModel)]="paginationPageSize"
          >
            <option value="10" selected>10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{ rowData.length }}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div id="dvgrid" class="gridStyle">
    <app-grid
      class="table-outer"
      #osPartsGrid
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [rowSelection]="rowSelection"
      [gridOptions]="gridOptions"
      [pagination]="pagination"
      [paginationPageSize]="paginationPageSize"
      [context]="context"
      [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay"
      [tooltipMouseTrack]="true"
      [editType]="editType"
    >
    </app-grid>
  </div>
  <!-- <div id="dvgrid" class="gridStyle">
    <ag-grid-angular
      #osPartsGrid
      style="width: 100%; top: 1%; margin-bottom: 5%"
      class="ag-theme-balham"
      [columnDefs]="columnDefs"
      [rowData]="rowData"
      [rowSelection]="rowSelection"
      [gridOptions]="gridOptions"
      [pagination]="true"
      [paginationPageSize]="paginationPageSize"
      [context]="context"
      [suppressClickEdit]="suppressClickEdit"
      [frameworkComponents]="frameworkComponents"
      [tooltipShowDelay]="tooltipShowDelay"
      [tooltipMouseTrack]="true"
      [editType]="editType"
      [groupRowsSticky]="groupRowsSticky"
      [autoGroupColumnDef]="autoGroupColumnDef"
      [groupDefaultExpanded]="groupDefaultExpanded"
      [animateRows]="true"
      [editType]="editType"
      [showOpenedGroup]="showOpenedGroup"
      [groupSelectsChildren]="groupSelectsChildren"
      [suppressRowClickSelection]="suppressRowClickSelection"
      [suppressAggFuncInHeader]="suppressAggFuncInHeader"
      (rowSelected)="onRowSelected($event)"
   
    >
    </ag-grid-angular>
  </div> -->
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
