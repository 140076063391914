<div class="background1">
  <div class="container-fluid">
    <div class="d-flex flex-row">
      <div class="p-2 flex-grow-1">
        <h2 class="heading">Release Notes</h2>
      </div>
    </div>
  </div>
  <div class="card" style="width: 18rem">
    <div class="card-header">Release Notes</div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item" *ngFor="let releasenote of fileNames">
        <a  (click)="downloadFile(releasenote.path)">
          {{ releasenote.fileName }}</a
        >
      </li>
    </ul>
  </div>
</div>

<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
