import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class BulkadjustmentService {
  bulkadjustmentUrl;

  getHeaders() {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: localStorage.getItem('Token'),
      'X-Api-Key': this.environment.bulkAdjustmentKey,
    });
  }

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.bulkadjustmentUrl = this.environment.bulkAdjustmentUrl;
  }

  getValidationData(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };
    return this.http.post<any>(
      `${this.bulkadjustmentUrl}/getValidationData`,
      data,
      httpCustomOptions
    );
  }
  getExcelFileUrl(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };
    return this.http.post<any>(
      `${this.bulkadjustmentUrl}/getExcelFileUrl`,
      data,
      httpCustomOptions
    );
  }
  putDatainExcel(file, url): Observable<any> {
    return this.http.put(url, file);
  }
  calculation(data): Observable<any> {
    const httpCustomOptions = {
      headers: this.getHeaders(),
    };
    return this.http.post<any>(
      `${this.bulkadjustmentUrl}/calculation`,
      data,
      httpCustomOptions
    );
  }
}
