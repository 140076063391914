import { Component, OnInit } from '@angular/core';

import { PipeLookupService } from '../services/pipe-lookup/pipe-lookup.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-pipe-relasenotes',
  templateUrl: './pipe-relasenotes.component.html',
  styleUrls: ['./pipe-relasenotes.component.scss']
})
export class PipeRelasenotesComponent implements OnInit{

  namcValue = localStorage.getItem('namcvalue');
  user: string;
  userRole: string;
  trainingVideos = [];
  fileNames = [];
  s3FilePath: any;
  releaseNotestList: any;

  constructor(private readonly lookupService: PipeLookupService, private readonly spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.spinner.show();
    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      s3folderName: 'Release_Notes',
      page_name:"releasenotes"
    };

    this.lookupService.getReleaseNotes(data).subscribe({
      next: (res) => {
        console.log('res---', res.body.getReleaseNotes);
        this.releaseNotestList = res.body.getReleaseNotes.filenames ? res.body.getReleaseNotes.filenames : [];
        this.releaseNotestList=this.releaseNotestList.slice(1)

        

       
        
        

        // releaseNotestList.forEach((element) => {
        //   var path = element;

        //   var filesName = path.split('/');
        //   console.log('filesName', filesName, '-----', filesName[1]);

        //   console.log('path--', path);

        //   if (filesName[1]) {
        //     this.fileNames.push({
        //       path: path,
        //       fileName: filesName[1],
        //     });
        //   }
        // });

        console.log()

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  downloadFile(path) {
    console.log(path);
    
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      fileNames: [`Ospipe_Release_Notes/`+path],
    };

    this.lookupService.downloadReleaseNotes(data).subscribe({
      next: (success) => {
        this.s3FilePath = success.body.fileurls[0];

        if (success) {

          saveAs(this.s3FilePath);

          
          this.spinner.hide();
        } else {
          this.spinner.hide();
          // this.warningMessage = warningMessage.fileNotAvilable;

          window.scroll(0, 0);
        }
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
}



