import { Component, ViewChild, Inject } from '@angular/core';

import { CustomTooltipComponent } from 'src/app/core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from 'src/app/services/griddata.service';

import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import * as mm from 'moment-timezone';
import { ExcelService } from 'src/app/services/excel.service';
import { dateFilterComparator, } from 'src/app/constants';

@Component({
  selector: 'app-partbystatus-dateview',
  templateUrl: './partbystatus-dateview.component.html',
  styleUrls: ['./partbystatus-dateview.component.scss'],
})
export class PartbystatusDateviewComponent {
  namcValue = '';
  user = '';
  userRole = '';
  rowGroupingDisplayType = 'custom';
  groupDefaultExpanded = 10;
  rowData: any = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  inTransitcolumnDefs;
  NamcArrivedcolumnDefs;
  vanningCompletedColdef;
  devannedColdef;

  public defaultColDef;
  rowSelection;
  headerName = '';

  groupDisplayType = 'groupRows';
  autoGroupColumnDef;
  getDataPath;

  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  selectedPopupName;
  frameworkComponents = { customTooltip: CustomTooltipComponent };

  // Dropdowns

  successMessage = '';
  warningMessage = '';

  kanbanListSelected = [];
  // Dropdown Options

  icons: { groupExpanded: string; groupContracted: string };

  headerHeight: number;
  loading: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly gridDataService: GriddataService,
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService
  ) {
    this.selectedPopupName = data.partStatus;
    console.log(this.selectedPopupName);

    if (this.selectedPopupName === 'NAMC ARRIVED') {
      this.headerName = ' NAMC ARRIVED';
    } else if (this.selectedPopupName === 'IN-TRANSIT') {
      this.headerName = ' IN-TRANSIT';
    } else if (this.selectedPopupName === 'VANNING COMPLETE') {
      this.headerName = 'VANNING COMPLETE';
    } else if (this.selectedPopupName === 'DEVANNED') {
      this.headerName = 'DEVANNED';
    }




    this.inTransitcolumnDefs = [
      {
        headerName: 'Renban',
        headerTooltip:'Renban',
        field: 'container_renban',
        sortable: true,
        width: 80,
        floatingFilter: true,
      },


      {
        headerName: 'Part No',
        headerTooltip:'Part Number',
        field: 'part_number',
        tooltipField: 'part_number',
        sortable: true,
        width: 150,
        floatingFilter: true,
      },
      {
        headerName: 'Kanban',
        headerTooltip:'Kanban',
        field: 'kanban',
        sortable: true,
        width: 90,
        floatingFilter: true,
      },
      {
        headerName: 'Supplier',
        field: 'supplier_code',
        headerTooltip:'Supplier Code',
        width: 100,
        //cellClass: this.agRightAlignedCell,
        sortable: true,
        floatingFilter: true,
        //filter: 'agNumberColumnFilter',
        //floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'CC',
        field: 'cc',
        headerTooltip: 'Container Code',

        width: 80,
        floatingFilter: true,
      },

      {
        headerName: 'Status',
        headerTooltip:'Status',
        field: 'status',
        width: 140,
        floatingFilter: true,
        tooltipField: 'status',
      },
      {
        headerName: 'Quantity',
        headerTooltip:'Quantity',
        field: 'quantity',
        width: 110,
        floatingFilter: true,



        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        valueGetter: (params) => parseInt(params.data.quantity),

        sortable: true,
        comparator: this.numberComparator,
      },
      {
        headerName: 'Mode Of Freight',
        headerTooltip:'Mode Of Freight',
        field: 'freight_mode',

        width: 150,
        //cellClass: this.agRightAlignedCell,

        sortable: true,
        floatingFilter: true,

        //filter: 'agNumberColumnFilter',
        //floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'Est Arr DT',
        headerTooltip: 'Estimated Arrival Date(mm/dd/yyyy)',
        field: 'estimated_namc_arrival',
        width: 120,
        //cellClass: this.agRightAlignedCell,

        sortable: true,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
       
        comparator: this.dateComparator,
      },

    ];


    //NamcArrived columndefs

    this.NamcArrivedcolumnDefs = [
      {
        headerName: 'Renban',
        headerTooltip:'Renban',
        field: 'container_renban',
        sortable: true,
        width: 80,
        floatingFilter: true,
      },


      {
        headerName: 'Part No',
        headerTooltip:'Part Number',
        field: 'part_number',
        tooltipField: 'part_number',
        sortable: true,
        width: 130,
        floatingFilter: true,
      },
      {
        headerName: 'Kanban',
        headerTooltip:'Kanban',
        field: 'kanban',
        sortable: true,
        width: 90,
        floatingFilter: true,
      },
      {
        headerName: 'Supplier',
        field: 'supplier_code',
        headerTooltip:'Supplier Code',
        width: 100,
        //cellClass: this.agRightAlignedCell,
        sortable: true,
        floatingFilter: true,
        //filter: 'agNumberColumnFilter',
        //floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'CC',
        field: 'cc',
        headerTooltip: 'Container Code',

        width: 80,
        floatingFilter: true,
      },

      {
        headerName: 'Status',
        headerTooltip:'Status',
        field: 'status',
        width: 130,
        tooltipField: 'status',

        floatingFilter: true,

        // tooltipField: 'Status',
      },
      {
        headerName: 'Quantity',
        headerTooltip:'Quantity',
        field: 'quantity',
        width: 110,
        floatingFilter: true,



        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        valueGetter: (params) => parseInt(params.data.quantity),

        sortable: true,
        comparator: this.numberComparator,
      },
      {
        headerName: 'Mode Of Freight',
        headerTooltip:'Mode Of Freight',
        field: 'freight_mode',

        width: 150,
        //cellClass: this.agRightAlignedCell,

        sortable: true,
        floatingFilter: true,

        //filter: 'agNumberColumnFilter',
        //floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'Act Arr DT',
        headerTooltip: 'Actual Arrival Date(mm/dd/yyyy)',
        field: 'actual_namc_arrival_date',
        width: 130,
        // 
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },

    ];
    // vanning completed

    this.vanningCompletedColdef = [
      {
        headerName: 'Renban',
        headerTooltip:'Renban',
        field: 'container_renban',
        sortable: true,
        width: 80,
        floatingFilter: true,
      },


      {
        headerName: 'Part No',
        headerTooltip:'Part Number',
        field: 'part_number',
        tooltipField: 'part_number',
        sortable: true,
        width: 130,
        floatingFilter: true,
      },
      {
        headerName: 'Kanban',
        headerTooltip:'Kanban',
        field: 'kanban',
        sortable: true,
        width: 90,
        floatingFilter: true,
      },
      {
        headerName: 'Supplier',
        field: 'supplier_code',
        headerTooltip:'Supplier Code',
        width: 100,
        //cellClass: this.agRightAlignedCell,
        sortable: true,
        floatingFilter: true,
        //filter: 'agNumberColumnFilter',
        //floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'CC',
        field: 'cc',
        headerTooltip: 'Container Code',

        width: 80,
        floatingFilter: true,
      },

      {
        headerName: 'Status',
        headerTooltip:'Status',
        field: 'status',
        tooltipField: 'status',
        width: 130,
        floatingFilter: true,

        // tooltipField: 'Status',
      },
      {
        headerName: 'Quantity',
        headerTooltip:'Quantity',
        field: 'quantity',
        width: 110,
        floatingFilter: true,



        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        valueGetter: (params) => parseInt(params.data.quantity),

        sortable: true,
        comparator: this.numberComparator,
      },
      {
        headerName: 'Mode Of Freight',
        headerTooltip:'Mode Of Freight',
        field: 'freight_mode',

        width: 150,
        //cellClass: this.agRightAlignedCell,

        sortable: true,
        floatingFilter: true,

        //filter: 'agNumberColumnFilter',
        //floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'Van Comp DT',
        field: 'tmc_van_completion_date',
        headerTooltip: 'Vanning  Completion Date(mm/dd/yyyy)',
        width: 130,
        //cellClass: this.agRightAlignedCell,

        sortable: true,


        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,

        //floatingFilterComponentParams: { suppressFilterButton: false },
      },

    ];

    //devanned part

    this.devannedColdef = [
      {
        headerName: 'Renban',
        headerTooltip:'Renban',
        field: 'container_renban',
        sortable: true,
        width: 80,
        floatingFilter: true,
      },


      {
        headerName: 'Part No',
        headerTooltip:'Part Number',
        field: 'part_number',
        tooltipField: 'part_number',
        sortable: true,
        width: 130,
        floatingFilter: true,
      },
      {
        headerName: 'Kanban',
        headerTooltip:'Kanban',
        field: 'kanban',
        sortable: true,
        width: 90,
        floatingFilter: true,
      },
      {
        headerName: 'Supplier',
        field: 'supplier_code',
        headerTooltip:'Supplier Code',
        width: 100,
        //cellClass: this.agRightAlignedCell,
        sortable: true,
        floatingFilter: true,
        //filter: 'agNumberColumnFilter',
        //floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'CC',
        field: 'cc',
        headerTooltip: 'Container Code',

        width: 80,
        floatingFilter: true,
      },

      {
        headerName: 'Status',
        headerTooltip:'Status',
        field: 'status',
        width: 130,
        tooltipField: 'status',
        floatingFilter: true,
        // tooltipField: 'Status',
      },
      {
        headerName: 'Quantity',
        headerTooltip:'Quantity',
        field: 'quantity',
        width: 110,
        floatingFilter: true,



        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        valueGetter: (params) => parseInt(params.data.quantity),

        sortable: true,
        comparator: this.numberComparator,
      },
      {
        headerName: 'Mode Of Freight',
        headerTooltip:'Mode Of Freight',
        field: 'freight_mode',

        width: 150,
        //cellClass: this.agRightAlignedCell,

        sortable: true,
        floatingFilter: true,

        //filter: 'agNumberColumnFilter',
        //floatingFilterComponentParams: { suppressFilterButton: false },
      },
      {
        headerName: 'Devan Date',
        headerTooltip:'Devan Date',
        field: 'devan_date',
        width: 130,
        //cellClass: this.agRightAlignedCell,

        sortable: true,
        floatingFilter: true,
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },

        comparator: this.dateComparator,
      },

    ];

    this.rowData = data.selectedPopupData;
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };


    this.headerHeight = 45;

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
  }

  //download popup grid

  exportAsXLSX(): void {

    this.spinner.show();
    // eastern time api
    let easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    console.log(easternCurrentDate);
    let data = [];

    // if (this.headerCheckboxChecked) {
    this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
      data.push(node.data);
    });

    if (data.length < 1) {
      this.spinner.hide();
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj: any = {
              container_renban:e.container_renban,
              PartNO: e.part_number,
              kanban: e.kanban,
              supplier: e.supplier_code,
              cc: e.cc,
              status: e.status,
              quantity: e.quantity,
              modeoffreight: e.freight_mode
            };
            if (this.selectedPopupName === 'NAMC ARRIVED') {
              obj.actualarrivaldate = e.actual_namc_arrival_date;
            } else if (this.selectedPopupName === 'IN-TRANSIT') {
              obj.estimatedarrivaldate = e.estimated_namc_arrival;
            } else if (this.selectedPopupName === 'VANNING COMPLETE') {
              obj.vanningCompleted = e.tmc_van_completion_date;
            } else if (this.selectedPopupName === 'DEVANNED') {
              obj.devannedPart = e.devan_date;
            }
            exportData.push(obj);
          });

          const headers = ['Renban','Part No', 'Kanban', 'Supplier', 'CC', 'Status', 'Quantity', 'Mode OF Freight'];
          if (this.selectedPopupName === 'NAMC ARRIVED') {
            headers.push('Act Arr DT');
          } else if (this.selectedPopupName === 'IN-TRANSIT') {
            headers.push('Est Arr DT');
          } else if (this.selectedPopupName === 'VANNING COMPLETE') {
            headers.push('Van Comp DT');
          } else if (this.selectedPopupName === 'DEVANNED') {
            headers.push('Devan Date');
          }
          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(
            exportData,
            this.selectedPopupName + '_' + timestamp,

            headers
          );
        }
        this.spinner.hide();
      }, 1000);
    }
  }

  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result =
        yearNumber * 10000 +
        monthNumber * 100 +
        dayNumber +
        hoursNumber * 60 +
        minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  numberComparator(number1, number2) {
    return number1 - number2;
  }

  ngOnInit(): void { }
}
