import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class FluctuationAllowanceService {
  getToken = () => localStorage.getItem('Token');

  httpCustomOptions = (service = 'fluctuation') => {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this.getToken(),
        'X-Api-Key':
          service === 'fluctuation'
            ? this.fluctuationApiKey
            : this.rundownApiKey,
      }),
    };
  };

  apiUrl = '';
  fluctuationApiKey = '';
  bulkAdjustmentApiUrl = '';
  rundownApiUrl = '';
  rundownApiKey = '';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.apiUrl = environment.fluctuationAllowanceUrl;
    this.bulkAdjustmentApiUrl = environment.bulkAdjustmentUrl;
    this.rundownApiUrl = environment.rundownUrl;
    this.fluctuationApiKey = environment.fluctuationAllowanceKey;
    this.rundownApiKey = environment.rundownKey;
  }

  downloadFluctuationAllowanceFile(data): Observable<any> {
    return this.http.post<any>(
      `${this.rundownApiUrl}/template`,
      data,
      this.httpCustomOptions('rundown')
    );
  }

  getFAGrid(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/getfluctuationallowance`,
      data,
      this.httpCustomOptions()
    );
  }

  editFAMaintenance(data): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/editfluctuationallowance`,
      data,
      this.httpCustomOptions()
    );
  }

  validateFAMaintenance(data): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/validate`,
      data,
      this.httpCustomOptions()
    );
  }

  getExcelFileUrl(data): Observable<any> {
    return this.http.post<any>(
      `${this.bulkAdjustmentApiUrl}/getExcelFileUrl`,
      data,
      this.httpCustomOptions()
    );
  }

  putDatainExcel(file, url): Observable<any> {
    return this.http.put(url, file);
  }

  getMaxDate(data): Observable<any> {
    return this.http.post<any>(
      `${this.rundownApiUrl}/getMaxDate`,
      data,
      this.httpCustomOptions('rundown')
    );
  }
}
