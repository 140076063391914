import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MenuService } from '../../services/menu.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  providers: [MenuService],
})
export class BreadcrumbComponent implements OnInit {
  name: string = '';
  menu: any[] = [];
  isactive = false;
  breadcrumbList: any[] = [];

  @Output() newEvent = new EventEmitter<string>();

  constructor(
    private readonly _router: Router,
    private readonly menuService: MenuService
  ) {}

  ngOnInit() {
    this.menu = this.menuService.getMenu();
    this.listenRouting();
  }

  ngOnChanges() {
    this.menu = this.menuService.getMenu();
    this.listenRouting();
  }

  listenRouting() {
    let routerUrl: string;
    let routerList: any[];
    let routers: any[];
    let target: any;
    this._router.events.subscribe((router: any) => {
      if (router.shouldActivate) {
        routerUrl = router.url;
        if (routerUrl && typeof routerUrl === 'string') {
          target = this.menu;
          this.breadcrumbList.length = 0;
          routerList = routerUrl.slice(1).split('/');
          routerList.forEach((route, index) => {
            routers = route.slice().split('?');
            route = routers[0];

            target = target.find((page) => page.path.slice(2) === route);

            if (!target) {
              return;
            }

            this.breadcrumbList.push({
              name: target.name,
              path:
                index === 0
                  ? target.path
                  : `${this.breadcrumbList[index - 1].path}/${target.path.slice(
                      2
                    )}`,
            });
            if (index + 1 !== routerList.length) {
              target = target.children;
            }
          });
        }
      }
    });
  }

  toHome(path) {
    if (path === './home') {
      localStorage.removeItem('landingUrl');
      this.newEvent.emit(path);
    }
  }
}
