import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { LookupService } from '../services/lookup/lookup.service';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { OSPStateService } from '../services/osp-state.service';


@Component({
  selector: 'app-release-notes',
  templateUrl: './release-notes.component.html',
  styleUrls: ['./release-notes.component.scss'],
})
export class ReleaseNotesComponent implements OnInit {
  namcValue = localStorage.getItem('namcvalue');
  user: string;
  userRole: string;
  trainingVideos = [];
  fileNames = [];
  s3FilePath: any;
  adjustSubscription$: Subscription;

  constructor(private readonly lookupService: LookupService, private readonly spinner: NgxSpinnerService,
    private readonly stateService: OSPStateService,) { }

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }

  ngOnInit(): void {

    this.initializeData();


    this.adjustSubscription$ = this.stateService
      .getNamc()
      .subscribe((observable) => {
        this.initializeData();
      });

  }

 

  initializeData() {
    this.spinner.show();
    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      s3folderName: 'Release_Notes',
    };
   
    this.lookupService.getReleaseNotes(data).subscribe({
      next: (res) => {
       
        console.log('res---', res.body.releaseNotesList);
        var releaseNotestList = res.body.releaseNotesList ? res.body.releaseNotesList : [];
        this.fileNames=[]

        releaseNotestList.forEach((element) => {
          var path = element.Key;

          var filesName = path.split('/');
          console.log('filesName', filesName, '-----', filesName[1]);

          console.log('path--', path);

          if (filesName[1]) {
            this.fileNames.push({
              path: path,
              fileName: filesName[1],
            });
          }
        });

        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }

  downloadFile(path) {
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      fileNames: [path],
    };

    this.lookupService.getVideos(data).subscribe({
      next: (success) => {
        this.s3FilePath = success.body.fileurls[0];

        if (success) {

          saveAs(this.s3FilePath);


          this.spinner.hide();
        } else {
          this.spinner.hide();
          // this.warningMessage = warningMessage.fileNotAvilable;

          window.scroll(0, 0);
        }
        this.spinner.hide();
      },
      error: (error) => {
        this.spinner.hide();
      },
    });
  }
}
