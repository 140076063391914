import { Component } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'grid-text-editor',
  template: `
    <label *ngIf="showInput">{{ value }} </label>
    <input
      *ngIf="!showInput"
      #input
      [(ngModel)]="value"
      [ngClass]="{ 'invalid-text': isEmpty }"
      class="grid-cell-edit-layout "
      (change)="textUpdated()"
    />
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
    `,
  ],
})
export class GridCalcDtEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  constructor() {}

  agInit(params: any): void {
    if (params.column === 'calc_date') {
      params.data.rundownData = this;
      this.value = params.data.calc_date;
      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;    
    if (
      this.params.data.checkedEditMode &&
      new Date(params.data.calc_date) > new Date(params.data.maxcalc) &&
      params.data.calc_status !== ''
    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'calc_date') {
      if (this.value !== this.params.data.calc_date) {
        this.params.data.calc_date = this.value;
        this.params.data.isCalcDateUpdated = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }
}
