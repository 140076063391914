<div style="display : inline-block;margin-top: -3px;" >
    <a [routerLink]="[]" id="edit">
        <img  *ngIf="access" class="detailiconhome" (click)="editData($event)"  style = "margin-right: 2px;" src="./assets/images/Edit_icon.svg" title="edit" alt="Edit">
    </a>
    <span></span>
    <a [routerLink]="[]" id="delete">
        <img  *ngIf="access" class="detailiconhome" (click)="deleteData()" src="assets/images/icon-delete.svg" title="delete" alt="Delete">
    </a>
    <span></span>
    
</div>