import { Component, OnInit } from '@angular/core';
import {OspService} from '../master-services/osp.service'

@Component({
  selector: 'app-master-delete',
  templateUrl: './master-delete.component.html',
  styleUrls: ['./master-delete.component.scss']
})
export class MasterDeleteComponent implements OnInit {
  business_entity:any;

  constructor() { }

  ngOnInit(): void {
    this.business_entity=localStorage.getItem('namcvalue')
  }
  deleteRecord(){
    return {
      "business_entity":this.business_entity
    }

  }

}
