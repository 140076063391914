import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'sealStatusHistory-component',
  template: `
  <img
  width="30px"
  height="30px"
  align-self="center"
  *ngIf="showInput"

  src="../../assets/images/bubble.png"
/>


  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      input {
        width: 150px;
        height: 25px;
      }
    `,
  ],
})
export class SealStatusHistoryComponent {
  

    public params: any;
    showInput = true;
    value: any;
    isEmpty = true;
    comments: any;
    singleComment: boolean = false;
    constructor(private readonly elementRef: ElementRef) {}
  
    agInit(params: any): void {
  
      if (params.column === 'customs_seal_status') {
        this.showInput=true;
          params.data.comment = this;
  
          this.value = params.data.customs_seal_status;
    
  
        if (params.data.customs_seal_status) {
            
          if (params.data.customs_seal_status.split('$').length > 1) {
              this.value = params.data.customs_seal_status;
            this.value = '';
          } else {
            this.value = params.data.customs_seal_status;
            this.singleComment = true;
          }
        }
  
      }
  
      this.params = params;
      if (
        params.data.customs_seal_status === null  || params.data.customs_seal_status.trim() === ''
      ) {
        this.showInput = false;
        this.value='';
      } else {
        this.showInput = true;
        this.value = params.data.customs_seal_status;
  
      }
  
    }
  
    // called when the cell is refreshed
    refresh(params: any): boolean {
      this.params = params;
      return true;
    }
}
