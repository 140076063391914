import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-master-refreshheader',
  templateUrl: './master-refreshheader.component.html',
  styleUrls: [],
})
export class MasterRefreshheaderComponent implements OnInit {
  ngOnInit(): void {}

  // tslint:disable-next-line:member-ordering
  @Input() cell: any;
  // @Output() onClicked = new EventEmitter<boolean>();

  // tslint:disable-next-line:member-ordering
  private params: any;
  // tslint:disable-next-line:member-ordering
  checked: any;

  // called on init
  agInit(params: any): void {
    this.params = params;

    if (params.column.colDef.headerComponentFramework.checked === true) {
      this.checked = true;
    } else {
      this.checked = false;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    this.checked = false;
    return true;
  }

  // tslint:disable-next-line:typedef
  headerCheckChanged(event) {
    this.checked = event.checked;
    this.params.context.componentParent.headerCheckChanged(event);
  }

  constructor() {
    this.checked = false;
  }
}
