import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'fa-comments-editor',
  template: `
    <img
      width="30px"
      height="30px"
      align-self="center"
      *ngIf=" comments != null"
      src="../../assets/images/bubble.png"
    />
    
    <p *ngIf=" singleComment">{{ value }}</p>
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      input {
        width: 150px;
        height: 25px;
      }
    `,
  ],
})
export class PartsMaintenanceCommentsComponent {
  public params: any;
  value: any;
  isEmpty = true;
  comments: any;
  singleComment: boolean = false;
  constructor(private readonly elementRef: ElementRef) {}

  agInit(params: any): void {
    if (params.column === 'tapped_history') {
      params.data.tapped_history = this;

      this.value = params.data.tapped_history;
      if (params.data.tapped_history) {
        
        if (params.data.tapped_history.split('$').length > 1) {
          this.comments = params.data.tapped_history;
          this.value = '';
        } else {
          this.value = params.data.tapped_history;
          this.singleComment = true;
        }
      }

      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;

   
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  
}
