import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  HostListener,
  Inject,
} from '@angular/core';

import { Component, Input, EventEmitter } from '@angular/core';
import { Output } from '@angular/core';

@Directive({
  selector: '[appclickOutside]',
})
export class ClickOutsideDirective {
  constructor(private readonly element: ElementRef) {}
  @Output() public clickedOutside = new EventEmitter();

  @HostListener('document:click', ['$event.target'])
  public onClick(target: any) {
    const clickedInside = this.element.nativeElement.contains(target);
    if (!clickedInside) {
      this.clickedOutside.emit(target);
    }
  }
}
