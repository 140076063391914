<footer class="mt-4">
	<!-- <div class="footer"> -->
		<div class="container-fluid py-3">
			<div class="row">
				<div class="col-sm-6">
					<img class="inl-nav-img" [src]="myimage" alt="image not found" width="22" height="15.13">
					<label class="copyright">Copyright © 2022</label>
				</div>
				<div class="col-sm-6 footer-link">
					<label><a [routerLink]="['faq']">&nbsp;FAQs&nbsp;</a>|</label>
					<label><a [routerLink]="['contact']">&nbsp;CONTACT US&nbsp;</a></label>
				</div>
			</div>
			
		</div>
	<!-- </div> -->
</footer>
