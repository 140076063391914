import { Injectable } from '@angular/core';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PipeJobstatusService {

  jobStatusUrl = '';

  appJson = 'application/json';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.jobStatusUrl = environment.ospLookupUrl;

  }

  getJobstatushttpCustomOptions() {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        // 'noloader': 'true',
        Authorization: localStorage.getItem('Token'),
        'X-Api-Key': this.environment.ospLookupKey,
      }),
    };
  }

  //get API

  getJob(data): Observable<any> {
    return this.http.post<any>(
      `${this.jobStatusUrl}/getpipejob`,
      data,
      this.getJobstatushttpCustomOptions()
    );
  }

  //jobstatus file details

  getFile(data): Observable<any> {
    return this.http.post<any>(
      `${this.jobStatusUrl}/getpipefile`,
      data,
      this.getJobstatushttpCustomOptions()
    );
  }

  downloadFile(data): Observable<any> {
    return this.http.post<any>(
      `${this.jobStatusUrl}/downloadfile`,
      data,
      this.getJobstatushttpCustomOptions()
    );
  }

  
}
