import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class PartstmaintenanceService {
  getToken = () => localStorage.getItem('Token');
  appJson = 'application/json';

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': this.appJson,
        Accept: this.appJson,
        Authorization: this.getToken(),
        'X-Api-Key': this.environment.partMaintenanceKey,
      }),
    };
  };

  

  getPieHeaders = (token) => {
    return {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + token,
      }),
    };
  };

  apiUrl = '';
  sendToPieDataUrl = '';

  constructor(readonly http: HttpClient, readonly environment: EnvironmentConfiguration) {
    this.apiUrl = environment.partMaintenance;
    this.sendToPieDataUrl = environment.sendToPieData;
  }

  getPartmaintenanceData(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/partMaintenanceData`, data, this.httpCustomOptions());
  }
  getContainerData(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/getcontainerdata`,
      //  `http://localhost:3000/status3`,

      data,
      this.httpCustomOptions()
    );
  }

  sendToPieData(data, token): Observable<any> {
    return this.http.post<any>(
      `${this.sendToPieDataUrl}`,
      data,
      this.getPieHeaders(token)
    );
  }

  

  editMaintenace(data): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/editPartMaintenanceData`,

      data,
      this.httpCustomOptions()
    );
  }

  editContainer(data): Observable<any> {
    return this.http.put<any>(
      `${this.apiUrl}/editContainerData`,
      //  `http://localhost:3000/status2`,
      data,
      this.httpCustomOptions()
    );
  }


}
