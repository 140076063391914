<div class="background">
  

  <div class="successMessage alert-dismissible">
    <div *ngIf="successMessage" class="alert alert-success" role="alert">
      {{ successMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
    <div
      *ngIf="warningMessage"
      class="alert alert-warning"
      role="alert"
    >
    {{ warningMessage }}

      <button
        *ngIf="warningMessage"
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>

  <div class="container-fluid">
    <div class="row">
      <div class="col-6">
        <h2 class="heading">Master Setup</h2>
      </div>
      <div class="col-6">
        <button *ngIf="access1" type="button" class="master-primary-btn" (click)="addData()">
          + ADD NEW
        </button>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="card">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-1 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">MASTER ID </label>
                <p-multiSelect
                  [(ngModel)]="master_id"
                  (onChange)="setName()"
                  [options]="masterList"
                  optionLabel="master_id"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>
            <div class="col-xl-2 col-md-2 col-sm-3">
              <div class="drpchildctrl">
                <label class="searchLable">MASTER NAME</label>
                <p-multiSelect
                  (onChange)="setId()"
                  [options]="masterList"
                  [(ngModel)]="m_name"
                  optionLabel="name"
                  [showTransitionOptions]="'0ms'"
                  [hideTransitionOptions]="'0ms'"
                  [defaultLabel]="'--Select--'"
                  autoWidth="true"
                  [style]="{ width: '100%' }"
                  [resetFilterOnHide]="true"
                >
                </p-multiSelect>
              </div>
            </div>

            <div class="col-xl-1 col-md-2 col-sm-3 reset_btn">
              <label class="resetIcon">
                <img
                  src="./assets/images/Group 115380.png"
                  alt="refresh icon"
                  (click)="onResetDropDown()"
                />
              </label>
              <label class="resetlabel" (click)="onResetDropDown()"
                >Reset</label
              >
              <span class="vertical"></span>
            </div>
            <div class="col-xl-1 col-md-2 col-sm-3 search">
              <button class="search_btn" (click)="onSearch()">SEARCH</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="records">
          <label class="page-drpdwn-label">RECORDS / PAGE:</label>
          
          <select
            class="page-drpdwn"
            (change)="onPageSizeChanged()"
            [(ngModel)]="paginationPageSize"
          >
            <option value="10" >10</option>
            <option value="30">30</option>
            <option value="50">50</option>
            <option value="{{rowData.length}}">All</option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <div id="dvgrid" class="gridStyle">
      <app-grid
        class="table-outer"
        #osPartsGrid
        [columnDefs]="columnDefs"
        [rowData]="rowData"
        [rowSelection]="rowSelection"
        [gridOptions]="gridOptions"
        [pagination]="pagination"
        [paginationPageSize]="paginationPageSize"
        [context]="context"
        [suppressClickEdit]="suppressClickEdit"
        [frameworkComponents]="frameworkComponents"
        [tooltipShowDelay]="tooltipShowDelay"
        [tooltipMouseTrack]="true"
        [editType]="editType"
      >
      </app-grid>
  </div>

  <ngx-spinner
    bdColor="rgba(51,51,51,0.8)"
    size="medium"
    color="#fff"
    type="ball-scale-multiple"
  >
    <p style="font-size: 20px; color: white">Loading... Please wait...</p>
  </ngx-spinner>
</div>
