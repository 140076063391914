import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentConfiguration } from 'src/app/environment/services/environment.configuration';

@Injectable({
  providedIn: 'root',
})
export class CalenderService {
  //apiUrl='http://localhost:3000'
  apiUrl = '';

  constructor(
    private http: HttpClient,
    private environment: EnvironmentConfiguration
  ) {
    this.apiUrl = environment.setupUrl;
  }

  getToken = () => {
    return localStorage.getItem('Token');
  };

  httpCustomOptions = () => {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: this.getToken(),
        'X-Api-Key': this.environment.setupKey,
      }),
    };
  };

  getCalenderList(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/calender`,
      data,
      this.httpCustomOptions()
    );
  }
  downloadCalenderFile(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/downloadtemplate`,
      data,
      this.httpCustomOptions()
    );
  }
  uploadCalenderFile(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/uploadcalender`,
      data,
      this.httpCustomOptions()
    );
  }
  editCalenderFile(data): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/editcalender`,
      data,
      this.httpCustomOptions()
    );
  }
}
