import { Component, Inject, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';




@Component({
  selector: 'app-container-devandialog',
  templateUrl: './container-devandialog.component.html',
  styleUrls: ['./container-devandialog.component.scss']
})
export class ContainerDevandialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,


  ) {}
  rowdata: any=[];
  renban: any=[];
  renbans='';
  mesaage: string;


  ngOnInit(): void {
    this.mesaage='The Following Renban(s) Will be Devanned - Kindly confirm'

    this.rowdata=this.data;
    this.rowdata.forEach((element) => {
       this.renban.push(element.container_renban);
      });
      this.renbans=this.renban.join(', ')
      if(this.renban.length === 1){
        this.mesaage='The Following Renban Will be Devanned - Kindly confirm'
      }

  }

  
  }


