import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gridtextchange',
  template: ` <p (click)="textUpdate()">{{ params.value }}</p> `,
  styles: [
    `
      p {
        text-decoration: underline;
        color: #2688da;
        cursor: pointer;
        font-size: 12px;
      }
    `,
  ],
})
export class GridtextchangeComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  filename;
  reportType
  batch_name: any;
  constructor() {}

  agInit(params: any): void {
    this.params = params;
   
    this.reportType=params.data.report_type
    this.filename = params.data.filename;
    
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdate() {
    
    this.params.context.componentParent.textUpdate(this.filename,this.reportType);
  }
}
