<div>
    <div class="row" style="border-bottom: 2px solid #ccc; margin-bottom: 10px">
      <!-- <div class="col-sm-11">
        <h3 class="head">UPLOADED DATA VANNING RANGE</h3>
      </div> -->
      <div class="col-sm-11 movable">
        <h3
          class="head"
          cdkDrag
          cdkDragHandle
          cdkDragRootElement=".cdk-overlay-pane"
        >
          <!-- <mat-icon> drag_handle </mat-icon> -->
          UPLOADED DATA VANNING RANGE
        </h3>
      </div>
  
      <div class="col-sm-1">
        <button
          type="button"
          class="btn btn-dark btn-circle btn-xl"
          [mat-dialog-close]="true"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
    <form [formGroup]="form">
      <div class="row">
      
  
        <div class="col-sm-6" style="margin-top: 10px">
          <div class="drpchildctrl">
            <span class="v1"></span>
            <label class="searchLable ">VANNING FROM</label>
            <input
              class="key"
              type="date"
              autoWidth="true"
              formControlName="vanningFrom"
              placeholder="--Type--"
              ng-required="true"
            />
            <ng-container *ngIf="formcontrol.vanningFrom.errors && submitted">
              <small *ngIf="formcontrol.vanningFrom.errors?.required">
                Vanning From is required.
              </small>
            </ng-container>
          </div>
        </div>
        <div class="col-sm-6 col-lg-6" style="margin-top: 10px">
          <div class="drpchildctrl">
            <span class="v1"></span>
            <label class="searchLable ">VANNING TO</label>
            <input
              class="key"
              type="date"
              autoWidth="true"
              formControlName="vanningTo"
              placeholder="--Type--"
              required
            />
            <ng-container *ngIf="formcontrol.vanningTo.errors && submitted">
              <small *ngIf="formcontrol.vanningTo.errors?.required">
                Vanning To is required.
              </small>
            </ng-container>
          </div>
        </div>
      </div>
    </form>
    <div class="text-right" style="margin-top: 20px">
      <button class="review_btn" mat-button (click)="addVanningDetails()">
        Save
      </button>
  
      <button class="cancel_btn" mat-button mat-dialog-close="cancel">
        Cancel
      </button>
    </div>
  </div>
  
  