<mat-label *ngIf="access" [ngClass]="isDisabled?'labelDisabled': checked? 'labelDisabled':'labelBeforeSlide'" [hidden]="toggle" [disabled]="isDisabled">HOLD</mat-label>

<mat-slide-toggle *ngIf="access"
  [hidden]="toggle"
  [(ngModel)]="checked"
  class="example-margin"
  [color]="color"
  [disabled]="isDisabled"
  (change)="changed()"
  
  labelPosition="after"
  >RELEASE
</mat-slide-toggle>


