import { Component } from '@angular/core';

@Component({
  selector: 'app-get-sudo-cell-renderer',
  templateUrl: './get-sudo-cell-renderer.component.html',
  styleUrls: ['./get-sudo-cell-renderer.component.scss'],
})
export class GetSudoCellRendererComponent {
  public params: any;
  value: any;
  backgroundColor: string;
  fontColor: string;

  constructor() {}

  agInit(params: any): void {
    this.value = params.value;

    const actual = params.column.colId;
    const status = params.column.colId.split('_')[0] + '_STATUS';

    let target = params.data.vehicle_target;
    if (actual.includes('TMMAL') || actual.includes('TMMWV')) {
      target = params.data.unit_target;
    }

    if (
      actual.includes('ACTUAL') &&
      params.value <= target &&
      params.data[status]?.toLowerCase() === 'completed'
    ) {
      this.backgroundColor = '#E1FFDD';
      this.fontColor = '#25AA00';
    }

    if (actual.includes('ACTUAL') && params.value > target) {
      this.backgroundColor = '#FEE1DD';
      this.fontColor = '#D20000';
    }

    if (
      params.data[status] &&
      (params.data[status]?.toLowerCase() === 'failed' ||
        params.value?.toLowerCase() === 'failed')
    ) {
      this.backgroundColor = undefined;
      this.fontColor = '#D20000';
    }

    if (
      params.rowIndex === 1 &&
      (params.column.colId.includes('TMMAL') ||
        params.column.colId.includes('TMMWV'))
    ) {
      params.setValue(null);
      this.backgroundColor = '#DADADA';
      this.fontColor = '#DADADA';
    }

    if (params.rowIndex === 0 && params.column.colId.includes('TMMBC')) {
      params.setValue(null);
      this.backgroundColor = '#DADADA';
      this.fontColor = '#DADADA';
    }

    this.params = params;
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }
}
