<div class="background">
  <div class="successMessage alert-dismissible">
    <div *ngIf="warningMessage" class="alert alert-warning" role="alert">
      {{ warningMessage }}
      <button
        type="button"
        class="btn-close"
        (click)="removeMessage()"
        data-bs-dismiss="alert"
        aria-label="Close"
      ></button>
    </div>
  </div>
  <div class="container-fluid">
    <mat-toolbar id="msgTicker" class="layout-header">
      <span id="msgTickerLabel" class="marquee-label">
        <mat-icon id="notificationImp" class="notification"
          >notifications_active</mat-icon
        >Messages

        <span class="arrow-msg"> </span>
      </span>

      <p class="marquee" *ngIf="urgentTickerMessages?.length > 0">
        <span>
          <div
            id="msgTickerDiv"
            class="marquee-message"
            *ngFor="let urgentMsg of urgentTickerMessages; let i = index"
          >
            <a class="ticker_message calendarDate">
              {{ urgentMsg.message }} &nbsp;</a
            >
          </div>
        </span>
      </p>

      <div
        id="urgentTicker"
        class="no-messages"
        *ngIf="!urgentTickerMessages?.length"
      >
        *** No Urgent Messages ***
      </div>
    </mat-toolbar>

    <div class="row">
      <div class="col-xl-4 col-md-6 col-sm-12 matCardContainer">
        <mat-card style="height: 100%">
          <mat-card-header>
            <mat-card-title>
              <h2 class="heading">PARTS / KANBANS</h2></mat-card-title
            >
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col-8">
                <canvas id="canvas"></canvas>
              </div>
              <div class="col-4">
                <ul class="list-group">
                  <li class="list-group-item item itemFont1">GOOD CONDITION</li>
                  <li class="list-group-item item itemFont">
                    {{ goodCondition }}
                  </li>
                  <li class="list-group-item item itemFont2">SHORTAGE</li>
                  <li class="list-group-item item itemFont">
                    <a (click)="openDialog('shortage')" >{{
                      shortageCondition
                    }}</a>
                  </li>
                  <li class="list-group-item item itemFont3">OVERAGE</li>
                  <li class="list-group-item item itemFont">
                    <a (click)="openDialog('overage')">{{
                      overageCondition
                    }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-xl-5 col-md-6 col-sm-12 matCardContainer">
        <mat-card style="height: 100%">
          <mat-card-header class="heading1">
            <mat-card-title class="card-container-left">
              PARTS STATUS</mat-card-title>
              <mat-card-title class="card-container-right">
                <button (click)="onSearch()" class="search_btn1">SEARCH</button>
              </mat-card-title>
              <span class="vertical"></span>
              <mat-card-title class="card-container-right reset_btn">
                <label class="resetIcon" (click)="onResetDropDown()">
                  <img
                    src="./assets/images/Group 115380.png"
                    alt="refresh icon"
                  />
                </label>
                <label class="resetlabel" (click)="onResetDropDown()">Reset
                  </label>
               
              </mat-card-title>

              <mat-card-title class="card-container-right">

                <p-dropdown
                [showTransitionOptions]="'0ms'"
                [hideTransitionOptions]="'0ms'"
                autoWidth="true"
                [resetFilterOnHide]="true"
                [(ngModel)]="selectedContainer"
                
               
                [options]="containerList"
                [filter]="true"
                [virtualScroll]="true"
                optionLabel="cc"
                [itemSize]="20"
                appendTo="body"
                placeholder="CC"
                [style]="{ width: '100%' }"
                scrollHeight="100px"
                [disabled]="disableContainer"
              >
              </p-dropdown>
              &nbsp;&nbsp;
                
              </mat-card-title>

              <mat-card-title class="card-container-right">

                <p-dropdown
                [showTransitionOptions]="'0ms'"
                [hideTransitionOptions]="'0ms'"
                autoWidth="true"
                [resetFilterOnHide]="true"
                [(ngModel)]="selectedKanban"
                (onChange)="kanbanChange()"
                [options]="kanbanList"
                [filter]="true"
                [virtualScroll]="true"
                optionLabel="kanban"
                [itemSize]="20"
                appendTo="body"
                placeholder="Kanban"
                [style]="{ width: '100%' }"
                scrollHeight="100px"
                [disabled]="disableKanban"
                
              >
              </p-dropdown>
              &nbsp;&nbsp;
                
              </mat-card-title>

            <mat-card-title class="card-container-right">

              <p-dropdown
              [showTransitionOptions]="'0ms'"
              [hideTransitionOptions]="'0ms'"
              autoWidth="true"
              [resetFilterOnHide]="true"
              (onChange)="partNo()"
              [(ngModel)]="selectedPart"
              [options]="partsList"
              [filter]="true"
              [virtualScroll]="true"
              [itemSize]="20"
              placeholder="Part No"
              appendTo="body"
              optionLabel="part_number"
              [style]="{ width: '100%' }"
              scrollHeight="100px"
              [disabled]="disablePartNumber"
            >
            </p-dropdown>
            &nbsp;&nbsp;
            
            </mat-card-title>
            
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              

              <div class="col-8">
                <canvas id="canvasStatus"></canvas>
              </div>
              <div class="col-4">
                <ul class="list-group">

                  <li class="list-group-item item itemFont3">VANNING COMPLETE</li>
                  <li class="list-group-item item itemFont">
                    <a (click)="apiCall('VANNING COMPLETE',vanningComplete)" [ngClass]="{'isDisabled':vanningComplete === 0}">{{
                      vanningComplete
                    }}</a>
                  </li>

                  <li class="list-group-item item itemFont2">IN-TRANSIT</li>
                  <li class="list-group-item item itemFont">
                    <a (click)="apiCall('IN-TRANSIT',inTransit)" [ngClass]="{'isDisabled':inTransit === 0}">{{ inTransit }}</a>
                  </li>
                  <li class="list-group-item item itemFont1">NAMC ARRIVED</li>
                  <li class="list-group-item item itemFont">
                    <a (click)="apiCall('NAMC ARRIVED',namcArrived)" [ngClass]="{'isDisabled':namcArrived === 0}">{{ namcArrived }}</a>
                  </li>
                  
                 
                  <li class="list-group-item item itemFont4">DEVANNED</li>
                  <li class="list-group-item item itemFont">
                    <a (click)="apiCall('DEVANNED',devanned)" [ngClass]="{'isDisabled':devanned === 0}">{{
                      devanned
                    }}</a>
                  </li>
                </ul>
              </div>

            
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="col-xl-3 col-md-6 col-sm-12 matCardContainer">
        <mat-card style="height: 100%">
          <mat-card-header>
            <mat-card-title
              ><h2 class="heading">QUICK CONTACT</h2></mat-card-title
            >
          </mat-card-header>
          <mat-card-content>
            <div class="d-flex flex-column">
              <div class="p-2">
                <img
                  src="./assets/images/envelope-solid.png"
                  alt="Mail icon"
                  style="height: 16px; padding-right: 7px"
                />
                <a class="email" [href]="emailTMC" target="_top">TMC</a>
              </div>
              <div class="p-2">
                <img
                  src="./assets/images/envelope-solid.png"
                  alt="Mail icon"
                  style="height: 16px; padding-right: 7px"
                />
                <a class="email" [href]="emailstring" target="_top">KVC</a>
              </div>
              <div class="p-2">
                <img
                  src="./assets/images/envelope-solid.png"
                  alt="Mail icon"
                  style="height: 16px; padding-right: 7px"
                />
                <a class="email" [href]="emailOSP" target="_top"
                  >OS ITx and Business Support</a
                >
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-4 col-md-6 col-sm-12">
        <mat-card style="height: 85%">
          <mat-card-header>
            <mat-card-title
              ><h2 class="heading">UVR GRAPH LINK</h2></mat-card-title
            >
          </mat-card-header>
          <mat-card-content> </mat-card-content>
        </mat-card>
      </div>
      <div class="col-xl-5 col-md-6 col-sm-12">
        <mat-card style="height: 85%">
          <mat-card-header>
            <mat-card-title
              ><h2 class="heading">PROGRESS INFORMATION</h2></mat-card-title
            >
          </mat-card-header>
          <mat-card-content> </mat-card-content>
        </mat-card>
      </div>

      <div class="col-xl-3 col-md-6 col-sm-12">
        <mat-card style="height: 85%; margin-bottom: 30px; overflow: hidden">
          <mat-card-header class="heading1">
            <mat-card-title class="card-container-left">
              CALENDAR</mat-card-title
            >
            <mat-card-title class="card-container-right">
              <p-dropdown
                [showTransitionOptions]="'0ms'"
                [hideTransitionOptions]="'0ms'"
                autoWidth="true"
                [resetFilterOnHide]="true"
                [(ngModel)]="currentYear"
                (onChange)="yearChange()"
                [options]="yearsList"
                [filter]="true"
                [virtualScroll]="true"
                appendTo="body"
                [style]="{ width: '100%' }"
                scrollHeight="100px"
              >
              </p-dropdown>
            </mat-card-title>
            <mat-card-title class="card-container-right">
              <p-dropdown
                [options]="monthList"
                [(ngModel)]="currentMonth"
                (onChange)="monthChange()"
                optionLabel="label"
                [filter]="true"
                [virtualScroll]="true"
                [itemSize]="20"
                [showTransitionOptions]="'0ms'"
                [resetFilterOnHide]="true"
                [hideTransitionOptions]="'0ms'"
                autoWidth="false"
                [style]="{ width: '100%' }"
                appendTo="body"
              >
              </p-dropdown
              >&nbsp;
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div
              class="no-messages calendarNoRecords"
              *ngIf="!calendar?.length"
            >
              No records to show
            </div>
            <div id="calendar">
              <ul class="list-group">
                <li
                  class="list-group-item mainItem"
                  *ngFor="let slide of calendar"
                >
                  <div class="row">
                    <div class="col-2 calendarDate">
                      <span class="calendarFont">{{ slide.date }}</span>
                      <div style="color: gray">{{ slide.day }}</div>
                    </div>
                    <div class="col calendarFont">
                      <li
                        class="list-group-item item"
                        *ngFor="let ev of slide.even"
                      >
                        {{ ev }}
                      </li>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
</div>
<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-scale-multiple"
>
  <p class="loading">Loading... Please wait...</p>
</ngx-spinner>
