import { Component, ElementRef } from '@angular/core';

@Component({
  selector: 'fa-comments-editor',
  template: `
    <img
      width="30px"
      height="30px"
      align-self="center"
      *ngIf="showInput && comments != null"
      src="../../assets/images/bubble.png"
    />
    <input
      *ngIf="!showInput"
      #input
      type="text"
      [(ngModel)]="value"
      [ngClass]="{ 'invalid-text': isEmpty }"
      (change)="textUpdated()"
      placeholder="Please add comment"
    />
    <p *ngIf="showInput && singleComment">{{ value }}</p>
  `,
  styles: [
    `
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      .invalid-text {
        border: 2px solid red !important;
      }
      input {
        width: 150px;
        height: 25px;
      }
    `,
  ],
})
export class FACommentsEditorComponent {
  public params: any;
  showInput = true;
  value: any;
  isEmpty = true;
  comments: any;
  singleComment: boolean = false;
  constructor(private readonly elementRef: ElementRef) {}

  agInit(params: any): void {
    if (params.column === 'fa_comment') {
      params.data.comment = this;

      this.value = params.data.fa_comment;
      if (params.data.fa_comment) {
        if (params.data.fa_comment.split('$').length > 1) {
          this.comments = params.data.fa_comment;
          this.value = '';
        } else {
          this.value = params.data.fa_comment;
          this.singleComment = true;
        }
      }

      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    }

    this.params = params;

    if (
      this.params.data.checkedEditMode &&
      params.data.cont_code !== 'ALL' &&
      (new Date(this.params.data.vanning_date) >
        new Date(localStorage.getItem('vanningFrom')) ||
        new Date(this.params.data.hidden_vanning_date) >
          new Date(localStorage.getItem('vanningFrom')))
    ) {
      this.showInput = false;
    } else {
      this.showInput = true;
    }
  }

  // called when the cell is refreshed
  refresh(params: any): boolean {
    this.params = params;
    return true;
  }

  public textUpdated() {
    if (this.params.column === 'fa_comment') {
      this.params.data.fa_comment = this.value;

      if (
        (this.value || this.value?.toString() === '0') &&
        this.value?.toString() !== '-'
      ) {
        this.isEmpty = false;
      } else {
        this.isEmpty = true;
      }
    } else {
      this.params.data.finalquantity = this.value;
    }
  }
}
