import { AgChartOptions } from 'ag-charts-community';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CalenderService } from '../services/calender-setup/calender.service';
import { Chart } from 'chart.js';
import { KanpanDialogComponent } from './kanpan-dialog/kanpan-dialog.component';

import { PipeLookupService } from '../services/pipe-lookup/pipe-lookup.service';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { LookupService } from '../services/lookup/lookup.service';
import { OrderforecastService } from '../services/orderforecast/orderforecast.service';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subscription } from 'rxjs';
import { getDayName, getDaysInMonth } from '../constants';
import * as mm from 'moment-timezone';

import { PartbystatusDateviewComponent } from './partbystatus-dateview/partbystatus-dateview.component';
import { OspService } from '../core/master-services/osp.service';
import { PartstatusService } from '../services/part-status/partstatus.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('scroll', [
      state('on', style({ left: '-100px' })),
      transition('* => *', [style({ left: '-100px' }), animate(30000, style({ left: '80%' }))]),
    ]),
  ],
})
export class HomeComponent implements OnInit, OnDestroy {
  namc: any;
  public options: AgChartOptions;
  emailstring = `mailto:xyz@example.com?Subject=Hello Team&body=Hi, %0D This is mail from OSP dashboard!`;
  emailOSP = ``;
  emailTMC = `mailto:xyz@example.com?Subject=&body=`;
  monthList: any;
  currentYear: any;
  yearsList = [];
  currentMonth: any;
  monthName: number;

  namcName: string;
  user: string;
  userRole: string;
  namcValue: string;
  userName: string;

  goodCondition = 0;
  overageCondition = 0;
  shortageCondition = 0;
  chart: any;
  disableContainer: boolean;
  offset: number = 0;
  dataCount: number = 0;
  dataCount1: number = 0;



  message = '*** No Urgent Messages***';

  calendar;
  urgentTickerMessages = [];
  partsShortageCondition = [];
  partsOverageCondition = [];
  loadnamcArrivedData;
  loadselectedPopupData;
  loadinTransmitData;
  loadVanningCompletedData;
  loadDevavannedPartData;
  freezingMesssage = '';
  labelArray = ['Good condition', 'Shortage', 'Overage'];
  homeSubscription$: Subscription;
  // part by status variable Declaration
  chartStatus: any;
  namcArrived = 0;
  inTransit = 0;
  vanningComplete = 0;
  devanned = 0;
  warningMessage = '';

  partsList = [];
  containerList = [];
  kanbanList = [];
  selectedPart = '';
  selectedContainer = [];
  selectedKanban = '';
  partStatusData: any = {};
  disablePartNumber: boolean;
  disableKanban: boolean;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly calenderService: CalenderService,
    public readonly dialog: MatDialog,
    private readonly lookupService: LookupService,
    private readonly orderforecastService: OrderforecastService,
    private readonly stateService: OSPStateService,
    private readonly OspService: OspService,
    private readonly partstatusService: PartstatusService,
    private readonly pipeLookupService: PipeLookupService
  ) { }

  ngOnDestroy(): void {
    this.homeSubscription$.unsubscribe();
  }
  ngOnInit(): void {
    this.homeSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  loadData() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };
    const dataCount = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      currentDate: String(mm().tz('US/Michigan').format('YYYY-MM-DD')),
    };

    const dataShortage = {
      business_entity: this.namcValue,
      overage: false,
      workid: this.user,
      user_role: this.userRole,
      currentDate: String(mm().tz('US/Michigan').format('YYYY-MM-DD')),
    };

    const dataOverage = {
      business_entity: this.namcValue,
      overage: true,
      workid: this.user,
      user_role: this.userRole,
      currentDate: String(mm().tz('US/Michigan').format('YYYY-MM-DD')),
    };

    const d = new Date();

    this.currentYear = d.getFullYear();
    this.yearsList = [];
    this.yearsList.push(d.getFullYear() - 1);
    this.yearsList.push(d.getFullYear());
    this.yearsList.push(d.getFullYear() + 1);
    this.monthList = [
      {
        label: 'January',
        value: 1,
      },
      {
        label: 'February',
        value: 2,
      },
      {
        label: 'March',
        value: 3,
      },
      {
        label: 'April',
        value: 4,
      },
      {
        label: 'May',
        value: 5,
      },
      {
        label: 'June',
        value: 6,
      },
      {
        label: 'July',
        value: 7,
      },
      {
        label: 'August',
        value: 8,
      },
      {
        label: 'September',
        value: 9,
      },
      {
        label: 'October',
        value: 10,
      },
      {
        label: 'November',
        value: 11,
      },
      {
        label: 'December',
        value: 12,
      },
    ];

    this.currentMonth = this.monthList[d.getMonth()];

    const currentYear = d.getFullYear();
    const currentMonth = d.getMonth() + 1;
    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

    const calendarData = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      start_date: `${currentYear}-${currentMonth}-1`,
      end_date: `${currentYear}-${currentMonth}-${daysInCurrentMonth}`,
    };

    var batchStatusData = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.lookupService.getNotification(),
      this.orderforecastService.getOverageShortage(dataOverage),
      this.orderforecastService.getOverageShortage(dataShortage),
      this.orderforecastService.getPartsStatusCount(dataCount),
      this.lookupService.getContact(data),
      this.calenderService.getCalenderList(calendarData),
      this.lookupService.getBatchStatus(batchStatusData),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        // get notification
        this.urgentTickerMessages = res[0].body.data;

        //get overage data
        this.partsOverageCondition = res[1].body.data;

        //get shortage data
        this.partsShortageCondition = res[2].body.data;

        //loading parts status count

        this.goodCondition = res[3].body.goodCondition ? Number(res[3].body.goodCondition) : 0;
        this.overageCondition = res[3].body.overageCondition ? Number(res[3].body.overageCondition) : 0;

        this.shortageCondition = res[3].body.shortageCondition ? Number(res[3].body.shortageCondition) : 0;

        this.chart.data.datasets[0].data = [this.goodCondition, this.shortageCondition, this.overageCondition];

        this.chart.update();

        //get contact
        this.emailOSP = `mailto:${res[4].body.data.contactInfos}?`;
        localStorage.setItem('itContact', res[4].body.data.contactInfos);

        //get calendar data
        var filteredData = [];

        for (let i = 1; i < daysInCurrentMonth + 1; i++) {
          const subdate = i < 10 ? '0' + i : i;
          const submonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
          const dateObj = `${currentYear}-${submonth}-${subdate}`;

          this.spinner.hide();
          const filteredData1 = res[5].body.calenderResponse.filter(
            (row) => new Date(dateObj) >= new Date(row.start_date) && new Date(dateObj) <= new Date(row.end_date)
          );
          const data = {
            date: subdate,
            day: getDayName(currentYear, currentMonth, i).substring(0, 3),
            even: [],
          };

          const event = [];
          filteredData1.forEach((element) => {
            if (element.event === 'FA Day' || element.event === 'Getsudo') {
              event.push(element.event);
            } else if (element.holiday_indicator.toLowerCase() === 'y') {
              event.push(element.event);
            }
          });
          data.even = event;

          filteredData.push(data);
        }

        this.calendar = filteredData.filter((e) => e.even.length > 0);

        this.calendar = filteredData.filter((e) => e.even.length > 0);

        //get batch status
        const count = res[6].body.data.runningBatches?.length;

        if (count > 0) {
          this.freezingMesssage = `${res[6].body.data.runningBatches}  batch(s) running.`;
          this.spinner.hide();
        } else {
          this.freezingMesssage = '';
        }
        if (this.freezingMesssage) {
          this.urgentTickerMessages.push({ message: this.freezingMesssage });
        }
      },
      error: this.errorCallback,
    });

    this.loadpartStatus();

    ////partStatus subscription
  }

  errorCallback = (error) => {
    console.log(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
  };

  initializeData() {
    this.spinner.show();

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }

    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }

    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    } else {
      this.userName = 'Test User';
    }

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }

    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }
    if (localStorage.getItem('UserName')) {
      this.userName = localStorage.getItem('UserName');
    } else {
      this.userName = 'Test User';
    }
    if (this.chart) {
      this.chart.destroy();
    }

    if (this.chartStatus) {
      this.chartStatus.destroy();
    }

    this.chart = new Chart('canvas', {
      type: 'doughnut',
      data: {
        showInLegend: true,

        automargin: true,
        labels: ['goodCondition', 'shortageCondition', 'overageCondition'],
        datasets: [
          {
            labels: ['goodCondition', 'shortageCondition', 'overageCondition'],
            data: [this.goodCondition, this.shortageCondition, this.overageCondition],
            backgroundColor: ['#0D933B', '#D20000', '#737070'],
            fill: false,
            datalabels: {
              formatter: function (tooltipItem, data) {
                const labelarray = ['Good condition', 'Shortage', 'Overage'];
                const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
                const percentage = ((data.datasets[0].data[tooltipItem.index] * 100) / sum).toFixed(2) + '%';
                return labelarray[tooltipItem.index] + ': ' + percentage;
              },
              color: '#000',
              display: true,
              align: 'bottom',
              backgroundColor: '#ccc',
              borderRadius: 3,
              font: {
                size: 18,
              },
            },
          },
        ],
      },

      options: {
        cutoutPercentage: 75,
        legend: {
          display: false,

          labels: {
            boxwidth: 15,
            fontSize: 12,
          },
        },
        plugins: {
          datalabels: {
            formatter: function (tooltipItem, data) {
              const labelarray = ['Good condition', 'Shortage', 'Overage'];
              const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
              const percentage = ((data.datasets[0].data[tooltipItem.index] * 100) / sum).toFixed(2) + '%';
              return labelarray[tooltipItem.index] + ': ' + percentage;
            },
            color: '#000',
            display: true,
            align: 'bottom',
            backgroundColor: '#ccc',
            borderRadius: 3,
            font: {
              size: 18,
            },
          },
        },

        responsive: true,

        tooltips: {
          enabled: true,
          callbacks: {
            // HERE YOU CUSTOMIZE THE LABELS

            label: function (tooltipItem, data) {
              const labelarray = ['Good condition', 'Shortage', 'Overage'];
              const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
              const percentage = ((data.datasets[0].data[tooltipItem.index] * 100) / sum).toFixed(2) + '%';
              return labelarray[tooltipItem.index] + ': ' + percentage;
            },
          },
        },
      },
    });

    // part by status pie chart

    this.loadPartByStatusGraph();
    this.onResetDropDown();
    this.removeMessage();


    this.loadData();
    this.loadpartStatus();

    this.statusCount();

    // this.loadpartStatusCount()
    // this.loadStatusCount()
  }

  openDialog(overageOrShortage) {
    var data = {
      orverageOrShortage: overageOrShortage,
      modifiedRowData: overageOrShortage === 'shortage' ? this.partsShortageCondition : this.partsOverageCondition,
    };
    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-component';
      dialogConfig.height = '550px';
      dialogConfig.width = '1200px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(KanpanDialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        }
      });
    }
  }
  monthChange() {
    this.initialiseCalendarData(this.currentMonth.value, this.currentYear);
  }
  yearChange() {
    this.initialiseCalendarData(this.currentMonth.value, this.currentYear);
  }

  initialiseCalendarData(currentMonth, currentYear) {
    this.spinner.show();
    const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      start_date: `${currentYear}-${currentMonth}-1`,
      end_date: `${currentYear}-${currentMonth}-${daysInCurrentMonth}`,
    };

    this.calenderService.getCalenderList(data).subscribe((res) => {
      this.spinner.hide();

      var filteredData = [];

      for (let i = 1; i < daysInCurrentMonth + 1; i++) {
        const subdate = i < 10 ? '0' + i : i;
        const submonth = currentMonth < 10 ? '0' + currentMonth : currentMonth;
        const dateObj = `${currentYear}-${submonth}-${subdate}`;

        const filteredData1 = res.body.calenderResponse.filter(
          (row) => new Date(dateObj) >= new Date(row.start_date) && new Date(dateObj) <= new Date(row.end_date)
        );
        const data = {
          date: subdate,
          day: getDayName(currentYear, currentMonth, i).substring(0, 3),
          even: [],
        };
        const event = [];
        filteredData1.forEach((element) => {
          if (element.event === 'FA Day' || element.event === 'Getsudo') {
            event.push(element.event);
          } else if (element.holiday_indicator.toLowerCase() === 'y') {
            event.push(element.event);
          }
        });
        data.even = event;

        filteredData.push(data);
      }

      this.calendar = filteredData.filter((e) => e.even.length > 0);
    });
  }

  //start of part by status logic

  loadPartByStatusGraph() {
    this.chartStatus = new Chart('canvasStatus', {
      type: 'doughnut',
      data: {
        showInLegend: true,

        automargin: true,
        labels: ['NAMC ARRIVED', 'IN-TRANSIT', 'VANNING COMPLETE', 'DEVANNED PART'],
        datasets: [
          {
            labels: ['NAMC ARRIVED', 'IN-TRANSIT', 'VANNING COMPLETE', 'DEVANNED PART'],
            data: [this.namcArrived, this.inTransit, this.vanningComplete, this.devanned],
            backgroundColor: ['#0D933B', '#D20000', '#737070', '#f0a309'],
            fill: false,
            datalabels: {
              formatter: function (tooltipItem, data) {
                const labelarray = ['NAMC ARRIVED', 'IN-TRANSIT', 'VANNING COMPLETE', 'DEVANNED PART'];
                const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
                const percentage = ((data.datasets[0].data[tooltipItem.index] * 100) / sum).toFixed(2) + '%';
                return labelarray[tooltipItem.index] + ': ' + percentage;
              },
              color: '#000',
              display: true,
              align: 'bottom',
              backgroundColor: '#ccc',
              borderRadius: 3,
              font: {
                size: 18,
              },
            },
          },
        ],
      },

      options: {
        cutoutPercentage: 75,
        legend: {
          display: false,

          labels: {
            boxwidth: 15,
            fontSize: 12,
          },
        },
        plugins: {
          datalabels: {
            formatter: function (tooltipItem, data) {
              const labelarray = ['NAMC ARRIVED', 'IN-TRANSIT', 'VANNING COMPLETE', 'DEVANNED PART'];
              const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
              const percentage = ((data.datasets[0].data[tooltipItem.index] * 100) / sum).toFixed(2) + '%';
              return labelarray[tooltipItem.index] + ': ' + percentage;
            },
            color: '#000',
            display: true,
            align: 'bottom',
            backgroundColor: '#ccc',
            borderRadius: 3,
            font: {
              size: 18,
            },
          },
        },

        responsive: true,

        tooltips: {
          enabled: true,
          callbacks: {
            // HERE YOU CUSTOMIZE THE LABELS

            label: function (tooltipItem, data) {
              const labelarray = ['NAMC ARRIVED', 'IN-TRANSIT', 'VANNING COMPLETE', 'DEVANNED'];
              const sum = data.datasets[0].data.reduce((a, b) => a + b, 0);
              const percentage = ((data.datasets[0].data[tooltipItem.index] * 100) / sum).toFixed(2) + '%';
              return labelarray[tooltipItem.index] + ': ' + percentage;
            },
          },
        },
      },
    });
  }

  value1 = '';
  partStatus1;
  rowData1 = [];


  apiCall(partStatus, value) {
    if (value === 0) {
      return
    }
    this.spinner.show();

   
    this.value1 = value;
    this.partStatusData.popupName = partStatus
    this.partStatus1 = partStatus;
    
    this.apiCallsearch(0);
  }

  apiCallsearch(offset) {
    this.spinner.show();
    console.log("offset",offset);

    
    this.partStatusData.offset = offset;
    this.partStatusData.limit = 20000;
    this.partstatusService.getNamcArrivedData(this.partStatusData).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body.data) {
          this.rowData1.push(...res.body.data.res);
          this.dataCount1 = parseInt(res.body.data.rowCount);
          const records = this.dataCount1 - (this.offset + 1) * 20000;
          if (records >= 1) {
            this.offset++;
            this.apiCallsearch(this.offset * 20000);
          } else {
            this.spinner.hide();
            // return this.rowData1 ;
            this.offset=0;
            this.dataCount1=0;
            this.loadselectedPopupData = this.rowData1;
            this.openPartStatusDialog(this.partStatus1);

          }
        } else {
          this.rowData1 = [];
          this.loadselectedPopupData=[];
          this.value1='';
          this.spinner.hide();
        }

      },
    });
  }




  openPartStatusDialog(partStatus) {
   

    let data = {
      partStatus: partStatus,
      selectedPopupData: this.loadselectedPopupData,
    };

   

    if (data.selectedPopupData.length > 0) {
     
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-component';
      dialogConfig.height = '550px';
      dialogConfig.width = '1200px';

      dialogConfig.data = data;
      const dialogRef = this.dialog.open(PartbystatusDateviewComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          this.rowData1 = [];
          this.loadselectedPopupData=[];
          this.offset = 0;

          return;
        }
      });
    }
    // if (data.partStatus === 'inTransmit') {
    //   console.log('inside dialog transmit');
    //   const dialogConfig = new MatDialogConfig();
    //   dialogConfig.disableClose = true;
    //   dialogConfig.id = 'modal-component';
    //   dialogConfig.height = '550px';
    //   dialogConfig.width = '1200px';

    //   dialogConfig.data = data;
    //   const dialogRef = this.dialog.open(PartbystatusDateviewComponent, dialogConfig);
    //   dialogRef.afterClosed().subscribe((res) => {
    //     if (res === 'cancel') {
    //       return;
    //     }
    //   });
    // }
  }

  //part by status API calls

  // loadpartStatusCount(){
  //   this.spinner.show()
  //   console.log('hi................')

  //   const data = {
  //     business_entity: this.namcValue,
  //     workid: this.user,
  //     user_role: this.userRole,
  //   };

  //   this.partstatusService.getStatusCount(data).subscribe((success) => {
  //     console.log(success,'res')
  //   });
  // }

  loadpartStatus() {
    this.spinner.show();

    
    if (this.selectedPart || this.selectedKanban) {
      this.disableContainer = false;
    } else {
      this.disableContainer = true;
    }

    //if user select All when we have more than one container in the dropdown
    let container = [];
    if(this.selectedContainer !== null && this.selectedContainer){
    if (this.selectedContainer['cc']) {
  

      container.push(this.selectedContainer['cc']);
    }
    }

  

    // this.partStatusData = {
    //   business_entity: this.namcValue,
    //   workid: this.user,
    //   user_role: this.userRole,
    //   part_number: this.selectedPart ? this.selectedPart['part_number'] : 'ALL',
    //   container_renban: container.length !== 0 ? container : ['ALL'],
    //   kanban: this.selectedKanban ? this.selectedKanban['kanban'] : 'ALL',
    // };
    // if (this.partStatusData.part_number === 'ALL') {
    //   this.partStatusData.part_number = 'ALL';
    // }
    // if (this.partStatusData.kanban === 'ALL') {
    //   this.partStatusData.part_number = 'ALL';
    // }
    // if (this.partStatusData.container_renban[0] === 'ALL') {
    //   console.log(this.containerList, 'hi');
    //   console.log(this.containerList.length > 0);
    //   if (this.containerList.length > 1) {
    //     //remove all
    //    this.containerList.shift();
    //     // get only values

    //     let k = this.containerList.map((x) => Object.values(x)[0]);

    //     this.partStatusData.container_renban = k;
    //   } 
    // }
  


    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,

    }

    const partSubscriptions = [
      this.pipeLookupService.getPartList(data),
      this.pipeLookupService.getContList(data),
      this.pipeLookupService.getKanbanList(data),
    ];

    forkJoin(partSubscriptions).subscribe({
      next: (res) => {


        this.partsList = [];
        this.containerList = [];
        this.kanbanList = [];

        if (res[0].body.data) {
          this.partsList.push({ part_number: 'ALL' });
        }

        if (res[1].body.data) {
          this.containerList.push({ cc: 'ALL' });
        }
        if (res[2].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.partsList.push(...res[0].body.data);

        this.containerList.push(...res[1].body.data);
        this.kanbanList.push(...res[2].body.data);
      },

      error: this.errorCallback,
    });
  }

  partNo() {
   
    if (this.selectedPart) {
      this.disableKanban = true;
      this.disableContainer = false;
    } else {
      this.disableKanban = false;
    }

  
    // let container = [];

    // container.push(this.selectedContainer['cc']);

    // console.log(this.selectedContainer['cc'], this.selectedContainer, container, 'container');


    // //this.loadpartStatus();
    // this.partStatusData = {
    //   business_entity: this.namcValue,
    //   workid: this.user,
    //   user_role: this.userRole,
    //   part_number: this.selectedPart ? this.selectedPart['part_number'] : 'ALL',
    //   container_renban: container.length > 0 ? container : ['ALL'],
    //   kanban: this.selectedKanban ? this.selectedKanban['kanban'] : 'ALL',
    // };

    this.containerListApi();
  }
  containerChange() {
   

    let container = [];

    container.push(this.selectedContainer['cc']);

  
    // this.partStatusData = {
    //   business_entity: this.namcValue,
    //   workid: this.user,
    //   user_role: this.userRole,
    //   part_number: this.selectedPart ? this.selectedPart['part_number'] : 'ALL',
    //   container_renban: container.length > 0 ? container : ['ALL'],
    //   kanban: this.selectedKanban ? this.selectedKanban['kanban'] : 'ALL',
    // };
    // this.containerListApi();
  }
  kanbanChange() {
  
    if (this.selectedKanban) {
      this.disablePartNumber = true;
      this.disableContainer = false;
    } else {
      this.disablePartNumber = false;
    }




    //this.loadpartStatus();
    // this.partStatusData = {
    //   business_entity: this.namcValue,
    //   workid: this.user,
    //   user_role: this.userRole,
    //   part_number: this.selectedPart ? this.selectedPart['part_number'] : 'ALL',
    //   container_renban: container.length > 0 ? container : ['ALL'],
    //   kanban: this.selectedKanban ? this.selectedKanban['kanban'] : 'ALL',
    // };
    this.containerListApi();
  }

  removeMessage() {
    this.warningMessage = '';
  }

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = '';
    this.selectedPart = '';
    this.selectedContainer = [];
    this.disableContainer = false;
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.containerList = []
    // this.containerList.push({ cc: 'ALL' })
    this.loadpartStatus();
    this.statusCount();

   



  }
  onSearch() {

    this.spinner.show()
    this.removeMessage();

    let container = [];
    if(this.selectedContainer !== null && this.selectedContainer){
    if (this.selectedContainer['cc']) {
    


      container.push(this.selectedContainer['cc']);
    }
    }

    // this.partStatusData = {
    //   business_entity: this.namcValue,
    //   workid: this.user,
    //   user_role: this.userRole,
    //   part_number: this.selectedPart ? this.selectedPart['part_number'] : 'ALL',
    //   container_renban: container.length > 0 ? container : ['ALL'],
    //   kanban: this.selectedKanban ? this.selectedKanban['kanban'] : 'ALL',
    // };
    this.statusCount();
  }

  // loadStatusCount(){
  //   this.partstatusService.getStatusCount(this.partStatusData),

  //   this.partstatusService.getStatusCount().subscribe({
  //     error: this.errorCallback,
  //     next: (res) => {
  //       this.easternDate = res.body.data;
  //     },
  //   });

  // }

  containerListApi() {
    this.spinner.show();


    const data: any = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    }


    //this.selectedPart ? this.selectedPart['part_number']
    if (this.selectedPart['part_number'] != 'ALL') {

      data.part_number = this.selectedPart['part_number']
    }
    if (this.selectedKanban['kanban'] != 'ALL') {

      data.kanban = this.selectedKanban['kanban']

    }

    this.pipeLookupService.getContList(data).subscribe({
      error: this.errorCallback,
      next: (success) => {
        this.spinner.hide();
        this.containerList = [];
        if (success.body.data) {
          this.containerList.push({ cc: 'ALL' });
        }

        this.containerList.push(...success.body.data);

      },
    });
  }

  statusCount() {
   

    let container = [];
    if(this.selectedContainer !== null && this.selectedContainer){
    if (this.selectedContainer['cc']) {
   

      container.push(this.selectedContainer['cc']);
    }
  }
    this.partStatusData = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      // part_number: this.selectedPart ? this.selectedPart['part_number'] : 'ALL',
      // container_renban: container.length > 0 ? container : ['ALL'],
      // kanban: this.selectedKanban ? this.selectedKanban['kanban'] : 'ALL',
    };
    if (this.selectedPart['part_number'] != 'ALL' && this.selectedPart['part_number']) {
     

      this.partStatusData.part_number = this.selectedPart['part_number']

    }
    if (this.selectedKanban['kanban'] != 'ALL' && this.selectedKanban['kanban']) {
      this.partStatusData.kanban = this.selectedKanban['kanban']
    }
    
    if(this.selectedContainer !== null && this.selectedContainer){
    if (this.selectedContainer['cc'] != 'ALL' && this.selectedContainer['cc']) {
  
      this.partStatusData.container_renban = container

    }




   
    if (this.selectedContainer['cc']=='ALL') {
    
     

 
       
        if (this.containerList.length > 1) {

          let containerList1 = this.containerList




          let k = containerList1.map((x) => Object.values(x)[0]);

          //remove all
          k.splice(0, 1);

          this.partStatusData.container_renban = k;
        }
      
    }
  }
    
    this.partstatusService.getStatusCount(this.partStatusData).subscribe({
      error: this.errorCallback,
      next: (success) => {
        this.spinner.hide();

        if (success.body.data) {
          this.namcArrived = success.body.data.NamcarrivedCount[0].count
            ? Number(success.body.data.NamcarrivedCount[0].count)
            : 0;
          this.inTransit = success.body.data.INTransitCount[0].count
            ? Number(success.body.data.INTransitCount[0].count)
            : 0;
          this.vanningComplete = success.body.data.VancompleteCount[0].count
            ? Number(success.body.data.VancompleteCount[0].count)
            : 0;
          this.devanned = success.body.data.DevannedCount[0].count
            ? Number(success.body.data.DevannedCount[0].count)
            : 0;
        }

        this.chartStatus.data.datasets[0].data = [
          this.namcArrived,
          this.inTransit,
          this.vanningComplete,
          this.devanned,
        ];

        this.chartStatus.update();
      },
    });
  }
}
