import { Component, OnInit } from '@angular/core';
import { AgRendererComponent } from 'ag-grid-angular';
import { IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: [],
})
export class DownloadComponent implements AgRendererComponent {
  private params: any;
  ccsDownload: boolean = false;

  agInit(params: any): void {
    this.params = params;
    if (params.data.batch_name === 'CCS Input File') {
      this.ccsDownload = true;
    }
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  refresh(params: any): boolean {
    params.data.amount++;
    params.data.cbox = params.value;

    params.api.refreshCells(params);

    return false;
  }
  timeLeft: number = 5;
  interval;
  downloadFile(type) {
    console.log(type);
    this.params.context.componentParent.getdownloadTemplate(
      this.params.data,
      type
    );
  }
}
