import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ExcelService } from '../services/excel.service';
import { OspService } from '../core/master-services/osp.service';
import * as moment from 'moment';
import { OSPStateService } from '../services/osp-state.service';
import { forkJoin, Subscription } from 'rxjs';
import { FluctuationAllowanceService } from '../services/fluctuation-allowance/fluctuation-allowance.service';
import { parseAdjustmentType } from '../shared/adjustment-type-parser';
import { LookupService } from '../services/lookup/lookup.service';
import {
  dateFilterComparator,
  resetSortingAndFilters,
  validateVanningDates,
  warningMessage,
} from '../constants';
import { logErrors } from '../shared/logger';
import * as mm from 'moment-timezone';

@Component({
  selector: 'app-adjustment-inquiry',
  templateUrl: './adjustment-inquiry.component.html',
  styleUrls: ['./adjustment-inquiry.component.scss'],
})
export class AdjustmentInquiryComponent implements OnInit, OnDestroy {
  namcValue = localStorage.getItem('namcvalue');
  count = 0;
  rowData = [];
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefsMaster;
  columnDefs;
  // columnDefsTemp;
  public defaultColDef;
  rowSelection;
  user: string;
  userRole: any;
  warningMessage = '';
  offset: number = 0;
  dataCount: number = 0;

  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  onInitVanningFrom: any;
  onInitVanningTo: any;
  headerCheckboxChecked: boolean = false;
  partDesc = 'Part Description';

  editEnabled = false;
  freezeStatus = false;
  filter = false;
  showToggleAll = false;
  showClear = true;
  disablePartNumber: boolean;
  disableKanban: boolean;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedDestCode: any = [];
  selectedContainer: any = [];
  selectedSupplier: any = [];
  selectedDock: any = [];
  selectedSpecialist: any = [];
  selectedLifecycle: any = [];
  adjList: any = [];
  selectedAdjlist: any = [];

  vanningFrom: any;
  vanningTo: any;
  adjustSubscription$: Subscription;

  // Dropdown Options
  kanbanList: any = [];
  partNoList: any = [];
  destCodeList: any = [];
  containerList: any = [];
  supplierList: any = [];
  dockList: any = [];
  specialistList: any = [];
  lifecycleList: any = [];
  rowCount = 0;
  count1 = 0;
  isEditMode = false;

  minVanningFrom;
  showHideList: any = [
    {
      label: 'Part No',
      value: 'item_id',
    },
    {
      label: this.partDesc,
      value: 'part_description',
    },
    {
      label: 'Kanban',
      value: 'kanban',
    },
    {
      label: 'QPC (Quantity Per Container, Order Lot)',
      value: 'order_lot',
    },

    {
      label: 'Dock',
      value: 'dock',
    },
    {
      label: 'SPC (Specialist)',
      value: 'EMPLOYEE',
    },
    {
      label: 'CC (Container Code)',
      value: 'cont_code',
    },
    {
      label: 'Adj DT',
      value: 'adj_applied_date',
    },
    {
      label: 'Van DT',
      value: 'vanning_date',
    },
    {
      label: 'Adj Type',
      value: 'adj_type',
    },
    {
      label: 'Adj Qty',
      value: 'adj_qty',
    },
    // {
    //   label: 'Adj Code',
    //   value: 'adj_code',
    // },
    {
      label: 'Adj Reason',
      value: 'adj_reason',
    },
    {
      label: 'Updated DT',
      value: 'date_time',
    },

    {
      label: 'User',
      value: 'userid',
    },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;

  easternDate: any;
  defaultPageSize: any = 100000;
  kanbanSearchValue: any;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly OspService: OspService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    public dialog: MatDialog,
    private readonly stateService: OSPStateService,
    private readonly flalService: FluctuationAllowanceService,
    private readonly lookupService: LookupService
  ) {
    this.context = {
      componentParent: this,
    };
  }
  numberComparator(number1, number2) {
    return number1 - number2;
  }

  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result =
        yearNumber * 10000 +
        monthNumber * 100 +
        dayNumber +
        hoursNumber * 60 +
        minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    // To be used inside filter params when using date filter

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  onPageSizeChanged() {
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(
        Number(this.paginationPageSize)
      );
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  ngOnDestroy(): void {
    this.adjustSubscription$.unsubscribe();
  }

  ngOnInit(): void {
    this.columnDefsMaster = [
      {
        headerName: '',
        field: 'rowCheckBox',
        // suppressSorting: true,
        width: 44,
        headerComponentPa44rams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
      },

      {
        headerName: 'Part No',

        field: 'item_id',
        sortable: true,
        width: 120,
        floatingFilter: true,
      },
      {
        headerName: this.partDesc,
        field: 'part_description',
        headerTooltip: this.partDesc,
        width: 176,
        floatingFilter: true,
        supressMenu: true,
        tooltipField: 'part_description',
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        headerTooltip: 'Kanban',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'QPC',
        headerTooltip: 'Quantity Per Container, Order Lot',
        field: 'order_lot',

        sortable: true,
        width: 105,
        floatingFilter: true,
        filter: 'agNumberColumnFilter',
        //cellClass: this.agRightAlignedCell,
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
      },

      {
        headerName: 'Dock',
        field: 'dock',
        width: 120,
        floatingFilter: true,
        tooltipField: 'dock',
      },
      {
        headerName: 'SPC',
        field: 'EMPLOYEE',
        width: 120,
        floatingFilter: true,
        headerTooltip: 'Specialist',
        tooltipField: 'EMPLOYEE',
      },
      {
        headerName: 'CC',
        field: 'cont_code',
        width: 80,
        floatingFilter: true,
        headerTooltip: 'Container Code',
      },
      {
        headerName: 'Adj DT',
        field: 'adj_applied_date',
        width: 110,
        headerTooltip: 'Adj Date(mm/dd/yyyy)',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },
      {
        headerName: 'Van DT',
        field: 'vanning_date',
        width: 110,
        headerTooltip: 'Vanning Date(mm/dd/yyyy)',
        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,
        comparator: this.dateComparator,
      },

      {
        headerName: 'Adj Type',
        field: 'adj_type',
        width: 85,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',
      },
      {
        headerName: 'Adj Qty',
        field: 'adj_qty',
        width: 105,
        floatingFilter: true,
        headerClass: 'ag-theme-custom-text-right',

        filter: 'agNumberColumnFilter',
        floatingFilterComponentParams: { suppressFilterButton: false },
        filterParams: {
          numberParser: (text) => {
            return text === null ? null : parseFloat(text.replace(',', '.'));
          },
        },
        valueGetter: (params) => parseInt(params.data.adj_qty),

        sortable: true,
        comparator: this.numberComparator,
      },

      {
        headerName: 'Adj Reason',
        field: 'adj_reason',
        width: 160,
        floatingFilter: true,
        tooltipField: 'adj_reason',
      },
      {
        headerName: 'Updated DT',
        headerTooltip: 'Updated Date(mm/dd/yyyy hh:mm:ss (Eastern))',
        field: 'date_time',
        width: 152,
        floatingFilter: true,
      },
      {
        headerName: 'User',
        field: 'userid',
        width: 164,
        floatingFilter: true,
        tooltipField: 'userid',
      },
    ];

    this.columnDefs = [...this.columnDefsMaster];

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
    this.adjustSubscription$ = this.stateService
      .getNamc()
      .subscribe((observable) => {
        this.initializeData();
      });
  }

  setVanningDates(): void {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    this.flalService.getMaxDate(data).subscribe((success) => {
      if (!success.body.data.minVanDate[0].min) {
        this.spinner.hide();
        return;
      }

      const minVan = new Date(success.body.data.minVanDate[0].min);
      const inTenDays = new Date(success.body.data.minVanDateTo[0].min);

      this.vanningFrom = moment(minVan).format('YYYY-MM-DD');
      this.onInitVanningFrom = this.vanningFrom;

      // this.vanningTo = moment(inTenDays).format('YYYY-MM-DD');
      // this.onInitVanningTo = this.vanningTo;
      this.spinner.hide();
    });
  }

  initializeData() {
    this.spinner.show();
    this.rowData = [];
    this.spinner.show();

    this.namcValue = localStorage.getItem('namcvalue');

    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    this.loadDrop();
    this.onResetDropDown();

    this.setVanningDates();
    this.lookupService.getEasternDate().subscribe({
      error: this.errorCallback,
      next: (res) => {
        this.easternDate = res.body.data;
      },
    });
  }
  checkIfAnySelected() {
    if (this.kanbanSearchValue) return true;

    if (
      // this.selectedKanban.length > 0 ||
      // this.selectedPartNo.length > 0 ||
      // this.selectedDestCode.length > 0 ||
      // this.selectedContainer.length > 0 ||
      // this.selectedSupplier.length > 0 ||
      // this.selectedDock.length > 0 ||
      // this.selectedSpecialist.length > 0 ||
      // this.selectedAdjlist.length > 0 ||
      // (this.vanningFrom &&
      // this.vanningTo)
      this.vanningFrom
    ) {
      return true;
    }

    return false;
  }

  clearKanbanValue() {
    this.kanbanSearchValue = null;
  }

  handleKanbanSearch(event) {
    this.kanbanSearchValue = this.kanbanSearchValue.trim();
    const value = this.kanbanSearchValue;

    this.rowData = [];

    const searchedKanban = this.kanbanList.find(
      (kanban) => kanban.kanban === value
    );

    if (!searchedKanban || searchedKanban.kanban === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidKanbanSearch;
      return;
    }

    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedLifecycle = [];

    this.selectedKanban = [searchedKanban];
    this.vanningFrom = undefined;
    this.vanningTo = undefined;

    this.disablePartNumber = true;

    this.onSearch(0);
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onSearch(offset) {
    //this.refreshView()
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      const { valid, error } = validateVanningDates({
        vanningFrom: this.vanningFrom,
        vanningTo: this.vanningTo,
      });

      if (!valid) {
        this.warningMessage = error;
        window.scroll(0, 0);

        this.spinner.hide();

        return;
      }

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectVanFrom;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let kanban;
    let selectedPartNo;
    let selectedDestCode;
    let selectedContainer;
    let selectedSupplier;
    let selectedDock;
    let selectedSpecialist;
    let selecteAdjlist;
    let selectedAdjlist;

    if (this.selectedKanban.length > 0) {
      if (!this.selectedKanban.some((a) => a.kanban === 'ALL')) {
        kanban = [];
        this.selectedKanban.forEach((element) => {
          kanban.push(element.kanban);
        });
      }
    }

    if (this.selectedPartNo.length > 0) {
      if (!this.selectedPartNo.some((a) => a.item_id === 'ALL')) {
        selectedPartNo = [];
        this.selectedPartNo.forEach((element) => {
          selectedPartNo.push(element.item_id);
        });
      }
    }

    if (this.selectedDestCode.length > 0) {
      if (!this.selectedDestCode.some((a) => a.dest_code === 'ALL')) {
        selectedDestCode = [];
        this.selectedDestCode.forEach((element) => {
          selectedDestCode.push(element.dest_code);
        });
      }
    }

    if (this.selectedContainer.length > 0) {
      if (!this.selectedContainer.some((a) => a.cont_code === 'ALL')) {
        selectedContainer = [];
        this.selectedContainer.forEach((element) => {
          selectedContainer.push(element.cont_code);
        });
      }
    }

    if (this.selectedSupplier.length > 0) {
      if (!this.selectedSupplier.some((a) => a.customer_supp === 'ALL')) {
        selectedSupplier = [];
        this.selectedSupplier.forEach((element) => {
          selectedSupplier.push(element.customer_supp);
        });
      }
    }

    if (this.selectedDock.length > 0) {
      if (!this.selectedDock.some((a) => a.dock === 'ALL')) {
        selectedDock = [];
        this.selectedDock.forEach((element) => {
          selectedDock.push(element.dock);
        });
      }

      // selectedDock = [];
      // this.selectedDock.forEach((element) => {
      //   selectedDock.push(element.dock);
      // });
    }

    if (this.selectedSpecialist.length > 0) {
      if (!this.selectedSpecialist.some((a) => a.EMPLOYEE === 'ALL')) {
        selectedSpecialist = [];
        this.selectedSpecialist.forEach((element) => {
          selectedSpecialist.push(element.EMPLOYEE);
        });
      }
      // selectedSpecialist = [];
      // this.selectedSpecialist.forEach((element) => {
      //   selectedSpecialist.push(element.EMPLOYEE);
      // });
    }

    if (this.selectedAdjlist.length > 0) {
      if (!this.selectedAdjlist.some((a) => a.adj_type === 'ALL')) {
        selectedAdjlist = [];
        this.selectedAdjlist.forEach((element) => {
          selectedAdjlist.push(element.adj_type);
        });
      }
      // selecteAdjlist = [];
      // this.selectedAdjlist.forEach((element) => {
      //   selecteAdjlist.push(element.adj_type);
      // });
    }

    const data = {
      offset: offset,
      limit: 12000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      kanban: kanban,
      item_id: selectedPartNo,
      dest_code: selectedDestCode,
      cont_code: selectedContainer,
      customer_supp: selectedSupplier,
      dock: selectedDock,
      specialist: selectedSpecialist,
      adj_type: selectedAdjlist,
      vanning_to: this.vanningTo,
      vanning_from: this.vanningFrom,
      currenteasternDate: this.easternDate,
    };

    this.OspService.getAdjustmentGrid(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.adjustmentResponse) {
          this.rowData.push(...res.body.adjustmentResponse.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.adjustmentResponse.rowCount);
          const records = this.dataCount - (this.offset + 1) * 12000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 12000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.rowData = [];
          this.spinner.hide();
        }

        this.setPageSizeToAll();
      },
    });
    return true;
  }

  loadDrop() {
    this.spinner.show();

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.lookupService.getKanban(),
      this.lookupService.getPartNumber(),
      this.lookupService.getDestCode(),
      this.lookupService.getSupplier(),
      this.lookupService.getContainer(),
      this.lookupService.getSpecialist(),
      this.lookupService.getDock(),
      this.lookupService.getAdjtype(),
      this.flalService.getMaxDate(data),
      this.lookupService.getUserShowHideColumns(),
    ];
    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        //if kanbanList, add ALL option
        this.kanbanList = [];
        this.partNoList = [];
        this.destCodeList = [];
        this.containerList = [];
        this.supplierList = [];
        this.dockList = [];
        this.adjList = [];
        this.specialistList = [];
        if (res[0].body.data) {
          this.kanbanList.push({ kanban: 'ALL' });
        }

        this.kanbanList.push(...res[0].body.data);

        //if item id list, add ALL option
        if (res[1].body.data) {
          this.partNoList.push({ item_id: 'ALL' });
        }
        if (res[2].body.data) {
          this.destCodeList.push({ dest_code: 'ALL' });
        }
        if (res[4].body.data) {
          this.containerList.push({ cont_code: 'ALL' });
        }
        if (res[3].body.data) {
          this.supplierList.push({ customer_supp: 'ALL' });
        }
        if (res[6].body.data) {
          this.dockList.push({ dock: 'ALL' });
        }
        if (res[7].body.data) {
          this.adjList.push({ adj_type: 'ALL' });
        }
        if (res[5].body.data) {
          this.specialistList.push({ EMPLOYEE: 'ALL' });
        }

        this.partNoList.push(...res[1].body.data);
        this.destCodeList.push(...res[2].body.data);
        this.containerList.push(...res[4].body.data);
        this.supplierList.push(...res[3].body.data);

        this.specialistList.push(...res[5].body.data);
        this.dockList.push(...res[6].body.data);
        this.adjList.push(...res[7].body.data);

        this.adjList = parseAdjustmentType(this.adjList);

        // set vanning dates in UI dropdowns
        const vanningDatesData = res[8].body.data.minVanDate[0].vanning_date;

        if (!vanningDatesData) {
          this.spinner.hide();
          return;
        }

        this.vanningFrom = res[8].body.data.minVanDate[0].vanning_date;
        // this.vanningTo = res[8].body.data.minVanDateTo[0].vanning_date;

        this.onInitVanningFrom = this.vanningFrom;

        const { adjs_columns } = res[9].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(adjs_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) =>
                !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        // this.onInitVanningTo = this.vanningTo;
        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();

    this.warningMessage = 'Something went wrong! Please contact support team.';
    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedPartNo = [];
    this.selectedDestCode = [];
    this.selectedContainer = [];
    this.selectedSupplier = [];
    this.selectedDock = [];
    this.selectedSpecialist = [];
    this.selectedAdjlist = [];
    this.selectedLifecycle = [];
    this.vanningFrom = this.onInitVanningFrom;
    this.vanningTo = this.onInitVanningTo;

    this.kanbanSearchValue = null;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }
  // toogleHeaderCheckBox(checked) {
  //   if (checked) {
  //     const rowCount = this.osPartsGrid.gridOptions.rowData.length;
  //     const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter(
  //       (x) => x.rowCheckBox === true
  //     ).length;
  //     if (rowCount === selectedRowCount) {
  //       this.osPartsGrid.gridOptions.columnDefs[0].headerComponentFramework.checked =
  //         checked;
  //       this.osPartsGrid.gridOptions.api.refreshHeader();
  //     }
  //   } else {
  //     this.osPartsGrid.gridOptions.columnDefs[0].headerComponentFramework.checked =
  //       checked;
  //     this.osPartsGrid.gridOptions.api.refreshHeader();
  //   }
  // }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter(
      (x) => x.rowCheckBox === true
    ).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  userCheckChanged(checked, rowIndex) {
    this.spinner.show();
    const rowNode =
      this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);
    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }
   adjs_columns
  onShowHideChange(ev: any) {
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible(
      [...this.showHideList.map((e) => e.value)],
      true
    );

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible(
      [...hideItems.map((e) => e.value)],
      false
    );

    if (ev.onInit) return;

    this.adjs_columns = hideItems;
    if (!hideItems.length) this.adjs_columns = [{}];

    
  }

  saveShowHide(){
    this.spinner.show();

    let adjs_columns = this.adjs_columns;
if(adjs_columns){
    this.lookupService
      .updateUserShowHideColumns({ adjs_columns })
      .subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
         this.spinner.hide();

        },
      });}
    else{
      this.spinner.hide()
    }
    }

  onKanbanChange(): void {
    if (this.selectedKanban.length >= 1) {
      if (
        this.selectedKanban[this.selectedKanban.length - 1].kanban === 'ALL'
      ) {
        this.selectedKanban = [];
        this.selectedKanban.push(this.kanbanList[0]);
      } else {
        let indexAll = this.selectedKanban.findIndex(
          (data) => data.kanban === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedKanban.splice(indexAll, 1);
        }
      }
    }
    if (this.selectedKanban.length > 0) {
      this.disablePartNumber = true;
    } else {
      this.disablePartNumber = false;
    }
  }
  onPartNoChange(event): void {
    if (this.selectedPartNo.length >= 1) {
      if (
        this.selectedPartNo[this.selectedPartNo.length - 1].item_id === 'ALL'
      ) {
        this.selectedPartNo = [];
        this.selectedPartNo.push(this.partNoList[0]);
      } else {
        let indexAll = this.selectedPartNo.findIndex(
          (data) => data.item_id === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedPartNo.splice(indexAll, 1);
        }
      }
    }

    if (this.selectedPartNo.length > 0) {
      this.disableKanban = true;
    } else {
      this.disableKanban = false;
    }
  }

  onDestCodeChange(): void {
    if (this.selectedDestCode.length >= 1) {
      if (
        this.selectedDestCode[this.selectedDestCode.length - 1].dest_code ===
        'ALL'
      ) {
        this.selectedDestCode = [];
        this.selectedDestCode.push(this.destCodeList[0]);
      } else {
        let indexAll = this.selectedDestCode.findIndex(
          (data) => data.dest_code === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedDestCode.splice(indexAll, 1);
        }
      }
    }
  }
  containerCodeChange(): void {
    if (this.selectedContainer.length >= 1) {
      if (
        this.selectedContainer[this.selectedContainer.length - 1].cont_code ===
        'ALL'
      ) {
        this.selectedContainer = [];
        this.selectedContainer.push(this.containerList[0]);
      } else {
        let indexAll = this.selectedContainer.findIndex(
          (data) => data.cont_code === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedContainer.splice(indexAll, 1);
        }
      }
    }
  }

  supplierChange(): void {
    if (this.selectedSupplier.length >= 1) {
      if (
        this.selectedSupplier[this.selectedSupplier.length - 1]
          .customer_supp === 'ALL'
      ) {
        this.selectedSupplier = [];
        this.selectedSupplier.push(this.supplierList[0]);
      } else {
        let indexAll = this.selectedSupplier.findIndex(
          (data) => data.customer_supp === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedSupplier.splice(indexAll, 1);
        }
      }
    }
  }

  dockChange(): void {
    if (this.selectedDock.length >= 1) {
      if (this.selectedDock[this.selectedDock.length - 1].dock === 'ALL') {
        this.selectedDock = [];
        this.selectedDock.push(this.dockList[0]);
      } else {
        let indexAll = this.selectedDock.findIndex(
          (data) => data.dock === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedDock.splice(indexAll, 1);
        }
      }
    }
  }
  adjChange(): void {
    if (this.selectedAdjlist.length >= 1) {
      if (
        this.selectedAdjlist[this.selectedAdjlist.length - 1].adj_type === 'ALL'
      ) {
        this.selectedAdjlist = [];
        this.selectedAdjlist.push(this.adjList[0]);
      } else {
        let indexAll = this.selectedAdjlist.findIndex(
          (data) => data.adj_type === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedAdjlist.splice(indexAll, 1);
        }
      }
    }
  }

  specialistChange(): void {
    if (this.selectedSpecialist.length >= 1) {
      if (
        this.selectedSpecialist[this.selectedSpecialist.length - 1].EMPLOYEE ===
        'ALL'
      ) {
        this.selectedSpecialist = [];
        this.selectedSpecialist.push(this.specialistList[0]);
      } else {
        let indexAll = this.selectedSpecialist.findIndex(
          (data) => data.EMPLOYEE === 'ALL'
        );

        if (indexAll > -1) {
          this.selectedSpecialist.splice(indexAll, 1);
        }
      }
    }
  }

  getModifiedRowData(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }
  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api

    const easternCurrentDate = String(
      mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss')
    );
    console.log(easternCurrentDate);
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            const obj = {
              PartNO: e.item_id,
              partdescription: e.part_description,
              kanban: e.kanban,
              Qpc: e.order_lot,
              dock: e.dock,
              Specialist: e.EMPLOYEE,
              container: e.cont_code,
              adjdt: e.adj_applied_date,
              vandt: e.vanning_date,
              adjtype: e.adj_type,
              adjquantity: parseInt(e.adj_qty),
              adjreason: e.adj_reason,
              updatedt: e.date_time,
              User: e.userid,
            };
            exportData.push(obj);
          });

          const headers = [
            'PART NO',
            'PART DESCRIPTION',
            'KANBAN',
            'QPC',
            'DOCK',
            'SPC',
            'CC',
            'ADJ DT',
            'VAN DT',
            'ADJ TYPE',
            'ADJ QTY',
            'ADJ REASON',
            'UPDATED DT',
            'USER',
          ];

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(
            exportData,
            'AdjustmentInquiry_' + timestamp,

            headers
          );
        }
        this.spinner.hide();
      }, 1000);
    }
  }
  removeMessage() {
    this.warningMessage = '';
  }
}