import { Component, OnInit, Input } from '@angular/core';

import { AgRendererComponent } from 'ag-grid-angular';
import { IAfterGuiAttachedParams, IHeaderGroup } from 'ag-grid-community';

@Component({
  selector: 'app-header-checkbox',
  template: `
    <mat-checkbox
      class="cellcheckbox custom-mat-checkbox"
      [checked]="checked"
      color="warn"
      (change)="headerCheckChanged($event)"
    ></mat-checkbox>
  `,
  styles: [
    `
      .cellcheckbox {
        background: #fff;
        border-radius: 2px;
      }
    `,
  ],
})
export class HeaderCheckboxComponent implements IHeaderGroup {
  @Input() cell: any;

  // @Output() onClicked = new EventEmitter<boolean>();

  private params: any;
  checked: any;
  agInit(params: any): void {
    this.params = params;
    this.checked = params.context.componentParent.headerCheckboxChecked;
  }

  afterGuiAttached(params?: IAfterGuiAttachedParams): void {}

  headerCheckChanged(event) {
    this.checked = event.checked;
    this.params.context.componentParent.headerCheckChanged(event);
  }
}
