export const environment = {
  production: false,
  hostname: 'qa.osparts.toyota.com',

  REDIRECT_URL: 'https://qa.osparts.toyota.com/',

  CLIENT_ID: '44a22c00-69af-4ee5-b0e7-a8997235c0eb',
  TENANT_ID:
    'https://login.microsoftonline.com/9107b728-2166-4e5d-8d13-d1ffdf0351ef',

  ccsApiUrl: 'https://alb.ccsdev.toyota.com/os-parts/',
  ccsApiBasicAuth: 'Basic ' + btoa('CCS:78fa095d-3f4c-48b1-ad50-e24c31d5cf35'),

  fluctuationAllowanceKey: 'zkuoqoHcI382Vi2pfsGbk46vKVlirL0G2zZsFhdP',
  fluctuationAllowanceUrl:
    'https://iidzy2mvkc.execute-api.us-west-2.amazonaws.com/qa/api/v1/fluctuationallowance',

  bulkAdjustmentKey: 'uE5PzfHCMW5qqF8OMsMMf4cQ4t2x5eZG4lnkfnlU',
  bulkAdjustmentUrl:
    'https://8rjfgytv59.execute-api.us-west-2.amazonaws.com/qa/api/v1/bulkadjustment',

  rundownKey: 'liewZXlh1b3PzUH4sgiOM1F3gcv3Xu3D97QLeVPv',
  rundownUrl:
    'https://0mfgtzy4a8.execute-api.us-west-2.amazonaws.com/qa/api/v1/rundown',

  orderForecastKey: 'kpME0caBCm9xSnLLwW66t6BpsGh59MQU305LWCYD',
  orderForecastUrl:
    'https://k2knfle55f.execute-api.us-west-2.amazonaws.com/qa/api/v1/orderforecast',

  lookupKey: 'SuTu4nwZHj509T6JoXd6Q7JixnmO4ZKb3Xf2KSu4',
  lookupUrl:
    'https://dtpd393s65.execute-api.us-west-2.amazonaws.com/qa/api/v1/lookup',

  getsudoKey: 'LrYkI9t9Tu3DSBiKm9y0o6zNmLoX7MVE99Bx1PHT',
  getsudoUrl:
    'https://5x32mdj6m1.execute-api.us-west-2.amazonaws.com/qa/api/v1/getsudo',

  // This is in Setup service lambda with different url
  getsudoDatesUrl:
    'https://tktzpy70pj.execute-api.us-west-2.amazonaws.com/qa/api/v1/getsudo',

  setupKey: 'SuTu4nwZHj509T6JoXd6Q7JixnmO4ZKb3Xf2KSu4',
  setupUrl:
    'https://tktzpy70pj.execute-api.us-west-2.amazonaws.com/qa/api/v1/setup',

  adjustmentEnquiryKey: 'X9HtqDYiCl4n25z5resMs9tPxV9jbK7N9gc23Uyc',
  adjustmentEnquiryUrl:
    'https://krmqc4mm10.execute-api.us-west-2.amazonaws.com/qa/api/v1/adjustment',

  // pipe urls

  ospLookupUrl:'https://obc8rcouz1.execute-api.us-west-2.amazonaws.com/qa/api/v1/lookup',
  ospLookupKey:'Byhg5XOcjC3kq0ttPxwSN5KFH8mpqkbv2jCSDGRb',

partStatusUrl:'https://2e2phzc8qa.execute-api.us-west-2.amazonaws.com/qa/api/v1/getpartstatus',
  partStatusKey:'rlg1d5Zxcu7M1G4nqPsvZ5nV1j7o6qec1AUhiKZB',


  partMaintenance:'https://yzo4810udc.execute-api.us-west-2.amazonaws.com/qa/api/v1/partMaintenance',
  partMaintenanceKey:'saXKQV66AV3j5dhvU8OxS4yHumhMHTsbeTeWPRcg',

  ospSealVerificationUrl:'https://geyayspbl8.execute-api.us-west-2.amazonaws.com/qa/api/v1/sealVerification',
  ospSealVerificationKey:'KLnNqIkUouaAXLlNtr2Po82NsIzfKOHj88klwcHW',
    //PIE lambda urls

    sendToPieData: `https://internalapi.pie.subprod.toyota.com/tnso/update-container-info`


};
