import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { GridOptions } from 'ag-grid-community';
import { GriddataService } from 'src/app/services/griddata.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as mm from 'moment-timezone';
import { ExcelService } from 'src/app/services/excel.service';

@Component({
  selector: 'app-kanpan-dialog',
  templateUrl: './kanpan-dialog.component.html',
  styleUrls: ['./kanpan-dialog.component.scss'],
})
export class KanpanDialogComponent implements OnInit {
  rowData: any;
  @ViewChild('osPartsGrid') osPartsGrid;
  columnDefs;
  columnDefsOverage;
  defaultColDef;
  rowSelection;

  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = true;
  context: any;
  editType;
  suppressClickEdit = true;
  //rightAlligned = 'ag-right-aligned-cell';

  editEnabled: boolean = false;
  freezeStatus: boolean = false;

  adjCodeList: any = [];
  editform: UntypedFormGroup = this.formBuilder.group({
    adjCode: [[], Validators.required],
    adjReason: [''],
  });
  editFormcontrol = this.editform.controls;
  orverageOrShortage;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private readonly formBuilder: UntypedFormBuilder,
    private readonly gridDataService: GriddataService,
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly dialogRef: MatDialogRef<KanpanDialogComponent>
  ) {
    this.orverageOrShortage = data.orverageOrShortage;

    this.columnDefs = [
      {
        headerName: 'Van DT',
        field: 'vanning_date',
        flex: 0.6,
        floatingFilter: true,
        headerTooltip: 'Vanning Date(mm/dd/yyyy)',
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        width: 100,
        floatingFilter: true,
        //cellClass: this.rightAlligned,
      },

      {
        headerName: 'Part No',
        field: 'item_id',
        width: 120,
        floatingFilter: true,
      },
      {
        headerName: 'Part Description',
        field: 'part_description',
        width: 176,
        floatingFilter: true,
        tooltipField:'part_description'
      },
      {
        headerName: 'QPC',
        headerTooltip: 'Quantity Per Container, Order Lot',
        field: 'order_lot',
        sortable: true,
        width: 100,
        floatingFilter: true,
        //cellClass: this.rightAlligned,
      },
      {
        headerName: 'Dock',
        field: 'dock',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'CC',
        field: 'cont_code',
        headerTooltip: 'Container Code',
        sortable: true,
        width: 80,
        floatingFilter: true,
      },
      {
        headerName: 'Shortage(Pcs)',
        field: 'carry_over_qty',
        width: 150,
        headerTooltip: 'Shortage(Pcs)',
        floatingFilter: true,
        //cellClass: this.rightAlligned,
        valueGetter: (params) => {
          if (params.data.carry_over_qty) {
            return Number(params.data.carry_over_qty);
          } else {
            return params.data.carry_over_qty;
          }
        },
      },

      {
        headerName: 'Shortage(Lots)',
        field: 'overagelots',
        width: 150,
        floatingFilter: true,
        //cellClass: this.rightAlligned,
        headerTooltip: 'Shortage(Lots)',
        valueGetter: (params) => {
          if (params.data.overagelots) {
            return Number(params.data.overagelots);
          } else {
            return params.data.overagelots;
          }
        },
      },
    ];
    this.columnDefsOverage = [
      {
        headerName: 'Van DT',
        field: 'vanning_date',
        width: 105,
        floatingFilter: true,
        headerTooltip: 'Vanning Date(mm/dd/yyyy)',
      },
      {
        headerName: 'Kanban',
        field: 'kanban',
        width: 100,
        floatingFilter: true,
        //cellClass: this.rightAlligned,
      },

      {
        headerName: 'Part No',
        field: 'item_id',
        width: 120,
        floatingFilter: true,
      },
      {
        headerName: 'Part Description',
        field: 'part_description',
        width: 176,
        floatingFilter: true,
        tooltipField:'part_description'
      },
      {
        headerName: 'QPC',
        headerTooltip: 'Quantity Per Container, Order Lot',
        field: 'order_lot',
        sortable: true,
        width: 105,
        floatingFilter: true,
        //cellClass: this.rightAlligned,
      },
      {
        headerName: 'Dock',
        field: 'dock',
        sortable: true,
        width: 100,
        floatingFilter: true,
      },
      {
        headerName: 'CC',
        field: 'cont_code',
        headerTooltip: 'Container Code',
        sortable: true,
        width: 80,
        floatingFilter: true,
      },
      {
        headerName: 'Overage(Pcs)',
        field: 'carry_over_qty',
        width: 150,
        floatingFilter: true,
        //cellClass: this.rightAlligned,
        valueGetter: (params) => {
          if (params.data.carry_over_qty) {
            return Number(params.data.carry_over_qty);
          } else {
            return params.data.carry_over_qty;
          }
        },
      },

      {
        headerName: 'Overage(Lots)',
        field: 'overagelots',
        width: 150,
        floatingFilter: true,
        //cellClass: this.rightAlligned,
        valueGetter: (params) => {
          if (params.data.overagelots) {
            return Number(params.data.overagelots);
          } else {
            return params.data.overagelots;
          }
        },
      },
    ];
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.paginationPageSize = 10;
    this.rowData = data.modifiedRowData;
    this.adjCodeList = data.adjCodeList;
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };
  }

  enableSave: boolean = false;

  ngOnInit() {
    var errorCount = this.rowData.filter((x) => x.error !== '').length;

    this.enableSave = errorCount === 0;
  }

  exportAsXLSX(): void {
    console.log('hi')
    this.spinner.show();
    // eastern time api
    let easternCurrentDate = String(
      mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss')
    );
    console.log(easternCurrentDate);
    let data = [];

    // if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    //} 
    // if {
    //   data = this.getModifiedRowData();
    // }

    if (data.length < 1) {
      this.spinner.hide();
     
    } 
    else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            
            const obj:any = {
              VanDT: e.vanning_date,
              kanban: e.kanban,
              Partno: e.item_id,
              partdesc: e.part_description,
              qpc: e.order_lot,
              dock: e.dock,
              containercode: e.cont_code,
              
              
            };
            if(this.orverageOrShortage==='shortage'){ 
              obj.shortagepcs= e.carry_over_qty,
              obj.shortagelots= e.overagelots
            }
            else{
              obj.overagepcs=e.carry_over_qty
              obj.overagelots=e.overagelots
            }
            exportData.push(obj);
          });
          

          const headers = [
            'Van DT',
            'KANBAN',
            'PART NO',
            'PART DESCRIPTION',
            'QPC',
            'DOCK',
            'CC'

            
            
            
          ];
          if(this.orverageOrShortage==='shortage'){ 
            // obj.shortagepcs= e.carry_over_qty,
            // obj.shortagelots= e.overagelots
            headers.push('SHORTAGE(PCS)')
            headers.push('SHORTAGE(LOTS)')

          }
          else{
            headers.push('Overage(PCS)')
            headers.push('OVERAGE(LOTS)')

          }

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(
            exportData,
            this.orverageOrShortage+'_' + timestamp,

            headers
          );
        }
        this.spinner.hide();
      }, 1000);
    }
  }
}
  
   

