<div class="custom-tooltip" *ngIf="isVisiable">
  <div *ngFor="let item of cmdList">
    <div>
      <label class="header">{{ item.user }}</label> <br />
      <label class="datelabel">{{ item.time }}</label>
    </div>
    <div class="content">
      <label *ngFor="let msg of item.changeQty">{{ msg }}</label
      ><br />
      <label>{{ item.selectesCmd }} </label>
    </div>
  </div>
</div>
