import { Component, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { HeaderCheckboxComponent } from '../core/grid/header-checkbox/header-checkbox.component';
import { GridRefreshComponent } from '../core/grid/grid-refresh/grid-refresh.component';
import { RowCheckboxComponent } from '../core/grid/row-checkbox/row-checkbox.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomTooltipComponent } from '../core/custom-tooltip/custom-tooltip.component';
import { GriddataService } from '../services/griddata.service';
import { ExcelService } from '../services/excel.service';

import * as moment from 'moment';
import * as XLSX from 'xlsx';
import * as mm from 'moment-timezone';

import { forkJoin, Subscription } from 'rxjs';

import { checkAccess, checkAccessPipe, dateFilterComparator, resetSortingAndFilters } from '../constants';


import { OSPStateService } from '../services/osp-state.service';
import { GridApi } from 'ag-grid-community';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ConfirmCancelDailogComponent } from '../core/confirm-cancel-dailog/confirm-cancel-dailog.component';
import { warningMessage, successMessage, freezingMesssage } from '../constants';
import { logData, logErrors } from '../shared/logger';
import { AgGridAngular } from 'ag-grid-angular';
import { PipeLookupService } from '../services/pipe-lookup/pipe-lookup.service';
import { ModifiedboltComponent } from './modifiedboltseal-component';
import { SealverificationdialogComponent } from './sealverification-dialog/sealverification-dialog-component';
import { SealVerificationService } from '../services/seal-verification/seal-verification.service';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SealStatusHistoryComponent } from './sealStatusHistory-component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-seal-verification',
  templateUrl: './seal-verification.component.html',
  styleUrls: ['./seal-verification.component.scss'],
})
export class SealVerificationComponent {
  showDownloadUploadButtons: boolean = true;

  namcValue = '01';
  user = '';
  userRole = '';
  rowGroupingDisplayType = 'groupRows';
  groupDefaultExpanded = 1;
  rowData: any = [];
  @ViewChild('faMainGrid') faMainGrid: AgGridAngular;
  columnDefs;

  public defaultColDef;
  rowSelection;

  groupDisplayType = 'groupRows';
  autoGroupColumnDef;
  getDataPath;

  msgNoContent = 'No Content';
  gridOptions;
  paginationPageSize;
  paginationNumberFormatter;
  pagination = 'true';
  context: any;
  editType;
  suppressClickEdit = true;
  tooltipShowDelay = 0;
  frameworkComponents = { customTooltip: CustomTooltipComponent };
  editEnabled: boolean = false;
  freezeStatus: boolean = false;
  showToggleAll: boolean = false;
  showClear: boolean = true;
  @ViewChild('osPartsGrid') osPartsGrid;

  // Dropdowns
  selectedKanban: any = [];
  selectedPartNo: any = [];
  selectedShippingCarrier: any = [];
  selectedVesselName: any = [];
  selectedrenban = [];

  selectedContainer = [];

  successMessage = '';
  warningMessage = '';

  kanbanListSelected = [];
  // Dropdown Options
  shipping_carrierList: any = [];
  vesselNameList: any = [];
  renbanList: any = [];
  kanbanList: any = [];
  rowCount: number = 0;
  isEditMode: boolean = false;
  dataCount: number = 0;
  offset: number = 0;

  kanbanSearchValue: any;
  renbanSearchValue: any;
  vesselSerachValue: any;
  estDate: any;

  disableVesselName: boolean;
  disableShippingCarrier: boolean;
  disableEst: boolean;

  showHideList: any = [
    { value: 'container_renban', label: 'Renban' },
    {
      label: 'Est Arr DT',
      value: 'estimated_namc_arrival',
    },

    { value: 'vessel_company', label: 'Shipping Carrier' },
    { value: 'vessel_name', label: 'Vessel Name' },
    // { value: 'customs_seal_number', label: 'Current Bolt Seal' },
    { value: 'container_number', label: 'Container No' },
    { value: 'bolt_seal', label: 'Bolt Seal' },
    // { value: 'modifiedBolt', label: 'Modified Bolt Seal' },
    { value: 'customs_seal_status', label: 'Seal Status' },
    { value: 'user_verified', label: 'Verified User' },
    { value: 'verification_date', label: 'Verification DT' },
  ];

  selectedShowHideList: any = [...this.showHideList];

  kanbanSelected = 1;
  disablePartNumber: boolean;
  disableKanban: boolean;
  // Dropdowns

  selectedLinePath: any = [];

  // Dropdown Options
  linePathList: any = [];

  page = 0;
  apiDefaultLimit = 8000;
  icons: { groupExpanded: string; groupContracted: string };
  commentsList: any = [];

  uploadError: boolean;
  uploadEnabled: boolean;
  namcName: string;
  files: any[];
  fileBuffer: any;

  exportData: any;


  uploading: boolean = false;

  editApiDefaultLimit = 200;
  timesEditApiCalled: number = 0;
  editApiStartPosition: number = 0;
  editApiEndPosition: number = this.editApiDefaultLimit;
  batchRunLogID: any;
  headerHeight: number;
  loading: any;
  onInitEstdate: any;

  flalSubscription$: Subscription;
  headerCheckboxChecked: boolean = false;
  allowedFilesize: number;
  justFileName: any;
  validating: boolean;
  itContact: string;
  easternDate: any;
  defaultPageSize: any = 100000;
  flalLicenseKeySubscription$: Subscription;
  modalComponent = 'modal-component';
  sealverificationSubscription$: Subscription;
  vesselNameList1: any = [];
  userRole_passtoapi: string;

  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly excelService: ExcelService,
    private readonly gridDataService: GriddataService,
    private readonly pipeLookupService: PipeLookupService,
    private readonly sealVerificationService: SealVerificationService,
    private readonly stateService: OSPStateService,
    private readonly router:Router,


    public readonly dialog: MatDialog
  ) {
    this.context = {
      componentParent: this,
    };
  }
  suppressGroupRowsSticky: boolean = true;

  fileName: string = '';
  access1;
  // DATE COMPARATOR FOR SORTING
  dateComparator(date1, date2) {
    function monthToNum(date) {
      if (date === undefined || date === null) {
        return null;
      }

      const newDate: Date = new Date(date);

      const yearNumber = newDate.getFullYear();
      const monthNumber = newDate.getMonth();
      const dayNumber = newDate.getDate();
      const hoursNumber = newDate.getHours();
      const minutesNumber = newDate.getMinutes();

      const result = yearNumber * 10000 + monthNumber * 100 + dayNumber + hoursNumber * 60 + minutesNumber * 10;
      // 29/08/2004 => 20040829
      return result;
    }

    const date1Number = monthToNum(date1);
    const date2Number = monthToNum(date2);

    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }

    return date1Number - date2Number;
  }

  ngOnDestroy(): void {
    this.sealverificationSubscription$.unsubscribe();
  }
  ngOnInit(): void {

   
    this.columnDefs = [
      {
        headerName: '',
        field: 'rowCheckBox',
        width: 44,
        headerComponentParams: {
          headerChecked: this.headerCheckboxChecked,
        },
        headerComponentFramework: HeaderCheckboxComponent,
        floatingFilterComponentFramework: GridRefreshComponent,
        floatingFilterComponentParams: { suppressFilterButton: true },

        cellClass: 'ui-grid-cell-contents-auto',

        
        cellRendererFramework: RowCheckboxComponent,
        floatingFilter: true,
      //   tooltipValueGetter: (params) => {
      //     if (params.data.customs_seal_number === '' ||
      //     !params.data.customs_seal_number) {
      //       return 'Can not edit as the current bolt seal is empty';
      //     } else {
      //       return '';
      //     }
      // }
    },

      {
        headerName: 'Renban',
        headerTooltip: 'Renban',
        field: 'container_renban',
        sortable: true,
        floatingFilter: true,

        width: 120,
      },
      {
        headerName: 'Est Arr DT',
        headerTooltip: 'Estimated Arrival DT(mm/dd/yyyy)',
        field: 'estimated_namc_arrival',
        sortable: true,
        width: 120,

        filter: 'agDateColumnFilter',
        filterParams: {
          comparator: dateFilterComparator,
        },
        floatingFilter: true,

        comparator: this.dateComparator,
      },

      {
        headerName: 'Shipping Carrier',
        headerTooltip: 'Shipping Carrier',
        field: 'vessel_company',
        sortable: true,
        width: 150,
        floatingFilter: true,
      },

      {
        headerName: 'Vessel Name',
        headerTooltip: 'Vessel Name',
        field: 'vessel_name',
        width: 150,
        floatingFilter: true,
        sortable: true,
        tooltipField: 'Vessel Name',
      },

      // {
      //   headerName: 'Current Bolt Seal',
      //   field: 'customs_seal_number',
      //   headerTooltip: 'Current Bolt Seal',
      //   width: 180,
      //   floatingFilter: true,
      //   sortable: true,

      // },
      {
        headerName: 'Container No',
        field: 'container_number',
        headerTooltip: 'Container Number',
        width: 150,
        floatingFilter: true,
      },
      {
        headerName: 'Bolt Seal',
        headerTooltip: 'Bolt Seal',
        field: 'bolt_seal',
        width: 150,
        floatingFilter: true,
        sortable: true,
      },

      // {
      //   headerName: 'Modified Bolt Seal',
      //   headerTooltip: 'Modified Bolt Seal',
      //   field: 'modifiedBolt',
      //   cellRendererParams: { column: 'customs_seal_number_user_modified' },
      //   width: 140,
      //   floatingFilter: true,
      //   cellRendererFramework: ModifiedboltComponent,
      //   valueGetter: (params) => {
      //     if (!this.editEnabled || !params.data.rowCheckBox) {
      //       return params.data.customs_seal_number_user_modified;
      //     } else {
      //       const value = params.data.modifiedBolt?.value ?? params.data.customs_seal_number_user_modified;

      //       return value;
      //     }
      //   },

      //   sortable: true,
      // },

      {
        headerName: 'Seal Status',
        field: 'customs_seal_status',
        headerTooltip: 'Seal Status',
        cellRendererFramework: SealStatusHistoryComponent,
        cellRendererParams: { column: 'customs_seal_status' },
        tooltipField: 'customs_seal_status',
        // tooltipComponent: 'customTooltip',
        valueGetter: (params) => {
      
          return params.data.customs_seal_status;
        },

        width: 145,
        floatingFilter: true,
      },

      {
        headerName: 'Verified User',
        field: 'user_verified',
        width: 170,
        floatingFilter: true,
        headerTooltip: 'Verified User',
        tooltipField: 'user_verified'
      },
      {
        headerName: 'Verification DT',
        field: 'verification_date',
        width: 170,
        floatingFilter: true,
        headerTooltip: 'Verification Date (mm/dd/yyyy hh:mm:ss (Eastern))',
        tooltipField: 'verification_date',
      },
    ];

    this.headerHeight = 45;
    this.setPageSizeToAll();
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.rowSelection = 'multiple';
    this.editType = 'fullRow';
    this.gridOptions = this.gridDataService.getGridOptions();
    this.gridOptions.onGridReady = function (params) {
      this.gridApi = params.api;
    };

    this.sealverificationSubscription$ = this.stateService.getNamc().subscribe((observable) => {
      this.initializeData();
    });
  }

  gridApi: GridApi;
  onGridReady(params) {
    this.gridApi = params?.api;
  }

  initializeData() {
    this.onResetDropDown();
    this.itContact = localStorage.getItem('itContact');

    this.spinner.show();
    this.rowData = [];

    if (localStorage.getItem('namcvalue')) {
      this.namcValue = localStorage.getItem('namcvalue');
    }
    if (localStorage.getItem('namcName')) {
      this.namcName = localStorage.getItem('namcName');
    }
    if (localStorage.getItem('workdayId')) {
      this.user = localStorage.getItem('workdayId');
    }
    if (localStorage.getItem('UserRoles')) {
      this.userRole = localStorage.getItem('UserRoles');
    }

    const access = checkAccessPipe();
    this.access1 = access.access;
    this.takepiperoles()

    this.loadDrop();
  }
  takepiperoles(){

    let takePipeRoles=this.userRole.split(',')
    let pipeRoles=[]

    takePipeRoles.forEach(element => {
      console.log(element, element.includes('OSPIPE'))

      if(element.includes('OSPIPE'))

      pipeRoles.push(element)

      
    });

    this.userRole_passtoapi=pipeRoles.join(' ')


  }

  getModifiedRowData(): any {
    const modifiedRowData = this.rowData.filter((row) => row.rowCheckBox);
    return modifiedRowData;
  }
  getModifiedRowData2(): any {
    return this.rowData.filter((row) => row.rowCheckBox);
  }

  loadDrop() {
    this.spinner.show();
    console.log(this.estDate, this.selectedShippingCarrier, 'ok');

    // diabling vessel name dropdown initially
    if (this.estDate || this.selectedShippingCarrier?.length > 0) {
      console.log('if');
      this.disableVesselName = false;
    } else {
      console.log('else');
      this.disableVesselName = true;
    }

    console.log(this.disableVesselName, 'vessel');

    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
    };

    const dataShowHide = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    const lookupSubscriptions = [
      this.pipeLookupService.getShippingCarrier(data),
      this.pipeLookupService.getVesselNameList(data),
      this.pipeLookupService.getUserShowHideColumns(dataShowHide),
    ];

    forkJoin(lookupSubscriptions).subscribe({
      next: (res) => {
        this.shipping_carrierList = [];
        this.vesselNameList1 = [];

        if (res[0].body.data) {
          this.shipping_carrierList.push({
            vessel_company: 'ALL',
          });
        }

        this.shipping_carrierList.push(...res[0].body.data);

        if (res[1].body.data) {
          this.vesselNameList1.push({ vessel_name: 'ALL' });
        }

        this.vesselNameList1.push(...res[1].body.data);


        const { seal_verification_columns } = res[2].body.showHideColumnsResponse;

        try {
          const hideItems = JSON.parse(seal_verification_columns);

          if (typeof hideItems === 'object' && hideItems.length > 0) {
            this.selectedShowHideList = this.showHideList.filter(
              (sItem) => !hideItems.map((item) => item.value).includes(sItem.value)
            );

            // logData(hideItems);

            this.onShowHideChange({
              value: this.selectedShowHideList,
              onInit: true,
            });
          }
        } catch (error) {}

        this.spinner.hide();
      },
      error: this.errorCallback,
    });
  }

  errorCallback = (error) => {
    logErrors(error);
    this.spinner.hide();
    this.uploading = false;
    this.validating = false;

    if (error?.error?.message?.required_fields) {
      this.warningMessage = `${warningMessage.requiredFieldsMissing}  ${this.itContact}.`;
    } else {
      this.warningMessage = `${warningMessage.apiLogicFail}  ${this.itContact}.`;
    }

    this.rowData = [];
    this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
  };

  modifiedRowData = [];
  saveChanges() {
    this.removeMessage();
    this.modifiedRowData = this.getModifiedRowData();
    console.log('modifiedData', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }
    var editedRecords = [];
    this.modifiedRowData = this.modifiedRowData.filter((element) => element.boltmodified);
    console.log('editedRecords', this.modifiedRowData);

    if (this.modifiedRowData.length === 0) {
      this.warningMessage = warningMessage.editToReview;

      window.scroll(0, 0);
      return;
    }

    if (this.modifiedRowData.length > 0) {
      this.openReviewDialog(this.modifiedRowData);
    }
  }
  openReviewDialog(editedRecords) {
    var data = {
      modifiedRowData: editedRecords,
    };

    if (data.modifiedRowData.length > 0) {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.height = '204px';
      dialogConfig.width = '470px';
      dialogConfig.data = data;
      const dialogRef = this.dialog.open(SealverificationdialogComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        if (res === 'cancel') {
          return;
        } else if (res === 'save') {
          var updatedData = [];
          data.modifiedRowData.forEach((e) => {
            if (!e.customs_seal_status) {
              e.customs_seal_status = '';
            }
            if (!e.customs_seal_number_user_modified) {
              e.customs_seal_number_user_modified = '';
            }

            var updatedFields = {
              customs_seal_number_user_modified: e.customs_seal_number_user_modified?.toUpperCase(),
              vessel_company: e.vessel_company,
              vessel_name: e.vessel_name,
              container_renban: e.container_renban,
              customs_seal_number: e.customs_seal_number,
              customs_seal_status: e.customs_seal_status,
              userRole:this.userRole_passtoapi,
              userid: localStorage.getItem('UserName'),
              matchMismatch:`Updated Modified Bolt Seal 
              from ${e.customs_seal_number_user_modified_old} to ${e.customs_seal_number_user_modified?.toUpperCase()}.`
            };
            updatedData.push(updatedFields);
          });

          this.spinner.show();
          this.editSealdata(updatedData);
          this.successMessage = 'Success! Bolt Seal has been modified';

        }
      });
    } else {
      this.warningMessage = warningMessage.selectRowsToEdit;

      window.scroll(0, 0);
    }
  }
  updatedRows = [];
  editSealdata(updatedData) {
    var data1 = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      data: updatedData.slice(this.editApiStartPosition, this.editApiEndPosition),
    };

    this.spinner.show();
    this.sealVerificationService.editSealdata(data1).subscribe({
      error: this.errorCallback,
      next: (response) => {
        console.log('response', response);

        this.updatedRows.push(...response.body.sealData.updatedRows);

        if (updatedData.length > this.editApiEndPosition) {
          this.timesEditApiCalled++;

          this.editApiStartPosition = this.timesEditApiCalled * this.editApiDefaultLimit;

          this.editApiEndPosition = this.editApiStartPosition + this.editApiDefaultLimit;
          return this.editSealdata(updatedData);
        }

        this.spinner.hide();

        this.timesEditApiCalled = 0;
        this.editApiStartPosition = 0;
        this.editApiEndPosition = this.editApiDefaultLimit;

        if (this.updatedRows.length > 0) {
          this.rowData = [];
          this.spinner.hide();
          this.rowData = this.updatedRows;
          this.updatedRows = [];

          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
        } else {
          this.updatedRows = [];
          this.warningMessage = `${warningMessage.reportsNotUpdated}  ${this.itContact}.`;
        }

        this.editEnabled = false;
        this.isEditMode = false;
        this.headerCheckboxChecked = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
        this.resetGrid();

        window.scroll(0, 0);
        this.setPageSizeToAll();
        // this.successMessage = 'Success! Bolt Seal has been modified';

        // this.onSearch(0);
      },
    });
  }

  onEditMode() {
    console.log('edit mode');
    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData2();

    if (data.length > 0) {
      this.isEditMode = !this.isEditMode;
      this.editEnabled = this.isEditMode;

      if (this.isEditMode) {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          rowNode.data.checkedEditMode = true;
          if (rowNode.data.rowCheckBox) {
          } else {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      } else {
        this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
          if (rowNode.data.rowCheckBox) {
            rowNode.data.checkedEditMode = false;
          }
          rowNode.setData(rowNode.data);
        });
      }
      setTimeout(() => {
        window.scroll(0, document.body.scrollHeight);
      }, 200);

    } else {
      this.isEditMode = false;
      this.editEnabled = false;
      this.warningMessage = warningMessage.selectRowsToEdit;
      window.scroll(0, 0);
    }
  }

  openDialog1() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.id = this.modalComponent;
    dialogConfig.height = '204px';
    dialogConfig.width = '475px';
    const dialogRef = this.dialog.open(ConfirmCancelDailogComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((res) => {
      if (res === 'cancel') {
        this.columnDefs[7].cellStyle = function (params) {
          if ('modified' in params.data) {
            return { backgroundColor: '#E4ECF0' };
          } else {
            return { backgroundColor: 'white' };
          }
        };

        this.onSearch(0);
        this.editEnabled = false;
        this.isEditMode = false;
        this.osPartsGrid.gridOptions.api.refreshHeader();
      }
    });
  }

  cancelEdit() {
    this.removeMessage();
    this.openDialog1();
  }

  handleVesselSearch(event) {
    this.vesselSerachValue = this.vesselSerachValue.trim();
    const value = this.vesselSerachValue;

    this.rowData = [];

    if(this.vesselNameList.length === 0){
      this.vesselNameList =this.vesselNameList1;
    }

    const searchedVessel = this.vesselNameList.find(
      (vesselName) => vesselName.vessel_name?.toLowerCase() === value.toLowerCase()
    );

    if (!searchedVessel || searchedVessel.vessel_name === 'ALL') {
      this.onResetDropDown();
      this.warningMessage = warningMessage.invalidVesselName;
      return;
    }

    this.selectedVesselName = [];
    this.selectedrenban = [];
    this.selectedShippingCarrier = [];
    this.estDate=null

    this.selectedContainer = [];
    this.selectedVesselName = [searchedVessel];

    this.onSearch(0);
  }

  resetGrid() {
    if (this.osPartsGrid) {
      resetSortingAndFilters(this.osPartsGrid);

      return;
    }
  }

  onResetDropDown() {
    this.removeMessage();
    this.selectedKanban = [];
    this.selectedVesselName = [];
    this.selectedShippingCarrier = [];
    this.selectedrenban = [];
    this.selectedContainer = [];
    this.kanbanSearchValue = null;
    this.renbanSearchValue = null;
    this.vesselSerachValue = null;
    this.estDate = null;
    this.kanbanSearchValue = null;
    this.disableVesselName = true;
    this.disableEst = false;
    this.disableShippingCarrier = false;

    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();
      this.resetGrid();
    }

    this.setPageSizeToAll(this.defaultPageSize);

    this.rowData = [];
    this.disableKanban = false;
    this.disablePartNumber = false;
    this.offset = 0;
  }

  onPageSizeChanged() {
    // this.removeMessage();
    if (this.gridOptions?.api) {
      this.gridOptions.api.paginationSetPageSize(Number(this.paginationPageSize));
    }
  }

  setPageSizeToAll(pageSize?) {
    this.paginationPageSize = this.rowData.length;

    if (pageSize) {
      this.paginationPageSize = 0;
      this.rowData.length = 0;
    }

    this.onPageSizeChanged();
  }

  canDeactivate() {
    return this.editEnabled;
  }

  //Download

  exportAsXLSX(): void {
    this.removeMessage();

    this.spinner.show();

    // eastern time api

    const easternCurrentDate = String(mm().tz('US/Michigan').format('YYYY-MM-DD_HH:mm:ss'));
    let data = [];

    if (this.headerCheckboxChecked) {
      this.osPartsGrid.gridOptions.api.forEachNodeAfterFilter((node) => {
        data.push(node.data);
      });
    } else {
      data = this.getModifiedRowData2();
    }

    if (data.length < 1) {
      this.spinner.hide();
      this.warningMessage = warningMessage.noRowsSelected;

      window.scroll(0, 0);
    } else {
      setTimeout(async () => {
        if (this.rowData.length > 0) {
          const exportData: any = [];

          data.forEach((e) => {
            if (e.old_customs_seal_status) {
              e.old_customs_seal_status = e.old_customs_seal_status.replaceAll('$', ' ');
            }


            const obj = {
              container_renban: e.container_renban,
              estimated_namc_arrival: e.estimated_namc_arrival,
              vessel_company: e.vessel_company,
              vessel_name: e.vessel_name,
              container_number: e.container_number,
              bolt_seal:'',
              old_customs_seal_status: e.old_customs_seal_status?.replaceAll('#', ''),
              user_verified: e.user_verified,
              verification_date: e.verification_date,
            };
            exportData.push(obj);
          });

          const headers = [
            'Renban',
            'Est Arr DT',
            'Shipping Carrier',
            'Vessel Name',
            'Container No',
            'Bolt Seal',
            'Seal Status',
            'Verified User',
            'Verification DT',
          ];

          const timestamp = easternCurrentDate

            .split('.')[0]
            .replace('T', '_')
            .split('')

            .filter((d) => d !== '-' && d !== ':')
            .join('');

          this.excelService.exportAsExcelFile(
            exportData,
            'Seal Verification' + timestamp,

            headers
          );
        }
        this.spinner.hide();
      }, 1000);
    }
  }

  removeMessage() {
    this.warningMessage = '';
    this.successMessage = '';
   
  }

  // call show/hide api to show user preference column

  seal_verification_columns;
  onShowHideChange(ev: any) {
    this.removeMessage();
    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...this.showHideList.map((e) => e.value)], true);

    const hideItems = this.showHideList.filter((item) => {
      return !ev.value.map((sItem) => sItem.value).includes(item.value);
    });

    this.osPartsGrid.gridOptions.columnApi.setColumnsVisible([...hideItems.map((e) => e.value)], false);

    if (ev.onInit) {
      return;
    }

    this.seal_verification_columns = hideItems;
    if (!hideItems.length) {
      this.seal_verification_columns = [{}];
    }
  }

  checkIfAnySelected() {
    if (this.kanbanSearchValue) {
      return true;
    }
    if (this.renbanSearchValue) {
      return true;
    }

    if (
      this.selectedKanban.length > 0 ||
      this.selectedVesselName.length > 0 ||
      this.selectedShippingCarrier.length > 0 ||
      this.selectedrenban.length > 0 ||
      this.estDate
    ) {
      return true;
    }

    return false;
  }

  clearKanbanValue() {
    this.kanbanSearchValue = null;
  }
  clearRenbanValue() {
    this.renbanSearchValue = null;
  }

  onShippingCarrierChange(): void {
    // diable est date dropdown and enable vessel name dropdown when shipping career is selected.

    if (this.selectedShippingCarrier.length > 0) {
      this.disableEst = true;
      this.disableVesselName = false;
    } else {
      this.disableEst = false;
      this.disableVesselName = true;
    }

    this.vesselNameList = [];
    this.selectedVesselName = [];

    if (this.selectedShippingCarrier.length >= 1) {
      if (this.selectedShippingCarrier[this.selectedShippingCarrier.length - 1].vessel_company === 'ALL') {
        this.selectedShippingCarrier = [];
        this.vesselSerachValue = null;
        this.selectedShippingCarrier.push(this.shipping_carrierList[0]);

        this.vesselNameList = this.vesselNameList1;
      } else {
        this.vesselSerachValue = null;
        const indexAll = this.selectedShippingCarrier.findIndex((data) => data.vessel_company === 'ALL');

        if (indexAll > -1) {
          this.selectedShippingCarrier.splice(indexAll, 1);
        }
        this.selectedShippingCarrier.forEach((element) => {
          const vesselName = this.vesselNameList1.filter((x) => {
            return x.vessel_company === element.vessel_company;
          });
          this.vesselNameList.push(...vesselName);
        });
      }
      this.vesselNameList = this.vesselNameList.filter(
        (value, index, self) => index === self.findIndex((t) => t.vessel_name === value.vessel_name)
      );
  
    }


  }

  onVesselNameChange(): void {
    if (this.selectedVesselName.length >= 1) {
      if (this.selectedVesselName[this.selectedVesselName.length - 1].vessel_name === 'ALL') {
        this.selectedVesselName = [];
        this.selectedVesselName.push(this.vesselNameList[0]);
      } else {
        const indexAll = this.selectedVesselName.findIndex((data) => data.vessel_name === 'ALL');

        if (indexAll > -1) {
          this.selectedVesselName.splice(indexAll, 1);
        }
      }
    }
  }

  toggleHeaderCheckbox(checked) {
    if (!checked) {
      this.headerCheckboxChecked = false;

      this.osPartsGrid.gridOptions.api.refreshHeader();

      return;
    }

    const rowCount = this.osPartsGrid.gridOptions.rowData.length;
    const selectedRowCount = this.osPartsGrid.gridOptions.rowData.filter((x) => x.rowCheckBox === true).length;

    if (rowCount === selectedRowCount) {
      this.headerCheckboxChecked = true;

      this.osPartsGrid.gridOptions.api.refreshHeader();
    }
  }
  userCheckChanged(checked, rowIndex) {
    if (
      this.warningMessage === 'Please select at least one row to edit.' ||
      this.warningMessage === 'No rows are selected.'
    ) {
      this.warningMessage = '';
    }
    this.spinner.show();

    const rowNode = this.osPartsGrid.gridOptions.api.getDisplayedRowAtIndex(rowIndex);

    if (checked === true) {
      rowNode.setSelected(true);
      if (this.isEditMode) {
        rowNode.data.checkedEditMode = true;
      }
      rowNode.data.rowCheckBox = true;
    } else {
      rowNode.setSelected(false);
      rowNode.data.checkedEditMode = false;
      rowNode.data.rowCheckBox = false;
    }

    this.gridOptions.getRowStyle = function (params) {
      if (params.node.rowIndex === rowIndex) {
        const color = checked ? '#E4ECF0' : 'white';
        return { background: color };
      }
      return { background: 'white' };
    };

    rowNode.setData(rowNode.data);
    this.toggleHeaderCheckbox(checked);
    this.spinner.hide();
  }
  headerCheckChanged(event) {
    this.headerCheckboxChecked = event.checked;
    const headerChecked = event.checked;
    const isEditable = this.isEditMode;
    this.osPartsGrid.gridOptions.api.forEachNode(function (rowNode, index) {
      if (headerChecked) {
        if (isEditable) {
          rowNode.data.checkedEditMode = true;
        }
        rowNode.data.rowCheckBox = true;
      } else {
        rowNode.data.rowCheckBox = false;
        rowNode.data.checkedEditMode = false;
      }
      rowNode.setData(rowNode.data);
    });
  }

  onSearch(offset) {
    if (this.osPartsGrid) {
      this.headerCheckboxChecked = false;
      this.osPartsGrid.gridOptions.api.refreshHeader();

      this.resetGrid();
    }

    this.removeMessage();
    this.editEnabled = false;
    this.isEditMode = false;

    if (offset === 0) {
      this.setPageSizeToAll(this.defaultPageSize);
      this.rowData = [];
      this.osPartsGrid.gridOptions.api.setRowData(this.rowData);

      this.offset = 0;
      this.spinner.show();
    }

    if (!this.checkIfAnySelected()) {
      this.rowData = [];
      this.warningMessage = warningMessage.selectToSearch;

      window.scroll(0, 0);
      this.spinner.hide();
      return false;
    }

    let selectedVesselName;
    let selectedShippingCarrier;

    if (this.selectedVesselName.length > 0) {
      if (!this.selectedVesselName.some((a) => a.vessel_name === 'ALL')) {
        selectedVesselName = [];
        this.selectedVesselName.forEach((element) => {
          selectedVesselName.push(element.vessel_name);
        });
      }
    }

    if (this.selectedShippingCarrier.length > 0) {
      if (!this.selectedShippingCarrier.some((a) => a.vessel_company === 'ALL')) {
        selectedShippingCarrier = [];
        this.selectedShippingCarrier.forEach((element) => {
          selectedShippingCarrier.push(element.vessel_company);
        });
      }
    }

    const data = {
      offset: offset,
      limit: 8000,
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      vessel_name: selectedVesselName,
      vessel_company: selectedShippingCarrier,

      estimated_namc_arrival: this.estDate,
    };

    this.sealVerificationService.getSealVerificationData(data).subscribe({
      error: this.errorCallback,
      next: (res) => {
        if (res.body && res.body.data) {
          this.rowData.push(...res.body.data.data);
          this.osPartsGrid.gridOptions.api.setRowData(this.rowData);
          this.dataCount = parseInt(res.body.data.rowCount);
          const records = this.dataCount - (this.offset + 1) * 8000;
          if (records >= 1) {
            this.offset++;
            this.onSearch(this.offset * 8000);
          } else {
            this.spinner.hide();
          }
        } else {
          this.warningMessage = 'No NAMC ARRIVED records.';
          this.rowData = [];
          this.spinner.hide();
        }
        this.spinner.hide();
        this.setPageSizeToAll();
      },
    });
    return true;
  }

  saveShowHide() {
    this.spinner.show();

    const seal_verification_columns = this.seal_verification_columns;
    const data = {
      business_entity: this.namcValue,
      business_entity_name: this.namcName,
      workid: this.user,
      user_role: this.userRole,
    };

    if (seal_verification_columns) {
      this.pipeLookupService.updateUserShowHideColumns(data, { seal_verification_columns }).subscribe({
        error: this.errorCallback,
        next: (res) => {
          console.log(res);
          this.spinner.hide();
        },
      });
    } else {
      this.spinner.hide();
    }
  }

  estChange() {
    console.log('estchange');

    if (this.estDate) {
      this.disableShippingCarrier = true;
      this.disableVesselName = false;
      this.callVesselNameApi();
      this.vesselSerachValue = null;
    } else {
      this.disableVesselName = true;
      this.disableShippingCarrier = false;
      this.selectedVesselName = [];
      this.vesselSerachValue = null;
    }
  }

  callVesselNameApi() {
    const data = {
      business_entity: this.namcValue,
      workid: this.user,
      user_role: this.userRole,
      estimated_namc_arrival: this.estDate,
    };

    this.selectedVesselName = [];

    this.pipeLookupService.getVesselNameList(data).subscribe((res) => {
      this.vesselNameList = res.body.data;
    });
  }
  match() {
    console.log('matched');
    this.removeMessage();
    let data = [];
    data = this.getModifiedRowData2();
    console.log('data-----match', data);

    if (data.length === 0) {
      this.warningMessage = 'Please select at least one row to verify';
      window.scroll(0, 0);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = this.modalComponent;
      dialogConfig.data = data;
      // dialogConfig.height = '204px';
      dialogConfig.width = '470px';
  

      const dialogRef = this.dialog.open(matchMismatchDialog, dialogConfig);
      dialogRef.afterClosed().subscribe((res) => {
        // this.spinner.show();
        console.log('res', res);

        if (res === 'cancel') {
          return;
        } 
        else if (res === 'matched') {
          var updatedData = [];
          data.forEach((e) => {
            if (!e.customs_seal_status) {
              e.customs_seal_status = '';
            }
            if (!e.customs_seal_number_user_modified) {
              e.customs_seal_number_user_modified = '';
            }

            var updatedFields = {
              customs_seal_number_user_modified: e.customs_seal_number_user_modified ?.toUpperCase(),
              vessel_company: e.vessel_company,
              vessel_name: e.vessel_name,
              container_renban: e.container_renban,
              customs_seal_number: e.customs_seal_number,
              customs_seal_status: e.customs_seal_status,
              userid: localStorage.getItem('UserName'),
              userRole:this.userRole_passtoapi,
              matchMismatch:`Verified as matched`
            };
            updatedData.push(updatedFields);
          });
          window.scroll(0, 0);

          this.spinner.show();
          this.editSealdata(updatedData);
          this.successMessage = 'Success! User verified as match';


        }
        else if (res === 'mismatched') {
          var updatedData = [];
          data.forEach((e) => {
            if (!e.customs_seal_status) {
              e.customs_seal_status = '';
            }
            if (!e.customs_seal_number_user_modified) {
              e.customs_seal_number_user_modified = '';
            }

            var updatedFields = {
              customs_seal_number_user_modified: e.customs_seal_number_user_modified ?.toUpperCase(),
              vessel_company: e.vessel_company,
              vessel_name: e.vessel_name,
              container_renban: e.container_renban,
              customs_seal_number: e.customs_seal_number,
              customs_seal_status: e.customs_seal_status,
              userid: localStorage.getItem('UserName'),
              userRole:this.userRole_passtoapi,
              matchMismatch:`Verified as mismatched`
            };
            updatedData.push(updatedFields);

          });

          this.spinner.show();
          this.editSealdata(updatedData);
          this.successMessage = 'User verified as Mismatch';

        }
      });
    }
  }
}
@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'matchMismatchConfirmation.component.html',
  styleUrls: ['../core/master-delete/master-delete.component.scss'],
})
export class matchMismatchDialog {
  constructor(public dialogRef: MatDialogRef<matchMismatchDialog>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}